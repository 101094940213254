import React, { useEffect } from 'react';
import { selectPage, selectPageById, setIsEditingPage } from 'app/reducers/pageSlice';
import { useAppDispatch, useAppSelector } from 'app/store';
import { TritronikRouteComponentProps } from 'app/types/navigation';
import DashboardGrid from '../components/DashboardGrid';
import { Container } from 'reactstrap';
import { locationService } from 'app/services/LocationService';
import { useGetPanelsQuery } from 'app/services/PanelService';
import { usePermission } from 'app/hooks/usePermission';
import AccessForbidden from 'app/components/AccessForbidden';
import PageHeader from 'app/components/PageHeader/PageHeader';
import { useUpdateGridPageMutation } from 'app/services/PageService';
import { setIsEditing } from 'app/reducers/updateGridSlice';

export interface DashboardPageRouteParams {
	uid?: string;
	type?: string;
	slug?: string;
}

export type DashboardPageRouteSearchParams = {
	beginTs?: number;
	endTs?: number;
	refresh?: string;
};

export interface Props extends TritronikRouteComponentProps<DashboardPageRouteParams, DashboardPageRouteSearchParams> {}

const DashboardPage = (props: Props) => {
	const isEditing = useAppSelector(state => state.updateGrid.isEditing)
	const { match } = props;
	const pageId: any = match.params.uid;
	const { allowed: isAllowedTo } = usePermission();

	const defaultPage = useAppSelector((state) => state.page.defaultPage);
	const updatedGrid = useAppSelector((state) => state.updateGrid.updatedGrid);
	const currentPage = useAppSelector((state) => selectPageById(state, pageId));

	const { data: panels } = useGetPanelsQuery({ pageId: pageId }, { skip: !pageId });

	const [updateGridPage] = useUpdateGridPageMutation();

	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(selectPage(parseInt(pageId)));
	}, [dispatch, pageId]);

	useEffect(() => {
		const location = locationService.getLocation();

		if (location.pathname === '/' && defaultPage !== null && defaultPage !== '') {
			locationService.replace(defaultPage);
		}

		dispatch(setIsEditingPage(false))

	}, [defaultPage, dispatch]);

	if (currentPage && currentPage.permission !== null && !isAllowedTo(currentPage.permission)) {
		return <AccessForbidden />;
	}

	const handleSave = () => {
		if (isEditing) {
			dispatch(setIsEditing(!isEditing));
			const newArray = updatedGrid.map((item) => {
				const newObj = {
					i: item.i,
					x: item.x,
					y: item.y,
					w: item.w,
					h: item.h,
				};
				return {
					gridLayoutConfig: JSON.stringify(newObj),
					panelId: item.i,
				};
			});
			updateGridPage({ pageId, body: newArray });
		}
	};

	return (
		<>
			<div>
				<PageHeader match={match} handleSave={handleSave}  />
			</div>
			<Container fluid style={{ marginBottom: 20 }}>
				<DashboardGrid isEditing={isEditing} panels={panels} pageId={pageId} />
			</Container>
		</>
	);
};

export default DashboardPage;
