import { API_URL } from './../../../../config/config';
import Axios, { AxiosInstance } from 'axios';
import { AccountConfiguration, OptionItem } from '../types';
import keycloak from '../../../../keycloak';

export default class TenantService {
	api: AxiosInstance;

	constructor() {
		this.api = Axios.create({
			baseURL: API_URL,
			headers: {
				Accept: 'application/json',
			},
		});

		this.api.interceptors.request.use(
			async function (config) {
				const token = keycloak.token;
				// const token = await localStorage.getItem('token')
				config.headers.Authorization = 'Bearer' + token;
				return config;
			},
			function (error) {
				return Promise.reject(error);
			},
		);
	}

	async getAll(params: any = {}) {
		const response = await this.api.get('/accountRules/', { params });
		return response.data;
	}

	async getOne(id: number | string) {
		const response = await this.api.get(`/accountRules/${id}`);
		return response.data;
	}

	async update(data: AccountConfiguration) {
		const { id, ...updateData } = data;
		const response = await this.api.put(`/accountRules/${id}`, updateData);
		return response.data;
	}

	async create(body: AccountConfiguration) {
		const response = await this.api.post('/accountRules/', body);
		return response.data;
	}

	async delete(id: number | string) {
		const response = await this.api.delete(`/accountRules/${id}`);
		return response.data;
	}

	getApplicationOption(): OptionItem[] {
		return [{ value: 'SMART_SITE', label: 'SMART_SITE' }];
	}

	getRoleOption(): OptionItem[] {
		return [
			{ value: 'ADMIN', label: 'ADMIN' },
			{ value: 'USER', label: 'USER' },
		];
	}

	getTypeOption(): OptionItem[] {
		return [{ value: 'API', label: 'API' }];
	}
}

let tenantService = new TenantService();
export { tenantService };
