import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { calculateFontSize, getColor } from 'app/utils';
import { PanelProps } from 'app/types/Panel';
import { ThresholdsMode } from 'app/types';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import LoadingPage from 'app/components/LoadingPage';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';

interface GaugeAutoProps {
	titleFontSize: number;
	gaugeHeight: number;
	showLabel: boolean;
}

function calculateGaugeAutoProps(width: number, height: number, title: string | undefined): GaugeAutoProps {
	const showLabel = title !== null && title !== undefined;
	const titleFontSize = Math.min((width * 0.15) / 1.5, 20); // 20% of height * line-height, max 40px
	const titleHeight = titleFontSize * 1.5;
	const availableHeight = showLabel ? height - titleHeight : height;
	const gaugeHeight = Math.min(availableHeight, width);

	return {
		showLabel,
		gaugeHeight,
		titleFontSize,
	};
}

export interface Props extends PanelProps {
	showThresholdMarkers: boolean;
	showThresholdLabels: boolean;
}

const TritronikGauge = (props: Props) => {
	const { width, height, showThresholdLabels, showThresholdMarkers, fieldConfig, panel, refreshInterval } = props;

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const options = useMemo(() => {
		let min = fieldConfig?.defaults?.min || 0;
		let max = fieldConfig?.defaults?.max || 100;
		const decimals = fieldConfig && fieldConfig.defaults && fieldConfig.defaults.decimals ? fieldConfig.defaults.decimals : 2;

		const value =
			data && data.responseDataValue && data.responseDataValue.length > 0
				? data.responseDataValue[0].datapoints[0].value
				: 'NaN';

		const unit = fieldConfig?.defaults?.unit || 'none';

		const valueFormatted = getValueFormat(unit)(value, decimals);

		const autoProps = calculateGaugeAutoProps(width, height, undefined);
		const dimension = Math.min(width, autoProps.gaugeHeight);

		const fontSize = calculateFontSize(
			formattedValueToString(valueFormatted),
			dimension * 0.25,
			dimension * 0.25,
			1
		);

		const thresholds =
			fieldConfig?.defaults?.thresholds || TritronikGauge.defaultProps.fieldConfig.defaults.thresholds;
		const steps = thresholds.steps;

		if (showThresholdMarkers) {
			min = +min.toFixed(decimals);
			max = +max.toFixed(decimals);
		}

		const progressWidth = dimension * 0.095;
		const thresholdWidth = progressWidth * 0.25;

		const series: any = [
			{
				type: 'gauge',
				center: ['50%', '50%'],
				startAngle: 200,
				endAngle: -20,
				min: min,
				max: max,
				itemStyle: {
					color: getColor(value, steps),
				},
				progress: {
					show: true,
					width: progressWidth,
				},
				pointer: {
					show: false,
				},
				axisLine: {
					lineStyle: {
						width: progressWidth,
					},
				},
				axisTick: {
					show: false,
				},
				splitLine: {
					show: false,
				},
				axisLabel: {
					show: showThresholdLabels,
					distance: -40,
					color: '#999',
					fontSize: 12,
				},
				anchor: {
					show: false,
				},
				title: {
					show: false,
				},
				detail: {
					valueAnimation: true,
					borderRadius: 8,
					offsetCenter: [0, '50%'],
					fontSize: fontSize,
					fontWeight: 'bolder',
					formatter: `{value}${valueFormatted.suffix || ''}`,
					color: 'inherit',
				},
				data: [
					{
						value: valueFormatted.text,
					},
				],
			},
		];

		if (showThresholdMarkers) {
			series.push({
				type: 'gauge',
				center: ['50%', '50%'],
				startAngle: 200,
				endAngle: -20,
				min: min,
				max: max,
				itemStyle: {
					color: getColor(value, steps),
				},
				progress: {
					show: true,
					width: thresholdWidth,
				},
				pointer: {
					show: false,
				},
				axisLine: {
					show: false,
				},
				axisTick: {
					show: false,
				},
				splitLine: {
					show: false,
				},
				axisLabel: {
					show: false,
				},
				detail: {
					show: false,
				},
				data: [
					{
						value: max,
					},
				],
			});
		}

		return { series };
	}, [showThresholdMarkers, showThresholdLabels, data, width, height, fieldConfig]);

	if (isLoading) {
		return <LoadingPage />;
	}

	return <ReactEcharts style={{ width: '100%', height: '100%' }} option={options} />;
};

TritronikGauge.defaultProps = {
	showThresholdMarkers: true,
	showThresholdLabels: false,
	fieldConfig: {
		defaults: {
			min: 0,
			max: 100,
			thresholds: {
				mode: ThresholdsMode.Absolute,
				steps: [
					{ value: -Infinity, color: 'green' },
					{ value: 80, color: 'red' },
				],
			},
		},
	},
};

export default TritronikGauge;
