import { setIsEditingPage } from 'app/reducers/pageSlice';
import { useAppDispatch } from 'app/store';
import { FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import UserRuleForm from '../components/UserRuleForm';
import { useCreateRoleAssignment } from '../hooks/useCretaRoleAssignment';
import { UserConfiguration } from '../types';
import { locationService } from './../../../services/LocationService';

export interface UserRuleFormProps {}

const CreateUserPage = () => {

	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(setIsEditingPage(false))
	},[dispatch])

	const { createRoleAssignment, isLoading } = useCreateRoleAssignment();

	const history = locationService.getHistory();

	const initialValues: UserConfiguration = {
		id: 0,
		password: '',
		username: '',
		userInfo: {
			email: '',
			id: 0,
			name: '',
			phoneNumber: '',
			telegramUserId: '',
		},
		roleNames: [],
	};

	const onSubmit = (values: any, formik: FormikHelpers<UserConfiguration>): void => {
		createRoleAssignment(values).then(() => {
			history.push({
				pathname: '/user-management',
			});
		});
	};

	return (
		<Container fluid>
			<h2 className="my-3">API Management</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/user-management/">User Management</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>Add New User</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<UserRuleForm initialValues={initialValues} onSubmit={onSubmit} isSubmitting={isLoading} />
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default CreateUserPage;
