import { useCallback } from 'react';
import { useCreateUserConfigurationMutation, useSetUserRoleMutation } from '../services/userApi';

export function useCreateRoleAssignment() {
	const [createNewRole, { isLoading: creatingRoleAssignment }] = useCreateUserConfigurationMutation();
	const [setRole, { isLoading: getAssignment }] = useSetUserRoleMutation();

	const createRoleAssignment = useCallback(
		async ({ roleNames, role, ...data }: any) => {
			// temporary any type!
			try {
				const newUser = await createNewRole(data).unwrap();
				if (newUser) {
					// console.log('new', newUser.id);
					await setRole({ roleIds: roleNames, userId: newUser.id }).unwrap();
				}
			} catch (error) {
				console.error(error);
			}
		},
		[createNewRole, setRole],
	);
	return {
		createRoleAssignment,
		isLoading: creatingRoleAssignment || getAssignment,
	};
}
