import { PanelProps, ResponseData } from 'app/types/Panel'
import React, { useMemo } from 'react'
import ReactEcharts from 'echarts-for-react';
import LoadingPage from 'app/components/LoadingPage';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { css } from '@emotion/css';
import * as echarts from 'echarts';
import _ from 'lodash';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';

export interface DataMap {
	name: string;
	color: string;
}

interface MappingType {
	dataOptions: Record<string, any>[];
}

export interface ITritronikProfileOptions {
	chartOptions: any;
	mappings: MappingType;
}

export interface ITritronikTrendPanel extends PanelProps<ITritronikProfileOptions> { }

const defaultDataMapping = [
	{ name: 'running', options: { itemStyle: { color: '#97C33C' } } },
	{ name: 'iddle', options: { itemStyle: { color: '#172B4D' } } },
	{ name: 'stoppage', options: { itemStyle: { color: '#E83830' } } }
];

const TritronikTrendPanel = (props: ITritronikTrendPanel) => {
	const { width, height, panel, refreshInterval, options } = props;
	const params = usePanelDataParams();
	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const styles = getStyles(width, height);


	const chartOptions = useMemo(() => {
		const rawData: ResponseData[] = data && data.responseDataValue && Array.isArray(data.responseDataValue) ? data.responseDataValue : [];
		// get categories from options or set default categories from data
		let categories: string[] = [];
		if (options && options.chartOptions && options.chartOptions.yAxis && options.chartOptions.yAxis.data) {
			// for now only object axis is acceptable
			categories = options.chartOptions.yAxis.data;
		} else {
			// if no options provided, take categories from data
			categories = _.map(_.uniqBy(rawData, 'category'), 'category');
		}

		// sort data by date time
		const sortedData = _.sortBy(rawData, [function (d) { return d.begin }]);

		// get the startTime
		const startTime = _.minBy(sortedData, ['begin'])?.begin || new Date().getTime();

		const getData = () => {
			const dataMapping = options?.mappings?.dataOptions || defaultDataMapping
			return rawData.map((d) => {
				const dataOptionsIndex = dataMapping && dataMapping?.findIndex(t => t.name === d.status);
				const categoryIndex = categories?.findIndex(c => c === d.category);
				const dataOptions = dataOptionsIndex > -1 ? dataMapping[dataOptionsIndex] : {}

				return {
					name: d.status,
					value: [categoryIndex, d.begin, d.end, d.duration],
					itemStyle: {
						color: 'green'
					},
					...dataOptions.options,
					shortDescription: d.shortDescription
				}
			})
		}

		const HEIGHT_PROPORTION: number = categories.length > 1 ? 0.6 : 1;


		function renderItem(params: any, api: any) {
			var categoryIndex = api.value(0);
			var start = api.coord([api.value(1), categoryIndex]);
			var end = api.coord([api.value(2), categoryIndex]);
			var height = api.size([0, 1])[1] * HEIGHT_PROPORTION;
			var rectShape = echarts.graphic.clipRectByRect(
				{
					x: start[0],
					y: start[1] - height / 2,
					width: end[0] - start[0],
					height: height
				},
				{
					x: params.coordSys.x,
					y: params.coordSys.y,
					width: params.coordSys.width,
					height: params.coordSys.height
				}
			);
			return (
				rectShape && {
					type: 'rect',
					transition: ['shape'],
					shape: rectShape,
					style: api.style()
				}
			);
		}

		const tickFormat = (value: any, decimals: number = 0, format: string = 'none') => {
			return formattedValueToString(getValueFormat(format)(value, decimals));
		}

		const tooltipOptions: any = options?.chartOptions?.tooltip || { format: 'ms', decimals: 0 }

		const defaultOptions = {
			tooltip: {
				formatter: function (params) {
					return [
						params.marker + _.capitalize(params.name) + ': ' + tickFormat(params.value[3], tooltipOptions?.decimals, tooltipOptions?.format),
						params.data?.shortDescription,
					].join(params.data?.shortDescription ? '<br/><br/>' : '');
				}
			},
			dataZoom: [
				{
					type: 'inside',
					filterMode: 'weakFilter'
				}
			],
			grid: {
				top: 0,
				left: 0,
				right: 0
			},
			xAxis: {
				min: startTime,
				scale: true,
				axisLabel: {
					formatter: {
						year: '{yyyy}',
						month: '{MMM}',
						day: '{d}',
						hour: '{HH}:{mm}',
						minute: '{HH}:{mm}',
						second: '{HH}:{mm}:{ss}',
						millisecond: '{hh}:{mm}:{ss}',
						none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss}'
					},
					showMinLabel: true,
				},
				type: 'time',
			},
			yAxis: {
				data: categories,
				axisLabel: {
					show: true,
					rotate: 90,
					formatter: (params: any) => {
						return _.capitalize(params)
					}
				}
			},
			series: [
				{
					type: 'custom',
					renderItem: renderItem,
					itemStyle: {
						opacity: 0.8
					},
					encode: {
						x: [1, 2],
						y: 0
					},
					data: getData()
				}
			]
		}

		return _.merge({}, defaultOptions, options.chartOptions || {});
	}, [options, data]);
	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<ReactEcharts style={{ width: '100%', height: '100%', borderWidth: 2 }} option={chartOptions} />
			</div>
		</div>
	)

}
export default TritronikTrendPanel

const getStyles = (width: number, height: number) => ({
	wrapper: css`
		width: 100%;
		height: 100%;
		padding-left: .75rem;
		padding-right: .75rem;
		padding-bottom: .75rem;
	`,
	container: css`
		border: 1px solid #c9c9c9;
		width: 100%;
		height: 100%;
	`
})
