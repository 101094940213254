// Parent Component Form.
// Child (FormRuleInfo & SensorFormConfirm)

import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useState } from 'react';
import { SensorConfiguration } from '../types';
import { validationSensor } from '../ValidationSchema';
import SensorFormConfirm from './SensorFormConfirm';
import FormRuleInfo from './FormRuleInfo';
import { Row, Col } from 'reactstrap';
import { PrimeSteps } from 'components';
import { Button } from 'primereact/button';
import { history } from 'utils';

export interface SensorRuleFormProps {
	initialValues: SensorConfiguration;
	onSubmit: (values: SensorConfiguration, formik: FormikHelpers<SensorConfiguration>) => void;
	isSubmitting?: boolean;
}

const formSteps = [{ label: 'ASSET INFORMATION' }, { label: 'CONFIRMATION' }];

const SensorRuleInfo = ({ initialValues, onSubmit, isSubmitting }: SensorRuleFormProps) => {
	// step comfrim
	const [activeStep, setActiveStep] = useState(0);
	const isLastStep = activeStep === formSteps.length - 1;
	const currentValidation = validationSensor[activeStep]; // validation schema

	const [, setType] = useState('NUMERIC');

	const nextStep = () => {
		if (!isLastStep) {
			setActiveStep((currentStep) => currentStep + 1);
		}
	};

	const prevStep = () => {
		if (activeStep > 0) {
			setActiveStep((currentStep) => currentStep - 1);
		}
	};

	const _onSubmit = (values: SensorConfiguration, formik: FormikHelpers<SensorConfiguration>) => {
		const { entityTags, ...sensor } = values;

		let createTag = entityTags?.map((el) => {
			return {
				entityId: '',
				entityName: '',
				id: '',
				tagName: el,
			};
		});

		// console.log(createTag, 'tag');

		const sensorConfigForm: SensorConfiguration = {
			...sensor,
			id: sensor.id,
			entityTags: createTag,
		};

		// console.log('apa?', sensorConfigForm);
		onSubmit(sensorConfigForm, formik);

		if (isLastStep) {
			history.push('/sensor-property');
		} else {
			nextStep();
		}
	};

	const _handleSubmit = (values: any, formik: FormikHelpers<SensorConfiguration>): void => {
		if (isLastStep) {
			// isLastStep = true => isSubmitting = true
			_onSubmit(values, formik);
		} else {
			nextStep();
			formik.setTouched({}); // reset touched
			formik.setSubmitting(false); // reset submitting
		}
	};

	const _renderForm = useCallback((step: number, formik: FormikProps<SensorConfiguration>) => {
		const onTypeChange = (value: string) => {
			setType(value);
		};

		switch (step) {
			case 1:
				// case 1 when is last step => render form confirm
				return <SensorFormConfirm formik={formik} />;
			default:
				return <FormRuleInfo formik={formik} onTypeChange={onTypeChange} />;
		}
	}, []);

	return (
		<Formik initialValues={initialValues} onSubmit={_handleSubmit} validationSchema={currentValidation}>
			{(formik: FormikProps<SensorConfiguration>) => (
				<Form>
					<Row className="mt-3">
						<Col>
							<PrimeSteps model={formSteps} activeIndex={activeStep} />
						</Col>
					</Row>
					<Row className="mt-3">
						<Col>{_renderForm(activeStep, formik)}</Col>
					</Row>
					<Row className="mt-3">
						<Col className="text-right">
							<Button
								label="Cancel"
								type="button"
								className="mr-2 p-button-outlined"
								onClick={() => history.goBack()}
							/>
							{activeStep > 0 && (
								<Button
									type="button"
									label="Previous"
									onClick={prevStep}
									className="mr-2 p-button-outlined"
								/>
							)}
							<Button
								label={isLastStep ? 'Apply' : 'Next'}
								loading={isSubmitting}
								disabled={isSubmitting}
								type="submit"
								className=""
							/>
						</Col>
					</Row>
				</Form>
			)}
		</Formik>
	);
};

export const defaultProps: SensorRuleFormProps = {
	initialValues: {
		id: '',
		name: '',
		description: '',
		sensorConfig: '',
		sensorName: '',
		sensorParam: '',
		subparamName: '',
		entityTags: [
			{
				id: '',
				entityId: '',
				entityName: '',
				tagName: '',
			},
		],

		type: '',
	},
	onSubmit: () => {},
	isSubmitting: false,
};

SensorRuleInfo.defaultProps = defaultProps;

export default SensorRuleInfo;
