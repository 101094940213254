import { Project } from 'app/types';
import { FormOverview } from 'components';
import { FormikProps } from 'formik';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Settings } from './ProjectConfig';

type Props = {
	formik: FormikProps<Project<Settings>>;
};

const ConfigureConfirmation: React.FC<Props> = ({ formik }) => {
	return (
		<Row className="d-flex justify-content-center">
			<Col className="offset-md-2">
				<Card>
					<CardBody>
						<FormOverview label="Project Name" value={formik.values.name} stripped />
						<FormOverview label="Project Title" value={formik.values.settings.pageTitle} />
						<FormOverview label="Dashboard Icon" value={formik.values.settings.brandLogoUrl} />
						<FormOverview label="Favicon" value={formik.values.settings.favicon} stripped />
						<FormOverview label="Project Description" value={formik.values.description} />
					</CardBody>
				</Card>
			</Col>
			<Col md={2}></Col>
		</Row>
	);
};

export default ConfigureConfirmation;
