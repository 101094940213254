import React, { CSSProperties, PropsWithChildren } from 'react'
import classNames from 'classnames'

export interface FlexRowProps {
  stripped?: boolean;
  className?: string;
}

const FlexRow = (props: FlexRowProps & PropsWithChildren<unknown>) => {
  const { className, stripped, children } = props;

  const getStyles = (): CSSProperties | undefined => {
    return {
      backgroundColor: stripped ? "#F7FAFC" : "#FFFFFF",
      padding: 16
    } as CSSProperties
  }
  return (
    <div className={classNames('d-flex justify-content-between', className)} style={getStyles()}>
      {children}
    </div>
  )
}

export default FlexRow
