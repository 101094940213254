import React, { useCallback } from 'react';
import { css } from '@emotion/css';
import LoadingPage from 'app/components/LoadingPage';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { PanelProps } from 'app/types/Panel';
import * as L from 'leaflet';
import 'leaflet-search';
import { MapContainer, TileLayer } from 'react-leaflet';
import '../../../../node_modules/leaflet-search/dist/leaflet-search.src.css';
import * as styles from './../../utils/styles';
import { locationService } from 'app/services/LocationService';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';


interface QueryParamFilter {
	name: string;
	value: string;
	source: string;
}

interface MapPanelOptions {
	query?: QueryParamFilter[];
}

export interface Props extends PanelProps<MapPanelOptions> {}

const TritronikMapPanel = (props: Props) => {
	const { panel, fieldConfig, refreshInterval, options } = props;

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const defaultPosition: L.LatLngTuple = [-6.2, 106.816666]; // Jakarta

	const generateIcon = (imgName: string): L.Icon<L.IconOptions> => {
		return L.icon({
			iconUrl: `/img/${imgName}`,
			shadowUrl: `/img/marker-shadow.png`,
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [1, -34],
			tooltipAnchor: [1, -28],
			shadowSize: [41, 41],
		});
	};

	const icons: any = {
		CRITICAL: generateIcon('critical-icon.png'),
		MAJOR: generateIcon('major-icon.png'),
		LOW_AVAILABILITY: generateIcon('major-icon.png'),
		ABNORMAL: generateIcon('minor-icon.png'),
		MINOR: generateIcon('minor-icon.png'),
		NORMAL: generateIcon('marker-icon.png'),
		OFFLINE: generateIcon('offline-icon.png'),
		NOT_INSTALLED: generateIcon('not-installed-icon.png'),
		DEFAULT: generateIcon('not-installed-icon.png'),
	};

	const buildQuery = useCallback((query: QueryParamFilter[], data: any = {}) => {
		const q = locationService.getSearch();

		if (query && query.length > 0) {
			query.forEach((f) => {
				switch (f.source) {
					case 'dataset':
						const value = data[f.value];
						if (value) {
							q.set(f.name, value);
						}
						break;
					default:
						q.set(f.name, f.value);
						break;
				}
			});
		}

		return q.toString();
	}, []);

	const sitesLeyers = React.useMemo(() => {
		if (data && data.responseDataValue && Array.isArray(data.responseDataValue) && data.responseDataValue.length > 0) {
			const markers = data.responseDataValue.map((map) => {
				const { latitude, longitude, name, status, totalAlert } = map;

				const position = L.latLng(latitude, longitude);
				const marker = L.marker(position, { icon: icons[status] || icons['DEFAULT'], alt: name });
				const badge =
					status !== 'NORMAL' && totalAlert !== 0
						? `<span class="badge ${styles.statusToBackgroundColor(status)}">${totalAlert}</span>&nbsp;`
						: '';
				const tooltip = `<div style="display: flex; align-items: center;">${badge}${name}</div>`;

				marker.bindTooltip(tooltip, { direction: 'top' });
				if (fieldConfig.urlTemplate) {
					marker.on('click', () => {
						const url = new URL(fieldConfig.urlTemplate);
						const queryString = options.query && options.query.length > 0 ? buildQuery(options.query, map) : '';

						locationService.push({
							pathname: url.pathname,
							search: queryString,
						});
					});
				}
				return marker;
			});
			return markers;
		}
		return [];
	}, [data, icons, fieldConfig, buildQuery, options.query]);

	if (isLoading) {
		return <LoadingPage />;
	}

	const onMapCreated = (map: L.Map) => {
		let zoom = map.getZoom() + 4;
		zoom = zoom > 12 ? map.getZoom() : zoom;

		const lastGroup = L.featureGroup(sitesLeyers);
		lastGroup.addTo(map);
		map.fitBounds(lastGroup.getBounds(), { padding: [50, 50] });

		// @ts-ignore
		const controlSearch = new L.Control.Search({
			position: 'topright',
			initial: false,
			layer: lastGroup,
			zoom,
			marker: false,
			propertyName: 'alt',
		});
		controlSearch.on('search:locationfound', ({ layer }: { layer: L.Marker }) => layer.fire('mouseover'));
		map.addControl(controlSearch);
	};

	const zoomSearch = css`
		.leaflet-control-search {
			zoom: 1.4;
		}
	`;

	return (
		<MapContainer
			id="tritronik-map"
			className={`${styles.trStyleFull} ${zoomSearch}`}
			center={defaultPosition}
			zoom={5}
			whenCreated={onMapCreated}
		>
			<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></TileLayer>
		</MapContainer>
	);
};

export default TritronikMapPanel;
