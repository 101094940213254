/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { PropsWithChildren, useState } from 'react';
// react library for routing
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from 'classnames';
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from 'react-perfect-scrollbar';
// reactstrap components
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav } from 'reactstrap';
import { IRouteProps } from 'app/types';

export interface SidebarLogoProps {
	innerLink?: string;
	// outterLink is for links that will direct the user outside the app
	// it will be rendered as simple <a href="...">...</a> tag
	outterLink?: string;
	// the image src of the logo
	imgSrc: string;
	// the alt for the img
	imgAlt: string;
}

export interface SidebarProps extends PropsWithChildren<any> {
	// function used to make sidenav mini or normal
	toggleSidenav: () => void;
	// prop to know if the sidenav is mini or normal
	sidenavOpen?: boolean;
	// links that will be displayed inside the component
	routes?: IRouteProps[];
	// logo
	logo?: SidebarLogoProps;
	// rtl active, this will make the sidebar to stay on the right side
	rtlActive?: boolean;
}

interface CollapseState {
	[key: string]: boolean;
}

const Sidebar: React.FC<SidebarProps> = (props) => {
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.js - route /admin/regular-forms
	const getCollapseInitialState = (routes: IRouteProps[]): boolean => {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
				return true;
			} else if (window.location.href.indexOf(routes[i].path || '/') !== -1) {
				return true;
			}
		}
		return false;
	};

	// this creates the intial state of this component based on the collapse routes
	// that it gets through this.props.routes
	const getCollapseStates = (routes: IRouteProps[] | undefined): any => {
		let initialState: CollapseState = {};
		if (routes) {
			routes.map((prop: IRouteProps) => {
				if (prop.collapse) {
					initialState = {
						[prop.state]: getCollapseInitialState(prop.views),
						...getCollapseStates(prop.views),
						...initialState,
					};
				}
				return null;
			});
		}
		return initialState;
	};

	const [collapseState, setCollapseState] = useState(getCollapseStates(props.routes));

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName: string | undefined) => {
		return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
	};
	// makes the sidenav normal on hover (actually when mouse enters on it)
	const onMouseEnterSidenav = () => {
		if (!document.body.classList.contains('g-sidenav-pinned')) {
			document.body.classList.add('g-sidenav-show');
		}
	};
	// makes the sidenav mini on hover (actually when mouse leaves from it)
	const onMouseLeaveSidenav = () => {
		if (!document.body.classList.contains('g-sidenav-pinned')) {
			document.body.classList.remove('g-sidenav-show');
		}
	};

	// this is used on mobile devices, when a user navigates
	// the sidebar will autoclose
	const closeSidenav = () => {
		if (window.innerWidth < 1200) {
			props.toggleSidenav();
		}
	};
	// this function creates the links and collapses that appear in the sidebar (left menu)
	const createLinks = (routes: IRouteProps[] | undefined) => {
		return (
			routes &&
			routes.map((prop, key) => {
				if (prop.invisible) {
					return null;
				}
				if (prop.redirect) {
					return null;
				}
				if (prop.collapse) {
					var st: CollapseState = {};
					st[prop.state] = !collapseState[prop.state];
					return (
						<NavItem key={key}>
							<NavLink
								href="#pablo"
								data-toggle="collapse"
								aria-expanded={collapseState[prop.state]}
								className={classnames({
									active: getCollapseInitialState(prop.views),
								})}
								onClick={(e) => {
									e.preventDefault();
									setCollapseState(st);
								}}
							>
								{prop.icon ? (
									<>
										<i className={prop.icon} />
										<span className="nav-link-text">{prop.name}</span>
									</>
								) : prop.miniName ? (
									<>
										<span className="sidenav-mini-icon"> {prop.miniName} </span>
										<span className="sidenav-normal"> {prop.name} </span>
									</>
								) : null}
							</NavLink>
							<Collapse isOpen={collapseState[prop.state]}>
								<Nav className="nav-sm flex-column">{createLinks(prop.views)}</Nav>
							</Collapse>
						</NavItem>
					);
				}
				if (prop.layout && prop.path) {
					return (
						<NavItem className={activeRoute(prop.layout + prop.path)} key={key}>
							<NavLink
								to={prop.layout + prop.path}
								activeClassName=""
								onClick={closeSidenav}
								tag={NavLinkRRD}
							>
								{prop.icon !== undefined ? (
									<>
										<i className={prop.icon} />
										<span className="nav-link-text">{prop.name}</span>
									</>
								) : prop.miniName !== undefined ? (
									<>
										<span className="sidenav-mini-icon"> {prop.miniName} </span>
										<span className="sidenav-normal"> {prop.name} </span>
									</>
								) : (
									prop.name
								)}
							</NavLink>
						</NavItem>
					);
				}
				return null;
			})
		);
	};

	const { routes, logo } = props;
	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link,
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: '_blank',
		};
	}
	const scrollBarInner = (
		<div className="scrollbar-inner">
			<div className="sidenav-header d-flex align-items-center">
				{logo ? (
					<NavbarBrand {...navbarBrandProps}>
						<img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
					</NavbarBrand>
				) : null}
				<div className="ml-auto">
					<div
						className={classnames('sidenav-toggler d-none d-xl-block', {
							active: props.sidenavOpen,
						})}
						onClick={props.toggleSidenav}
					>
						<div className="sidenav-toggler-inner">
							<i className="sidenav-toggler-line" />
							<i className="sidenav-toggler-line" />
							<i className="sidenav-toggler-line" />
						</div>
					</div>
				</div>
			</div>
			<div className="navbar-inner">
				<Collapse navbar isOpen={props.sidenavOpen}>
					<Nav navbar>{createLinks(routes)}</Nav>
				</Collapse>
			</div>
		</div>
	);
	return (
		<Navbar
			className={
				'sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ' +
				(props.rtlActive ? '' : 'fixed-left')
			}
			onMouseEnter={onMouseEnterSidenav}
			onMouseLeave={onMouseLeaveSidenav}
		>
			{navigator.platform.indexOf('Win') > -1 ? (
				<PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
			) : (
				scrollBarInner
			)}
		</Navbar>
	);
};

Sidebar.defaultProps = {
	routes: [{}],
	toggleSidenav: () => {},
	sidenavOpen: false,
	rtlActive: false,
};

export default Sidebar;
