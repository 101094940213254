import LoadingPage from 'app/components/LoadingPage';
import { FormikHelpers } from 'formik';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import ApiRuleForm, { defaultProps } from '../components/ApiRuleForm';

import { FormApiConfiguration } from '../types';
import { locationService } from './../../../services/LocationService';

import { useGetApiConfigurationQuery, useUpdateApiConfigurationMutation } from '../services/apiManagementApi';

interface PageParams {
	id: string;
}
export interface Props {}

const EditApi = (props: Props) => {
	const params = useParams<PageParams>();
	const { data, isLoading } = useGetApiConfigurationQuery(params.id, { skip: !params.id });
	const history = locationService.getHistory();

	const [updateApi, { isLoading: isUpdating }] = useUpdateApiConfigurationMutation();

	const initialValues = useMemo<FormApiConfiguration | any>(() => {
		if (!data) return defaultProps.initialValues;

		const { requestHeaders, pathVariables, requestParams, requestBody, endpointApiParams, ...apiData } = data;

		const getHeader = endpointApiParams?.map((el) => el).filter((el) => el.paramType === 'REQUEST_HEADER');

		const getVariable = endpointApiParams?.map((el) => el).filter((el) => el.paramType === 'PATH_VARIABLE');

		const getParams = endpointApiParams?.map((el) => el).filter((el) => el.paramType === 'REQUEST_PARAM');

		const getBody = endpointApiParams?.map((el) => el).filter((el) => el.paramType === 'REQUEST_BODY');

		return {
			...apiData,
			requestHeaders: getHeader,
			pathVariables: getVariable,
			requestParams: getParams,
			requestBody: getBody,
		};
	}, [data]);

	React.useEffect(() => console.log(initialValues), [initialValues]);

	const onSubmit = (values: FormApiConfiguration, formik: FormikHelpers<FormApiConfiguration>): void => {
		updateApi(values)
			.unwrap()
			.then(() => {
				history.push({
					pathname: '/endpoint-api-management',
				});
			});
	};

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<Container fluid>
			<h2 className="my-3">Api Management</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/endpoint-api-management/">All APIs</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>Add New</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<ApiRuleForm initialValues={initialValues} onSubmit={onSubmit} isSubmitting={isUpdating} />
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default EditApi;
