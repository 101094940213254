import { FormOverview } from 'components';
import { FormikProps } from 'formik';
import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { useRoleAssignment } from '../hooks/useRoleAssignment';
import { UserConfiguration } from '../types';

export interface UserFormConfirmProps {
	formik: FormikProps<UserConfiguration>;
}

const FormConfirmation = (props: UserFormConfirmProps) => {
	const { values } = props.formik;
	const { data: roleAssignment } = useRoleAssignment();

	let roleNamesEdit = values.roleNames;

	const roleNames = roleAssignment?.map((el) => {
		if (el.id === roleNamesEdit) {
			return el.name;
		}
	});

	return (
		<React.Fragment>
			<Row>
				<Col>
					<Row className="mt-4">
						<Col lg="6">
							<Card>
								<CardBody>
									<h3 className="mb-2">Information</h3>
									<FormOverview label="Name" value={values.userInfo.name} stripped />
									<FormOverview label="Contact Number" value={values.userInfo.phoneNumber} />
									<FormOverview label="Email" value={values.userInfo.email} stripped />
									<FormOverview label="Username" value={values.username} />
									<FormOverview label="Password" value={'******'} stripped />
									<FormOverview label="Role" value={roleNames} />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default FormConfirmation;
