import * as Yup from 'yup';

export const taskValidationSchema = Yup.object().shape({
    name:Yup.string().required('Task name can not empty.'),
});

export const dailyTaskValidationSchema = Yup.object().shape({
    productionId: Yup.string().required('Select production cycle.'),
    cageId: Yup.string().required('Cage must be selected.'),
    taskName: Yup.string().required('Task name can not empty.'),
    day: Yup.number().required('Day must be set.'),
    assignTo: Yup.string().required('Task assignee required.')
});
