// Form Rule -> Form for updating and creating

import { FormikInputText, FormikSelect } from 'components';
import { FormikProps, ArrayHelpers, FieldArray } from 'formik';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { FormApiConfiguration } from '../types';
import { Button } from 'primereact/button';

export interface FormRuleInfoProps {
	targetMethodType: string[];
	formik: FormikProps<FormApiConfiguration>;
	addHeader: (arrayHelper: ArrayHelpers) => void;
	addVariable: (arrayHelper: ArrayHelpers) => void;
	addParam: (arrayHelper: ArrayHelpers) => void;
	addBody: (arrayHelper: ArrayHelpers) => void;
}

const FormRuleInfo = (props: FormRuleInfoProps) => {
	const { formik, targetMethodType, addHeader, addVariable, addParam, addBody } = props;

	return (
		<React.Fragment>
			<Row>
				<Col lg="6" sm="12">
					<h2>Information</h2>
					<FormikInputText row name="name" label="Name" placeholder="Name" required />
					<FormikInputText row name="description" label="Description" placeholder="Description" />
					<FormikInputText row name="urlEndpoint" label="URL" placeholder="URL EndPoint" required />
					<FormikSelect row name="requestMethod" label="Method" options={targetMethodType} required />

					<FieldArray
						name="requestHeaders"
						render={(arrayHelper: ArrayHelpers) => (
							<div>
								<h2>Request Header</h2>

								{formik.values.requestHeaders &&
									formik.values.requestHeaders.map((item, index) => (
										<div key={index}>
											<p>
												Request Header - {index + 1}
												<span
													style={{
														marginLeft: '10px',
													}}
												>
													<Button
														icon="pi pi-trash"
														className="p-button-danger"
														onClick={() => arrayHelper.remove(index)}
													/>
												</span>
											</p>

											<FormikInputText
												row
												name={`requestHeaders.[${index}].paramName`}
												label="Key Parameter"
												placeholder="Key Parameter"
												required
											/>
											<FormikInputText
												row
												name={`requestHeaders.[${index}].paramDescription`}
												label="Description"
												placeholder="Description"
											/>
										</div>
									))}

								<Button
									label="Add Request Header"
									type="button"
									className="p-button-link"
									icon="mdi mdi-plus-circle"
									onClick={() => addHeader(arrayHelper)}
								/>
							</div>
						)}
					/>
				</Col>

				<Col lg="6" sm="12">
					<FieldArray
						name="pathVariables"
						render={(arrayHelper: ArrayHelpers) => (
							<div>
								<h2>Path Variable</h2>

								{formik.values.pathVariables &&
									formik.values.pathVariables.map((item, index) => (
										<div key={index}>
											<p>
												Path Variable - {index + 1}
												<span
													style={{
														marginLeft: '10px',
													}}
												>
													<Button
														icon="pi pi-trash"
														className="p-button-danger"
														onClick={() => arrayHelper.remove(index)}
													/>
												</span>
											</p>

											<FormikInputText
												row
												name={`pathVariables.[${index}].paramName`}
												label="Key Parameter"
												placeholder="Key Parameter"
												required
											/>
											<FormikInputText
												row
												name={`pathVariables.[${index}].paramDescription`}
												label="Description"
												placeholder="Description"
											/>
										</div>
									))}

								<Button
									label="Add Path Variable"
									type="button"
									className="p-button-link"
									icon="mdi mdi-plus-circle"
									onClick={() => addVariable(arrayHelper)}
								/>
							</div>
						)}
					/>

					<div className="mt-4">
						<FieldArray
							name="requestParams"
							render={(arrayHelper: ArrayHelpers) => (
								<div>
									<h2>Request Param</h2>

									{formik.values.requestParams &&
										formik.values.requestParams.map((item, index) => (
											<div key={index}>
												<p>
													Request Param - {index + 1}
													<span
														style={{
															marginLeft: '10px',
														}}
													>
														<Button
															icon="pi pi-trash"
															className="p-button-danger"
															onClick={() => arrayHelper.remove(index)}
														/>
													</span>
												</p>

												<FormikInputText
													row
													name={`requestParams.[${index}].paramName`}
													label="Key Parameter"
													placeholder="Key Parameter"
													required
												/>
												<FormikInputText
													row
													name={`requestParams.[${index}].paramDescription`}
													label="Description"
													placeholder="Description"
												/>
											</div>
										))}

									<Button
										label="Add Request Param"
										type="button"
										className="p-button-link"
										icon="mdi mdi-plus-circle"
										onClick={() => addParam(arrayHelper)}
									/>
								</div>
							)}
						/>
					</div>

					<FieldArray
						name="requestBody"
						render={(arrayHelper: ArrayHelpers) => (
							<div>
								<h2>Request Body</h2>

								{formik.values.requestBody &&
									formik.values.requestBody.map((item, index) => (
										<div key={index}>
											<p>
												Request Body - {index + 1}
												<span
													style={{
														marginLeft: '10px',
													}}
												>
													<Button
														icon="pi pi-trash"
														className="p-button-danger"
														onClick={() => arrayHelper.remove(index)}
													/>
												</span>
											</p>

											<FormikInputText
												row
												name={`requestBody.[${index}].paramName`}
												label="Key Parameter"
												placeholder="Key Parameter"
												required
											/>
											<FormikInputText
												row
												name={`requestBody.[${index}].paramDescription`}
												label="Description"
												placeholder="Description"
											/>
										</div>
									))}

								<Button
									label="Add Request Body"
									type="button"
									className="p-button-link"
									icon="mdi mdi-plus-circle"
									onClick={() => addBody(arrayHelper)}
								/>
							</div>
						)}
					/>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default FormRuleInfo;
