import * as Yup from 'yup';

export const validation = Yup.object().shape({
	name: Yup.string().required('Condition name required.'),
	urlEndpoint: Yup.string().required('Condition url required'),
	requestMethod: Yup.string().required('Condition request method required'),
	pathVariables: Yup.array().of(
		Yup.object().shape({
			paramName: Yup.string().required('Condition key parameter variable required'),
		}),
	),
	requestHeaders: Yup.array().of(
		Yup.object().shape({
			paramName: Yup.string().required('Condition key parameter header required'),
		}),
	),
	requestParams: Yup.array().of(
		Yup.object().shape({
			paramName: Yup.string().required('Condition key parameter params required'),
		}),
	),
	requestBody: Yup.array().of(
		Yup.object().shape({
			paramName: Yup.string().required('Condition key parameter body required'),
		}),
	),
});

export const validations = [validation, undefined];
