import React, { useEffect } from 'react';
import { setIsEditingPage } from 'app/reducers/pageSlice';
import { useAppDispatch } from 'app/store';
import { locationService } from './../../../services/LocationService';
import { SiteData } from '../types';
import { FormikHelpers } from 'formik';
import { useCreateSiteMutation } from '../services/siteApi';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import SiteForm from '../components/SiteForm';

const CreateSitePage: React.FC = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setIsEditingPage(false));
	}, [dispatch]);

	const history = locationService.getHistory();

	const [createSite, { isLoading }] = useCreateSiteMutation();

	const initialValues: SiteData = {
		id: '',
		lastAlertMessage: '',
		lastConnected: '',
		lastData: '',
		latitude: 0,
		longitude: 0,
		name: '',
		region: '',
		status: '',
		tenantId: '',
		totalAlert: 0,
		type: '',
		deviceId:''
	};

	const onSubmit = (values: any, formik: FormikHelpers<SiteData>): void => {
		createSite({ id: values.id, ...values })
			.unwrap()
			.then(() => history.push({ pathname: '/site-management' }));
	};

	return (
		<Container fluid>
			<h2 className="my-3">Site Management</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/site-management/">Site Management</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>Add New Site</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<SiteForm initialValues={initialValues} onSubmit={onSubmit} isSubmitting={isLoading} />
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default CreateSitePage;
