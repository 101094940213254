import { useDatatable } from "app/hooks/useDatatable";
import { useDebounceSearch } from "app/hooks/useDebounceSearch";
import { FilterMatchMode } from "primereact/api";
import { useMemo } from "react";
import { useGetAbnormalEventsQuery } from "../services/abnormalEventAPI";

export function useAbnormalEventDatatable() {
	const dt = useDatatable({
		first: 0,
		rows: 10,
		page: 0,
		pageCount: 0,
		sortField: 'id',
		sortOrder: -1,
		multiSortMeta: undefined,
		filters: {
			global: { value: null, matchMode: FilterMatchMode.CONTAINS }
		}
	});

	const search = useDebounceSearch(dt.globalFilter)

	const { data: abnormalResponse, ...query } = useGetAbnormalEventsQuery({
		pageSize: dt.rows,
		pageNumber: dt.page,
		sortFields: dt.sortField,
		sortOrder: dt.sortOrder === 1 ? "ASC" : (dt.sortOrder === -1 ? "DESC" : undefined),
		search: search || undefined
	});

	const totalRecords = useMemo(() => {
		if (!abnormalResponse?.content) return 0;

		return abnormalResponse.totalElements;
	}, [abnormalResponse]);

	return {
		...dt,
		...query,
		data: abnormalResponse?.content || [],
		totalRecords,
	}
}
