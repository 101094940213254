import { useToasts } from 'app/components/Toast';
import { PrimeTable, OverlayMenu } from 'components';
import { Button } from 'primereact/button';
import { Column, ColumnProps, ColumnBodyType } from 'primereact/column';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { useTenantDatatable } from '../hooks/useTenantDatatable';
import { useDeleteTenantConfigurationMutation } from '../services/tenantApi';
import { locationService } from './../../../services/LocationService';
import { AccountConfiguration } from '../types';
import { useAppDispatch } from 'app/store';
import { setIsEditingPage } from 'app/reducers/pageSlice';

export interface Props {
	columns?: ColumnProps[];
}

const TenantPage = (props: Props) => {

	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(setIsEditingPage(false)) // disable editing state in order to show add menu button
	},[dispatch])

	const toast = useToasts();
	const { data, isLoading, totalRecords, rows, first, filters, sortField, sortOrder, onPage, onSort, onFilter } =
		useTenantDatatable();

	const [selectedRows, setSelectedRows] = useState(null);
	const [globalFilter, setGlobalFilter] = useState(null);
	const dt = useRef<DataTable>(null);

	const history = locationService.getHistory();

	const [showDialog, setShowDialog] = useState(false);

	const [deleteAccount] = useDeleteTenantConfigurationMutation();

	const _handleDelete = useCallback(
		(id: number | string) => {
			confirmDialog({
				message: `Do you want to delete this record with ID: ${id}?`,
				header: 'Delete Confirmation',
				icon: 'pi pi-info-circle',
				acceptClassName: 'p-button-danger',
				accept: async () =>
					deleteAccount(id)
						.unwrap()
						.then(() => {
							toast.toastSuccess('Success', `Account tenant deleted.`);
						})
						.catch((error) => {
							toast.toastError(error?.data?.error, error?.data?.message);
						}),
			});
		},
		[deleteAccount, toast],
	);

	const _handleEdit = useCallback(
		(id: number | string) => {
			history.push({ pathname: `/tenant-management/edit/${id}` });
		},
		[history],
	);

	const columns: ColumnProps[] = React.useMemo(() => {
		const actionTemplateBody = (rowData: AccountConfiguration): ColumnBodyType => {
			const items = [
				{
					label: 'Edit',
					icon: 'mdi mdi-pencil',
					command: () => _handleEdit(rowData.id),
				},
				{
					label: 'Delete',
					icon: 'mdi mdi-delete',
					command: () => _handleDelete(rowData.id),
				},
			];
			return (
				<React.Fragment>
					<OverlayMenu id={`overlay-menu-${rowData.id}`} items={items} />
				</React.Fragment>
			);
		};

		// template data type SMART SITE only
		const dataTypeTemplate = () => {
			return (
				<React.Fragment>
					<span>SMART SITE</span>
				</React.Fragment>
			);
		};

		// dataSourceTemplate
		const dataSourceTemplate = () => {
			return (
				<React.Fragment>
					<i className="mdi mdi-message-processing"></i>
				</React.Fragment>
			);
		};

		// data status template Active Only by default
		const statusTemplate = () => {
			return (
				<React.Fragment>
					<span> Active </span>
				</React.Fragment>
			);
		};

		return [
			{
				field: 'id',
				header: 'ID',
			},
			{
				field: 'address',
				header: 'Address',
			},
			{
				field: 'name',
				header: 'Tenant Name',
			},
			{
				field: 'contact',
				header: 'Contact Number',
			},
			{
				field: 'email',
				header: 'Email',
			},
			{
				field: 'type',
				header: 'Type',
				body: dataTypeTemplate,
			},
			{
				field: 'status',
				header: 'Status',
				body: statusTemplate,
			},
			{
				field: 'data',
				header: 'Data Source Integration',
				body: dataSourceTemplate,
				bodyStyle: {
					textAlign: 'center',
					overflow: 'visible',
				} as CSSProperties,
			},
			{
				headerStyle: {
					width: '5em',
				} as CSSProperties,
				body: actionTemplateBody,
				bodyStyle: {
					textAlign: 'center',
					overflow: 'visible',
				} as CSSProperties,
			},
		];
	}, [_handleDelete, _handleEdit]);

	const createNew = () => {
		history.push({
			pathname: '/tenant-management/new',
		});
	};

	const renderHeader = () => {
		return (
			<div className="table-header">
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						type="search"
						onInput={(e: any) => setGlobalFilter(e.target.value)}
						placeholder="Search"
					/>
				</span>

				<Button
					label="Add New"
					icon="mdi mdi-plus-circle"
					iconPos="right"
					className="p-button-link text-default"
					onClick={createNew}
				/>
			</div>
		);
	};

	const renderColumns = () => {
		return columns && columns.map((column: ColumnProps, i) => <Column key={i} {...column} />);
	};

	const header = renderHeader();

	return (
		<Container fluid>
			<h2 className="my-3">All Tenant</h2>
			<div>
				<Card>
					<CardBody>
						<PrimeTable
							lazy
							ref={dt}
							value={data}
							header={header}
							dataKey="id"
							globalFilter={globalFilter}
							selection={selectedRows}
							onSelectionChange={(e) => setSelectedRows(e.value)}
							rows={rows}
							first={first}
							sortField={sortField}
							sortOrder={sortOrder}
							loading={isLoading}
							totalRecords={totalRecords}
							filters={filters}
							onPage={onPage}
							onSort={onSort}
							onFilter={onFilter}
						>
							{renderColumns()}
						</PrimeTable>
						<ConfirmDialog visible={showDialog} onHide={() => setShowDialog(false)} />
					</CardBody>
				</Card>
			</div>
		</Container>
	);
};

export default TenantPage;
