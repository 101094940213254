import { useCallback } from 'react';
import { FormRoleValues } from '../components/FormRuleInfo';
import { useCreateRoleConfigurationMutation, useGetPermissionCreateMutation } from '../services/roleApi';

export function useCreateRole() {
	const [createNewRole, { isLoading: creatingRole }] = useCreateRoleConfigurationMutation();
	const [setPermissionsToRole, { isLoading: settingPermissions }] = useGetPermissionCreateMutation();

	const createRole = useCallback(
		async (data: FormRoleValues) => {
			const { permissionsInput, ...roleData } = data;

			try {
				const newRole = await createNewRole(roleData).unwrap();

				await setPermissionsToRole({
					roleId: newRole.id,
					featureIds: permissionsInput || [],
				});
			} catch (error) {
				console.error(error);
			}
		},
		[createNewRole, setPermissionsToRole],
	);

	return {
		createRole,
		isLoading: creatingRole || settingPermissions,
	};
}
