import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryAlert } from './../../../services/BaseApi'
import { AbnormalEvent, AbnormalEventsRequest, AbnormalEventsResponse } from './../types';

export const abnormalEventApi = createApi({
	reducerPath: 'abnormalEventApi',
	baseQuery: baseQueryAlert,
	tagTypes: ['AbnormalEvent'],
	endpoints: (builder) => ({
		getAbnormalEvents: builder.query<AbnormalEventsResponse, AbnormalEventsRequest>({
			query: (params) => ({
				url: '/v1/detectedAnomaly/',
				params
			}),
			providesTags: (result) =>
				result?.content
					? [
						...result.content.map(({ id }) => ({ type: 'AbnormalEvent' as const, id })),
						{ type: 'AbnormalEvent', id: 'LIST' }
					]
					: [
						{ type: 'AbnormalEvent', id: 'LIST' }
					],
		}),
		getAbnormalEvent: builder.query<AbnormalEvent, number>({
			query: (id) => ({ url: `/v1/detectedAnomaly/${id}` }),
			providesTags: (result, error, id) => [{ type: 'AbnormalEvent', id }]
		}),
		createAbnormalEvent: builder.mutation<AbnormalEvent, Partial<AbnormalEvent>>({
			query: (body) => ({
				url: '/v1/detectedAnomaly/',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'AbnormalEvent', id: 'LIST' }]
		}),
		updateAbnormalEvent: builder.mutation<AbnormalEvent, Partial<AbnormalEvent>>({
			query: ({ id, ...body }) => ({
				url: `/v1/detectedAnomaly/${id}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'AbnormalEvent', id }]
		}),
		deleteAbnormalEvent: builder.mutation<AbnormalEvent, number>({
			query: (id) => ({
				url: `/v1/detectedAnomaly/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: (result, error, id) => [{ type: 'AbnormalEvent', id }]
		}),
	}),
})

export const {
	useGetAbnormalEventsQuery,
	useGetAbnormalEventQuery,
	useCreateAbnormalEventMutation,
	useUpdateAbnormalEventMutation,
	useDeleteAbnormalEventMutation
} = abnormalEventApi;
