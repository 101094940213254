import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import TenantRuleForm from '../components/TenantRuleForm';
import { locationService } from './../../../services/LocationService';
import { useCreateTenantConfigurationMutation } from '../services/tenantApi';
import { AccountConfiguration } from '../types';
import { FormikHelpers } from 'formik';

export interface TenantRuleFormProps {}

const CreateTenant = () => {
	const [createTenant, { isLoading }] = useCreateTenantConfigurationMutation();
	const history = locationService.getHistory();

	const initialValues: AccountConfiguration = {
		id: 0,
		account: {
			id: 0,
			name: '',
			address: '',
			email: '',
			contact: '',
		},
		adminUser: {
			password: '',
			username: '',
		},
		deviceApi: {
			url: '',
			headerInfo: '',
			tenantId: '',
			id: '',
		},

		name: '',
		address: '',
		email: '',
		contact: '',
	};

	const onSubmit = (values: AccountConfiguration, formik: FormikHelpers<AccountConfiguration>): void => {
		createTenant(values)
			.unwrap()
			.then(() => {
				history.push({
					pathname: '/tenant-management',
				});
			});
	};

	return (
		<Container fluid>
			<h2 className="my-3">Add New Tenant</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/tenant-management/">All Tenant</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>Add New Tenant</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<TenantRuleForm
								initialValues={initialValues}
								onSubmit={onSubmit}
								isSubmitting={isLoading}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default CreateTenant;
