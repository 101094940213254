import { useUploadImageMutation } from 'app/services/ProjectService';
import { FormikInputTextArea } from 'components';
import { FormikInputEmail } from 'components/formik/FormikInputEmail';
import { FormikInputFile } from 'components/formik/FormikInputFile';
import { FormikProps } from 'formik';
import React from 'react';
import { Col, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { InitialValuesLoginSetting } from './LoginConfig';

type Props = {
	projectId: string;
	formik: FormikProps<InitialValuesLoginSetting>;
};

const LoginInformation: React.FC<Props> = ({ formik }) => {
	const [uploadImage] = useUploadImageMutation();

	const handleFileLogo = (e) => {
		const formData = new FormData();

		formData.append('imageMultipart', e.target.files[0]);

		uploadImage({ body: formData })
			.unwrap()
			.then((res) => formik.setFieldValue('logoImg', res.url))
			.catch((err) =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Something went wrong!',
				}),
			);
	};
	const handleFileBackground = (e) => {
		const formData = new FormData();

		formData.append('imageMultipart', e.target.files[0]);

		uploadImage({ body: formData })
			.unwrap()
			.then((res) => formik.setFieldValue('backgroundImg', res.url))
			.catch((err) =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Something went wrong!',
				}),
			);
	};

	return (
		<Row className="d-flex justify-content-center">
			<Col className="offset-md-2">
				<FormikInputEmail
					row
					name="subdomain"
					label="Domain"
					placeholder="Sub Domain"
					value={formik.values.subdomain}
					required
					domain={"smartsol.id"}
				/>
				<FormikInputFile
					onChange={handleFileLogo}
					row
					accept="image/*"
					name="logo"
					label="Brand Logo"
					defaultImg={formik.values.logoImg}
				/>
				<FormikInputFile
					onChange={handleFileBackground}
					row
					accept="image/*"
					name="background"
					label="Background"
					defaultImg={formik.values.backgroundImg}
				/>
				<FormikInputTextArea row name="caption" label="Caption" placeholder="Caption" />
				
			</Col>
			<Col md={2}></Col>
		</Row>
	);
};

export default LoginInformation;
