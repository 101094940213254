import React, { useMemo } from 'react';
import { css } from '@emotion/css';
import LoadingPage from 'app/components/LoadingPage';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { PanelProps } from 'app/types/Panel';
import { calculateFontSize } from 'app/utils';
import moment from 'moment';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';

export interface TritronikSiteInformationPanelProps extends PanelProps { }

interface Site {
	name: string;
	region: string;
	lastUpdate: string;
	severityColor: SeverityColor;
	location: { latitude: number; longitude: number };
}

type Color = 'NORMAL' | 'CRITICAL' | 'MAJOR' | 'MINOR' | 'OFFLINE';

enum SeverityColor {
	NORMAL = '#8bc34a',
	CRITICAL = '#e91e63',
	MAJOR = '#ff9800',
	MINOR = '#ffeb3b',
	OFFLINE = '#b777d9',
}

interface Weather {
	temperature: number;
	humidity: number;
	windSpeed: number;
	imageUrl: string;
	weatherStatus: string;
}

interface WeatherInfo {
	id: number;
	main: string;
	description: string;
	icon: string;
}
interface CurrentWeather {
	dt: number;
	sunrise: number;
	sunset: number;
	temp: number;
	feels_like: number;
	pressure: number;
	humidity: number;
	dew_point: number;
	uvi: number;
	clouds: number;
	wind_speed: number;
	wind_deg: number;
	weather: WeatherInfo[];
}
interface OpenWeather {
	lat: number;
	lon: number;
	timezone: string;
	timezone_offset: number;
	current: CurrentWeather;
}

type SiteInfo = Site & Weather;

const TritronikSiteInformationPanel = (props: TritronikSiteInformationPanelProps) => {
	const { width, height, panel, refreshInterval } = props;

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const siteInfo = useMemo<SiteInfo>(() => {
		if (!data || !data.responseDataValue) {
			return {
				temperature: 0,
				humidity: 0,
				windSpeed: 0,
				imageUrl: `http://openweathermap.org/img/wn/50d@4x.png`,
				weatherStatus: '-',
				name: '-',
				region: '-',
				lastUpdate: '-',
				severityColor: SeverityColor.OFFLINE,
				location: {
					latitude: -6.2,
					longitude: 106.816666,
				},
			};
		}

		const { responseDataValue } = data;

		const ds = Array.isArray(responseDataValue) ? responseDataValue[0] : responseDataValue;

		const lastUpdate = ds.lastConnected ? moment(ds.lastConnected).format('D MMM YYYY, HH:mm') : '-';

		const weatherInfo: Partial<OpenWeather> | null = ds.weatherInfo ? JSON.parse(ds.weatherInfo) : null;

		return {
			temperature: weatherInfo?.current?.temp || 0,
			humidity: weatherInfo?.current?.humidity || 0,
			windSpeed: weatherInfo?.current?.wind_speed || 0,
			imageUrl: weatherInfo
				? `http://openweathermap.org/img/wn/${weatherInfo.current?.weather[0]?.icon}@4x.png`
				: `http://openweathermap.org/img/wn/50d@4x.png`,
			weatherStatus: weatherInfo?.current?.weather[0]?.main || '-',
			name: ds.name || '-',
			region: ds.region || '-',
			severityColor: ds.status ? SeverityColor[ds.status as Color] : SeverityColor.OFFLINE,
			lastUpdate: lastUpdate,
			location: {
				latitude: ds.latitude,
				longitude: ds.longitude,
			},
		};
	}, [data]);

	let primary = calculateFontSize(siteInfo ? siteInfo.name : '', width / 3, height / 7 > 42 ? 42 : height / 7, 1);
	let secondary = calculateFontSize(
		'Last update: XX XXX XXXX, XX:XX',
		width * 0.3,
		height / 10 > 30 ? 30 : height / 10,
		1,
	);
	primary = primary < 16 ? 16 : primary;
	secondary = secondary < 12 ? 12 : secondary;

	const wrapper = css`
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		h1 {
			font-size: ${primary}px;
			line-height: 0.75;
		}

		h6 {
			font-size: ${secondary}px;
		}
	`;

	const container = css`
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	`;

	const siteInfoContainer = css`
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	`;

	const siteNameInfo = css`
		display: flex;
		flex-direction: column;
	`;

	const iconStyle = css`
		margin-top: -${height * 0.095}px;
		font-size: ${primary * 1.2}px;
	`;

	const weatherInfoContainer = css`
		display: flex;
		flex-direction: row;
		align-items: center;
	`;
	const weatherIconContainer = css`
		img {
			width: ${primary * 3}px;
			transform: translateY(-25%);
		}

		h6 {
			margin-top: -${primary * 3 * 0.4}px;
			text-align: center;
		}
	`;

	const weatherInfoStatus = css`
		h6 {
			line-height: 0.75;
		}
	`;
	const updateInfoContainer = css`
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;

		h6 {
			line-height: 0.75;
		}
	`;

	const locationHref = useMemo(() => {
		if (!siteInfo) return '#';

		return `https://www.google.com/maps/search/?api=1&query=${siteInfo.location.latitude},${siteInfo.location.longitude}`;
	}, [siteInfo]);

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div className={wrapper}>
			<div className={container}>
				<div className={siteInfoContainer}>
					<a className={iconStyle} href={locationHref} target="_blank" rel="noopener noreferrer">
						<i className="mdi mdi-map-marker location"></i>
					</a>
					<div className={siteNameInfo}>
						<h1>{siteInfo.name || '-'}</h1>
						<h6>{siteInfo.region || '-'}</h6>
					</div>
				</div>
				<div className={weatherInfoContainer}>
					<div className={weatherIconContainer}>
						<img src={siteInfo.imageUrl} alt={siteInfo.weatherStatus} />
						<h6>{siteInfo.weatherStatus}</h6>
					</div>
					<div className={weatherInfoStatus}>
						<h1>{siteInfo.temperature}<span> °C</span></h1>
						<h6>{siteInfo.humidity}<span> %</span></h6>
						<h6>{siteInfo.windSpeed}<span> m/s</span></h6>
					</div>
				</div>
			</div>
			<div className={updateInfoContainer}>
				<i className="mdi mdi-record" style={{ color: siteInfo.severityColor }}></i>
				Last update: {siteInfo.lastUpdate}
			</div>
		</div>
	);
};

export default TritronikSiteInformationPanel;
