// Parent Component
// Child Component -> FormConfirmation and FormRuleInfo

import { PrimeSteps } from 'components';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useState } from 'react';
import { Button } from 'primereact/button';
import { UserConfiguration } from '../types';
import FormConfirmation from './FormConfirmation';
import FormRuleInfo from './FormRuleInfo';
import { history } from 'utils';
import { Row, Col } from 'reactstrap';
import { validationUser } from '../ValidationSchema';
import { userService } from '../services/UserService';

export type FormUserValues = Partial<UserConfiguration>;

export interface UserRuleFormProps {
	initialValues: UserConfiguration;
	onSubmit: (values: UserConfiguration, formik: FormikHelpers<UserConfiguration>) => void;
	isSubmitting?: boolean;
}

const formSteps = [{ label: 'USER INFORMATION' }, { label: 'CONFIRMATION' }];

const UserRuleForm = (props: UserRuleFormProps) => {
	const [activeStep, setActiveStep] = useState(0);
	const isLastStep = activeStep === formSteps.length - 1;
	const currentValidation = validationUser[activeStep];

	const roleType = userService.getRoleType();

	const { initialValues, isSubmitting, onSubmit } = props;

	const nextStep = () => {
		if (!isLastStep) {
			setActiveStep((currentStep) => currentStep + 1);
		}
	};

	const prevStep = () => {
		if (activeStep > 0) {
			setActiveStep((currentStep) => currentStep - 1);
		}
	};

	const _onSubmit = (values: UserConfiguration, formik: FormikHelpers<UserConfiguration>) => {
		onSubmit(values, formik);
	};

	const _handleSubmit = (values: UserConfiguration, formik: FormikHelpers<UserConfiguration>): void => {
		if (isLastStep) {
			_onSubmit(values, formik);
		} else {
			nextStep();
			formik.setTouched({});
			formik.setSubmitting(false);
		}
	};

	const _renderForm = useCallback(
		(step: number, formik: FormikProps<UserConfiguration>) => {
			switch (step) {
				case 1:
					return <FormConfirmation formik={formik} />;
				default:
					return <FormRuleInfo formik={formik} roleType={roleType} />;
			}
		},
		[roleType],
	);

	return (
		<Formik initialValues={initialValues} onSubmit={_handleSubmit} validationSchema={currentValidation}>
			{(formik: FormikProps<UserConfiguration>) => (
				<Form onSubmit={formik.handleSubmit}>
					<Row className="mt-3">
						<Col>
							<PrimeSteps model={formSteps} activeIndex={activeStep} />
						</Col>
					</Row>
					<Row className="mt-3">
						<Col>{_renderForm(activeStep, formik)}</Col>
					</Row>
					<Row className="mt-3">
						<Col className="text-right">
							<Button
								label="Cancel"
								type="button"
								className="mr-2 p-button-outlined"
								onClick={() => history.goBack()}
							/>
							{activeStep > 0 && (
								<Button
									type="button"
									label="Previous"
									onClick={prevStep}
									className="mr-2 p-button-outlined"
								/>
							)}
							<Button
								label={isLastStep ? 'Apply' : 'Next'}
								loading={isSubmitting}
								disabled={isSubmitting}
								type="submit"
								className=""
							/>
						</Col>
					</Row>
				</Form>
			)}
		</Formik>
	);
};

export const defaultProps: UserRuleFormProps = {
	initialValues: {
		id: 0,
		username: '',
		password: '',
		userInfo: {
			email: '',
			id: 0,
			name: '',
			phoneNumber: '',
			telegramUserId: '',
		},

		roleNames: [],
	},

	onSubmit: () => {},
	isSubmitting: false,
};

UserRuleForm.defaultProps = defaultProps;

export default UserRuleForm;
