import { FormikInputText, FormikInputTextArea, FormikSelect } from 'components';
import { FormikInputNumber } from 'components/formik/FormikInputNumber';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button } from 'primereact/button';
import React, { useMemo, useState } from 'react';
import { useCageList } from '../../hooks/useCageList';
import { useProductionList } from '../../hooks/useProductionList';
import { useUserList } from '../../hooks/useUserList';
import { ICage, IDailyTask } from '../../types';
import { dailyTaskValidationSchema } from '../../utils/validation';

export type TDailyTaskForm = Partial<IDailyTask> & {
    cageId?: string;
};

type Props = {
    title?: string;
    dailyTask?: TDailyTaskForm;
    onSubmit: (values: TDailyTaskForm, formikHelpers: FormikHelpers<TDailyTaskForm>) => void | Promise<any>;
    onCancel?: () => void;
    isLoading?: boolean;
};

const FormDailyTask = ({ dailyTask, onSubmit, isLoading, onCancel }: Props) => {
    const [selectedCage, setSelectedCage] = useState<ICage|null>(null);

    const { data: cageData, options: cageOptions } = useCageList();
    const { options: productionOptions } = useProductionList({
        cageId: dailyTask ? dailyTask.cageId : (selectedCage ? selectedCage.id : undefined),
    });

    const { options: userOptions } = useUserList();

    const initialValues = useMemo(() => {
        if (!dailyTask) {
            return {
                productionId: undefined,
                productionMonitoringId: undefined,
                day: 1,
                date: undefined,
                cage: '',
                cageId: undefined,
                taskName: '',
                taskDesc: '',
                status: 'unfinish',
                assignTo: undefined,
                id: undefined,
            } as TDailyTaskForm;
        }

        const cage = cageData?.find(({ cageName }) => cageName === dailyTask.cage);

        return {
            ...dailyTask,
            cageId: cage ? cage.id : undefined,
        };
    }, [dailyTask, cageData]);

    const submitLabel = dailyTask ? 'Save Changes' : 'Create';

    return (
        <Formik<TDailyTaskForm>
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={dailyTaskValidationSchema}
            enableReinitialize
        >
            {({ handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                    <FormikInputText label="Name" name="taskName" required />
                    <FormikInputTextArea label="Description" name="taskDesc" rows={3} />
                    <FormikSelect
                        showClear
                        label="Cage"
                        name="cageId"
                        required
                        options={cageOptions}
                        onChange={e => {
                            handleChange(e);
                            if (e.value && cageData) {
                                const cage = cageData.find(({ id }) => id === e.value);
                                if (cage) {
                                    setFieldValue('cage', cage.cageName);
                                    setSelectedCage(cage);
                                }
                            }
                        }}
                    />
                    <FormikSelect
                        showClear
                        label="Production"
                        name="productionId"
                        required
                        options={productionOptions}
                    />
                    <FormikInputNumber
                        label="Day"
                        name="day"
                        showButtons
                        min={1}
                        max={31}
                        mode="decimal"
                        onChange={(e) => {
                            setFieldValue('day', e.value);
                        }}
                        required
                    />
                    <FormikSelect
                        showClear
                        label="Assign To"
                        name="assignTo"
                        required
                        options={userOptions}
                    />

                    <div className="d-flex align-items-center justify-content-end">
                        <Button type="button" label="Cancel" icon="pi pi-times" className="p-button-text p-button-sm" onClick={onCancel} />
                        <Button
                            type="submit"
                            label={submitLabel}
                            icon="pi pi-save"
                            className="p-button-sm"
                            autoFocus
                            disabled={isLoading || isSubmitting}
                            loading={isLoading}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default FormDailyTask;
