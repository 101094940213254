import { SelectableValue } from 'app/types';
import { FormikInputText, FormikSelect } from 'components';
import { Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import React from 'react';

export interface FormSensorConfigurationProps {
	siteOptions?: SelectableValue[];
	paramOptions?: SelectableValue[];
	subParamOptions?: SelectableValue[];
	intervalOptions?: SelectableValue[];
	sensorOptions?: SelectableValue[];
	isLoading?: boolean;
	onSubmit?: (values: SensorConfigurationForm) => void | Promise<any>;
	initialValues?: SensorConfigurationForm;
	onReset?: () => void;
}

export interface SensorProperty {
	deviceId: string;
	sensorName: string;
	sensorParam: string;
	subparamName: string;
	timeGroupping: string;
}

export type SensorConfigurationForm = SensorProperty & { message?: string };

const validate = (values: SensorConfigurationForm) => {
	const errors: any = {};

	if (!values.deviceId
		&& !values.sensorName
		&& !values.sensorParam
		&& !values.subparamName
		&& !values.timeGroupping) {
			errors.message = 'Fill the input form at least one field.'
		}

	return errors;
}

const FormSensorConfiguration: React.FC<FormSensorConfigurationProps> = (props) => {
	const initialValues: SensorConfigurationForm = props.initialValues || {
		deviceId: '',
		sensorName: '',
		timeGroupping: '',
		sensorParam: '',
		subparamName: ''
	}

	const _handleSubmit = (values: SensorConfigurationForm) => {
		props.onSubmit?.(values);
	}

	const _handleReset = () => {
		props.onReset?.();
	}

	return (
		<div className='d-flex flex-column'>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={_handleSubmit}
				validate={validate}
			>
				{({ handleSubmit, errors }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<div className="row border-top border-bottom">
								<div className="col-12 py-3">
									<div className='d-flex flex-column'>
										<p className='h4'>Device Data</p>
										<div className="row mt-3">
											<div className="col-12 col-md-6">
												<FormikInputText
													row
													className='mb-2'
													name='deviceId'
													label='Device ID'
													placeholder='Device ID'
												/>
											</div>
											<div className="col-12 col-md-6">
												<FormikInputText
													row
													className='mb-2'
													name='sensorName'
													label='Sensor Name'
													placeholder='Sensor Name'
												/>
											</div>
											<div className="col-12 col-md-6">
												<FormikInputText
													row
													className='mb-2'
													name='sensorParam'
													label='Sensor Param'
													placeholder='Sensor Param'
												/>
											</div>
											<div className="col-12 col-md-6">
												<FormikInputText
													row
													className='mb-2'
													name='subparamName'
													label='Sensor Subparam'
													placeholder='Sensor Subparam'
												/>
											</div>
											<div className="col-12 col-md-6">
												<FormikSelect
													row
													formGroupClass='mb-2'
													name='timeGroupping'
													label='Interval'
													placeholder='Interval'
													options={props.intervalOptions}
												/>
											</div>
										</div>
									</div>
									<div className="d-flex">
										{errors.message && <div className="p-error">{errors.message}</div>}
									</div>
									<div className="d-flex justify-content-end">
										<div className='my-3 d-flex justify-content-end border-left-md'>
											<Button
												label="Reset"
												type="reset"
												className="mr-2 p-button-outlined"
												onClick={_handleReset}

											/>
											<Button
												label={'Apply'}
												type="submit"
												loading={props.isLoading}
											/>
										</div>
									</div>
								</div>
							</div>
						</Form>
					)
				}}
			</Formik>
		</div>
	)
}

FormSensorConfiguration.displayName = 'FormSensorConfiguration'

export default FormSensorConfiguration
