import React from 'react';
import { Steps, StepsProps } from 'primereact/steps';

import './PrimeSteps.scss';

export interface PrimeStepsProps extends StepsProps {}

const PrimeSteps = (props: PrimeStepsProps) => {
	return (
		<div className="prime-steps">
			<Steps {...props} />
		</div>
	);
};

export default PrimeSteps;
