/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Login } from 'app/features/auth';
import { DashboardRoutes } from 'app/types/dashboard';
import { RouteDescriptor } from 'app/types/navigation';
import DashboardPage from '../features/dashboard/containers/DashboardPage';

export function getAppRoutes(): RouteDescriptor[] {
	return [
		{
			path: '/',
			pageClass: 'page-dashboard',
			routeName: DashboardRoutes.Home,
			component: DashboardPage,
		},
		{
			path: '/d/:uid/:slug?/:page',
			pageClass: 'page-dashboard',
			routeName: DashboardRoutes.Normal,
			component: DashboardPage,
		},
	]
}

const routes = [
	{
		path: "/login",
		icon: "mdi mdi-account",
		name: "Login",
		component: Login,
		layout: "/auth",
		invisible: true,
	},
];

export default routes;
