import { createApi } from '@reduxjs/toolkit/query/react';
import { authApi } from 'app/features/auth/authAPI';
import { roleConfigurationApi } from 'app/features/RoleManagement/services/roleApi';
import { baseQuery } from './../../../services/BaseApi';
import { PaginatedResponse, SetUserType, UserConfiguration } from './../types';

export const userConfigurationApi = createApi({
	reducerPath: 'userConfigurationApi',
	baseQuery: baseQuery,
	tagTypes: ['UserConfiguration', 'RoleAssignment'],
	endpoints: (builder) => ({
		getUserConfigurations: builder.query<PaginatedResponse<UserConfiguration>, any>({
			query: (params) => ({
				url: '/user/',
				params,
			}),
			providesTags: (result) =>
				result?.content
					? [
							...result.content.map(({ id }) => ({ type: 'UserConfiguration' as const, id })),
							{ type: 'UserConfiguration', id: 'LIST' },
					  ]
					: [{ type: 'UserConfiguration', id: 'LIST' }],
		}),
		getUserConfiguration: builder.query<UserConfiguration, number | string>({
			query: (id) => ({ url: `/user/${id}` }),
			providesTags: (result, error, id) => [{ type: 'UserConfiguration', id }],
		}),

		createUserConfiguration: builder.mutation<UserConfiguration, Partial<UserConfiguration>>({
			query: (body) => ({
				url: '/user/',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'UserConfiguration', id: 'LIST' }],
		}),
		updateUserConfiguration: builder.mutation<UserConfiguration, Partial<UserConfiguration>>({
			query: ({ id, ...body }) => ({
				url: `/user/${id}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'UserConfiguration', id }],
		}),

		deleteUserConfiguration: builder.mutation<UserConfiguration, number | string>({
			query: (id) => ({
				url: `/user/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, id) => [{ type: 'UserConfiguration', id }],
		}),

		getRoleAssignment: builder.query<PaginatedResponse<any>, void>({
			query: () => ({
				url: '/role/',
			}),
			providesTags: (result) =>
				result?.content
					? [
							...result.content.map(({ id }) => ({ type: 'RoleAssignment' as const, id })),
							{ type: 'RoleAssignment', id: 'LIST' },
					  ]
					: [{ type: 'RoleAssignment', id: 'LIST' }],
		}),

		setUserRole: builder.mutation<undefined, SetUserType>({
			query: ({ roleIds, userId }) => ({
				url: `/role/${roleIds}/user/${userId}/replace`,
				method: 'PUT',
			}),
			invalidatesTags: (res, err, { userId }) => [{ type: 'UserConfiguration', id: userId }],
			async onQueryStarted({ roleIds }, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					// get ID role
					dispatch(roleConfigurationApi.util.invalidateTags([{ type: 'RoleConfiguration', id: roleIds }]));
					dispatch(authApi.util.invalidateTags(['Profile']));
				} catch {}
			},
		}),
	}),
});

export const {
	useGetUserConfigurationQuery,
	useGetUserConfigurationsQuery,
	useCreateUserConfigurationMutation,
	useUpdateUserConfigurationMutation,
	useDeleteUserConfigurationMutation,
	useGetRoleAssignmentQuery,
	useSetUserRoleMutation,
} = userConfigurationApi;
