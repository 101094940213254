import { locationService } from 'app/services/LocationService';
import { OverlayMenu, PrimeTable } from 'components';
import { Column, ColumnProps, ColumnBodyType } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { CSSProperties, useCallback, useMemo, useRef, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { useSensorDatatable } from '../hooks/useSensorDatatable';
import { SensorConfiguration } from '../types';

export interface Props {
	columns?: ColumnProps[];
}

const SensorPropertyPage = (props: Props) => {
	const { data, isLoading, totalRecords, rows, first, filters, sortField, sortOrder, onPage, onSort, onFilter } =
		useSensorDatatable();

	console.log('all data', data);

	const [selectedRows, setSelectedRows] = useState(null);
	const [globalFilter, setGlobalFilter] = useState(null);
	const dt = useRef<DataTable>(null);

	const history = locationService.getHistory();

	const [showDialog, setShowDialog] = useState(false);

	const _handleEdit = useCallback(
		(id: number | string) => {
			history.push({ pathname: `/sensor-property/edit/${id}` });
		},
		[history],
	);

	const columns: ColumnProps[] = useMemo(() => {
		const actionTemplateBody = (rowData: SensorConfiguration): ColumnBodyType => {
			const items = [
				{
					label: 'Edit',
					icon: 'mdi mdi-pencil',
					command: () => _handleEdit(rowData.id),
				},
			];
			return (
				<React.Fragment>
					<OverlayMenu id={`overlay-menu-${rowData.id}`} items={items} />
				</React.Fragment>
			);
		};

		const createdTags = (rowData: SensorConfiguration): ColumnBodyType => {
			if (rowData.entityTags && rowData.entityTags.length > 0) {
				return rowData.entityTags
					.map((tag: any) => {
						return tag.tagName || '-';
					})
					.join(', ');
			} else {
				return '-';
			}
		};

		return [
			{
				field: 'name',
				header: 'NAME',
			},
			{
				field: 'id',
				header: 'PROPERTY',
			},
			{
				field: 'entityTags.tagName',
				header: 'TAG',
				body: createdTags,
			},
			{
				field: 'type',
				header: 'SENSOR TYPE',
			},
			{
				field: 'description',
				header: 'DESCRIPTION',
			},

			{
				headerStyle: {
					width: '5em',
				} as CSSProperties,
				body: actionTemplateBody,
				bodyStyle: {
					textAlign: 'center',
					overflow: 'visible',
				} as CSSProperties,
			},
		];
	}, [_handleEdit]);

	const renderHeader = () => {
		return (
			<div className="table-header">
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						type="search"
						onInput={(e: any) => setGlobalFilter(e.target.value)}
						placeholder="Search"
					/>
				</span>
			</div>
		);
	};

	const renderColumns = () => {
		return columns && columns.map((column: ColumnProps, i) => <Column key={i} {...column} />);
	};

	const header = renderHeader();

	return (
		<Container fluid>
			<h2 className="my-3">Sensor Property</h2>
			<div>
				<Card>
					<CardBody>
						<PrimeTable
							lazy
							ref={dt}
							value={data}
							header={header}
							dataKey="id"
							globalFilter={globalFilter}
							selection={selectedRows}
							onSelectionChange={(e) => setSelectedRows(e.value)}
							rows={rows}
							first={first}
							sortField={sortField}
							sortOrder={sortOrder}
							loading={isLoading}
							totalRecords={totalRecords}
							filters={filters}
							onPage={onPage}
							onSort={onSort}
							onFilter={onFilter}
						>
							{renderColumns()}
						</PrimeTable>
						<ConfirmDialog visible={showDialog} onHide={() => setShowDialog(false)} />
					</CardBody>
				</Card>
			</div>
		</Container>
	);
};

export default SensorPropertyPage;
