import { useToasts } from 'app/components/Toast';
import { Dialog, DialogProps } from 'primereact/dialog';
import React, { useMemo } from 'react';
import { useAppConfig } from '../../hooks/useAppConfig';
import { useCreateTaskMutation } from '../../services';
import { IDailyTask } from '../../types';
import FormTask, { TTaskForm } from '../forms/FormTask';

const ModalCreateTask = (props: DialogProps & { dailyTask: IDailyTask }) => {
    const { getConfigByName } = useAppConfig();
    const [createTask, { isLoading }] = useCreateTaskMutation();
    const toast = useToasts();

    const onSubmit = async (values: TTaskForm) => {
        const { id, dailyTaskId, desc, inputField, ...data } = values;
        try {
            await createTask({
                dailyTaskId,
                inputField: inputField ?? null,
                desc,
                ...data
            }).unwrap();
            props.onHide();
        } catch (error) {
            toast.toastError('Update task failed.', error?.data?.message);
        }

    };

    const options = useMemo(() => {
        const config = getConfigByName('taskInputField');
        if (!config) return [];

        return config.map((val) => {
            return Object.entries(val).reduce((a, [value, label]) => {
                return { value, label }
            }, {})
        });
    }, [getConfigByName]);

    return (
        <Dialog header="Add New Task" modal style={{ width: '20vw' }} {...props}>
            <FormTask
                dailyTaskId={props.dailyTask.id}
                onSubmit={onSubmit}
                onCancel={props.onHide}
                inputFieldOptions={options}
                isLoading={isLoading}
            />
        </Dialog>
    );
};

export default ModalCreateTask;
