import Restricted from 'app/components/Restricted';
import React, { useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import ProjectConfig from '../components/ProjectConfig';

const ProjectConfigPage: React.FC = () => {
	const { projectId } = useParams<any>();

	const renderForm = useCallback(() => {
		return <ProjectConfig projectId={projectId} />;
	}, [projectId]);
	return (
		<Restricted to={'UPDATE DASHBOARD'}>
			<Container fluid>
				<h2 className="my-3">Project Configuration</h2>
				<Card>
					<CardBody>
						<Row className="d-flex justify-content-between">
							<Col className="d-flex align-items-center" xs="12" md="10">
								<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
									<BreadcrumbItem>
										<Link to={`/project-configuration/${projectId}`}>Project Configuration</Link>
									</BreadcrumbItem>
									<BreadcrumbItem active>Project Configuration</BreadcrumbItem>
								</Breadcrumb>
							</Col>
						</Row>
					</CardBody>
					{renderForm()}
				</Card>
			</Container>
		</Restricted>
	);
};

export default ProjectConfigPage;
