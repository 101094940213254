import { FormikHelpers } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import AlertRuleForm from '../components/AlertRuleForm';
import { useCreateAlertConfigurationMutation } from '../services/alertApi';
import { AlertConfiguration, AlertConfigurationForm } from '../types';
import { locationService } from './../../../services/LocationService';

export interface AlertRuleFormProps { }

const CreateAlertRulePage = () => {
	const [createAlert, { isLoading }] = useCreateAlertConfigurationMutation();
	const history = locationService.getHistory();

	const initialValues: AlertConfigurationForm = {
		id: 0,
		name: '',
		applyTo: '',
		targetId: '',
		tenantId: 'komatsu',
		minuteInterval: 5,
		severity: 'MINOR',
		metricType: 'NUMERIC',
		conditions: [
			{
				id: 0,
				name: '',
				comparator: '',
				thresholdValue: 0,
				dataFunction: 'AVERAGE',
			},
		],
		message: '',
		channelRecipient: [
			{
				channel: 'email',
				recipients: [],
			},
		],
		tags: []
	};

	const onSubmit = (values: AlertConfiguration, formik: FormikHelpers<AlertConfigurationForm>): void => {
		createAlert(values).unwrap().then(() => {
			history.push({
				pathname: '/alert-configuration',
			});
		});
	};

	return (
		<Container fluid>
			<h2 className="my-3">Alert Configuration</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/alert-configuration/">Rules</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>Add New</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<AlertRuleForm
								initialValues={initialValues}
								onSubmit={onSubmit}
								isSubmitting={isLoading}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default CreateAlertRulePage;
