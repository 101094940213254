import { useEffect, useRef, useState } from "react";
import debounce from 'lodash/debounce';
import { search_debounce_time } from "../../config/config";

export function useDebounceSearch(keyword: string, delay: number = search_debounce_time) {
	const [search, setSearch] = useState(keyword);
	const debouncedSearch = useRef(
		debounce((criteria) => {
			setSearch(criteria);
		}, delay)
	).current;
	useEffect(() => {
		debouncedSearch(keyword)
		return () => {
			debouncedSearch.cancel();
		}
	}, [debouncedSearch, keyword]);
	return search;
}
