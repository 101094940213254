import { useMemo } from 'react';
import { useDatatable } from 'app/hooks/useDatatable';
import { FilterMatchMode } from 'primereact/api';
import { useGetTasksQuery } from '../services';

export const useTaskDatatable = (dailyTaskId?: string) => {
	const dt = useDatatable({
		first: 0,
		rows: 5,
		page: 0,
		pageCount: 0,
		sortField: 'id',
		sortOrder: -1,
		multiSortMeta: undefined,
		filters: {
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		},
	});

	const { data: response, ...query } = useGetTasksQuery({
		pageSize: dt.rows,
		pageNumber: dt.page ? dt.page + 1 : 1,
		sortFields: dt.sortField,
		sortOrder: dt.sortOrder === 1 ? 'desc' : dt.sortOrder === -1 ? 'asc' : undefined,
        dailyTaskId
	});

	const totalRecords = useMemo(() => {
		if (!response?.content) return 0;

		return response.totalElements;
	}, [response]);

	return {
		dt,
		...query,
		data: response?.content || [],
		totalRecords,
	};
};
