import LoadingPage from 'app/components/LoadingPage';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { ThresholdsMode } from 'app/types';
import { PanelProps } from 'app/types/Panel';
import { calculateFontSize } from 'app/utils';
import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';
import _ from 'lodash';

export interface TritronikGaugePanelOptions {
	chartOptions: any;
	valueType: 'decimal' | 'float'
}

export interface TritronikGaugePanelProps extends PanelProps<TritronikGaugePanelOptions> {}

const TritronikGaugePanel = (props: TritronikGaugePanelProps) => {
	const { width, height, fieldConfig, panel, refreshInterval, options: panelOptions } = props;

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const thresholds = fieldConfig.defaults.thresholds!;
	const steps = thresholds.steps;
	const decimals = fieldConfig.defaults.decimals ?? 2;
	const dimension = Math.min(width, height);
	const unit = fieldConfig.defaults.unit;
	const min = fieldConfig.defaults.min ?? TritronikGaugePanel.defaultProps.fieldConfig.defaults.min;
	const max = fieldConfig.defaults.max ?? TritronikGaugePanel.defaultProps.fieldConfig.defaults.max;

	const dataChart = useMemo(() => {
		if (data && data.responseDataValue && data.responseDataValue.length > 0) {
			if (data.responseDataValue[0].datapoints) {
				return data.responseDataValue[0]?.datapoints?.map(({ value }) => {
					const mapValue = panelOptions?.valueType === 'decimal' ? value : value * 100;
					return {
						value: mapValue.toFixed(decimals)
					}
				});
			}
			return [{ value: 0 }];
		}

		return [{ value: 0 }];
	}, [data, decimals, panelOptions]);

	const options = useMemo(() => {
		const value = dataChart[0].value;

		const valueWidth = dimension * 0.15;

		const valueFormatted = getValueFormat(unit)(value, decimals);

		const fontSize = calculateFontSize(formattedValueToString(valueFormatted), valueWidth, dimension, 1);

		const fontSizeLabel = calculateFontSize('000', dimension * 0.045, dimension * 0.045, 0.5);

		const lineWidth = Math.min(dimension * 0.07, 30);

		const distanceLabel = dimension * 0.035;

		const pointerLength = Math.min(dimension * 0.25, 60);

		const pointerWidth = dimension * 0.015;

		const stepSize = 1 / steps.length;
		const colors = steps.map((step, i) => {
			return [stepSize * (i + 1), step.color];
		});

		return _.merge({
			series: [
				{
					type: 'gauge',
					min: min,
					max: max,
					axisLine: {
						lineStyle: {
							width: lineWidth,
							color: colors,
						},
					},
					pointer: {
						show: true,
						itemStyle: {
							color: 'auto',
						},
						length: `${pointerLength}%`,
						width: pointerWidth,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: dimension < 200 ? false : true,
						color: 'auto',
						distance: distanceLabel,
						fontSize: fontSizeLabel,
					},
					detail: {
						valueAnimation: true,
						formatter: `{value}${valueFormatted.suffix}`,
						color: 'auto',
						fontSize: fontSize,
					},
					data: dataChart,
				},
			],
		}, panelOptions.chartOptions || {});
	}, [dataChart, steps, decimals, dimension, unit, min, max, panelOptions.chartOptions]);

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<ReactEcharts style={{ width: '100%', height: '100%' }} option={options} />
	)
};

TritronikGaugePanel.defaultProps = {
	fieldConfig: {
		defaults: {
			min: 0,
			max: 100,
			thresholds: {
				mode: ThresholdsMode.Absolute,
				steps: [
					{ value: -Infinity, color: 'green' },
					{ value: 80, color: 'red' },
				],
			},
		},
	},
};

export default TritronikGaugePanel;
