import { useAvailablePermissions } from 'app/hooks/useAvailablePermissions';
import { FormOverview } from 'components';
import { FormikProps } from 'formik';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { capitalizeFirstLetter } from 'utils';
import { RoleConfiguration } from '../types';

export interface FormConfirmationProps {
	formik: FormikProps<RoleConfiguration>;
}

const FormConfirmation = (props: FormConfirmationProps) => {
	const { values } = props.formik;

	const { data: permissions } = useAvailablePermissions();

	let getPermissions = permissions?.map((el) => el);

	let permissionsInputTemp = values.permissionsInput?.map(Number);

	const getConfirmPrivileges = () => {
		let resultOverview = getPermissions?.filter((w) => permissionsInputTemp.includes(w.id)).filter((w) => w.id);

		return resultOverview?.map((el: any, id) => {
			return (
				<React.Fragment key={id}>
					<FormOverview
						label={capitalizeFirstLetter(el.name || '-')}
						value={capitalizeFirstLetter(el.category || 'None' || '-')}
						stripped={id % 2 === 0}
					/>
				</React.Fragment>
			);
		});
	};

	return (
		<React.Fragment>
			<Row>
				<Col>
					<Row className="mt-4">
						<Col>
							<Card>
								<CardBody>
									<h3 className="mb-2">Information</h3>

									<FormOverview label="Name" value={values.name || '-'} stripped />

									<FormOverview label="Tagging" value={values.tag?.join(', ') || '-'} />

									<FormOverview label="Description" value={values.description || '-'} stripped />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row className="mt-4">
						<Col>
							<Card>
								<CardBody>
									<h3 className="mb-2">Permission</h3>
									{getConfirmPrivileges()}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default FormConfirmation;
