import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { logout } from '../../features/auth/authSlice';
import { resetStateAction } from '../actions/resetStateAction';

export const unauthenticatedMiddleware: Middleware = ({
	dispatch
}) => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		if (action.payload.status === 401) {
			dispatch(logout());
			dispatch(resetStateAction());
		}
	}

	return next(action);
};
