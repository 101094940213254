import { createPanel } from 'app/types';
import { FormikProps } from 'formik';
import React, {FC} from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
type Props = {
	formik: FormikProps<createPanel>;
};
const EndPointForm:FC<Props> = (props:Props) => {
	const { formik} = props;

	return formik.values.endPointParams ? formik.values.endPointParams?.map((e,i) => (
		<FormGroup row key={e.id}>
			<Label sm={3}>
				{e.paramName}
			</Label>
			<Col sm={9}>
				<Input
				style={{color:'black', height: e.paramType === 'REQUEST_BODY' ? '100px' : '46px'}}
				name={`endPointParams[${i}].paramValue`}
				type={e.paramType === 'REQUEST_BODY' ? 'textarea' : 'text'}
				onChange={formik.handleChange}
				value={formik.values.endPointParams[i].paramValue}
				 />
			</Col>
		</FormGroup>
	)) : null;
};
export default EndPointForm;
