import React, { createContext, PropsWithChildren, useContext, useRef } from 'react';
import { Toast, ToastMessage } from 'primereact/toast';

type ToastAction = (summary: React.ReactNode, detail: React.ReactNode, life?: number | undefined) => void;
export interface ToastContextProps {
	showToast: (message: ToastMessage) => void;
	toastSuccess: ToastAction;
	toastInfo: ToastAction;
	toastWarn: ToastAction;
	toastError: ToastAction;
}

export const ToastContext = createContext<ToastContextProps | null>(null);

export const ToastProvider = (props: PropsWithChildren<unknown>) => {
	const toast = useRef<Toast>(null);

	const showToast = (message: ToastMessage) => {
		if (toast.current) {
			toast.current.show(message);
		}
	};

	const toastSuccess = (summary: React.ReactNode, detail: React.ReactNode, life?: number | undefined) => {
		showToast({ severity: 'success', contentClassName: '', summary, detail, life });
	};
	const toastInfo = (summary: React.ReactNode, detail: React.ReactNode, life?: number | undefined) => {
		showToast({ severity: 'info', contentClassName: '', summary, detail, life });
	};
	const toastWarn = (summary: React.ReactNode, detail: React.ReactNode, life?: number | undefined) => {
		showToast({ severity: 'warn', contentClassName: '', summary, detail, life });
	};
	const toastError = (summary: React.ReactNode, detail: React.ReactNode, life?: number | undefined) => {
		showToast({ severity: 'error', contentClassName: '', summary, detail, life });
	};

	return (
		<ToastContext.Provider value={{ showToast, toastSuccess, toastInfo, toastWarn, toastError }}>
			{props.children}
			<Toast ref={toast} />
		</ToastContext.Provider>
	);
};

export function useToasts() {
	const ctx = useContext(ToastContext);

	if (!ctx) {
		throw Error('The `useToasts` hook must be called from a descendent of the `ToastProvider`.');
	}

	return ctx;
}
