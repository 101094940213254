import { PanelProps } from 'app/types/Panel'
import React from 'react'
import { TritronikTimelinePanel } from '../TritronikTimelinePanel'

export interface TritronikDevPanelProps extends PanelProps {
}

const TritronikDevPanel = (props: TritronikDevPanelProps) => {

	return <TritronikTimelinePanel {...props} />
}

export default TritronikDevPanel
