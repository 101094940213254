import { useGetPageInfosQuery } from "app/services/PageService";
import { useMemo } from "react";

export function usePageInfo(pageId?: string) {
	const { data, isLoading }  = useGetPageInfosQuery({ pageId }, { skip: !pageId });

	const pageInfo = useMemo(() => {
		if (!data?.content) {
			return null;
		}

		return data.content[0];
	}, [data])

	return { pageInfo, isLoading };
}
