import { FormikInputText, FormikSelect } from 'components'
import { FormikProps } from 'formik'
import React, { useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import { useGetDeviceListQuery } from '../services/siteApi'
import { SiteData } from '../types'

type Props = {
    formik:FormikProps<SiteData>
}

const SiteInfo:React.FC<Props> = ({formik}) => {

	const {data} = useGetDeviceListQuery({})

	const deviceOptions = useMemo(() => {
		if(!data) return []

		return data.map(d => ({
			label:d.id,
			value:d.id
		}))

	}, [data])

  return (
   		 <Row>
			<Col lg="6" sm="12">
        		<h2>Information</h2>
				<FormikInputText row name="name" label="Name" placeholder="Name"  required />
				<FormikInputText row name="region" label="Region" placeholder="Region" required />	
				<FormikSelect row name='deviceId' label="Device ID" value={formik.values.deviceId} options={deviceOptions} filter required/>	
			</Col>
			<Col lg="6" sm="12">
				<h2>Mapping</h2>
				<FormikInputText row type="number" name="latitude" label="Latitude" placeholder="Latitude" required />
				<FormikInputText row type="number" name="longitude" label="Longitude" placeholder="Longitude" required />
			</Col>
		</Row>
  )
}

export default SiteInfo