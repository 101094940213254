import { createApi } from '@reduxjs/toolkit/query/react';
import { authApi } from 'app/features/auth/authAPI';
import { baseQuery } from './../../../services/BaseApi';
import {
	PaginatedResponse,
	RoleConfiguration,
	FeatureType,
	PermissionGroup,
	SetPermissionsToRole,
	FeatureId,
} from './../types';

export const roleConfigurationApi = createApi({
	reducerPath: 'roleConfigurationApi',
	baseQuery: baseQuery,
	tagTypes: ['RoleConfiguration', 'Permission', 'PermissionGroups'],
	endpoints: (builder) => ({
		getRoleConfigurations: builder.query<PaginatedResponse<RoleConfiguration>, any>({
			query: (params) => ({
				url: '/role/',
				params,
			}),
			providesTags: (result) =>
				result?.content
					? [
							...result.content.map(({ id }) => ({ type: 'RoleConfiguration' as const, id })),
							{ type: 'RoleConfiguration', id: 'LIST' },
					  ]
					: [{ type: 'RoleConfiguration', id: 'LIST' }],
		}),

		getRoleById: builder.query<RoleConfiguration, number | string>({
			query: (id) => ({ url: `/role/${id}` }),
			providesTags: (result, error, id) => [{ type: 'RoleConfiguration', id }],
		}),

		// create new
		createRoleConfiguration: builder.mutation<RoleConfiguration, Partial<RoleConfiguration>>({
			query: (body) => ({
				url: '/role/',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'RoleConfiguration', id: 'LIST' }],
		}),

		// update role
		updateRoleConfiguration: builder.mutation<RoleConfiguration, Partial<RoleConfiguration>>({
			query: ({ id, ...body }) => ({
				url: `/role/${id}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'RoleConfiguration', id }],
		}),

		// delete role
		deleteRoleConfiguration: builder.mutation<RoleConfiguration, number | string>({
			query: (id) => ({
				url: `/role/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, id) => [{ type: 'RoleConfiguration', id }],
		}),

		// get feature permissions
		getPermissions: builder.query<PaginatedResponse<FeatureType>, void>({
			query: () => ({
				url: '/privilege/feature',
			}),
			providesTags: (result) =>
				result?.content
					? [
							...result.content.map(({ id }) => ({ type: 'PermissionGroups' as const, id })),
							{ type: 'PermissionGroups', id: 'LIST' },
					  ]
					: [{ type: 'PermissionGroups', id: 'LIST' }],
		}),

		// get permission category
		getPermissionGroupByCategory: builder.query<PermissionGroup, SetPermissionsToRole>({
			query: (body) => ({
				url: '/privilege/feature/group-by-category',
				method: 'POST',
				body,
			}),
		}),

		getPermissionCreate: builder.mutation<any, FeatureId>({
			query: (body) => ({
				url: '/privilege/feature/role/replace',
				method: 'POST',
				body,
			}),
			async onQueryStarted({ roleId }, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(roleConfigurationApi.util.invalidateTags([{ type: 'RoleConfiguration', id: roleId }]));
					dispatch(authApi.util.invalidateTags(['Profile']));
				} catch {}
			},
		}),
	}),
});

export const {
	useGetRoleByIdQuery,
	useGetRoleConfigurationsQuery,
	useCreateRoleConfigurationMutation,
	useUpdateRoleConfigurationMutation,
	useDeleteRoleConfigurationMutation,
	useGetPermissionsQuery,
	useGetPermissionCreateMutation,
} = roleConfigurationApi;
