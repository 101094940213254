import React from 'react';
import classnames from 'classnames';

interface MyCheckboxProps {
	id?: string | undefined;
	label?: string | undefined;
	alternative?: boolean;
}

const MyCheckbox: React.FC<MyCheckboxProps> = ({ id, label, alternative, ...props }) => {
	return (
		<div className={classnames('custom-control custom-checkbox', alternative && 'custom-control-alternative')}>
			<input className="custom-control-input" id={id} type="checkbox" {...props} />
			{label && (
				<label className="custom-control-label" htmlFor={id}>
					<span className={classnames(alternative && 'text-muted')}>{label}</span>
				</label>
			)}
		</div>
	);
};

MyCheckbox.defaultProps = {
	id: Math.random().toString(),
	alternative: false,
};

export default MyCheckbox;
