import { Group, Monitor, ZMLoginRequest, ZMLoginResponse } from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryZM } from './../../services/BaseApi';

export const zmApi = createApi({
    reducerPath: 'zmApi',
    baseQuery: baseQueryZM,
    tagTypes: ["Monitor", "Group"],
    endpoints: (builder) => ({
        login: builder.mutation<ZMLoginResponse, ZMLoginRequest>({
            query: ({ username, password }) => {
                return {
                    url: '/api/host/login.json',
                    method: 'POST',
                    body: new URLSearchParams({ user: username, pass: password }),
                }
            },
        }),
        getMonitorList: builder.query<Monitor[], void>({
            query: () => {
                return {
                    url: '/api/monitors'
                }
            },
            providesTags: ["Monitor"]
        }),
        getMonitor: builder.query<Monitor, number>({
            query: (id) => {
                return {
                    url: '/api/monitors/' + id
                }
            },
            providesTags: ["Monitor"]
        }),
        getGroupList: builder.query<Group[], void>({
            query: () => {
                return {
                    url: '/api/groups'
                }
            },
            providesTags: ["Group"]
        }),
        getGroupByName: builder.query<Group[], any>({
            query: (name) => {
                return {
                    url: '/api/groups/' + name
                }
            },
            providesTags: ["Group"]
        }),
    }),
});

export const {
    useLoginMutation,
    useGetMonitorListQuery,
    useGetGroupListQuery,
    useGetMonitorQuery,
    useGetGroupByNameQuery,
} = zmApi;
