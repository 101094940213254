import LoadingPage from 'app/components/LoadingPage';
import { FormikHelpers } from 'formik';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import SensorRuleInfo, { defaultProps } from '../components/SensorRuleInfo';
import { useGetSensorConfigurationQuery, useUpdateSensorConfigurationMutation } from '../services/sensorApi';
import { SensorConfiguration } from '../types';
import { locationService } from './../../../services/LocationService';

interface PageParams {
	id: string;
}

interface Props {}

const EditSensorPage = (props: Props) => {
	const params = useParams<PageParams>();
	const { data, isLoading } = useGetSensorConfigurationQuery(params.id, { skip: !params.id });
	const history = locationService.getHistory();

	const [updateSensor, { isLoading: isUpdating }] = useUpdateSensorConfigurationMutation();

	const initialValues = useMemo<any>(() => {
		if (!data) return defaultProps.initialValues;

		const { entityTags, ...sensor } = data;

		const refactor = entityTags?.map((el) => el.tagName);

		return {
			entityTags: refactor,
			id: sensor.id,
			name: sensor.name || '',
			description: sensor.description || '',
			type: sensor.type || '',
			sensorName: sensor.sensorName || '',
			sensorConfig: sensor.sensorConfig || '',
			sensorParam: sensor.sensorParam || '',
			subparamName: sensor.subparamName || '',
		};
	}, [data]);

	React.useEffect(() => console.log(initialValues), [initialValues]);

	const onSubmit = (values: SensorConfiguration, formik: FormikHelpers<SensorConfiguration>): void => {
		updateSensor(values)
			.unwrap()
			.then(() => {
				history.push({
					pathname: '/sensor-property',
				});
			});
	};

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<Container fluid>
			<h2 className="my-3">Sensor Configuration</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/sensor-property/">All Assets</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>LVD 1 Operator</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<SensorRuleInfo
								initialValues={initialValues}
								onSubmit={onSubmit}
								isSubmitting={isUpdating}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default EditSensorPage;
