import React, { useCallback } from "react";
import PermissionContext from "./PermissionContext";
import _ from 'lodash';
import { useAppSelector } from "app/store";
import { selectUserPermissions } from "app/features/auth/authSlice";

type Props = {}

const PermissionProvider: React.FunctionComponent<Props> = ({ children }) => {
    const permissions = useAppSelector(selectUserPermissions);

    const allowed = useCallback((permission?: string | string[]) => {
        if (!permission) return false;

        if (permission && Array.isArray(permission)) {
            return _.some(permissions, function(perm) {
                return permission.includes(perm);
            })
        }

        return permissions.includes(permission);
    }, [permissions]);

    return <PermissionContext.Provider value={{ allowed }}>{children}</PermissionContext.Provider>;
}

export default PermissionProvider;
