import { createApi } from '@reduxjs/toolkit/query/react';
import { DeviceDataModel, DeviceDataRequestParams } from '../types';
import { baseQueryAlert } from './../../../services/BaseApi';

export const explorationApi = createApi({
	reducerPath: 'explorationApi',
	baseQuery: baseQueryAlert,
	endpoints: (builder) => ({
		getDeviceData: builder.mutation<DeviceDataModel[], Partial<DeviceDataRequestParams>>({
			query: (params) => ({
				url: '/api/smart-solution/data-device/get-data',
				method: 'POST',
				params: params,
				body: {}
			}),
		})
	})
});

export const {
	useGetDeviceDataMutation,
} = explorationApi;
