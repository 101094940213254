/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { defaultUserProfile } from 'assets';
import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
// reactstrap components
import {
	Collapse,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	// Form,
	// FormGroup,
	// Input,
	// InputGroup,
	// InputGroupAddon,
	// InputGroupText,
	Media,
	Nav,
	Navbar,
	NavItem,
	// NavLink,
	UncontrolledDropdown,
} from 'reactstrap';

import { useAuth } from 'app/features/auth/hooks/useAuth';

export interface AdminNavbarProps extends PropsWithChildren<any> {
	toggleSidenav: () => void;
	sidenavOpen: boolean;
	theme: string;
}

const AdminNavbar: React.FC<AdminNavbarProps> = (props) => {
	const { user, doLogout } = useAuth();

	// function that on mobile devices makes the search open
	// const openSearch = () => {
	// 	document.body.classList.add('g-navbar-search-showing');
	// 	setTimeout(function () {
	// 		document.body.classList.remove('g-navbar-search-showing');
	// 		document.body.classList.add('g-navbar-search-show');
	// 	}, 150);
	// 	setTimeout(function () {
	// 		document.body.classList.add('g-navbar-search-shown');
	// 	}, 300);
	// };
	// function that on mobile devices makes the search close
	// const closeSearch = () => {
	// 	document.body.classList.remove('g-navbar-search-shown');
	// 	setTimeout(function () {
	// 		document.body.classList.remove('g-navbar-search-show');
	// 		document.body.classList.add('g-navbar-search-hiding');
	// 	}, 150);
	// 	setTimeout(function () {
	// 		document.body.classList.remove('g-navbar-search-hiding');
	// 		document.body.classList.add('g-navbar-search-hidden');
	// 	}, 300);
	// 	setTimeout(function () {
	// 		document.body.classList.remove('g-navbar-search-hidden');
	// 	}, 500);
	// };
	return (
		<>
			<Navbar
				className={classnames(
					'navbar-top navbar-expand',
					{ 'navbar-dark bg-default': props.theme === 'dark' },
					{ 'navbar-light bg-secondary': props.theme === 'light' },
				)}
			>
				<Container fluid>
					<Collapse navbar isOpen={true}>
						{/* <Form
							className={classnames(
								'navbar-search form-inline mr-sm-3',
								{ 'navbar-search-light': props.theme === 'dark' },
								{ 'navbar-search-dark': props.theme === 'light' },
							)}
						>
							<FormGroup className="mb-0">
								<InputGroup className="input-group-alternative input-group-merge">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="fas fa-search" />
										</InputGroupText>
									</InputGroupAddon>
									<Input placeholder="Search" type="text" />
								</InputGroup>
							</FormGroup>
							<button aria-label="Close" className="close" type="button" onClick={closeSearch}>
								<span aria-hidden={true}>×</span>
							</button>
						</Form> */}

						<Nav className="align-items-center ml-md-auto" navbar>
							<NavItem className="d-xl-none">
								<div
									className={classnames(
										'pr-3 sidenav-toggler',
										{ active: props.sidenavOpen },
										{ 'sidenav-toggler-dark': props.theme === 'dark' },
									)}
									onClick={props.toggleSidenav}
								>
									<div className="sidenav-toggler-inner">
										<i className="sidenav-toggler-line" />
										<i className="sidenav-toggler-line" />
										<i className="sidenav-toggler-line" />
									</div>
								</div>
							</NavItem>
							{/* <NavItem className="d-sm-none">
								<NavLink onClick={openSearch}>
									<i className="ni ni-zoom-split-in" />
								</NavLink>
							</NavItem> */}
						</Nav>
						<Nav className="align-items-center ml-auto ml-md-0" navbar>
							<UncontrolledDropdown nav>
								<DropdownToggle className="nav-link pr-0" color="" tag="a">
									<Media className="align-items-center">
										<span className="avatar avatar-sm rounded-circle">
											<img alt="..." src={defaultUserProfile} />
										</span>
										<Media className="ml-2 d-none d-lg-block">
											<span className="mb-0 text-sm font-weight-bold">
												{user?.userInfo?.name || user?.username}
											</span>
										</Media>
									</Media>
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem className="noti-title" header tag="div">
										<h6 className="text-overflow m-0">Welcome!</h6>
									</DropdownItem>
									{/* <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
										<i className="ni ni-single-02" />
										<span>My profile</span>
									</DropdownItem> */}
									<DropdownItem divider />
									<DropdownItem
										href="#logout-action"
										onClick={(e) => {
											e.preventDefault();
											doLogout();
										}}
									>
										<i className="mdi mdi-power-settings" />
										<span>Logout</span>
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		</>
	);
};

AdminNavbar.defaultProps = {
	toggleSidenav: () => {},
	sidenavOpen: false,
	theme: 'dark',
};

export default AdminNavbar;
