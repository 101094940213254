import LoadingPage from 'app/components/LoadingPage';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import {
	useCutPanelMutation,
	useDeletePanelMutation,
	useDuplicatePanelMutation,
	useGetPanelDataQuery,
} from 'app/services/PanelService';
import { PanelProps } from 'app/types/Panel';
import { Dropdown as DropdownNormal, DropdownChangeParams, DropdownProps } from 'primereact/dropdown';
import React, { useCallback, useMemo, useState } from 'react';
import { locationService } from 'app/services/LocationService';
import { css } from '@emotion/css';
import { useAppSelector } from 'app/store';
import Dropdown from 'react-multilevel-dropdown';
import { useParams } from 'react-router';
import Swal from 'sweetalert2';

export interface TritronikFilterDropdownPanelOptions extends DropdownProps {
	queryFieldName?: string;
}

export interface TritronikFilterDropdownPanelProps extends PanelProps<TritronikFilterDropdownPanelOptions> {}

const TritronikFilterDropdownPanel = (props: TritronikFilterDropdownPanelProps) => {
	const { panel, refreshInterval, options } = props;
	const params = usePanelDataParams();
	const { data, isLoading } = useGetPanelDataQuery(
		{ id: panel.id, ...params },
		{ skip: !panel, pollingInterval: refreshInterval },
	);

	const [selectedValue, setSelectedValue] = useState<any>(options.queryFieldName ? params[options.queryFieldName] : null);

	const dropdownOptions: any[] = useMemo(() => {
		const opts: any[] = [];

		if (
			data &&
			data.responseDataValue &&
			Array.isArray(data.responseDataValue) &&
			data.responseDataValue.length > 0
		) {
			data.responseDataValue.forEach((d) => {
				if (options.optionValue && options.optionLabel) {
					opts.push({
						[options.optionValue]: d[options.optionValue]?.toString(),
						[options.optionLabel]: d[options.optionLabel],
					});
				}
			});
		}

		return opts;
	}, [data, options]);

	const updateVariable = useCallback(
		(value) => {
			const { hash, ...location } = locationService.getLocation();
			const urlParams = locationService.getSearch();

			if (options.queryFieldName) {
				urlParams.set(options.queryFieldName, value);
			}

			locationService.push({
				...location,
				search: urlParams.toString(),
			});
		},
		[options.queryFieldName],
	);

	const onChange = (e: DropdownChangeParams) => {
		setSelectedValue(e.value);
		updateVariable(e.value);
	};

	const [deletePanel] = useDeletePanelMutation();

	const isEditing = useAppSelector((state) => state.updateGrid.isEditing);

	const history = locationService.getHistory();

	const { uid: pageId } = useParams<any>();

	const navigateEditPanel = () => {
		history.push({ pathname: `/dashboard-management/edit-panel/${pageId}/${panel.id}` });
	};

	const handleDelete = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
		}).then((result) => {
			if (result.isConfirmed) {
				deletePanel(panel.id);
				Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
			}
		});
	};
	const [duplicatePanel] = useDuplicatePanelMutation();

	const [cutPanel] = useCutPanelMutation();

	const handleDuplicate = () => {
		duplicatePanel({ panelId: panel.id, pageId });
	};

	const menus = useAppSelector((state) => state.page.pages);

	const subMenu = useMemo(() => {
		return menus.filter((menu) => menu.pageType === 'DASHBOARD');
	}, [menus]);

	const handleDuplicateTo = (panelId, menu) => {
		const { id, name, path } = menu;
		duplicatePanel({ panelId, pageId: id })
			.then(() =>
				Swal.fire({
					title: 'success !',
					icon: 'success',
					html: `the panel has moved to page ${name}. <a href='/d/${id}/smart-site/${path}'>Click here to redirect </a>`,
				}),
			)
			.catch((err) =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: err,
				}),
			);
	};

	const cutValue = useCallback(
		(id) => {
			return {
				...panel,
				pageId: id,
			};
		},
		[panel],
	);

	const handleMoveTo = useCallback(
		(panelId, menu) => {
			const { id, name, path } = menu;
			const body = cutValue(id);
			cutPanel({ panelId, body })
				.then(() =>
					Swal.fire({
						title: 'success !',
						icon: 'success',
						html: `the panel has moved to page ${name}. <a href='/d/${id}/smart-site/${path}'>Click here to redirect </a>`,
					}),
				)
				.catch((err) =>
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: err,
					}),
				);
		},
		[cutPanel, cutValue],
	);

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div className={styles.wrapper}>
			<DropdownNormal
				value={selectedValue}
				onChange={onChange}
				options={dropdownOptions}
				optionValue={options.optionValue}
				optionLabel={options.optionLabel}
				className={styles.dropdown}
			/>
			{isEditing && (
				<span className={styles.editButton}>
					<Dropdown
						menuClassName={styles.menuOverflow}
						buttonClassName={styles.buttonContainer}
						title={<i className="pi pi-ellipsis-v" style={{ fontSize: '1.5em' }}></i>}
					>
						<Dropdown.Item onClick={navigateEditPanel}>
							<i className="pi pi-pencil mr-2"></i> Edit Panel
						</Dropdown.Item>
						<Dropdown.Item onClick={handleDuplicate}>
							<i className="pi pi-copy mr-2"></i> Duplicate Panel
						</Dropdown.Item>
						<Dropdown.Item className={styles.overflow}>
							<i className="pi pi-external-link mr-2"></i> Copy Panel To
							<Dropdown.Submenu>
								{subMenu &&
									subMenu.map((menu) => (
										<Dropdown.Item onClick={() => handleDuplicateTo(panel.id, menu)} key={menu.id}>
											{menu.name}
										</Dropdown.Item>
									))}
							</Dropdown.Submenu>
						</Dropdown.Item>
						<Dropdown.Item className={styles.overflow}>
							<i className="pi pi-external-link mr-2"></i> Move Panel To
							<Dropdown.Submenu>
								{subMenu &&
									subMenu.map((menu) => (
										<Dropdown.Item onClick={() => handleMoveTo(panel.id, menu)} key={menu.id}>
											{menu.name}
										</Dropdown.Item>
									))}
							</Dropdown.Submenu>
						</Dropdown.Item>
						<Dropdown.Item onClick={handleDelete} className={styles.deleteText}>
							<i className="pi pi-trash mr-2"></i> Delete Panel
						</Dropdown.Item>
					</Dropdown>
				</span>
			)}
		</div>
	);
};

const styles = {
	wrapper: css`
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	`,
	dropdown: css`
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
	`,
	editButton: css`
		text-align: end;
		cursor: pointer;
		margin-top: 5px;
	`,
	buttonContainer: css`
		padding: 0 !important;
		box-shadow: none !important;
		background-color: transparent !important;
		margin-right: 6px !important;
		font-size: 15px !important;
	`,
	deleteText: css`
		color: red !important;
	`,
	menuOverflow: css`
		z-index: 99999999999 !important;
		position: absolute !important;
	`,
	overflow: css`
		z-index: 99999999999 !important;
		position: relative !important;
	`,
};

export default TritronikFilterDropdownPanel;
