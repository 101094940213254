import LoadingPage from 'app/components/LoadingPage';
import { locationService } from 'app/services/LocationService';
import { FormikHelpers } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import SiteForm, { defaultProps } from '../components/SiteForm';
import { useGetSiteByIdQuery, useUpdateSiteMutation } from '../services/siteApi';
import { SiteData } from '../types';

interface PageParams {
	id: string;
}

interface Props {}

const EditSitePage: React.FC<Props> = () => {
	const { id } = useParams<PageParams>();

	const { data, isLoading } = useGetSiteByIdQuery(id, { skip: !id });

	const initialValues = useMemo<any>(() => {
		if (!data) return defaultProps.initialValues;

		const { ...site } = data;

		return { ...site };
	}, [data]);

	useEffect(() => {}, [initialValues]);

	const history = locationService.getHistory();

	const [updateSite, { isLoading: isUpdating }] = useUpdateSiteMutation();

	const onSubmit = (values: SiteData, formik: FormikHelpers<SiteData>): void => {
		const id = values.id;
		updateSite({ id, body: values })
			.unwrap()
			.then(() => history.push({ pathname: '/site-management' }));
	};

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<Container fluid>
			<h2 className="my-3">Site Management</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/site-management">Site Management</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>Edit Site</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<SiteForm initialValues={initialValues} onSubmit={onSubmit} isSubmitting={isUpdating} />
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default EditSitePage;
