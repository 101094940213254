import LoadingPage from 'app/components/LoadingPage';
import { FormikHelpers } from 'formik';
import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import FormEdit, { defaultProps } from '../components/FormEdit';
import { useGetTenantConfigurationQuery, useUpdateTenantConfigurationMutation } from '../services/tenantApi';
import { AccountConfiguration } from '../types';
import { locationService } from './../../../services/LocationService';

interface PageParams {
	id: string;
}
export interface Props {}

const EditTenant = (props: Props) => {
	const params = useParams<PageParams>();
	const { data, isLoading } = useGetTenantConfigurationQuery(params.id, { skip: !params.id });
	const history = locationService.getHistory();

	const [updateAccount, { isLoading: isUpdating }] = useUpdateTenantConfigurationMutation();

	const initialValues = useMemo<AccountConfiguration>(() => {
		if (!data) return defaultProps.initialValues;

		const { name, address, contact, email, deviceApi, ...tenant } = data;

		return {
			...tenant,
			name: name || '',
			address: address || '',
			contact: contact || '',
			email: email || '',
			deviceApi: deviceApi || {
				headerInfo: '',
				url: '',
				tenantId: '',
				id: '',
			},
		};
	}, [data]);

	React.useEffect(() => console.log(initialValues), [initialValues]);

	useEffect(() => {}, [initialValues]);

	const onSubmit = (values: AccountConfiguration, formik: FormikHelpers<AccountConfiguration>): void => {
		updateAccount(values)
			.unwrap()
			.then(() => {
				history.push({
					pathname: '/tenant-management',
				});
			});
	};

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<Container fluid>
			<h2 className="my-3">Edit Account</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/tenant-management/">Rules</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>Add New</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormEdit initialValues={initialValues} onSubmit={onSubmit} isSubmitting={isUpdating} />
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default EditTenant;
