import { createApi } from '@reduxjs/toolkit/query/react';
import { DeviceListResponse, SiteData, SiteResponse } from '../types';
import { baseQuerySite } from './../../../services/BaseApi';

export const siteManagementApi = createApi({
	reducerPath: 'siteManagementApi',
	baseQuery: baseQuerySite,
	tagTypes: ['siteManagement'],
	endpoints: (builder) => ({
		getSiteManagement:builder.query<SiteResponse, any>({
            query:() => ({
                url:'/v1/sites/'
            }),
            providesTags: (result) =>
				result?.content
					? [
							...result.content.map(({ id }) => ({ type: 'siteManagement' as const, id })),
							{ type: 'siteManagement', id: 'LIST' },
					  ]
					: [{ type: 'siteManagement', id: 'LIST' }],
        }),
        createSite:builder.mutation<SiteData, Partial<SiteData>>({
            query:({id, ...body}) => ({
                url:`/v1/sites/${id}`,
                method:'POST',
                body
            }),
            invalidatesTags: [{ type: 'siteManagement', id: 'LIST' }],
        }),
        getSiteById:builder.query<SiteResponse, string>({
            query:(id) => ({
                url:`/v1/sites/${id}`,
            }),
            providesTags: (result, error, id) => [{ type: 'siteManagement', id }],
        }),
        updateSite:builder.mutation<SiteResponse, any | number>({
            query:({id, body}) => ({
                url:`/v1/sites/${id}`,
                method:'PUT',
                body
            }),
            invalidatesTags: [{type:'siteManagement', id: 'LIST'}]
        }),
        deleteSite:builder.mutation<SiteResponse, string>({
            query:(id) => ({
                url:`v1/sites/${id}`,
                method:'DELETE',
            }),
            invalidatesTags: [{ type: 'siteManagement', id: 'LIST' }],
        }),
        getDeviceList:builder.query<DeviceListResponse[], any>({
            query: () => `v1/devices/list`
        })
	}),
});

export const {
	useGetSiteManagementQuery,
    useCreateSiteMutation,
    useGetSiteByIdQuery,
    useUpdateSiteMutation,
    useDeleteSiteMutation,
    useGetDeviceListQuery
} = siteManagementApi;






