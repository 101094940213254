import React from 'react';
import FlexRow from './FlexRow';
import FlexRowLeftItem from './FlexRowLeftItem';
import FlexRowRightItem from './FlexRowRightItem';

interface FormOverviewProps {
	stripped?: boolean;
	label?: any;
	value?: any;
  leftItemClassName?: string;
  rightItemClassName?: string;
  isJSON?:boolean
  icon?:string
}

const FormOverview = (props: FormOverviewProps) => {
	const { stripped, label, value, leftItemClassName, rightItemClassName, isJSON, icon } = props;
	return (
		<FlexRow stripped={stripped}>
			<FlexRowLeftItem className={leftItemClassName}>{label}</FlexRowLeftItem>
			<FlexRowRightItem isJSON={isJSON} className={rightItemClassName}><i className={icon} style={{marginRight:'0.25rem'}}></i>{value}</FlexRowRightItem>
		</FlexRow>
	);
};

export default FormOverview;
