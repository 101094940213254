import { createContext } from 'react';

type PermissionContextType = {
    allowed: (permission?: string | string[]) => boolean;
}

const defaultBehaviour: PermissionContextType = {
    allowed: () => false,
}

const PermissionContext = createContext<PermissionContextType>(defaultBehaviour);

export default PermissionContext;
