import { nullableString } from 'app/utils/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { useTaskDatatable } from '../hooks/useTaskDatatable';
import { IDailyTask, ITask } from '../types';

type Props = {
    data: IDailyTask;
    onAddTask?: (data: IDailyTask) => void;
    onEditTask?: (task: ITask, dailyTask: IDailyTask) => void;
    onDeleteTask?: (data: ITask) => void;
};

const TaskExpansionTemplate = ({ data, onAddTask, onEditTask, onDeleteTask }: Props) => {
    const { data: tasks, isLoading, totalRecords, dt } = useTaskDatatable(data.id);

    const actionBodyTemplate = (rowData: ITask) => {
        return (
            <div className='d-flex align-items-center justify-content-end'>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-text p-button-warning"
                    onClick={() => onEditTask?.(rowData, data)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-text p-button-danger"
                    onClick={() => onDeleteTask?.(rowData)}
                />
            </div>
        );
    }

    return (
        <div className="sub-daily-task">
            <div className="d-flex flex-fill align-items-center justify-content-between mb-2">
                <h3>
                    Day {data.day} - {data.taskName}
                </h3>
                <Button
                    icon="mdi mdi-plus-circle"
                    label="Add Task"
                    className="p-button-sm"
                    onClick={() => onAddTask?.(data)}
                />
            </div>
            <DataTable
                lazy
                loading={isLoading}
                value={tasks}
                responsiveLayout="scroll"
                paginator
                rows={dt.rows}
                first={dt.first}
                totalRecords={totalRecords}
                sortField={dt.sortField}
                sortOrder={dt.sortOrder}
                onPage={dt.onPage}
                onSort={dt.onSort}
            >
                <Column field="name" header="Name" body={({ name }) => nullableString(name)}></Column>
                <Column field="desc" header="Description" body={({ desc }) => nullableString(desc)}></Column>
                <Column field="inputField" header="Input Field" body={({ inputField }) => nullableString(inputField)}></Column>
                <Column headerStyle={{ width: '4rem' }} body={actionBodyTemplate}></Column>
            </DataTable>
        </div>
    );
};

export default TaskExpansionTemplate;
