import LoadingPage from 'app/components/LoadingPage';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { PanelProps } from 'app/types/Panel';
import { getDuration } from 'app/utils/formatValue';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import React, { useMemo } from 'react';

type Options = {
	chartOptions: any;
	mappings: any;
}

export interface TritronikHorizontalBarChartPanelProps extends PanelProps<Options> {

}

const TritronikHorizontalBarChartPanel = (props: TritronikHorizontalBarChartPanelProps) => {
	const { width, height, panel, refreshInterval, options: { chartOptions, mappings } } = props;

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, modelId: params.id || undefined, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const datasource = data && data.responseDataValue && data.responseDataValue.length > 0 ? data.responseDataValue : [];

	const options = useMemo(() => {

		const labels = datasource.map((ds) => ds.target);

		const tooltipUnit = chartOptions?.tooltip?.unit ?? chartOptions?.unit;
		const labelUnit = chartOptions?.label?.unit ?? chartOptions?.unit;
		const axisLabelUnit = chartOptions?.axisLabel?.unit ?? chartOptions?.unit;

		const formatterValue = (value: number, labelUnit?: string) => {
			if (labelUnit === 'duration') {
				return getDuration(value);
			}
			return formattedValueToString(getValueFormat(labelUnit || 'none')(value));
		}

		const dataMapping = mappings?.dataOptions;
		const dataSeries = datasource.map((ds) => {
			const dataOptionsIndex = dataMapping && dataMapping?.findIndex(t => t.name === ds.target);
			const dataOptions = dataOptionsIndex > -1 ? dataMapping[dataOptionsIndex] : {}

			return {
				value: ds.datapoints[0]?.value || 0,
				label: {
					show: true,
					precision: 1,
					position: 'right',
					valueAnimation: true,
					formatter: (params: any) => {
						if (chartOptions?.useFormatter) {
							return formatterValue(params.value, labelUnit);
						}
						return undefined;
					}
				},
				tooltip: {
					formatter: (params: any) => {
						const value = chartOptions?.useFormatter ? formatterValue(params.data.value, tooltipUnit) : params.data.value;
						return `<div style="margin: 0px 0 0;line-height:1;">${params.marker}<span style="font-size:14px;font-weight:400;margin-left:2px;">${params.name}<span><span style="float:right;margin-left:20px;font-size:14px;font-weight:900;">${value}</span><div style="clear:both"></div></div>`;
					},
				},
				emphasis: {
					label: { show: false }
				},
				...dataOptions.options,
			}
		});

		return _.merge({
			tooltip: {},
			legend: {},
			grid: {
				top: 10,
				right: 80,
				left: '15%'
			},
			xAxis: {
				type: 'value',
				max: 'dataMax',
				axisLabel: {
					formatter: (value: any) => {
						if (chartOptions?.useFormatter) {
							return formatterValue(value, axisLabelUnit);
						}
						return undefined;
					}
				}
			},
			yAxis: {
				type: 'category',
				inverse: true,
				data: labels,
				axisLabel: {
					show: true,
				}
			},
			series: [
				{
					realtimeSort: true,
					type: 'bar',
					data: dataSeries
				}
			]
		}, chartOptions);
	}, [datasource, chartOptions, mappings])

	if (isLoading) {
		return <LoadingPage />;
	}

	return <ReactEcharts style={{ width, height }} option={options} />;
}

export default TritronikHorizontalBarChartPanel
