import { ParentSize } from '@vx/responsive';
import Battery from 'app/components/Battery';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { PanelProps, ResponseData } from 'app/types';
import React, { CSSProperties, useCallback } from 'react';
import { css } from '@emotion/css';
import { useMemo } from 'react';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';
import LoadingPage from 'app/components/LoadingPage';

export interface Description {
    show: boolean;
    label: string;
    dataset: {
        from: 'query' | 'data' | string;
        datasetKey: string;
        datasetIndex: number;
        datasetField: string;
        filterField: string;
        filterKey: string;
    };
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    valueStyle?: CSSProperties;
}

export interface BatteryOverviewOptions {
    descriptions?: Description[];
    forceFullBattery?: boolean;
}

export interface Props extends PanelProps<BatteryOverviewOptions> {}

const TritronikBatteryOverviewPanel = (props: Props) => {
    const { width, height, panel, refreshInterval, options } = props;

    const { forceFullBattery = true, descriptions = [] } = options;

    const params = usePanelDataParams();
    const { data, isLoading } = useGetPanelDataQuery(
        { id: panel.id, ...params },
        { skip: !panel, pollingInterval: refreshInterval },
    );

    const datasets = useMemo(() => {
        if (!data) return [] as ResponseData[];

        return data.responseDataValue as ResponseData[];
    }, [data]);

    const getDataByAliasLabel = useCallback(
        (field: string, search: string) => {
            const dataFound = datasets.find((d) => d[field] === search);

            return dataFound;
        },
        [datasets],
    );

    const descCount = descriptions ? descriptions.length : 0;

    const labelClassName = css`
        font-size: ${(Math.min(width, height) * 0.225) / descCount}px;
    `
    const valueClassName = css`
        font-size: ${(Math.min(width, height) * 0.175) / descCount}px;
    `

    const renderDescriptions = useMemo(() => {
        return (
            descriptions &&
            descriptions.map(({ label, dataset, containerStyle, labelStyle, valueStyle }, i) => {
                let displayValue: any = null;

                if (dataset && dataset.from === 'data') {
                    const { filterField, filterKey, datasetField, datasetIndex } = dataset;
                    const ds = getDataByAliasLabel(filterField, filterKey);
                    const value = ds ? ds[datasetField][datasetIndex || 0]?.value : null;
                    const formattedValue = getValueFormat(ds?.unit || 'none')(value);

                    displayValue = formattedValueToString(formattedValue);
                }

                return (
                    <div key={`desc-item-${i}`} className="d-flex flex-column mb-2" style={containerStyle}>
                        <div style={labelStyle} className={labelClassName}>{label}</div>
                        <div style={valueStyle} className={valueClassName}>{displayValue}</div>
                    </div>
                );
            })
        );
    }, [descriptions, getDataByAliasLabel, labelClassName, valueClassName]);

    if (isLoading) {
		return <LoadingPage />;
	}

    return (
        <ParentSize>
            {({ width, height }) => (
                <div className="p-2 d-flex flex-wrap justify-content-center" style={{ width: '100%', height: '100%' }}>
                    <div className="d-flex flex-1 pt-2">
                        <Battery
                            width={width * 0.2}
                            height={height * 0.8}
                            percentage={forceFullBattery ? 100 : 0}
                            showInfo={false}
                        />
                    </div>
                    <div className="ml-sm-0 ml-md-4">
                        <div className="d-flex flex-column">{renderDescriptions}</div>
                    </div>
                </div>
            )}
        </ParentSize>
    );
};

export default TritronikBatteryOverviewPanel;
