import React from 'react'

type Props = {}

const EditTask = (props: Props) => {
  return (
    <div>EditTask</div>
  )
}

export default EditTask
