import { FormOverview } from 'components'
import { FormikProps } from 'formik'
import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { InitialValuesLoginSetting } from './LoginConfig'

type Props = {
    formik:FormikProps<InitialValuesLoginSetting>
}

const LoginConfirmation:React.FC<Props> = ({formik}) => {
  return (
    <Row className="d-flex justify-content-center">
    <Col>
        <Card>
            <CardBody>
                <FormOverview label="Domain" value={formik.values.subdomain} stripped />
                <FormOverview label="Project Title" value={formik.values.logoImg} />
                <FormOverview label="Dashboard Icon" value={formik.values.backgroundImg} stripped />
                <FormOverview label="Favicon" value={formik.values.caption}  />
            </CardBody>
        </Card>
    </Col>
</Row>
  )
}

export default LoginConfirmation