import React, { useMemo, useRef } from 'react';
import { css } from '@emotion/css';
import LoadingPage from 'app/components/LoadingPage';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { PanelProps } from 'app/types/Panel';
import { calculateFontSize } from 'app/utils';
import moment from 'moment';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import classNames from 'classnames';

export interface PanelOptions {
	defaultImageUrl: string;
	timestampLabel: string;
	noDataValue: string;
	ocrLabel: string;
	noOcrValue: string;
}

export interface TritronikBasicImageOcrPanelProps extends PanelProps<PanelOptions> {

}

const TritronikBasicImageOcrPanel = (props: TritronikBasicImageOcrPanelProps) => {
	const { width, height, panel, refreshInterval, options } = props;
	const op = useRef<any>(null);

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const dimension = Math.min(width, height);

	const { imageUrl, timestamp, detectedText } = useMemo(() => {
		let imageUrl = options.defaultImageUrl || process.env.PUBLIC_URL+'/img/no-image.jpg';
		let timestamp = options.noDataValue || 'No Data';
		let detectedText = options.noOcrValue || 'No Data';

		if (data && data.responseDataValue && data.responseDataValue.length > 0) {
			if (data.responseDataValue[0].imageUrl) {
				imageUrl = data.responseDataValue[0].imageUrl;
			}
			if (data.responseDataValue[0].dataTimestamp) {
				timestamp = moment(data.responseDataValue[0].dataTimestamp).format('LLL');
			}
			if (data.responseDataValue[0].detectedText) {
				detectedText = data.responseDataValue[0].detectedText;
			}
		}

		return { imageUrl, timestamp, detectedText };
	}, [data, options]);

	const timestampLabel = options.timestampLabel || 'Time Captured';

	const timestampLabelFs = calculateFontSize(timestampLabel, dimension * 0.3, dimension * 0.3, 1, (dimension * 0.2));
	const timestampFs = calculateFontSize(timestamp, dimension * 0.6, dimension * 0.6, 1, (dimension * 0.05));

	const wrapper = css`
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
	`;

	const containerStyle = css`
		height: ${dimension}px;
		width: ${dimension}px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: ${width * 0.025}px ${height * 0.025}px;
	`;


	const imageContainerStyle = css`
		height: ${height * 0.55}px;
		width: ${width * 0.6}px;
		position: relative;
	`;

	const imageStyle = css`
		width: 100%;
		height: 100%;
		object-fit: contain;
		text-align: center;
		vertical-align: middle;
	`;

	const timestampLabelStyle = css`
		margin-top: ${height * 0.025}px;
		text-align: center;
		color: gray;
		font-weight: bold;
		line-height: 0.5;
		font-size: ${timestampLabelFs}px;
	`;

	const timestampStyle = css`
		margin-top: ${height * 0.025}px;
		text-align: center;
		color: gray;
		line-height: 0.5;
		font-size: ${timestampFs}px;
	`;

	const buttonStyle = css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #cecece !important;
	`;

	const containerInfo = css`
		margin-top: ${height * 0.025}px;
	`;

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div className={wrapper}>
			<div className={containerStyle}>
				<div className={imageContainerStyle}>
					<img className={imageStyle} src={imageUrl} title={timestamp} alt={timestamp} />
					<Button
						icon="pi pi-search-plus"
						className={classNames("p-button-rounded p-button-text", buttonStyle)}
						onClick={(e) => op.current.toggle(e)}
						aria-haspopup
						aria-controls={`overlay_image_${panel.id}`}
					/>

					<OverlayPanel ref={op} showCloseIcon id={`overlay_image_${panel.id}`} style={{ width: '500px' }}>
						<img className={imageStyle} src={imageUrl} title={timestamp} alt={timestamp} />
					</OverlayPanel>
				</div>
				<div className={containerInfo}>
					<div>
						<p className={timestampLabelStyle}>{options.timestampLabel || 'Time Captured'}</p>
						<p className={timestampStyle}>{timestamp}</p>
					</div>
					<div>
						<p className={timestampLabelStyle}>{options.ocrLabel || 'Text Detected'}</p>
						<p className={timestampStyle}>{detectedText}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TritronikBasicImageOcrPanel
