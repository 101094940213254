import { DataTableFilterParams, DataTablePageParams, DataTableSortParams } from "primereact/datatable";
import { useMemo, useState } from "react";
import { useGetAlertConfigurationsQuery } from "../services/alertApi";

export function useAlertDatatable() {
	const [pageState, setPageState] = useState<DataTablePageParams>({
		first: 0,
		rows: 10,
		page: 1,
		pageCount: 0
	})

	const [sortState, setSortState] = useState<DataTableSortParams>({
		sortField: 'id',
		sortOrder: null,
		multiSortMeta: undefined,
	});

	const [filterState, setFilterState] = useState<DataTableFilterParams>({
		filters: {}
	});

	const sortOrder = useMemo(() => {
		switch (sortState.sortOrder) {
			case 1:
				return "ASC";
			case -1:
				return "DESC";
			default: return null;
		}
	}, [sortState.sortOrder]);

	const { data, ...query } = useGetAlertConfigurationsQuery({
		pageNumber: pageState.page ? (pageState.page > 0 ? pageState.page - 1 : pageState.page) : undefined,
		pageSize: pageState.rows,
		sortFields: sortState.sortField,
		sortOrder: sortOrder
	});

	const totalRecords = useMemo(() => {
		if (!data?.content) return 0;

		return data.totalElements;
	}, [data]);

	const onPage = (event: DataTablePageParams) => {
		setPageState((_state) => ({ ..._state, ...event }));
		console.log({ paging: event });
	}

	const onSort = (event: DataTableSortParams) => {
		setSortState((_state) => ({ ..._state, ...event }));
		console.log({ sorts: event });
	}

	const onFilter = (event: DataTableFilterParams) => {
		setPageState((_state) => ({ ..._state, first: 0 }));
		setFilterState((_state) => ({ ..._state, ...event }));
		console.log({ filters: event });
	}

	return {
		data,
		...query,
		...pageState,
		...sortState,
		...filterState,
		totalRecords,
		onPage,
		onSort,
		onFilter,
	}
}
