import React from 'react';
import { Button, ButtonProps } from 'reactstrap';

interface MyButtonProps extends ButtonProps {}

const MyButton: React.FC<MyButtonProps> = ({ children, ...props }) => {
	return <Button {...props}>{children}</Button>;
};

MyButton.defaultProps = {
	color: 'default',
	tag: 'button',
};

export default MyButton;
