// Parent Component.
// Child Component ( FormRuleInfo & TenantFormConfirm)

import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useState } from 'react';
import { AccountConfiguration } from '../types';
import { validationsEdit } from '../validationSchema';
import TenantFormConfirm from './TenantFormConfirm';
import { Row, Col } from 'reactstrap';
import { PrimeSteps } from 'components';
import { Button } from 'primereact/button';
import { history } from 'utils';
import { tenantService } from '../services/TenantSevice';
import FormRuleInfo from './FormRuleInfo';

export interface AccountRuleFormProps {
	initialValues: AccountConfiguration;
	onSubmit: (values: AccountConfiguration, formik: FormikHelpers<AccountConfiguration>) => void;
	isSubmitting?: boolean;
}

const formSteps = [{ label: 'TENANT INFORMATION' }, { label: 'CONFIRMATION' }];

const FormEdit = ({ initialValues, onSubmit, isSubmitting }: AccountRuleFormProps) => {
	const [activeStep, setActiveStep] = useState(0);
	const isLastStep = activeStep === formSteps.length - 1;
	const currentValidation = validationsEdit[activeStep];
	const applicationCategory = tenantService.getApplicationOption();

	const typeApiCategory = tenantService.getTypeOption();

	const nextStep = () => {
		if (!isLastStep) {
			setActiveStep((currentStep) => currentStep + 1);
		}
	};

	const prevStep = () => {
		if (activeStep > 0) {
			setActiveStep((currentStep) => currentStep - 1);
		}
	};

	const _onSubmit = (values: AccountConfiguration, formik: FormikHelpers<AccountConfiguration>) => {
		onSubmit(values, formik);
		console.log('value input edit', values);

		if (isLastStep) {
			history.push('/tenant-management');
		} else {
			nextStep();
		}
	};

	const _handleSubmit = (values: AccountConfiguration, formik: FormikHelpers<AccountConfiguration>): void => {
		if (isLastStep) {
			_onSubmit(values, formik);
		} else {
			nextStep();
			formik.setTouched({});
			formik.setSubmitting(false);
		}
	};

	const _renderForm = useCallback(
		(step: number, formik: FormikProps<AccountConfiguration>) => {
			switch (step) {
				case 1:
					return <TenantFormConfirm formik={formik} />;
				default:
					return (
						<FormRuleInfo
							formik={formik}
							applicationCategory={applicationCategory}
							typeApiCategory={typeApiCategory}
						/>
					);
			}
		},
		[applicationCategory, typeApiCategory],
	);

	return (
		<Formik initialValues={initialValues} onSubmit={_handleSubmit} validationSchema={currentValidation}>
			{(formik: FormikProps<AccountConfiguration>) => (
				<Form>
					<Row className="mt-3">
						<Col>
							<PrimeSteps model={formSteps} activeIndex={activeStep} />
						</Col>
					</Row>
					<Row className="mt-3">
						<Col>{_renderForm(activeStep, formik)}</Col>
					</Row>
					<Row className="mt-3">
						<Col className="text-right">
							<Button
								label="Cancel"
								type="button"
								className="mr-2 p-button-outlined"
								onClick={() => history.goBack()}
							/>
							{activeStep > 0 && (
								<Button
									type="button"
									label="Previous"
									onClick={prevStep}
									className="mr-2 p-button-outlined"
								/>
							)}
							<Button
								label={isLastStep ? 'Apply' : 'Next'}
								loading={isSubmitting}
								disabled={isSubmitting}
								type="submit"
								className=""
							/>
						</Col>
					</Row>
				</Form>
			)}
		</Formik>
	);
};

export const defaultProps: AccountRuleFormProps = {
	initialValues: {
		type: '',
		applicationType: '',
		name: '',
		address: '',
		email: '',
		contact: '',

		id: 0,
		account: {
			id: 0,
			name: '',
			address: '',
			email: '',
			contact: '',
		},
		adminUser: {
			password: '',
			username: '',
		},
		deviceApi: {
			url: '',
			headerInfo: '',
			tenantId: '',
			id: '',
		},
	},
	onSubmit: () => {},
	isSubmitting: false,
};

FormEdit.defaultProps = defaultProps;

export default FormEdit;
