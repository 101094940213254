import React, { CSSProperties } from 'react'
import { DashboardGridItemProps } from 'app/types/dashboard';


const DashboardGridItem = React.forwardRef<HTMLDivElement, DashboardGridItemProps>((props, ref) => {
  let width = 100;
  let height = 100;

  const { gridWidth, gridPos, isViewing, windowHeight, ...divProps } = props;
  const style: CSSProperties = props.style ?? {};

  if (isViewing) {
    width = props.gridWidth!;
    height = windowHeight * 0.85;
    style.height = height;
    style.width = '100%';
  } else if (props.gridWidth! < 769) {
    width = props.gridWidth!;
    height = props.gridPos!.h * (30 + 8) - 8;
    style.height = height;
    style.width = '100%';
  } else {
    // RGL passes width and height directly to children as style props.
    width = props.style && props.style.width ? parseFloat(props.style.width) : width;
    height = props.style && props.style.height ? parseFloat(props.style.height) : height;
  }

  // props.children[0] is our main children. RGL adds the drag handle at props.children[1]
  return (
    <div {...divProps} ref={ref}>
      {/* Pass width and height to children as render props */}
      {[props.children[0](width, height), props.children.slice(1)]}
    </div>
  );
});

export default DashboardGridItem;
