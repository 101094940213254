import { LoginRequest } from 'app/features/auth/types';
import { TenantConfig } from 'app/types';
import { InputTextGroup, MyCheckbox } from 'components';
import { Formik } from 'formik';
import React, { ReactNode } from 'react';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import * as Yup from 'yup';

type Props = {
	children?: ReactNode;
	fieldConfig: TenantConfig;
	onSubmit?: (values: LoginRequest) => Promise<any>;
	isSubmitting?: boolean;
	isAuthenticated?: boolean;
	showFooter?: boolean;
}

const LoginPageTemplateRightForm: React.FC<Props> = (props) => {
	const {
		fieldConfig,
		isSubmitting,
		showFooter,
		onSubmit,
	} = props;

	const _onSubmit = async (credentials) => {
		onSubmit?.(credentials);
	};

	return (
		<div style={{ position: 'relative' }}>
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					backgroundImage: `url(${fieldConfig.backgroundImg})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					width: '75vw',
					height: '100vh',
					zIndex: 0,
				}}
			></div>
			<div className="overlay">
				<div className="header py-5 py-lg-7">
					<Container>
						<div className="header-body text-center mb-7"></div>
						<div className="tritronik-logo d-sm-none d-md-block">
							<h1 className="text-white logo-title">{fieldConfig.caption}</h1>
						</div>
					</Container>
				</div>
			</div>
			<Container className="page-content" fluid>
				<Row className="justify-content-end">
					<Col className="form-column" lg="4" md="8" style={{ padding: 0, marginRight: '-15px' }}>
						<Card className="bg-secondary border-0 mb-0" style={{ borderRadius: 0 }}>
							<CardBody
								style={{
									height: '100vh',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<div style={{ textAlign: 'center', padding: '10px' }}>
									<img
										src={fieldConfig.logoImg}
										alt="Client Logo"
										loading="lazy"
										style={{ objectFit: 'cover', width: '20rem', height: 'auto' }}
									/>
								</div>
								<div className="mb-4 text-center mt-3">
									<p className="text-dark text-center mt-4">Please login to continue.</p>
								</div>
								<Formik
									initialValues={{
										username: '',
										password: '',
										remember: false,
									}}
									validationSchema={Yup.object().shape({
										username: Yup.string().required('Username is required!'),
										password: Yup.string().required('Password is required!'),
									})}
									onSubmit={_onSubmit}
								>
									{(formik) => (
										<Form role="form" onSubmit={formik.handleSubmit}>
											<InputTextGroup
												placeholder="Email"
												icon="ni ni-email-83"
												invalid={Boolean(formik.errors.username && formik.touched.username)}
												{...formik.getFieldProps('username')}
											/>
											<InputTextGroup
												placeholder="Password"
												type="password"
												icon="ni ni-lock-circle-open"
												invalid={Boolean(formik.errors.password && formik.touched.password)}
												{...formik.getFieldProps('password')}
											/>
											<div className="d-flex justify-content-between align-items-center">
												<MyCheckbox
													id="rememberMe"
													label="Remember Me"
													alternative
													{...formik.getFieldProps('remember')}
												/>
												<div>
													<a
														href="#forgotPassword"
														onClick={(e) => e.preventDefault()}
														className="text-sm"
													>
														Forgot Password?
													</a>
												</div>
											</div>
											<div className="text-center my-4">
												<Button block className="btn-icon btn-3" color="primary" type="submit" disabled={isSubmitting}>
													<span className="btn-inner--text">Sign In</span>
													{isSubmitting && (
														<span className="btn-inner--icon">
															<i className="fa fa-spinner fa-spin" />
														</span>
													)}
												</Button>
											</div>
										</Form>
									)}
								</Formik>
								{showFooter && (
									<div className="copyright" style={{ marginTop: '5rem', textAlign: 'center' }}>
										&#169; 2021 PT Tricada Intronik
									</div>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default LoginPageTemplateRightForm;
