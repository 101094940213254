import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

type Props = {
    pageTitle:string | null
    description?:string
}

const MetaDecorator:FC<Props> = (props:Props) => {

    const {pageTitle} = props

	return (
		<Helmet>
			<title>{pageTitle}</title>
			<meta name="description" content="Nested component" />
		</Helmet>
	);
};

export default MetaDecorator;
