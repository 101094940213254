import { defaultUserProfile } from 'assets';
import { locationService } from 'app/services/LocationService';
import { useGetProfileQuery } from '../authAPI';
import { useAppSelector, useAppDispatch } from './../../../store';
import { logout, selectCurrentUser, selectIsAuthenticated } from './../authSlice';
import { useMemo } from 'react';
import { resetStateAction } from 'app/store/actions/resetStateAction';

export function useAuth() {
	const isAuthenticated = useAppSelector(selectIsAuthenticated);
    useGetProfileQuery(undefined, { skip: !isAuthenticated });
	const user = useAppSelector(selectCurrentUser);

	const avatar = useMemo(() => {
		if (user && user.userProfilePicture) {
			return user.userProfilePicture.actualLink;
		}
		return defaultUserProfile;

	}, [user]);

    const dispatch = useAppDispatch();
    const history = locationService.getHistory();

    const doLogout = () => {
        dispatch(logout());
		dispatch(resetStateAction());
        history.replace("/auth/login");
    }

    return {
        user,
		avatar,
        isAuthenticated,
        doLogout,
    };
}
