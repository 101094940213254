import React from 'react'
import { locationService } from 'app/services/LocationService';
import { useAppSelector } from 'app/store';
import { selectDateTime } from 'app/reducers/appSlice';

export function usePanelDataParams() {
  const urlParams = locationService.getSearch();
  const { beginTs, endTs } = useAppSelector(selectDateTime);

  const params: any = React.useMemo(() => {
    let queryParams: any = {};

    urlParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    return queryParams;
  }, [urlParams]);

  return { ...params, beginTs, endTs };
}
