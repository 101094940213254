import React from 'react'
import { PanelProps } from 'app/types/Panel'
import { css } from '@emotion/css';
import { calculateFontSize } from 'app/utils';

export interface TritronikBatteryPanelProps extends PanelProps {

}

const TritronikBatteryPanel = (props: TritronikBatteryPanelProps) => {
	const { height } = props;

	const batteryHeight = height * 0.6 < 100 ? 100 : height * 0.6;
	const batteryWidth = batteryHeight * 0.6;
	const batteryHeadHeight = batteryHeight * 0.05
	const batteryBorderWidth = (batteryWidth * 0.025) <= 2 ? 2 : batteryWidth * 0.025;

	const color = '#73bf69';
	const batteryContainerColor = '#73bf69';

	const batteryStyle = css`
		border: ${batteryBorderWidth}px solid ${batteryContainerColor};
		width: ${batteryWidth}px;
		height: ${batteryHeight}px;
		border-radius: 8px;
		position: relative;

		&:before {
			content: "";
			height: ${batteryHeadHeight}px;
			width: ${batteryWidth * 0.5}px;
			background: ${batteryContainerColor};
			display: block;
			position: absolute;
			top: -${batteryHeadHeight}px;
			left: 25%;
			border-radius: 12px 12px 0 0;
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			border: ${batteryBorderWidth}px solid #ffffff;
			border-radius: 8px;
		}
	`;

	const batteryLevelStyle = css`
		background: ${color};
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0px;
		left: 0;
		right: 0;
		border-radius: 8px;

		&.warn {
			background-color: #efaf13;
			padding: 0;
		}

		&.alert {
			background-color: #e81309;
			padding: 0;
		}
	`;

	const displayContainer = css`
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: transparent;
	`;

	const current = {
		value: 0,
		unit: 'A'
	}

	const currentFontSize = calculateFontSize(`${current.value}${current.unit}`, batteryWidth * 0.5, batteryHeight * 0.5, 1)

	const currentValueStyle = css`
		font-size: ${currentFontSize}px;
		font-weight: 600;
		color: #333;
	`;

	const voltage = {
		value: 0,
		unit: 'V'
	}

	const voltageFontSize = calculateFontSize(`${voltage.value}${voltage.unit}`, batteryWidth * 0.5, batteryHeight * 0.5, 1)

	const voltageValueStyle = css`
		font-size: ${voltageFontSize}px;
		font-weight: 600;
		color: #333;
		margin-top: ${batteryHeight * 0.0075}px;
	`;

	return (
		<div className={wrapper}>
			<div className={container}>
				<div className={batteryStyle}>
					<div className={`${batteryLevelStyle}`} style={{ height: "60%" }}>
					</div>
					<div className={displayContainer}>
						<p className={currentValueStyle}>{`${current.value}${current.unit}`}</p>
						<p className={voltageValueStyle}>{`${voltage.value}${voltage.unit}`}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TritronikBatteryPanel;

const wrapper = css`
	height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;

    * {
      white-space: nowrap;
      margin-bottom: 0;
      line-height: 1;
    }

    span {
      font-size: 70%;
    }
`;

const container = css`
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
	height: 100%;
`;
