import React from 'react'
import { IConfigComponent, COMPONENTS } from './ComponentMapper';

export function renderer(config: IConfigComponent, index?: number): any {
	if (typeof COMPONENTS[config.component] !== "undefined") {
		return React.createElement(
			COMPONENTS[config.component.toString()],
			{
				key: `${config.component}-${index}`,
				...config.props
			},
			config.children &&
			(typeof config.children === "string"
				? config.children
				: config.children.map(renderer))
		);
	}
	return null
}
