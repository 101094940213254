import { configureStore, $CombinedState } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import { Reducer } from 'redux';

import middleware from './middleware';
import reducers from "./rootReducer";
import { RESET_STATE_ACTION_TYPE } from './actions/resetStateAction';

export const rootReducer: Reducer<RootState> = (
	state,
	action
   ) => {
	if (action.type === RESET_STATE_ACTION_TYPE) {
	  state = {} as RootState;
	}

	return reducers(state, action);
   };

const persistConfig = {
    key: 'smart-solutions-root',
    version: 1,
    storage: storage,
    whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
	reducer: persistedReducer,
	middleware: (gDM) => gDM({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }).concat(middleware),
	devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof reducers> & { readonly [$CombinedState]?: undefined};
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

setupListeners(store.dispatch);
