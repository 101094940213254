import { createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { authApi } from './authAPI';
import { User } from './types';

export interface AuthState {
    isFetching?: boolean;
    isLoggedIn?: boolean;
    access_token: string|null;
	expires_in: number|null;
	refresh_expires_in: number|null;
	refresh_token: string|null;
	token_type: string|null;
    user: User|null;
}

const initialState: AuthState = {
    isLoggedIn: false,
    access_token: null,
	expires_in: null,
	refresh_expires_in: null,
	refresh_token: null,
	token_type: null,
    user: null,
    isFetching: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        logout: (state) => {
            state.isLoggedIn = false;
			state.access_token = null;
			state.expires_in = null;
			state.refresh_expires_in = null
			state.refresh_token = null;
        }
    },
    extraReducers: (builder) => {
        builder
		.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state, action) => {
                state.isLoggedIn = true;
                state.access_token = action.payload.access_token;
                state.expires_in = action.payload.expires_in;
                state.refresh_expires_in = action.payload.refresh_expires_in;
                state.refresh_token = action.payload.refresh_token;
            }
        )
		.addMatcher(
            authApi.endpoints.getProfile.matchFulfilled,
            (state, action) => {
                state.user = action.payload;
            }
        )
    }
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectIsAuthenticated = (state: RootState) => state.auth.isLoggedIn;
export const selectUserPermissions = createSelector(
    selectCurrentUser,
    (user) => {
        const permissions: string[] = [];
        user?.roles?.forEach((role) => {
            role?.rolePrivileges?.map((rolePrivilege) => {
                if (rolePrivilege?.feature?.name) {
                    permissions.push(rolePrivilege.feature.name);
                }
            })
        });

        return permissions;
    }
)
