import { abnormalEventApi } from './../features/AbnormalEvent/services/abnormalEventAPI';
import { combineReducers } from '@reduxjs/toolkit';
import { projectApi } from './../services/ProjectService';
import { panelApi } from './../services/PanelService';
import { default as appReducer } from './../reducers/appSlice';
import { default as pageReducer } from './../reducers/pageSlice';
import { default as panelReducer } from './../reducers/panelSlice';
import { default as authReducer } from './../features/auth/authSlice';
import { default as updateGridReducer } from './../reducers/updateGridSlice';
import { alertConfigurationApi } from 'app/features/AlertConfiguration/services/alertApi';
import { authApi } from 'app/features/auth/authAPI';
import { pageApi } from './../services/PageService';
import { tenantConfigurationApi } from 'app/features/Tenant/services/tenantApi';
import { sensorConfigurationApi } from 'app/features/SensorProperty/services/sensorApi';
import { userConfigurationApi } from 'app/features/UserManagement/services/userApi';
import { apiConfigurationApi } from 'app/features/ApiManagement/services/apiManagementApi';
import { roleConfigurationApi } from 'app/features/RoleManagement/services/roleApi';
import { explorationApi } from './../features/DataExploration/services/dataExplorationApi';
import { siteManagementApi } from 'app/features/SiteManagement/services/siteApi';
import { zmApi } from 'app/features/ZoneMinder/zmApi';
import { livestockBaseApi } from 'app/services/api';

const rootReducer = combineReducers({
	application: appReducer,
	page: pageReducer,
	panel:panelReducer,
	auth: authReducer,
	[authApi.reducerPath]: authApi.reducer,
	[projectApi.reducerPath]: projectApi.reducer,
	[panelApi.reducerPath]: panelApi.reducer,
	[roleConfigurationApi.reducerPath]: roleConfigurationApi.reducer,
	[apiConfigurationApi.reducerPath]: apiConfigurationApi.reducer,
	[userConfigurationApi.reducerPath]: userConfigurationApi.reducer,
	[sensorConfigurationApi.reducerPath]: sensorConfigurationApi.reducer,
	[tenantConfigurationApi.reducerPath]: tenantConfigurationApi.reducer,
	[alertConfigurationApi.reducerPath]: alertConfigurationApi.reducer,
	[abnormalEventApi.reducerPath]: abnormalEventApi.reducer,
	[pageApi.reducerPath]: pageApi.reducer,
	[explorationApi.reducerPath]: explorationApi.reducer,
	[siteManagementApi.reducerPath]:siteManagementApi.reducer,
	updateGrid:updateGridReducer,
    [zmApi.reducerPath]: zmApi.reducer,
    [livestockBaseApi.reducerPath]: livestockBaseApi.reducer,
});

export default rootReducer;
