import { useCallback, useMemo } from "react";
import { useGetAppConfigQuery } from "../services";
import { IAppConfig } from "../types";

export function useAppConfig() {
    const { data } = useGetAppConfigQuery({});

    const configData = useMemo<IAppConfig[]>(() => {
        if (!data || !data.content) return [];

        const mapData = data.content.map((d) => {
            let config = null;
            try {
                if (d.value !== null && typeof d.value !== 'undefined' && d.value !== '') {
                    config = JSON.parse(d.value);
                }

                while (typeof config === 'string') {
                    config = JSON.parse(config)
                }
            } catch {}
            return {
                ...d,
                value: config,
            }
        });

        return mapData;
    }, [data]);

    const getConfigByName = useCallback((name: string) => {
        if (!configData) return null;

        const foundConfig = configData.find((d) => d.name?.toLowerCase() === name?.toLowerCase())
        if (foundConfig) {
            return foundConfig.value;
        }

        return null;
    }, [configData]);

    return {
        data: configData,
        getConfigByName,
    }
}
