import { useState } from 'react';

export function useModal() {
    const [visible, setVisible] = useState(false);

    const hide = () => {
        setVisible(false);
    }

    const show = () => {
        setVisible(true);
    }

    return {
        visible,
        show,
        hide,
    }
}
