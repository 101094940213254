import * as Yup from 'yup';

const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validation = Yup.object().shape({
	username: Yup.string().required('Condition Username required'),
	password: Yup.string().required('Condition Password require'),

	userInfo: Yup.object().shape({
		email: Yup.string().email('Invalid email').required('Email is required'),
		name: Yup.string().required('Condition Name is required'),
		phoneNumber: Yup.string().required('This field is Required').matches(phoneRegExp, 'Phone number is not valid'),
	}),
});

export const validationUser = [validation, undefined];
