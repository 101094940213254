import React, { useEffect, useMemo } from 'react';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import RoleRuleForm, { defaultProps } from '../components/RoleRuleForm';
import { useGetRoleByIdQuery } from '../services/roleApi';
import { useParams } from 'react-router';
import { RoleConfiguration } from '../types';
import { FormikHelpers } from 'formik';
import { locationService } from './../../../services/LocationService';
import LoadingPage from 'app/components/LoadingPage';
import { useUpdateRole } from '../hooks/useUpdateRole';

interface PageParams {
	id: string;
}

interface Props {}

const EditRolePage = (props: Props) => {
	const params = useParams<PageParams>();
	const { data, isLoading } = useGetRoleByIdQuery(params.id, { skip: !params.id });

	const history = locationService.getHistory();

	const { updateRole, isLoadingUpdate } = useUpdateRole();

	const initialValues = useMemo<any>(() => {
		if (!data) return defaultProps.initialValues;

		const { permissionsInput, ...configRole } = data;

		const getPrivileges = data.rolePrivileges?.map((el: any) => el.feature).map((el) => el.id);

		return {
			permissionsInput: getPrivileges?.map(String) || '',
			...configRole,
		};
	}, [data]);

	useEffect(() => {}, [initialValues]);

	const onUpdate = (values: RoleConfiguration, formik: FormikHelpers<RoleConfiguration>): void => {
		updateRole(values).then(() => {
			history.push({
				pathname: '/role-management',
			});
		});
	};

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<Container fluid>
			<h2 className="my-3">Role Management</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/role-management/">All Roles</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>Edit New</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<RoleRuleForm
								initialValues={initialValues}
								onSubmit={onUpdate}
								isSubmitting={isLoadingUpdate}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default EditRolePage;
