import { API_URL } from './../config/config';
import axios from "axios"
import keycloak from "./../keycloak";

const api = axios.create({
	baseURL: API_URL,
	headers: {
		Accept: "application/json"
	}
})

api.interceptors.request.use(async function (config) {
	// Do something before request is sent
	// TODO: Fix this using real token
	const token = keycloak.token;
	// const token = await localStorage.getItem('token')
	config.headers.Authorization = "Bearer " + token

	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});

export default api;
