import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'app/features/auth/hooks/useAuth';
import { locationService } from 'app/services/LocationService';

export function ProtectedRoute({ component: Component, ...rest }) {
	const { isAuthenticated } = useAuth();
	const location = locationService.getLocation();

	if (isAuthenticated) {
		return (
			<Route
				{...rest}
				render={(props) => {
					return <Component {...props} />;
				}}
			/>
		);
	}

	return <Redirect to="/auth/login" from={location.pathname} />

}
