import { PrimeSteps } from 'components';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import React, { useCallback, useState } from 'react'
import { Col, Row } from 'reactstrap';
import { history } from 'utils';
import { SiteData } from '../types'
import { validationSite } from '../ValidationSchema';
import SiteConfirmation from './SiteConfirmation';
import SiteInfo from './SiteInfo';

export type FormSiteValues = Partial<SiteData>;

export interface SiteFormProps {
	initialValues: SiteData;
	onSubmit: (values: SiteData, formik: FormikHelpers<SiteData>) => void;
	isSubmitting?: boolean;
}

const formSteps = [{ label: 'USER INFORMATION' }, { label: 'CONFIRMATION' }];

const SiteForm:React.FC<SiteFormProps> = ({isSubmitting, initialValues, onSubmit}) => {
    const [activeStep, setActiveStep] = useState(0);
	const isLastStep = activeStep === formSteps.length - 1;
	const currentValidation = validationSite[activeStep];

    const nextStep = () => {
		if (!isLastStep) {
			setActiveStep((currentStep) => currentStep + 1);
		}
	};

	const prevStep = () => {
		if (activeStep > 0) {
			setActiveStep((currentStep) => currentStep - 1);
		}
	};

	const _onSubmit = (values: SiteData, formik: FormikHelpers<SiteData>) => {
		onSubmit(values, formik);
	};

	const _handleSubmit = (values: SiteData, formik: FormikHelpers<SiteData>): void => {
		if (isLastStep) {
			_onSubmit(values, formik);
		} else {
			nextStep();
			formik.setTouched({});
			formik.setSubmitting(false);
		}
	};

	const _renderForm = useCallback(
		(step: number, formik: FormikProps<SiteData>) => {
			switch (step) {
				case 1:
					return <SiteConfirmation formik={formik} />;
				default:
					return <SiteInfo formik={formik} />;
			}
		},
		[],
	);

  return (
    <Formik initialValues={initialValues} onSubmit={_handleSubmit} validationSchema={currentValidation} enableReinitialize>
			{(formik: FormikProps<SiteData>) => (
				<Form onSubmit={formik.handleSubmit}>
					<Row className="mt-3">
						<Col>
							<PrimeSteps model={formSteps} activeIndex={activeStep} />
						</Col>
					</Row>
					<Row className="mt-3">
						<Col>{_renderForm(activeStep, formik)}</Col>
					</Row>
					<Row className="mt-3">
						<Col className="text-right">
							<Button
								label="Cancel"
								type="button"
								className="mr-2 p-button-outlined"
								onClick={() => history.goBack()}
							/>
							{activeStep > 0 && (
								<Button
									type="button"
									label="Previous"
									onClick={prevStep}
									className="mr-2 p-button-outlined"
								/>
							)}
							<Button
								label={isLastStep ? 'Apply' : 'Next'}
								loading={isSubmitting}
								disabled={isSubmitting}
								type="submit"
								className=""
							/>
						</Col>
					</Row>
				</Form>
			)}
		</Formik>
  )
}

export const defaultProps: SiteFormProps = {
	initialValues: {
		id:'',
        lastAlertMessage:'',
        lastConnected:'',
        lastData:'',
        latitude:0,
        longitude:0,
        name:'',
        region:'',
        status:'',
        tenantId:'',
        totalAlert:0,
        type:'',
		deviceId:''
	},

	onSubmit: () => {},
	isSubmitting: false,
};

SiteForm.defaultProps = defaultProps;

export default SiteForm