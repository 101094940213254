// Parent Component
// Child Component -> FormConfirmation and FormRuleInfo

import { PrimeSteps } from 'components';
import { Form, Formik, FormikHelpers, FormikProps, ArrayHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { Button } from 'primereact/button';
import { ApiConfiguration, FormApiConfiguration } from '../types';
import FormConfirmation from './FormConfirmation';
import FormRuleInfo from './FormRuleInfo';
import { history } from 'utils';
import { Row, Col } from 'reactstrap';
import { validations } from '../validationSchema';

export interface ApiRuleFormProps {
	initialValues: FormApiConfiguration;
	onSubmit: (values: ApiConfiguration, formik: FormikHelpers<FormApiConfiguration>) => void;
	isSubmitting?: boolean;
}

const formSteps = [{ label: 'API INFORMATION' }, { label: 'CONFIRMATION' }];

const ApiRuleForm = (props: ApiRuleFormProps) => {
	const [activeStep, setActiveStep] = useState(0);
	const isLastStep = activeStep === formSteps.length - 1;
	const currentValidation = validations[activeStep];

	const targetMethodType = ['GET', 'PATCH', 'POST', 'PUT', 'DELETE'];

	const { initialValues, isSubmitting, onSubmit } = props;

	const nextStep = () => {
		if (!isLastStep) {
			setActiveStep((currentStep) => currentStep + 1);
		}
	};

	const prevStep = () => {
		if (activeStep > 0) {
			setActiveStep((currentStep) => currentStep - 1);
		}
	};

	const _onSubmit = (values: any, formik: FormikHelpers<any>) => {
		const { requestHeaders, pathVariables, requestParams, requestBody, ...ApiRuleForm } = values;

		const apiConfig: ApiConfiguration = {
			...ApiRuleForm,
			endpointApiParams: requestBody.concat(requestHeaders, pathVariables, requestParams),
		};

		onSubmit(apiConfig, formik);
		// console.log('apa nih ya?', apiConfig);
	};

	const _handleSubmit = (values: FormApiConfiguration, formik: FormikHelpers<FormApiConfiguration>): void => {
		if (isLastStep) {
			_onSubmit(values, formik);
		} else {
			nextStep();
			formik.setTouched({});
			formik.setSubmitting(false);
		}
	};

	const _addParam = useCallback((arrayHelper: ArrayHelpers) => {
		arrayHelper.push({
			id: '',
			paramName: '',
			paramType: 'REQUEST_PARAM',
			paramDescription: '',
		});
		return arrayHelper;
	}, []);

	const _addHeader = useCallback((arrayHelper: ArrayHelpers) => {
		arrayHelper.push({
			id: '',
			paramName: '',
			paramType: 'REQUEST_HEADER',
			paramDescription: '',
		});

		return arrayHelper;
	}, []);

	const _addVariable = useCallback((arrayHelper: ArrayHelpers) => {
		arrayHelper.push({
			id: '',
			paramName: '',
			paramType: 'PATH_VARIABLE',
			paramDescription: '',
		});

		return arrayHelper;
	}, []);

	const _addBody = useCallback((arrayHelper: ArrayHelpers) => {
		arrayHelper.push({
			id: '',
			paramName: '',
			paramType: 'REQUEST_BODY',
			paramDescription: '',
		});
		return arrayHelper;
	}, []);

	const _renderForm = useCallback(
		(step: number, formik: FormikProps<FormApiConfiguration>) => {
			switch (step) {
				case 1:
					return <FormConfirmation formik={formik} />;
				default:
					return (
						<FormRuleInfo
							formik={formik}
							targetMethodType={targetMethodType}
							addHeader={_addHeader}
							addVariable={_addVariable}
							addParam={_addParam}
							addBody={_addBody}
						/>
					);
			}
		},
		[targetMethodType, _addHeader, _addVariable, _addParam, _addBody],
	);

	return (
		<Formik initialValues={initialValues} onSubmit={_handleSubmit} validationSchema={currentValidation}>
			{(formik: FormikProps<FormApiConfiguration>) => (
				<Form onSubmit={formik.handleSubmit}>
					<Row className="mt-3">
						<Col>
							<PrimeSteps model={formSteps} activeIndex={activeStep} />
						</Col>
					</Row>
					<Row className="mt-3">
						<Col>{_renderForm(activeStep, formik)}</Col>
					</Row>
					<Row className="mt-3">
						<Col className="text-right">
							<Button
								label="Cancel"
								type="button"
								className="mr-2 p-button-outlined"
								onClick={() => history.goBack()}
							/>
							{activeStep > 0 && (
								<Button
									type="button"
									label="Previous"
									onClick={prevStep}
									className="mr-2 p-button-outlined"
								/>
							)}
							<Button
								label={isLastStep ? 'Apply' : 'Next'}
								loading={isSubmitting}
								disabled={isSubmitting}
								type="submit"
								className=""
							/>
						</Col>
					</Row>
				</Form>
			)}
		</Formik>
	);
};

export const defaultProps: ApiRuleFormProps = {
	initialValues: {
		id: '',
		urlEndpoint: '',
		name: '',
		description: '',
		projectCategory: '',
		requestMethod: '',

		pathVariables: [
			{
				paramName: '',
				paramDescription: '',
				paramType: 'PATH_VARIABLE',
			},
		],

		requestHeaders: [
			{
				paramName: '',
				paramDescription: '',
				paramType: 'REQUEST_HEADER',
			},
		],

		requestParams: [
			{
				paramName: '',
				paramDescription: '',
				paramType: 'REQUEST_PARAM',
			},
		],

		requestBody: [
			{
				paramName: '',
				paramDescription: '',
				paramType: 'REQUEST_BODY',
			},
		],
	},
	onSubmit: () => {},
	isSubmitting: false,
};

ApiRuleForm.defaultProps = defaultProps;

export default ApiRuleForm;
