import { FormOverview } from 'components';
import { FormikProps } from 'formik';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { EndPoint, FormApiConfiguration } from '../types';

export interface ApiFormConfirmProps {
	formik: FormikProps<FormApiConfiguration>;
}

const FormConfirmation = (props: ApiFormConfirmProps) => {
	const { values } = props.formik;

	// render variable
	const renderEndpointVariable = () => {
		const { pathVariables } = values;

		const endpointApiParams: EndPoint[] = [];

		if (pathVariables && pathVariables.length > 0) endpointApiParams.push(...pathVariables);

		return (
			endpointApiParams &&
			endpointApiParams.length > 0 &&
			endpointApiParams.map((param, index) => {
				return (
					<React.Fragment key={`param-${index}`}>
						<Card>
							<CardBody>
								<FormOverview
									leftItemClassName="font-weight-bold"
									stripped
									label={`Path Variable - ${index + 1}`}
								/>

								<FormOverview label="Key Parameter" value={param.paramName || '-'} />

								<FormOverview stripped label="Description" value={param.paramDescription || '-'} />
							</CardBody>
						</Card>
					</React.Fragment>
				);
			})
		);
	};

	// render request body
	const rederEndpointBody = () => {
		const { requestBody } = values;

		const endpointApiParams: EndPoint[] = [];

		if (requestBody) endpointApiParams.push(...requestBody);
		return (
			endpointApiParams &&
			endpointApiParams.length > 0 &&
			endpointApiParams.map((param, index) => {
				return (
					<React.Fragment key={`param-${index}`}>
						<Card>
							<CardBody>
								<FormOverview
									leftItemClassName="font-weight-bold"
									stripped
									label={`Request Body - ${index + 1}`}
								/>

								<FormOverview label="Key Parameter" value={param.paramName || '-'} />

								<FormOverview stripped label="Description" value={param.paramDescription || '-'} />
							</CardBody>
						</Card>
					</React.Fragment>
				);
			})
		);
	};

	// render request param
	const renderEndpointParam = () => {
		const { requestParams } = values;

		const endpointApiParams: EndPoint[] = [];

		if (requestParams) endpointApiParams.push(...requestParams);

		return (
			endpointApiParams &&
			endpointApiParams.length > 0 &&
			endpointApiParams.map((param, index) => {
				return (
					<React.Fragment key={`param-${index}`}>
						<Card>
							<CardBody>
								<FormOverview
									leftItemClassName="font-weight-bold"
									stripped
									label={`Request Param - ${index + 1}`}
								/>

								<FormOverview label="Key Parameter" value={param.paramName || '-'} />

								<FormOverview stripped label="Description" value={param.paramDescription || '-'} />
							</CardBody>
						</Card>
					</React.Fragment>
				);
			})
		);
	};

	// render header
	const renderEndpointHeader = () => {
		const { requestHeaders } = values;

		const endpointApiParams: EndPoint[] = [];

		if (requestHeaders) endpointApiParams.push(...requestHeaders);
		return (
			endpointApiParams &&
			endpointApiParams.length > 0 &&
			endpointApiParams.map((param, index) => {
				return (
					<React.Fragment key={`param-${index}`}>
						<Card>
							<CardBody>
								<FormOverview
									leftItemClassName="font-weight-bold"
									stripped
									label={`Request Headers - ${index + 1}`}
								/>

								<FormOverview label="Key Parameter" value={param.paramName || '-'} />

								<FormOverview stripped label="Description" value={param.paramDescription || '-'} />
							</CardBody>
						</Card>
					</React.Fragment>
				);
			})
		);
	};

	return (
		<React.Fragment>
			<Row>
				<Col lg="6">
					<Card>
						<CardBody>
							<h3 className="mb-2">Information</h3>

							<FormOverview label="Name" value={values.name || '-'} stripped />

							<FormOverview label="Description" value={values.description || '-'} />

							<FormOverview label="URL" value={values.urlEndpoint || '-'} stripped />

							<FormOverview label="Method" value={values.requestMethod || '-'} />
						</CardBody>
					</Card>

					{renderEndpointHeader() ? (
						<Card>
							<CardBody>
								<h3 className="mb-2"> Request Header </h3>
								{renderEndpointHeader()}
							</CardBody>
						</Card>
					) : (
						false
					)}
				</Col>

				<Col>
					{renderEndpointVariable() ? (
						<Card>
							<CardBody>
								<h3 className="mb-2"> Path Variable </h3>
								{renderEndpointVariable()}
							</CardBody>
						</Card>
					) : (
						false
					)}

					{renderEndpointParam() ? (
						<Card>
							<CardBody>
								<h3 className="mb-2"> Request Param </h3>
								{renderEndpointParam()}
							</CardBody>
						</Card>
					) : (
						false
					)}

					{rederEndpointBody() ? (
						<Card>
							<CardBody>
								<h3 className="mb-2"> Request Body </h3>
								{rederEndpointBody()}
							</CardBody>
						</Card>
					) : (
						false
					)}
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default FormConfirmation;
