import LoadingPage from 'app/components/LoadingPage';
import { FormikHelpers } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import UserRuleForm, { defaultProps } from '../components/UserRuleForm';
import { useUpdateRoleAssignment } from '../hooks/useUpdateRoleAssignment';
import { useGetUserConfigurationQuery } from '../services/userApi';
import { UserConfiguration } from '../types';
import { locationService } from './../../../services/LocationService';

interface PageParams {
	id: string;
}

interface Props {}

const EditUserPage = (props: Props) => {
	const params = useParams<PageParams>();
	const { data, isLoading } = useGetUserConfigurationQuery(params.id, { skip: !params.id });

	const history = locationService.getHistory();

	const { updateRoleToUser, isLoading: isUpdating } = useUpdateRoleAssignment();

	const initialValues = useMemo<any>(() => {
		if (!data) return defaultProps.initialValues;

		const { roles, roleNames, password, ...user } = data;

		const roleNamValueString = roleNames.toString();

		const rolesM = roles?.map((el: any) => {
			if (el.name === roleNamValueString) {
				return el.id;
			}
		});

		return {
			password: '******',
			roleNames: Number(rolesM) || [],
			...user,
		};
	}, [data]);

	useEffect(() => {}, [initialValues]);

	const onSubmit = (values: UserConfiguration, formik: FormikHelpers<UserConfiguration>): void => {
		updateRoleToUser(values).then(() => {
			history.push({
				pathname: '/user-management',
			});
		});
	};

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<Container fluid>
			<h2 className="my-3">User Management</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/user-management">User Management</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>Edit User</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<UserRuleForm initialValues={initialValues} onSubmit={onSubmit} isSubmitting={isUpdating} />
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default EditUserPage;
