import * as Yup from 'yup';

export const validation = Yup.object().shape({
    name:Yup.string().required('this field cannot be empty'),
    region:Yup.string().required('this field cannot be empty'),
    latitude:Yup.number().required('this field cannot be empty'),
    longitude:Yup.number().required('this field cannot be empty'),
    deviceId:Yup.string().required('this field cannot be empty')
})

export const validationSite = [validation, undefined]