import { locationSearchToObject } from 'app/services/LocationService';
import { TritronikRouteComponentProps } from 'app/types/navigation';
import React, { useCallback, useEffect } from 'react';

export interface Props extends Omit<TritronikRouteComponentProps, 'queryParams'> {}

const TritronikRoute = (props: Props) => {

  const getPageClasses = useCallback(() => {
		return props.route.pageClass ? props.route.pageClass.split(' ') : [];
	}, [props.route.pageClass]);

	const updateBodyClassNames = useCallback((clear = false) => {
		for (const cls of getPageClasses()) {
			if (clear) {
				document.body.classList.remove(cls);
			} else {
				document.body.classList.add(cls);
			}
		}
	}, [getPageClasses]);

	useEffect(() => {
		updateBodyClassNames();
		return () => {
			updateBodyClassNames(true);
		};
	}, [updateBodyClassNames]);

	

	const RouteComponent = props.route.component;

	return <RouteComponent {...props} queryParams={locationSearchToObject(props.location.search)} />;
};

export default TritronikRoute;
