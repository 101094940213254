import { useToasts } from 'app/components/Toast';
import { setIsEditingPage } from 'app/reducers/pageSlice';
import { useAppDispatch } from 'app/store';
import { OverlayMenu, PrimeTable } from 'components';
import { Button } from 'primereact/button';
import { Column, ColumnBodyType, ColumnProps } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { capitalizeFirstLetter } from 'utils';
import { useAlertDatatable } from '../hooks/useAlertDatatable';
import { useDeleteAlertConfigurationMutation } from '../services/alertApi';
import { AlertConfiguration } from '../types';
import { locationService } from './../../../services/LocationService';


export interface Props {
	columns?: ColumnProps[];
}

const AlertConfigurationPage = (props: Props) => {

	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(setIsEditingPage(false)) // disable editing state in order to show add menu button
	},[dispatch])

	const toast = useToasts();
	const { data, isLoading, totalRecords, rows, first, filters, sortField, sortOrder, onPage, onSort, onFilter } =
		useAlertDatatable();
	const [selectedRows, setSelectedRows] = useState(null);
	const [globalFilter, setGlobalFilter] = useState(null);
	const dt = useRef<DataTable>(null);

	const history = locationService.getHistory();

	const [deleteAlert] = useDeleteAlertConfigurationMutation();

	const [showDialog, setShowDialog] = useState(false);

	const _handleDelete = useCallback((id: number | string) => {
		confirmDialog({
			message: `Do you want to delete this record with ID: ${id}?`,
			header: 'Delete Confirmation',
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: async () => deleteAlert(id).unwrap().then(() => {
				toast.toastSuccess('Success', `Alert configuration deleted.`);
			}).catch((error) => {
				toast.toastError(error?.data?.error, error?.data?.message);
			})
		});
	}, [deleteAlert, toast]);

	const _handleEdit = useCallback((id: number | string) => {
		history.push({ pathname: `/alert-configuration/edit/${id}`})
	}, [history]);

	const columns: ColumnProps[] = React.useMemo(() => {
		const notificationTemplateBody = (rowData: any): ColumnBodyType => {
			return (
				<React.Fragment>
					<i className="mdi mdi-message-processing"></i>
				</React.Fragment>
			);
		};
		const notificationChannelTemplateBody = (rowData: AlertConfiguration): ColumnBodyType => {
			const channel = Object.keys(rowData.channelRecipient).map((v) => capitalizeFirstLetter(v));
			return <React.Fragment>{channel && channel.join(', ')}</React.Fragment>;
		};

		const recipientTemplateBody = (rowData: AlertConfiguration): ColumnBodyType => {
			return (
				<React.Fragment>
					<i className="mdi mdi-account-multiple"></i>
				</React.Fragment>
			);
		};
		const actionTemplateBody = (rowData: AlertConfiguration): ColumnBodyType => {
			const items = [
				{
					label: 'Edit',
					icon: 'mdi mdi-pencil',
					command: () => _handleEdit(rowData.id),
				},
				{
					label: 'Delete',
					icon: 'mdi mdi-delete',
					command: () => _handleDelete(rowData.id),
				},
			];
			return (
				<React.Fragment>
					<OverlayMenu id={`alert-rule-${rowData.id}`} items={items} />
				</React.Fragment>
			);
		};

		const getSymbolFromOperator = (operator: string): string => {
			switch (operator) {
				case 'EQ':
					return '=';
				case 'NE':
					return '!=';
				case 'LT':
					return '<';
				case 'LTE':
					return '<=';
				case 'GT':
					return '>';
				case 'GTE':
					return '>=';
				default:
					return '?';
			}
		};

		const rulesTemplateBody = (rowData: AlertConfiguration): ColumnBodyType => {
			const rules = rowData.conditions.map((c) => {
				return `Value ${getSymbolFromOperator(c.comparator)} ${c.thresholdValue}`;
			});
			return <React.Fragment>{rules && rules.length > 0 && rules.join(', ')}</React.Fragment>;
		};

		return [
			{
				field: 'name',
				header: 'Rule Name',
				sortable: true,
			},
			{
				field: 'severity',
				header: 'Severity',
				sortable: true,
			},
			{
				field: 'applyTo',
				header: 'Sensor/Device',
			},
			{
				field: 'rules',
				body: rulesTemplateBody,
			},
			{
				header: 'Notification',
				headerStyle: {
					width: '8rem',
					textAlign: 'center',
				} as CSSProperties,
				body: notificationTemplateBody,
				bodyStyle: {
					textAlign: 'center',
				} as CSSProperties,
			},
			{
				header: 'Recipient Channel',
				body: notificationChannelTemplateBody,
			},
			{
				header: 'Recipient',
				headerStyle: {
					width: '8em',
					textAlign: 'center',
				} as CSSProperties,
				body: recipientTemplateBody,
				bodyStyle: {
					textAlign: 'center',
				} as CSSProperties,
			},
			{
				headerStyle: {
					width: '5em',
				} as CSSProperties,
				body: actionTemplateBody,
				bodyStyle: {
					textAlign: 'center',
					overflow: 'visible',
				} as CSSProperties,
			},
		];
	}, [_handleDelete, _handleEdit]);

	const gotoCreateAlertRulePage = () => {
		history.push({
			pathname: '/alert-configuration/new',
		});
	};

	const renderHeader = () => {
		return (
			<div className="table-header">
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						type="search"
						onInput={(e: any) => setGlobalFilter(e.target.value)}
						placeholder="Search"
					/>
				</span>

				<Button
					label="Add New"
					icon="mdi mdi-plus-circle"
					iconPos="right"
					className="p-button-link text-default"
					onClick={gotoCreateAlertRulePage}
				/>
			</div>
		);
	};

	const renderColumns = () => {
		return columns && columns.map((column: ColumnProps, i) => <Column key={i} {...column} />);
	};

	const header = renderHeader();

	return (
		<Container fluid>
			<h2 className="my-3">Alert Configuration</h2>
			<div>
				<Card>
					<CardBody>
						<PrimeTable
							lazy
							ref={dt}
							value={data?.content}
							header={header}
							dataKey="id"
							globalFilter={globalFilter}
							selection={selectedRows}
							onSelectionChange={(e) => setSelectedRows(e.value)}
							rows={rows}
							first={first}
							sortField={sortField}
							sortOrder={sortOrder}
							loading={isLoading}
							totalRecords={totalRecords}
							filters={filters}
							onPage={onPage}
							onSort={onSort}
							onFilter={onFilter}
						>
							{/* <Column selectionMode="multiple" style={{ width: '3em' }} /> */}
							{renderColumns()}
						</PrimeTable>
						<ConfirmDialog visible={showDialog} onHide={() => setShowDialog(false)} />
					</CardBody>
				</Card>
			</div>
		</Container>
	);
};

export default AlertConfigurationPage;
