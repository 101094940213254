import React from 'react';
import { Field } from 'formik';
import { InputTextProps } from 'primereact/inputtext';
import classNames from 'classnames';
import FormikFormGroup from '../FormikFormGroup';

export interface FormikInputTextProps {
	[key: string]: any;
	className?: string;
	name: string;
	label?: string;
	required?: boolean;
	row?: boolean;
	caption?: string;
}

const FormikInputEmail = (props: FormikInputTextProps & InputTextProps) => {
	const { className, id, name, label, required, row, caption, domain, ...inputProps } = props;

	const renderInput = ({ field, form, meta }) => {
		return (
			<FormikFormGroup
				className={className}
				id={id}
				name={name}
				label={label}
				field={field}
				form={form}
				meta={meta}
				row={row}
				required={required}
				caption={caption}
			>
				<div className="input-group mb-3">
					<input
						type="text"
						placeholder="Recipient's username"
						aria-label="Recipient's username"
						aria-describedby="basic-addon2"
						id={id}
						{...field}
						{...inputProps}
						className={classNames('w-100 form-control', {
							'p-invalid': Boolean(meta.touched && meta.error),
						})}
                        style={{color:'black'}}
					/>
					<span className="input-group-text" id="basic-addon2" style={{color:'black', backgroundColor:'#f8f9fe'}}>
						{domain}
					</span>
				</div>
			</FormikFormGroup>
		);
	};

	return <Field name={name}>{renderInput}</Field>;
};

export default FormikInputEmail;
