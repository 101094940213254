import React from 'react';
import { locationService } from 'app/services/LocationService';

import { formattedValueToString, getValueFormat } from "app/valueFormats";

export const textBodyTemplate = (data: any, field: string) => {
	return data[field] || '-';
}

export const dateBodyTemplate = (data: any, field: string) => {
	const v = getValueFormat('dateTimeAsLocal')(data[field]);
	return <span>{formattedValueToString(v)}</span>
}


export interface FilterOption {
	type: 'dataset' | 'value';
	value: any;
	query: string;
}
export interface LinkUrlOptions {
	type: 'internal' | 'external';
	url: string;
	filters: FilterOption[];

}
export const anchorBodyTemplate = (data: any, field: string, options: LinkUrlOptions) => {
	const query = locationService.getSearch();
	if (options.filters && options.filters.length > 0) {
		options.filters.forEach((q) => {
			const value = q.type === "dataset" ? data[q.value] : q.value;
			if (value) {
				query.set(q.query, value);
			}
		});
	}

	const goToLink = (targetUrl: string, query: URLSearchParams) => {
		const url = new URL(targetUrl);
		const queryString = query.toString();

		locationService.push({
			pathname: url.pathname,
			search: queryString
		});
	}

	if (options.type === 'internal') {
		return <span className={'text-primary'} style={{ cursor: 'pointer' }} onClick={() => goToLink(options.url, query)}>{data[field]}</span>
	}

	return <a href={`${options.url}?${query.toString()}`}>{data[field]}</a>
}
