import { useState } from "react";
import { DataTableFilterParams, DataTablePageParams, DataTableSortParams } from "primereact/datatable";

interface DatatableParams extends DataTablePageParams, DataTableSortParams, DataTableFilterParams {};

export function useDatatable(params: DatatableParams) {
    const [pageState, setPageState] = useState<DataTablePageParams>({
		first: params.first || 0,
		rows: params.rows || 10,
		page: params.page || 0,
		pageCount: params.pageCount || 0,
	});

	const [sortState, setSortState] = useState<DataTableSortParams>({
		sortField: params.sortField || 'id',
		sortOrder: params.sortOrder || null,
		multiSortMeta: params.multiSortMeta || undefined,
	});

	const [filterState, setFilterState] = useState<DataTableFilterParams>({
		filters: params.filters || {}
	});

    const [globalFilter, setGlobalFilter] = useState<string>('');

	const onPage = (event: DataTablePageParams) => {
		setPageState((_state) => ({ ..._state, ...event }));
	}

	const onSort = (event: DataTableSortParams) => {
		setSortState((_state) => ({ ..._state, ...event }));
	}

	const onFilter = (event: DataTableFilterParams) => {
		setPageState((_state) => ({ ..._state, first: 0 }));
		setFilterState((_state) => ({ ..._state, ...event }));
	}

    const onGlobalFilterChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;

        let _filters: any  = filterState.filters;
        _filters['global'].value = value;

        setFilterState({ filters: _filters });
        setGlobalFilter(value);
    }

    const resetFilter = () => {
        let _filters: any = filterState.filters;
        Object.entries(_filters).forEach(([key]) => {
            _filters[key].value = null;
        });
        setGlobalFilter('');
    }

	return {
		...pageState,
		...sortState,
		...filterState,
        globalFilter,
        setGlobalFilter,
		setFilterState,
		onPage,
		onSort,
		onFilter,
        onGlobalFilterChange,
        resetFilter,
	}
}
