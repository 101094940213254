import { renderer } from 'app/renderer';
import { useAppDispatch, useAppSelector } from 'app/store';
import { FieldConfigSource, ThresholdsMode } from 'app/types';
import { PaginatedResponsePanel } from 'app/types/Panel';
import React, { CSSProperties, ReactElement } from 'react';
import ReactGridLayout, { Layout } from 'react-grid-layout';
import AutoSizer from 'react-virtualized-auto-sizer';
import DashboardGridItem from './DashboardGridItem';
import DashboardPanel from './DashboardPanel';
import { setGrid } from 'app/reducers/updateGridSlice';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../style.css'
export interface Props {
	panels?: PaginatedResponsePanel;
	isEditing?: boolean;
	pageId?:string
}

const DashboardGrid = ({ panels, isEditing, pageId }: Props) => {
	const refreshInterval = useAppSelector((state) => state.application.refreshInterval);
	const panelStatus = useAppSelector((state) => state.panel.status);
	const buildLayout = React.useCallback((): Layout[] => {
		const layout: Layout[] = [];
		if (panels && panels.content && panels.content.length > 0) {
			for (const panel of panels.content) {
				const stringId = panel.id.toString();

				if (!panel.gridLayoutConfig) {
					console.log('panel without gridpos');
					continue;
				}

				const gridPos = JSON.parse(panel.gridLayoutConfig);

				const panelPos: Layout = {
					i: stringId,
					x: gridPos.x,
					y: gridPos.y === 99999 ? Infinity : gridPos.y,
					w: gridPos.w,
					h: gridPos.h,
					static: isEditing ? false : true,
				};

				layout.push(panelPos);
			}
		}
		return layout;
	}, [panels, isEditing]);

	const renderPanels = React.useCallback(
		(width: number) => {
			const panelElements: ReactElement[] = [];

			if (panels && panels.content && panels.content.length > 0) {
				for (const panel of panels.content) {
					const itemKey = panel.id.toString();

					if (!panel.gridLayoutConfig) {
						console.log('panel without gridpos');
						continue;
					}

					const gridPos = JSON.parse(panel.gridLayoutConfig);

					const panelPos: Layout = {
						i: itemKey,
						x: gridPos.x,
						y: gridPos.y === 99999 ? Infinity : gridPos.y,
						w: gridPos.w,
						h: gridPos.h,
						static: isEditing ? false : true,
					};

					const defaultFieldConfig: FieldConfigSource = {
						defaults: {
							custom: {},
							unit: 'none',
							decimals: 2,
							thresholds: {
								mode: ThresholdsMode.Absolute,
								steps: [
									{
										color: 'green',
										value: 0,
									},
								],
							},
						},
						options: {}
					};

					let fieldConfig: FieldConfigSource = defaultFieldConfig;

					try {
						if (panel.componentConfig && panel.componentConfig !== '{}' && panel.componentConfig !== 'null') {
							fieldConfig = JSON.parse(panel.componentConfig);
						}
					} catch {}

					const componentName = panel.panelComponentName;

					const { options, ...config } = fieldConfig;
					const renderPanel = (width: number, height: number) =>
						renderer({
							component: componentName,
							props: {
								panel: panel,
								height: height,
								width: width,
								fieldConfig: config,
								refreshInterval: refreshInterval,
								options: options || {},
							},
						});

					panelElements.push(
						<DashboardGridItem key={itemKey} windowHeight={1200} gridPos={panelPos} gridWidth={width}>
							{(width: number, height: number) => (
									<DashboardPanel
										key={itemKey}
										width={width}
										height={height}
										component={() => renderPanel(width, height)}
										panel={panel}
									/>
							)}
						</DashboardGridItem>,
					);
				}
			}

			return panelElements;
		},
		[panels, refreshInterval, isEditing],
	);

	const autoSizerStyle: CSSProperties = {
		width: '100%',
		height: '100%',
	};

	const containerStyle: CSSProperties = {
		marginTop: 16,
	};

	const dispatch = useAppDispatch();

	const handleChange = (e) => {
		dispatch(setGrid(e));
	};

	if (panelStatus === 'loading') {
		return (
			<div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
				<ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="6" animationDuration=".5s" />
			</div>
		);
	} else {
		return (
			<div style={containerStyle}>
				<AutoSizer style={autoSizerStyle} disableHeight>
					{({ width }) => {
						if (width === 0) {
							return null;
						}

						const draggable = width <= 769 ? false : true;

						return (
							<ReactGridLayout
								width={width}
								isDraggable={draggable}
								containerPadding={[0, 0]}
								className="layout"
								useCSSTransforms={false}
								layout={buildLayout()}
								margin={[10, 10]}
								cols={24}
								rowHeight={30}
								draggableHandle=".grid-drag-handle"
								compactType={'vertical'}
								onLayoutChange={handleChange}
							>
								{renderPanels(width)}
							</ReactGridLayout>
						);
					}}
				</AutoSizer>
			</div>
		);
	}
};

export default DashboardGrid;
