import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

export interface FlexRowLeftItemProps {
  className?: string;
}

const FlexRowLeftItem = (props: FlexRowLeftItemProps & PropsWithChildren<unknown>) => {
  const { className } = props;
  return (
    <div className={classNames("d-flex", className)}>
      {props.children}
    </div>
  )
}

export default FlexRowLeftItem;
