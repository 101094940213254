import { useToasts } from 'app/components/Toast';
import { Dialog, DialogProps } from 'primereact/dialog';
import React from 'react';
import { useCreateDailyTaskMutation } from '../../services';
import { IDailyTask } from '../../types';
import FormDailyTask, { TDailyTaskForm } from '../forms/FormDailyTask';

const ModalCreateDailyTask = (props: DialogProps) => {
    const [createDailyTask, { isLoading }] = useCreateDailyTaskMutation();
    const toast = useToasts();

    const onSubmit = async (values: TDailyTaskForm) => {
        const { id, cageId, date, ...data } = values;
        try {

            await createDailyTask(data as IDailyTask).unwrap();
            toast.toastSuccess('Daily task created', '');
            props.onHide();
        } catch (error) {
            toast.toastError('Create daily task failed.', error?.data?.message);
        }
    }

    return (
        <Dialog
            header="Add New Daily Task"
            modal
            style={{ width: '30vw' }}
            {...props}
        >
            <FormDailyTask onSubmit={onSubmit} isLoading={isLoading} onCancel={props.onHide} />
        </Dialog>
    );
};

export default ModalCreateDailyTask;
