import React from 'react';
import { FormikInputText, FormikSelect } from 'components';
import { FormikProps } from 'formik';
import { Col, Row } from 'reactstrap';
import { OptionItem, UserConfiguration } from '../types';
import { useRoleAssignment } from '../hooks/useRoleAssignment';

export interface FormRuleInfoProps {
	formik: FormikProps<UserConfiguration>;
	roleType: OptionItem[] | any;
}

const FormRuleInfo = (props: FormRuleInfoProps) => {
	const { data: roleAssignment } = useRoleAssignment();

	const { formik } = props;

	let nameArray = roleAssignment?.map((el) => {
		return { value: el.id, label: el.name };
	});

	return (
		<React.Fragment>
			<Row>
				<Col lg="6" sm="12">
					<h2>Information</h2>
					<FormikInputText row name="userInfo.name" label="Name" placeholder="Name" required />

					<FormikInputText
						row
						name="userInfo.phoneNumber"
						label="Contact Number"
						placeholder="Contact Number"
						required
					/>
					<FormikInputText row name="userInfo.email" label="Email" placeholder="Email" required />
				</Col>

				<Col className="mt-4" lg="6" sm="12">
					{formik?.values?.id === 0 ? (
						<div>
							<FormikInputText row name="username" label="Username" placeholder="Username" required />
							<FormikInputText row name="password" label="Password" placeholder="Password" required />

							<FormikSelect row name="roleNames" label="Role" placeholder="Role" options={nameArray} />
						</div>
					) : (
						<div>
							<FormikInputText
								row
								name="username"
								label="Username"
								placeholder="Username"
								required
								disabled
							/>
							<FormikInputText
								row
								name="password"
								label="Password"
								placeholder="Password"
								required
								disabled
							/>
							<FormikSelect row name="roleNames" label="Role" placeholder="Role" options={nameArray} />
						</div>
					)}
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default FormRuleInfo;
