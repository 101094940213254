import * as Yup from 'yup';

export const validationAccount = Yup.object().shape({
	account: Yup.object().shape({
		name: Yup.string().required('Condition Name is required'),
		address: Yup.string().required('Address is required'),
		contact: Yup.string().required('Contact is required'),
		email: Yup.string().email('Invalid email').required('Email is required'),
	}),
	adminUser: Yup.object().shape({
		password: Yup.string().required('Password is required'),
		username: Yup.string().required('Username is required'),
	}),
	deviceApi: Yup.object().shape({
		url: Yup.string().required('URL / Endpoint is required'),
	}),
	applicationType: Yup.string().required('Application Type is required'),
	type: Yup.string().required('Type is required'),
});

export const validationAccounts = [validationAccount, undefined];

export const validationEdit = Yup.object().shape({
	name: Yup.string().required('Condition Name is required'),
	address: Yup.string().required('Condition Address is required'),
	contact: Yup.string().required('Condition Contact is required'),
	email: Yup.string().required('Condition Email is required'),
});

export const validationsEdit = [validationEdit, undefined];
