/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// react library for routing
import { Route, Switch, Redirect, useLocation, RouteComponentProps } from 'react-router-dom';

import { IRouteProps } from 'app/types';
import { history } from './../../../utils';

export interface AuthLayoutProps extends RouteComponentProps {
	routes: IRouteProps[]
}

const AuthLayout: React.FC<AuthLayoutProps> = (props) => {
	const mainRef = React.createRef<HTMLDivElement>();
	const location = useLocation();

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		if (document.scrollingElement) {
			document.scrollingElement.scrollTop = 0;
		}
		if (mainRef.current) {
			mainRef.current.scrollTop = 0;
		}
		document.body.classList.add('bg-default');
		return () => {
			document.body.classList.remove('bg-default');
		};
	}, [mainRef]);

	React.useLayoutEffect(() => {
		if (history.location.pathname !== location.pathname) {
			document.documentElement.scrollTop = 0;
			if (document.scrollingElement) {
				document.scrollingElement.scrollTop = 0;
			}
			if (mainRef.current) {
				mainRef.current.scrollTop = 0;
			}
		}
	}, [location, mainRef]);

	const getRoutes = (routes: Array<IRouteProps>): React.ReactNode => {
		return routes.map((prop, key) => {
			if (prop.collapse && prop.views) {
				return getRoutes(prop.views);
			}
			if (prop.layout === '/auth') {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			} else {
				return null;
			}
		});
	};
	return (
		<>
			<div className="main-content" ref={mainRef}>
				<Switch>
					{getRoutes(props.routes)}
					<Redirect from="*" to="/auth/login" />
				</Switch>
			</div>
		</>
	);
};

export default AuthLayout;
