import { API_URL } from './../../../../config/config';
import Axios, { AxiosInstance } from 'axios';
import { UserConfiguration, OptionItem } from '../types';
import keycloak from '../../../../keycloak';

export default class UserService {
	api: AxiosInstance;

	constructor() {
		this.api = Axios.create({
			baseURL: API_URL,
			headers: {
				Accept: 'application/json',
			},
		});

		this.api.interceptors.request.use(
			async function (config) {
				const token = keycloak.token;
				config.headers.Authorization = 'Bearer' + token;
				return config;
			},
			function (error) {
				return Promise.reject(error);
			},
		);
	}

	async getAll(params: any = {}) {
		const response = await this.api.get('/user/', { params });
		return response.data;
	}

	async getOne(id: number | string) {
		const response = await this.api.get(`/user/${id}`);
		return response.data;
	}

	async update(data: UserConfiguration) {
		const { id, ...updateData } = data;
		const response = await this.api.put(`/user/${id}`, updateData);
		return response.data;
	}

	async create(body: UserConfiguration) {
		const response = await this.api.post('/user/', body);
		return response.data;
	}

	async delete(id: number | string) {
		const response = await this.api.delete(`/user/${id}`);
		return response.data;
	}

	getRoleType(): OptionItem[] {
		return [{ value: 'Admin', label: 'Admin' }];
	}
}

let userService = new UserService();
export { userService };
