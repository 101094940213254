/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { smartSolutionLogo } from "assets";

import React from "react";
// react library for routing
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "app/routes/routes";
// core components
import { AdminNavbar, Sidebar } from "../../index";

class AdminLayout extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			sidenavOpen: true
		};
	}

	componentDidUpdate(e) {
		if (e.history.pathname !== e.location.pathname) {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			this.refs.mainContent.scrollTop = 0;
		}
	}
	getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return this.getRoutes(prop.views);
			}
			if (prop.layout === "/admin") {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	getBrandText = path => {
		for (let i = 0; i < routes.length; i++) {
			if (
				this.props.location.pathname.indexOf(
					routes[i].layout + routes[i].path
				) !== -1
			) {
				return routes[i].name;
			}
		}
		return "Brand";
	};
	// toggles collapse between mini sidenav and normal
	toggleSidenav = () => {
		if (document.body.classList.contains('g-sidenav-pinned')) {
			document.body.classList.remove('g-sidenav-pinned');
			document.body.classList.add('g-sidenav-hidden');
		} else {
			document.body.classList.add('g-sidenav-pinned');
			document.body.classList.remove('g-sidenav-hidden');
		}
		this.setState({
			sidenavOpen: !this.state.sidenavOpen,
		});
	};

	closeSidenav = () => {
		if (document.body.classList.contains('g-sidenav-show')) {
			document.body.classList.remove('g-sidenav-show');
		}
	};
	getNavbarTheme = () => {
		return this.props.location.pathname.indexOf(
			"admin/alternative-dashboard"
		) === -1
			? "dark"
			: "light";
	};
	render() {
		return (
			<>
				<Sidebar
					{...this.props}
					routes={this.props.routes}
					toggleSidenav={this.toggleSidenav}
					sidenavOpen={this.state.sidenavOpen}
					logo={{
						innerLink: "/",
						imgSrc: smartSolutionLogo,
						imgAlt: "..."
					}}
				/>
				<div
					className="main-content"
					ref="mainContent"
					onClick={this.closeSidenav}
				>
					<AdminNavbar
						{...this.props}
						theme={this.getNavbarTheme()}
						toggleSidenav={this.toggleSidenav}
						sidenavOpen={this.state.sidenavOpen}
						brandText={this.getBrandText(this.props.location.pathname)}
					/>
					<Switch>
						{this.getRoutes(this.props.routes)}
						<Redirect from="/" to="/admin/smart-site/dashboard" />
					</Switch>
				</div>
				{this.state.sidenavOpen ? (
					<div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
				) : null}
			</>
		);
	}
}

export default AdminLayout;
