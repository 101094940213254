import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { PanelHeader } from './PanelHeader';
import { Panel as PanelModel } from 'app/types/Panel';
import { usePermission } from 'app/hooks/usePermission';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'app/components/ErrorFallback';

export interface PanelProps {
    title?: string;
    width: number;
    height: number;
    hasTitle?: boolean;
    noPadding?: boolean;
    panel: any;
    hasAlert?: boolean;
    transparent?: boolean;
    links?: any[];
    panelModel?: PanelModel;
    key?:any
}

const Panel = (props: PanelProps) => {
    const {
        title,
        width,
        height,
        hasTitle = false,
        noPadding = true,
        panel,
        transparent = false,
        links,
        panelModel,
        key
    } = props;
    const { allowed } = usePermission();

    const renderPanel = useCallback((width: number, height: number) => {
        const PanelComponent = panel;

        const headerHeight = hasTitle ? 0 : 30;
        const chromePadding = noPadding ? 0 : 8;
        const panelWidth = width - (chromePadding * 2) - 2;
        const innerPanelHeight = height - headerHeight - (chromePadding * 2) - 2;
        const panelContentClassNames = classNames({
            'panel-content': true,
            'panel-content--no-padding': noPadding,
        });

        return (
            <div className={panelContentClassNames}>
                <PanelComponent width={panelWidth} height={innerPanelHeight} />
            </div>
        );
    }, [hasTitle, noPadding, panel]);

    const containerClassNames = classNames({
        'panel-container': true,
        'panel-container--absolute': true,
        'panel-container--transparent': transparent,
        'panel-container--no-title': !hasTitle,
    });

    const panelContent = useMemo(() => {
        if (panelModel && panelModel.permission && panelModel.permission !== null) {
            if (!allowed(panelModel.permission)) {
                return null;
            }
        }

		const componentConfig = panelModel && panelModel?.componentConfig && panelModel.componentConfig !== '' && panelModel.componentConfig !== '{}' && panelModel.componentConfig !== 'null' ? JSON.parse(panelModel.componentConfig) : {};

		const noHeader = componentConfig.options?.noHeader || false;

        return (
            <>
                <PanelHeader panelModel={panelModel} title={title} links={links} panelId={panelModel?.id} noHeader={noHeader} />
                <ErrorBoundary key={key} FallbackComponent={ErrorFallback}> 
                    {renderPanel(width, height)}
				</ErrorBoundary> 
                
            </>
        )
    }, [allowed, panelModel, height, links, renderPanel, title, width, key]);

    const isEmptyPanel = useMemo(() =>{
        if(panelModel?.panelComponentName === 'TritronikEmptyPanel') return true
        return false
    },[panelModel])

    return (
        <div style={{borderStyle:isEmptyPanel ? 'none' : ''}} className={containerClassNames}>
            {panelContent}
        </div>
    );
};

export default Panel;
