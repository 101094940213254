import { PrimeSteps } from 'components';
import React, { useCallback, useMemo, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { Button } from 'primereact/button';
import LoginConfirmation from './LoginConfirmation';
import LoginInformation from './LoginInformation';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useEditTenantConfigMutation, useGetTenantConfigQuery } from '../../../services/ProjectService';
import { TenantConfig } from 'app/types';
import { useAppSelector } from 'app/store';
import { locationService } from 'app/services/LocationService';
import Swal from 'sweetalert2';

type Props = {
	projectId: string;
};

export interface InitialValuesLoginSetting extends TenantConfig {
	subdomain: string;
	templateName: string;
	id: string;
	projectId: string;
}

const defaultVal = {
	backgroundImg: '',
	caption: '',
	logoImg: '',
	id: '',
	projectId: '',
	templateName: '',
};

const LoginConfig: React.FC<Props> = ({ projectId }) => {
	const formSteps = [{ label: 'LOGIN INFORMATION' }, { label: 'CONFIRMATION' }];

	const [activeStep, setActiveStep] = useState(0);

	const defaultPage = useAppSelector((state) => state.page.defaultPage);

	const isLastStep = activeStep === formSteps.length - 1;

	const nextStep = () => {
		if (!isLastStep) {
			setActiveStep((currentStep) => currentStep + 1);
		}
	};

	const prevStep = () => {
		if (activeStep > 0) {
			setActiveStep((currentStep) => currentStep - 1);
		}
	};

	const renderForm = useCallback(
		(step: number, formik: FormikProps<InitialValuesLoginSetting>) => {
			switch (step) {
				case 1:
					return <LoginConfirmation formik={formik} />;
				default:
					return <LoginInformation projectId={projectId} formik={formik} />;
			}
		},
		[projectId],
	);

	const subdomain = window.location.host.split('.')[0];

	const { data } = useGetTenantConfigQuery(subdomain);

	const dataConfig = useMemo<InitialValuesLoginSetting>(() => {
		if (!data) return defaultVal;
		try {
			return {
				...JSON.parse(data.configs),
				templateName: data.templateName,
				id: data.id,
				projectId: data.projectId,
			};
		} catch {
			return defaultVal;
		}
	}, [data]);

	const initialValues = useMemo<InitialValuesLoginSetting>(() => {
		return {
			subdomain: subdomain ? subdomain : '',
			logoImg: dataConfig.logoImg ? dataConfig.logoImg : '',
			caption: dataConfig.caption ? dataConfig.caption : '',
			backgroundImg: dataConfig.backgroundImg ? dataConfig.backgroundImg : '',
			templateName: dataConfig.templateName ? dataConfig.templateName : '',
			id: dataConfig.id ? dataConfig.id : '',
			projectId: dataConfig.projectId ? dataConfig.projectId : '',
		};
	}, [subdomain, dataConfig]);

	const [editTenantConfig] = useEditTenantConfigMutation();

	const valueToSend = (value) => {
		return {
			configs: JSON.stringify({
				backgroundImg: value.backgroundImg,
				caption: value.caption,
				logoImg: value.logoImg,
			}),
			id: value.id,
			projectId: value.projectId,
			templateName: value.templateName,
			subdomain: value.subdomain,
		};
	};

	const onsubmit = (val: InitialValuesLoginSetting, formik: FormikHelpers<InitialValuesLoginSetting>) => {
		if (isLastStep) {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes',
			}).then((result) => {
				if (result.isConfirmed) {
					editTenantConfig({ id: val.id, body: valueToSend(val) })
						.unwrap()
						.then(() => {
							history.push({ pathname: defaultPage });
						})
						.catch(() =>
							Swal.fire({
								icon: 'error',
								title: 'Oops...',
								text: 'Something went wrong!',
							}),
						);
					Swal.fire('Success!', 'Your Config has been Saved.', 'success');
				}
			});
		} else {
			nextStep();
			formik.setTouched({});
			formik.setSubmitting(false);
		}
	};
	const history = locationService.getHistory();

	return (
		<Formik initialValues={initialValues} onSubmit={onsubmit} enableReinitialize={true}>
			{(formik) => (
				<Form className="p-3" onSubmit={formik.handleSubmit}>
					<Row className="mt--1 mb-3">
						<Col>
							<PrimeSteps model={formSteps} activeIndex={activeStep} />
						</Col>
					</Row>
					{renderForm(activeStep, formik)}
					<Row className="d-flex justify-content-end pr-3">
						<Button
							type="button"
							label="Cancel"
							onClick={async () => {
								await history.push({ pathname: defaultPage });
								formik.resetForm();
							}}
							className="mr-2 p-button-outlined"
						/>
						{activeStep > 0 && (
							<Button
								type="button"
								label="Previous"
								onClick={prevStep}
								className="mr-2 p-button-outlined"
							/>
						)}
						<Button label={isLastStep ? 'Apply' : 'Next'} type="submit" />
					</Row>
				</Form>
			)}
		</Formik>
	);
};

export default LoginConfig;
