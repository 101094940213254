import { Col, Row } from 'reactstrap';
import LoginPageTemplateDefault from './features/auth/pages/Login/components/LoginPageTemplateDefault';
import LoginPageTemplateRightForm from './features/auth/pages/Login/components/LoginPageTemplateRightForm';
import {
    TritronikAlertStatusPanel,
    TritronikBarChartPanel,
    TritronikGaugePanel,
    TritronikHorizontalBarChartPanel,
    TritronikLineChartPanel,
    TritronikListStatusPanel,
    TritronikMapPanel,
    TritronikStatusPanel,
    TritronikGauge,
    TritronikConsumptionPanel,
    TritronikSiteInformationPanel,
    TritronikAlertListPanel,
    TritronikDevPanel,
    TritronikBatteryPanel,
    TritronikThreePhasePanel,
    TritronikDoorStatusPanel,
    TritronikTextPanel,
    TritronikImagePanel,
    TritronikBasicImageOcrPanel,
    TritronikDeltaStatusPanel,
    TritronikVisualMapPanel,
    TritronikAlertPieChartPanel,
    TritronikBatteryInfoPanel,
    TritronikOverviewPanel,
    TritronikFilterDropdownPanel,
    TritronikLiquidFillPanel,
    TritronikDoorPanel,
    TritronikEmptyPanel,
    TritronikCCTVPanel,
    TritronikBatteryOverviewPanel,
    TritronikTimelinePanel,
} from './plugins';
import TritronikTabelPanel from './plugins/TritronikTabelPanel/TritronikTabelPanel';
import TritronikTrendPanel from './plugins/TritronikTrendPanel';

export const COMPONENTS: Record<string, any> = {
    row: Row,
    col: Col,
    TritronikStatusPanel: TritronikStatusPanel,
    TritronikMapPanel: TritronikMapPanel,
    TritronikConsumptionPanel: TritronikConsumptionPanel,
    TritronikLinePanel: TritronikLineChartPanel,
    TritronikSiteLocationPanel: TritronikSiteInformationPanel,
    TritronikStatePanel: TritronikGauge,
    TritronikGaugePanel: TritronikGaugePanel,
    TritronikLineChartPanel: TritronikLineChartPanel,
    TritronikBarChartPanel: TritronikBarChartPanel,
    TritronikHorizontalBarChartPanel: TritronikHorizontalBarChartPanel,
    TritronikListStatusPanel: TritronikListStatusPanel,
    TritronikAlertStatusPanel: TritronikAlertStatusPanel,
    TritronikAlertListPanel: TritronikAlertListPanel,
    TritronikAlertPieChartPanel: TritronikAlertPieChartPanel,
    TritronikBatteryPanel: TritronikBatteryPanel,
    TritronikThreePhasePanel: TritronikThreePhasePanel,
    TritronikDoorStatusPanel: TritronikDoorStatusPanel,
    TritronikTextPanel: TritronikTextPanel,
    TritronikImagePanel: TritronikImagePanel,
    TritronikBasicImageOcrPanel: TritronikBasicImageOcrPanel,
    TritronikDeltaStatusPanel: TritronikDeltaStatusPanel,
    TritronikVisualMapPanel: TritronikVisualMapPanel,
    TritronikTabelPanel: TritronikTabelPanel,
    TritronikDevPanel: TritronikDevPanel,
    TritronikBatteryInfoPanel: TritronikBatteryInfoPanel,
    TritronikOverviewPanel: TritronikOverviewPanel,
    TritronikFilterDropdown: TritronikFilterDropdownPanel,
    TritronikLiquidFillPanel: TritronikLiquidFillPanel,
    TritronikTrendPanel: TritronikTrendPanel,
    TritronikDoorPanel: TritronikDoorPanel,
    TritronikEmptyPanel: TritronikEmptyPanel,
    LoginPageTemplateDefault: LoginPageTemplateDefault,
    LoginPageTemplateRightForm: LoginPageTemplateRightForm,
    TritronikCCTVPanel: TritronikCCTVPanel,
    TritronikBatteryOverviewPanel: TritronikBatteryOverviewPanel,
    TritronikTimelinePanel: TritronikTimelinePanel,
};

export type ComponentType = {
    [key: string]: any;
};

export type ComponentTypeList = keyof typeof COMPONENTS;

export interface IConfigComponent {
    component: ComponentTypeList | string;
    children?: IConfigComponent[] | string;
    props?: any;
    [key: string]: any;
}
