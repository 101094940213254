import { PanelProps } from 'app/types/Panel';
import { ZM_BASE_URL } from 'config';
import React, { useState } from 'react';
import { randomString } from 'utils';
import { useZoneMinderToken } from 'app/hooks/useZoneMinder';

interface CCTVPanelOptions {
    monitorId: string | number;
}

export interface TritronikCCTVPanelProps extends PanelProps<CCTVPanelOptions> {}

const TritronikCCTVPanel = (props: TritronikCCTVPanelProps) => {
    const { height, width, options } = props;
    // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJab25lTWluZGVyIiwiaWF0IjoxNjczMjM4MzgxLCJleHAiOjE2NzMyNDU1ODEsInVzZXIiOiJhZG1pbiIsInR5cGUiOiJhY2Nlc3MifQ.u9mpGe38NIjPf1UJeNKhhVrTEgp1Vle1MgIP5aFLCH4";
    const token = useZoneMinderToken();
    const connkey = randomString(8);
    const [maxFps] = useState(10);
    const [buffer] = useState(1000);
    const monitorId = options.monitorId;
    const [scale] = useState(50);

    if (!monitorId) return <div>Loading...</div>

    return (
        <div style={{ width, height }}>
            <img alt="camera" src={`${ZM_BASE_URL}/cgi-bin/nph-zms?scale=${scale/2}&width=${width/2}p&height=${height/2}px&mode=jpeg&maxfps=${maxFps}&buffer=${buffer}&monitor=${monitorId}&token=${token}&connkey=${connkey}`} />
        </div>
    )
}

export default TritronikCCTVPanel
