import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { RoleRuleForm } from '../components';
import { RoleConfiguration } from '../types';
import { locationService } from './../../../services/LocationService';
import { FormikHelpers } from 'formik';
import { useCreateRole } from '../hooks/useCreateRole';
import { FormRoleValues } from '../components/FormRuleInfo';

interface Props {}

const CreateRolePage = () => {
	const history = locationService.getHistory();

	const { createRole, isLoading } = useCreateRole();

	const initialValues: RoleConfiguration = {
		tag: [],
		cliendId: '',
		description: '',
		id: 0,
		name: '',
		permissionsInput: '',
	};

	const onSubmit = (values: FormRoleValues, formik: FormikHelpers<RoleConfiguration>): void => {
		createRole(values).then(() => {
			history.push({
				pathname: '/role-management',
			});
		});
	};

	return (
		<Container fluid>
			<h2 className="my-3">Role Management</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/role-management/">All Roles</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>Add New Role</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<RoleRuleForm initialValues={initialValues} onSubmit={onSubmit} isSubmitting={isLoading} />
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default CreateRolePage;
