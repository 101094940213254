import { IProduction, IProductionListRequest, IProductionMonitoring, IProductionMonitoringListRequest } from './../types';
import { livestockBaseApi } from 'app/services/api';
import { IAppConfigRaw, IAppConfigRequest, ICage, ICageListRequest, IDailyTask, IDailyTaskRequest, IPaginatedRequest, IPaginatedResponse, ITask, ITaskRequest } from '../types';

const enhancedEnpoints = livestockBaseApi.enhanceEndpoints({
    addTagTypes: ['Task', 'DailyTask'],
});

export const taskApi = enhancedEnpoints.injectEndpoints({
    endpoints: (builder) => ({
        getTasks: builder.query<IPaginatedResponse<ITask>, IPaginatedRequest & ITaskRequest>({
            query: (params) => ({
                url: '/livestock-service/Task',
                params,
            }),
            providesTags: (result) => {
                return result && result.content
                    ? [...result.content.map(({ id }) => ({ type: 'Task' as const, id })), 'Task']
                    : ['Task'];
            },
        }),
        createTask: builder.mutation<ITask, Omit<ITask, 'id'>>({
            query: (body) => ({
                url: `/livestock-service/Task`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Task']
        }),
        getTask: builder.query<ITask, string>({
            query: (id) => ({
                url: `/livestock-service/Task/${id}`,
            }),
            providesTags: (res, err, id) => [{ type: 'Task', id }]
        }),
        updateTask: builder.mutation<ITask, Partial<ITask> & Pick<ITask, 'id'>>({
            query: (body) => ({
                url: `/livestock-service/Task/${body.id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: (res, err, { id }) => [{ type: 'Task', id }]
        }),
        deleteTask: builder.mutation<ITask, string>({
            query: (id) => ({
                url: `/livestock-service/Task/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Task']
        }),
        getDailyTasks: builder.query<IPaginatedResponse<IDailyTask>, IPaginatedRequest & IDailyTaskRequest>({
            query: (params) => ({
                url: '/livestock-service/Daily-Task',
                params,
            }),
            providesTags: (result) => {
                return result && result.content
                    ? [...result.content.map(({ id }) => ({ type: 'DailyTask' as const, id })), 'DailyTask']
                    : ['DailyTask'];
            },
        }),
        createDailyTask: builder.mutation<IDailyTask, Omit<IDailyTask, 'id'>>({
            query: (body) => ({
                url: `/livestock-service/Daily-Task`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['DailyTask']
        }),
        getDailyTask: builder.query<IDailyTask, string>({
            query: (id) => ({
                url: `/livestock-service/Daily-Task/${id}`,
            }),
            providesTags: (res, err, id) => [{ type: 'DailyTask', id }]
        }),
        updateDailyTask: builder.mutation<IDailyTask, Partial<IDailyTask> & Pick<IDailyTask, 'id'>>({
            query: (body) => ({
                url: `/livestock-service/Daily-Task/${body.id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: (res, err, { id }) => [{ type: 'DailyTask', id }]
        }),
        deleteDailyTask: builder.mutation<ITask, string>({
            query: (id) => ({
                url: `/livestock-service/Daily-Task/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DailyTask']
        }),
        getAppConfig: builder.query<IPaginatedResponse<IAppConfigRaw>, IAppConfigRequest>({
            query: (params) => ({
                url: '/livestock-service/App-Config',
                params
            })
        }),
        getCageList: builder.query<ICage[], ICageListRequest>({
            query: (params) => ({
                url: '/livestock-service/Cage/list',
                params
            })
        }),
        getProductionList: builder.query<IProduction[], IProductionListRequest>({
            query: (params) => ({
                url: '/livestock-service/Production/List',
                params
            })
        }),
        getProductionMonitoringList: builder.query<IProductionMonitoring[], IProductionMonitoringListRequest>({
            query: (params) => ({
                url: '/livestock-service/Production-Monitoring/List',
                params
            })
        }),
    }),
});

export const {
    useGetTaskQuery,
    useGetTasksQuery,
    useCreateTaskMutation,
    useDeleteTaskMutation,
    useUpdateTaskMutation,
    useGetDailyTaskQuery,
    useGetDailyTasksQuery,
    useCreateDailyTaskMutation,
    useDeleteDailyTaskMutation,
    useUpdateDailyTaskMutation,
    useGetAppConfigQuery,
    useGetCageListQuery,
    useGetProductionListQuery,
    useGetProductionMonitoringListQuery,
} = taskApi;
