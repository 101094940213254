import React, { useState } from 'react';
import { SelectableValue } from 'app/types';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import './refresh-picker.scss';
import classNames from 'classnames';

export const defaultIntervals = ['5s', '10s', '30s', '1m', '5m', '15m', '30m', '1h', '2h', '1d'];

const offOption = { label: 'Off', value: '' };

export interface RefreshPickerProps {
	intervals?: string[];
	onRefresh?: () => any;
	onIntervalChanged: (interval: string) => void;
	value?: string;
	isLoading?: boolean;
	className?: any;
}

const RefreshPicker = (props: RefreshPickerProps) => {
	const { onIntervalChanged, onRefresh, isLoading, intervals, value } = props;
	const options = intervalsToOptions({ intervals });
	const [selectedValue, setSelectedValue] = useState(value || '');

	const onSelectChange = (e: DropdownChangeParams) => {
		if (onIntervalChanged) {
			onIntervalChanged(e.value);
		}
		setSelectedValue(e.value);
	};

	return (
		<div className="refresh-picker">
			<Button
				icon={`${isLoading ? 'fa fa-spinner fa-spin' : 'fa fa-sync'}`}
				onClick={onRefresh}
				className={classNames('p-button-outlined p-button-custom', props.className)}
			/>
			<Dropdown className={props.className} value={selectedValue} options={options} onChange={onSelectChange} />
		</div>
	);
};

export default RefreshPicker;

export function intervalsToOptions({ intervals = defaultIntervals }: { intervals?: string[] } = {}): Array<
	SelectableValue<string>
> {
	const intervalsOrDefault = intervals || defaultIntervals;
	const options = intervalsOrDefault.map((interval) => ({ label: interval, value: interval }));

	options.unshift(offOption);
	return options;
}
