import React from 'react';
import AppLayout from 'app/components/AppLayout';
import { useGetProjectsQuery } from 'app/services/ProjectService';
import { useAppSelector } from 'app/store';
import { appRoutesSelector } from 'app/reducers/appSlice';
import FullScreenLoading from './FullScreenLoading';
import PermissionProvider from 'app/context/PermissionProvider';

const MainLayout = (props) => {
	const { isLoading } = useGetProjectsQuery('projects');
	const menus = useAppSelector(appRoutesSelector);

	if (isLoading) {
		return <FullScreenLoading />
	}

	return (
        <PermissionProvider>
            <AppLayout {...props} menus={menus} />
        </PermissionProvider>
    );
};

export default MainLayout;
