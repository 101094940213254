import { useCallback } from 'react';
import { useUpdateUserConfigurationMutation, useSetUserRoleMutation } from '../services/userApi';

export function useUpdateRoleAssignment() {
	const [updateRole, { isLoading: updateRoleAssignment }] = useUpdateUserConfigurationMutation();
	const [setRole, { isLoading: getAssignment }] = useSetUserRoleMutation();

	const updateRoleToUser = useCallback(
		async ({ roleNames, ...data }) => {
			try {
				const newUser = await updateRole(data).unwrap();
				if (newUser) {
					await setRole({ roleIds: roleNames, userId: newUser.id }).unwrap();
				}
			} catch (error) {
				console.error(error);
			}
		},
		[updateRole, setRole],
	);
	return {
		updateRoleToUser,
		isLoading: updateRoleAssignment || getAssignment,
	};
}
