import { API_URL_ALERT } from './../../../../config/config';
import Axios, { AxiosInstance } from 'axios';
import { AlertConfiguration, OptionItem } from '../types';
import keycloak from "../../../../keycloak";

export default class AlertService {
	api: AxiosInstance;

	constructor() {
		this.api = Axios.create({
			baseURL: API_URL_ALERT,
			headers: {
				"Accept": "application/json"
			}
		});

		this.api.interceptors.request.use(async function (config) {
			const token = keycloak.token;
			// const token = await localStorage.getItem('token')
			config.headers.Authorization = "Bearer " + token
			return config;
		}, function (error) {
			return Promise.reject(error);
		});
	}

	async getAll(params: any = {}) {
		const response = await this.api.get('/alertRules/', { params });
		return response.data;
	}

	async getOne(id: number | string) {
		const response = await this.api.get(`/alertRules/${id}`);
		return response.data;
	}

	async update(data: AlertConfiguration) {
		const { id, ...updateData } = data;
		const response = await this.api.put(`/alertRules/${id}`, updateData);
		return response.data;
	}

	async create(body: AlertConfiguration) {
		const response = await this.api.post('/alertRules/', body);
		return response.data;
	}

	async delete(id: number | string) {
		const response = await this.api.delete(`/alertRules/${id}`);
		return response.data;
	}

	getTargetCategoryOptions(): OptionItem[] {
		return [
			{ value: 'SENSOR_INFO', label: 'SENSOR_INFO' },
			{ value: 'DEVICE_SENSOR_PROPERTY', label: 'DEVICE_SENSOR_PROPERTY' }
		]
	}

	getComparatorOptions(type: string = 'NUMERIC'): OptionItem[] {
		if (type === 'NON_NUMERIC') {
			return [
				{ value: 'CTN', label: 'Contains' },
				{ value: 'EQ', label: 'Equal' },
			]
		}
		return [
			{ value: 'LT', label: 'Lower Than' },
			{ value: 'LTE', label: 'Lower Than Equal' },
			{ value: 'GT', label: 'Greater Than' },
			{ value: 'GTE', label: 'Greater Than Equal' },
		]
	}

	getSeverityOptions(): OptionItem[] {
		return [
			{ value: 'MINOR', label: 'MINOR' },
			{ value: 'MAJOR', label: 'MAJOR' },
			{ value: 'CRITICAL', label: 'CRITICAL' },
		]
	}

	getIntervalOptions(): OptionItem[] {
		return [
			{ value: 5, label: 5 },
			{ value: 15, label: 15 },
			{ value: 30, label: 30 },
			{ value: 60, label: 60 },
		]
	}

	getAlertChannelOptions(): OptionItem[] {
		return [
			{ value: 'email', label: 'Email' },
			{ value: 'telegram', label: 'Telegram' },
		]
	}
}

let alertService = new AlertService();

export { alertService };
