import { useUploadImageMutation } from 'app/services/ProjectService'
import { Project } from 'app/types'
import { FormikInputText, FormikInputTextArea } from 'components'
import { FormikInputFile } from 'components/formik/FormikInputFile'
import { FormikProps } from 'formik'
import React from 'react'
import { Col, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import { Settings } from './ProjectConfig'

type Props = {
    projectId:string
    formik:FormikProps<Project<Settings>>
}



const ConfigureInfo:React.FC<Props> = (props) => {
  const { formik } = props

  const [uploadImage] = useUploadImageMutation() 

  
  
  const handleFileBrand = (e) => {
    const formData = new FormData()

    formData.append('imageMultipart', e.target.files[0])

    uploadImage({body:formData})
      .unwrap()
      .then((res) => formik.setFieldValue('settings.brandLogoUrl', res.url))
      .catch((err) =>
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: 'Something went wrong!',
						}),
					);
  }
  const handleFileFavicon = (e) => {
    const formData = new FormData()

    formData.append('imageMultipart', e.target.files[0])

    uploadImage({body:formData})
      .unwrap()
      .then((res) => formik.setFieldValue('settings.favicon', res.url))
      .catch(() =>
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: 'Something went wrong!',
						}),
					);
  }

  return (
    <Row className='d-flex justify-content-center'>
        <Col className="offset-md-2">
            <FormikInputText row name="name" label="Project Name" placeholder="Project Name"  required />
            <FormikInputText row name="settings.pageTitle" label="Project Title" placeholder="Project Title"  required />
            <FormikInputFile onChange={handleFileBrand} row accept='image/*' name="brandLogo" label="Dashboard Icon" defaultImg={formik.values.settings.brandLogoUrl}/>
            <FormikInputFile onChange={handleFileFavicon} row accept='image/*' name="favicon" label="Favicon" defaultImg={formik.values.settings.favicon} />
            <FormikInputTextArea row name="description" label="Project Description" placeholder="Project Description" />
        </Col>
        <Col md={2}></Col>
    </Row>
  )
}

export default ConfigureInfo