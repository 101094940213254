import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './../../../services/BaseApi';
import { PaginatedResponse, SensorConfiguration } from './../types';

export const sensorConfigurationApi = createApi({
	reducerPath: 'sensorConfigurationApi',
	baseQuery: baseQuery,
	tagTypes: ['SensorConfiguration'],
	endpoints: (builder) => ({
		getSensorConfigurations: builder.query<PaginatedResponse<SensorConfiguration>, any>({
			query: (params) => ({
				url: '/v1/additionalSensorInfos/',
				params,
			}),
			providesTags: (result) =>
				result?.content
					? [
							...result.content.map(({ id }) => ({ type: 'SensorConfiguration' as const, id })),
							{ type: 'SensorConfiguration', id: 'LIST' },
					  ]
					: [{ type: 'SensorConfiguration', id: 'LIST' }],
		}),
		getSensorConfiguration: builder.query<SensorConfiguration, number | string>({
			query: (id) => ({ url: `/v1/additionalSensorInfos/${id}` }),
			providesTags: (result, error, id) => [{ type: 'SensorConfiguration', id }],
		}),
		createSensorConfiguration: builder.mutation<SensorConfiguration, Partial<SensorConfiguration>>({
			query: (body) => ({
				url: '/v1/additionalSensorInfos/',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'SensorConfiguration', id: 'LIST' }],
		}),
		updateSensorConfiguration: builder.mutation<SensorConfiguration, Partial<SensorConfiguration>>({
			query: ({ id, ...body }) => ({
				url: `/v1/additionalSensorInfos/${id}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'SensorConfiguration', id }],
		}),
		deleteSensorConfiguration: builder.mutation<SensorConfiguration, number | string>({
			query: (id) => ({
				url: `/v1/additionalSensorInfos/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, id) => [{ type: 'SensorConfiguration', id }],
		}),
	}),
});

export const {
	useGetSensorConfigurationQuery,
	useGetSensorConfigurationsQuery,
	useCreateSensorConfigurationMutation,
	useUpdateSensorConfigurationMutation,
	useDeleteSensorConfigurationMutation,
} = sensorConfigurationApi;
