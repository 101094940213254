import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';

const FullScreenLoading = () => (
	<div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
		<ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="6" animationDuration=".5s"/>
	</div>
);

export default FullScreenLoading;
