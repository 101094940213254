import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './../../../services/BaseApi';
import { PaginatedResponse, AccountConfiguration } from './../types';

export const tenantConfigurationApi = createApi({
	reducerPath: 'tenantConfigurationApi',
	baseQuery: baseQuery,
	tagTypes: ['AccountConfiguration'],
	endpoints: (builder) => ({
		getTenantConfigurations: builder.query<PaginatedResponse<AccountConfiguration>, any>({
			query: (params) => ({
				url: '/v1/account/',
				params,
			}),
			providesTags: (result) =>
				result?.content
					? [
							...result.content.map(({ id }) => ({ type: 'AccountConfiguration' as const, id })),
							{ type: 'AccountConfiguration', id: 'LIST' },
					  ]
					: [{ type: 'AccountConfiguration', id: 'LIST' }],
		}),
		getTenantConfiguration: builder.query<AccountConfiguration, number | string>({
			query: (id) => ({ url: `/v1/account/project/${id}` }),
			providesTags: (result, error, id) => [{ type: 'AccountConfiguration', id }],
		}),
		createTenantConfiguration: builder.mutation<AccountConfiguration, Partial<AccountConfiguration>>({
			query: (body) => ({
				url: '/v1/account/projectRegistration',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'AccountConfiguration', id: 'LIST' }],
		}),
		updateTenantConfiguration: builder.mutation<AccountConfiguration, Partial<AccountConfiguration>>({
			query: ({ id, ...body }) => ({
				url: `/v1/account/project/${id}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'AccountConfiguration', id }],
		}),
		deleteTenantConfiguration: builder.mutation<AccountConfiguration, number | string>({
			query: (id) => ({
				url: `/v1/account/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, id) => [{ type: 'AccountConfiguration', id }],
		}),
	}),
});

export const {
	useGetTenantConfigurationQuery,
	useGetTenantConfigurationsQuery,
	useCreateTenantConfigurationMutation,
	useUpdateTenantConfigurationMutation,
	useDeleteTenantConfigurationMutation,
} = tenantConfigurationApi;
