import * as Yup from 'yup';

export const validation = Yup.object().shape({
  name: Yup.string().required('Condition name required.'),
  applyTo: Yup.string().required('Target category required.'),
  targetId: Yup.string(),
  message: Yup.string().required('Message required.'),
  conditions: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Condition name required.'),
      comparator: Yup.string().required('Condition operator required.'),
      thresholdValue: Yup.string().when('metricType', { is: 'NUMERIC', then: Yup.number(), otherwise: Yup.string() }).required('Threshold required.'),
    })
  ),
  channelRecipient: Yup.array().of(
    Yup.object().shape({
      channel: Yup.string().required('Channel required.'),
      recipients: Yup.array().of(Yup.string()).required('Recipients required').min(1, 'Fill reficipient at least one.'),
    })
  )
})

export const validations = [validation, undefined];
