import { css } from '@emotion/css';
import LoadingPage from 'app/components/LoadingPage';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { PanelProps } from 'app/types/Panel';
import { calculateFontSize } from 'app/utils';
import classNames from 'classnames';
import moment from 'moment';
import { Button } from 'primereact/button';
import React, { useMemo, useState } from 'react';
import Lightbox from "react-awesome-lightbox";

interface ImageOption {
	objectFit: string;
}

interface CaptionStyle {
	color?: string;
	fontSize?: number;
}
export interface PanelOptions {
	defaultImageUrl: string;
	timestampLabel: string;
	noDataValue: string;
	rotate: number;
	imageOptions?: ImageOption;
	captionStyle?: CaptionStyle;
}

export interface TritronikImagePanelProps extends PanelProps<PanelOptions> {

}

const TritronikImagePanel = (props: TritronikImagePanelProps) => {
	const { width, height, panel, refreshInterval, options } = props;
	const [showPreview, setShowPreview] = useState(false);

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const { imageUrl, timestamp } = useMemo(() => {
		let imageUrl = options.defaultImageUrl || 'https://www.propanraya.com/files/uploads/product_image/no-photo.jpg';
		let timestamp = options.noDataValue || 'No data provided';

		if (data && data.responseDataValue && data.responseDataValue.length > 0) {
			if (data.responseDataValue[0].subparamValue) {
				imageUrl = data.responseDataValue[0].subparamValue;
			}
			if (data.responseDataValue[0].dataTimestamp) {
				timestamp = moment(data.responseDataValue[0].dataTimestamp).format('LLL');
			}
		}

		return { imageUrl, timestamp };
	}, [data, options]);

	const timestampLabel = options.timestampLabel || 'Time Captured';

	const defaultCaptionFontSize = calculateFontSize(timestampLabel, width * 0.225, height * 0.225, 0);

	const rotate = useMemo(() => {
		if (!options.rotate) return 0;

		return options.rotate;
	}, [options.rotate])

	const imageOptions = useMemo(() => {
		if (!options.imageOptions) {
			return {
				objectFit: 'cover'
			}
		}

		return options.imageOptions;
	}, [options.imageOptions])

	const captionStyleOptions = useMemo<CaptionStyle>(() => {
		if (!options.captionStyle) {
			return {
				color: '#fafafa',
				fontSize: defaultCaptionFontSize,
			}
		}

		return options.captionStyle;
	}, [options.captionStyle, defaultCaptionFontSize])

	const wrapper = css`
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
	`;

	const containerStyle = css`
		height: 100%;
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 8px 8px;
		position: relative;
	`;


	const imageContainerStyle = css`
		height: 100%;
		width: 100%;
		display: flex;
		position: relative;
	`;

	const imageStyle = css`
		width: 100%;
		height: 100%;
		object-fit: ${imageOptions.objectFit};
		text-align: center;
		vertical-align: middle;
		transform: rotate(${rotate}deg);
		transition: all 400ms;
	`;

	const timestampLabelStyle = css`
		margin-top: 8px;
		text-align: center;
		font-weight: bold;
		line-height: 0;
		color: ${captionStyleOptions.color};
		font-size: ${captionStyleOptions.fontSize}px;
	`;

	const timestampStyle = css`
		margin-top: 8px;
		text-align: center;
		line-height: 0;
		color: ${captionStyleOptions.color};
		font-size: ${captionStyleOptions.fontSize}px;
	`;

	const buttonStyle = css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #cecece !important;
		z-index: 2;
	`;

	const captionContainer = css`
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		padding: 8px;
		z-index: 1;
		background-color: rgba(0,0,0, 0.35);
	`;

	const captionStyle = css`
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding: 8px;
	`;

	const previewTitle = useMemo(() => {
		return `${panel.title || 'No Description'} - ${timestamp}`
	}, [panel, timestamp])

	const onImageClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		e.preventDefault();
		setShowPreview(true);
	}

	const onPreviewClose = () => {
		setShowPreview(false);
	}

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div className={wrapper}>
			<div className={containerStyle}>
				<div className={imageContainerStyle}>
					<a href={`#${timestamp}`} onClick={onImageClick}>
						<img className={imageStyle} src={imageUrl} alt={timestamp} />
					</a>
					{showPreview &&
						<Lightbox
							className={imageStyle}
							image={imageUrl}
							alt={timestamp}
							onClose={onPreviewClose}
							title={previewTitle}
						/>
					}
					<Button
						icon="pi pi-search-plus"
						className={classNames("p-button-rounded p-button-text text-primary", buttonStyle)}
						onClick={() => setShowPreview(true)}
					/>
					<div className={captionContainer}>
						<div className={captionStyle}>
							<p className={timestampLabelStyle}>{options.timestampLabel || 'Time Captured'}</p>
							<p className={timestampStyle}>{timestamp}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TritronikImagePanel
