import React from 'react';
import { ParentSize } from "@vx/responsive";
import CameraStatus from './CameraStatus';
import { Monitor } from '../types';
import { randomString } from 'utils';
import { ZM_BASE_URL, ZM_PASSWORD, ZM_USERNAME } from 'config';

type Props = {
    data: Monitor;
    onClickName?: (id: any, groupName?: string) => void;
    scale?: number;
    mode?: string;
    maxFps?: number;
    buffer?: number;
}

const CardCamera = ({
    data,
    scale = 50,
    mode = 'jpeg',
    maxFps = 10,
    buffer = 1000,
    onClickName
}: Props) => {
    const renderLabel = () => {
        if (typeof onClickName === 'undefined') {
            return data.Monitor.Name;
        }

        return (
            <a href="/cctv/camera" onClick={(e) => {
                e.preventDefault();
                onClickName?.(data.Monitor.Id, data?.Group?.Name);
            }}>
                {data.Monitor.Name}
            </a>
        )
    }
    return (
        <ParentSize debounceTime={300}>
            {({ width, height }) => (
                <div
                    style={{
                        boxShadow: "2px 3px 4px 0px rgba(0,0,0,0.2)",
                        transition: "0.3s",
                        width, height
                    }}>
                    <div className="d-flex justify-content-between align-items-center p-2 py-3 border-bottom" style={{ height: 30 }}>
                        <div className="d-flex align-items-center font-weight-bold text-truncate text-sm">
                            {renderLabel()}
                        </div>
                        <div className="d-flex align-items-center justify-content-end text-xs">
                            <CameraStatus status={data.Monitor_Status.Status} />
                        </div>
                    </div>
                    <div className="p-2">
                        <img
                            width={"100%"}
                            height={height - 50}
                            alt={`camera-${data.Monitor.Id}`}
                            src={`${ZM_BASE_URL}/cgi-bin/nph-zms?scale=${scale}&mode=${mode}&maxfps=${maxFps}&buffer=${buffer}&monitor=${data.Monitor.Id}&connkey=${randomString(8)}&user=${ZM_USERNAME}&pass=${ZM_PASSWORD}`}
                        />
                    </div>
                </div>
            )}
        </ParentSize>
    )
}
export default CardCamera;
