import RowStatus from 'app/components/RowStatus';
import { Button } from 'primereact/button';
import React, { useMemo } from 'react'
import { Row, Col } from 'reactstrap';
import { User } from '../types';
import { defaultUserProfile } from './../../../../assets';

type Props = {
	user?: User|null;
	onChangePasswordClick: () => void;
}

const noData = '-';

const OverviewProfile = ({ user, onChangePasswordClick }: Props) => {
	const onLoadImageError = (event) => {
		event.target.src = defaultUserProfile;
		event.onerror = null
	}

	const avatar = useMemo(() => user?.userProfilePicture?.actualLink || defaultUserProfile, [user]);

	return (
		<Row>
			<Col md="2" xs="12" className="d-flex justify-content-center">
				<div className="profilepic_preview">
					<img alt="..." className="profilepic_preview__image" src={avatar} width="150" height="150" onError={onLoadImageError} />
				</div>
			</Col>
			<Col md="10" xs="12">
				<Row>
					<Col md="6" xs="12" className="px-5">
						<RowStatus stripped label='Name' value={user?.userInfo?.name || noData} />
						<RowStatus label='Email' value={user?.userInfo?.email || noData} />
						<RowStatus stripped label='Phone Number' value={user?.userInfo?.phoneNumber || noData} />
						<RowStatus label='Telegram Conversation ID' value={user?.userInfo?.telegramUserId || noData} />
					</Col>
					<Col md="6" xs="12" className="px-5">
						<RowStatus stripped label='Username' value={user?.username || noData} />
						<RowStatus label='Password' value={(
							<Button
								label={'Change Password'}
								type="button"
								className=""
								onClick={onChangePasswordClick}
							/>
						)} />
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default OverviewProfile;
