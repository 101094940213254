import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useState } from 'react';
import { RoleConfiguration } from '../types';
import FormConfirmation from './FormConfirmation';
import FormRuleInfo from './FormRuleInfo';
import { Row, Col } from 'reactstrap';
import { PrimeSteps } from 'components';
import { Button } from 'primereact/button';
import { history } from 'utils';
import { validationRole } from '../validationSchema';

export interface RoleRuleFormProps {
	initialValues: RoleConfiguration;
	onSubmit: (values: RoleConfiguration, formik: FormikHelpers<RoleConfiguration>) => void;
	isSubmitting?: boolean;
}

const formSteps = [{ label: 'Rule Information' }, { label: 'Confirmation' }];

const RoleRuleForm = (props: RoleRuleFormProps) => {
	const [activeStep, setActiveStep] = useState(0);
	const isLastStep = activeStep === formSteps.length - 1;
	const currentValidation = validationRole[activeStep];

	const { initialValues, isSubmitting, onSubmit } = props;

	const nextStep = () => {
		if (!isLastStep) {
			setActiveStep((currentStep) => currentStep + 1);
		}
	};

	const prevStep = () => {
		if (activeStep > 0) {
			setActiveStep((currentStep) => currentStep - 1);
		}
	};

	const _onSubmit = async (values: any, formik: FormikHelpers<RoleConfiguration>) => {
		console.log('value input ->', values);
		onSubmit(values, formik);
	};

	const _handleSubmit = (values: any, formik: FormikHelpers<RoleConfiguration>): void => {
		if (isLastStep) {
			_onSubmit(values, formik);
		} else {
			nextStep();
			formik.setTouched({});
			formik.setSubmitting(false);
		}
	};

	const _renderForm = useCallback((step: number, formik: FormikProps<RoleConfiguration | any>) => {
		switch (step) {
			case 1:
				return <FormConfirmation formik={formik} />;
			default:
				return <FormRuleInfo formik={formik} />;
		}
	}, []);

	return (
		<Formik initialValues={initialValues} onSubmit={_handleSubmit} validationSchema={currentValidation}>
			{(formik: FormikProps<RoleConfiguration>) => (
				<Form onSubmit={formik.handleSubmit}>
					<Row className="mt-3">
						<Col>
							<PrimeSteps model={formSteps} activeIndex={activeStep} />
						</Col>
					</Row>
					<Row className="mt-3">
						<Col>{_renderForm(activeStep, formik)}</Col>
					</Row>
					<Row className="mt-3">
						<Col className="text-right">
							<Button
								label="Cancel"
								type="button"
								className="mr-2 p-button-outlined"
								onClick={() => history.goBack()}
							/>
							{activeStep > 0 && (
								<Button
									type="button"
									label="Previous"
									onClick={prevStep}
									className="mr-2 p-button-outlined"
								/>
							)}
							<Button
								label={isLastStep ? 'Apply' : 'Next'}
								loading={isSubmitting}
								disabled={isSubmitting}
								type="submit"
								className=""
							/>
						</Col>
					</Row>
				</Form>
			)}
		</Formik>
	);
};

export const defaultProps: RoleRuleFormProps = {
	initialValues: {
		tag: [],
		permissionsInput: '',
		cliendId: '',
		description: '',
		id: 0,
		name: '',
		rolePrivileges: [
			{
				featureIds: [],
				roleId: 0,
			},
		],
	},
	onSubmit: () => {},
	isSubmitting: false,
};

RoleRuleForm.defaultProps = defaultProps;

export default RoleRuleForm;
