import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryAlert } from './../../../services/BaseApi'
import { PaginatedResponse, AlertConfiguration } from './../types';

export const alertConfigurationApi = createApi({
	reducerPath: 'alertConfigurationApi',
	baseQuery: baseQueryAlert,
	tagTypes: ['AlertConfiguration'],
	endpoints: (builder) => ({
		getAlertConfigurations: builder.query<PaginatedResponse<AlertConfiguration>, any>({
			query: (params) => ({
				url: '/v1/alertRules/',
				params
			}),
			providesTags: (result) =>
				result?.content
					? [
						...result.content.map(({ id }) => ({ type: 'AlertConfiguration' as const, id })),
						{ type: 'AlertConfiguration', id: 'LIST' }
					]
					: [
						{ type: 'AlertConfiguration', id: 'LIST' }
					],
		}),
		getAlertConfiguration: builder.query<AlertConfiguration, number | string>({
			query: (id) => ({ url: `/v1/alertRules/${id}` }),
			providesTags: (result, error, id) => [{ type: 'AlertConfiguration', id }]
		}),
		createAlertConfiguration: builder.mutation<AlertConfiguration, Partial<AlertConfiguration>>({
			query: (body) => ({
				url: '/v1/alertRules/',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'AlertConfiguration', id: 'LIST' }]
		}),
		updateAlertConfiguration: builder.mutation<AlertConfiguration, Partial<AlertConfiguration>>({
			query: ({ id, ...body }) => ({
				url: `/v1/alertRules/${id}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'AlertConfiguration', id }]
		}),
		deleteAlertConfiguration: builder.mutation<AlertConfiguration, number | string>({
			query: (id) => ({
				url: `/v1/alertRules/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: (result, error, id) => [{ type: 'AlertConfiguration', id }]
		}),
	}),
})

export const {
	useGetAlertConfigurationQuery,
	useGetAlertConfigurationsQuery,
	useCreateAlertConfigurationMutation,
	useUpdateAlertConfigurationMutation,
	useDeleteAlertConfigurationMutation,
} = alertConfigurationApi;
