import { Page } from 'app/types';
import { FormOverview } from 'components';
import { FormikProps } from 'formik';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
type Props = {
	formik: FormikProps<Page>;
};
const AddMenuConfirmation: React.FC<Props> = (props: Props) => {
	const { formik } = props;
	return (
		<Row>
			<Col>
				<Row className="mt-4">
					<Col lg="6">
						<Card>
							<CardBody>
								<FormOverview label="Name" value={formik.values.name} stripped />
								<FormOverview label="Title" value={formik.values.title} />
								<FormOverview label="Description" value={formik.values.description} stripped />
								<FormOverview label="Page Type" value={formik.values.pageType} />
							</CardBody>
						</Card>
					</Col>
					<Col lg="6">
						<Card>
							<CardBody>
								<FormOverview
									label="Icon Name"
									value={formik.values.iconName}
									icon={formik.values.iconName}
									stripped
								/>
								<FormOverview label="Mini Name" value={formik.values.miniName} />
								<FormOverview label="Component" value={formik.values.component} stripped />
								<FormOverview
									label="Visible in Menu"
									value={formik.values.visibleInMenu ? 'enabled' : 'disabled'}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
export default AddMenuConfirmation;
