import React, { useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { FormikChips, FormikInputText, FormikInputTextArea } from 'components';
import { Field, FormikProps } from 'formik';
import { useAvailablePermissions } from 'app/hooks/useAvailablePermissions';
import { FeatureType } from '../types';
import { capitalizeFirstLetter } from 'utils';

interface Role {
	id: number;
	name: string;
	description: string;
	hidden: boolean;
	rolePermissionNames: string[];
}

export type FormRoleValues = Partial<Role> & { permissionsInput: number[] };

type FormRoleProps = {
	role?: Partial<Role>;
	permissionsInput?: FeatureType[];
	onSubmit?: (values: FormRoleValues) => void | Promise<any>;
	isLoadingSubmit: boolean;
};

export interface FormRuleInfoProps {
	formik?: FormikProps<FormRoleProps>;
	onSubmit?: (values: FormRoleProps) => void | Promise<any>;
}

const FormRuleInfo = (props: FormRuleInfoProps) => {
	const { data: permissions } = useAvailablePermissions();

	const setFilter = useCallback(
		(string: any) => {
			return permissions?.filter((el) => el.category === string);
		},
		[permissions],
	);

	const getAdmin = setFilter('Admin');

	const getMotor = setFilter('Motor Performance');

	const getOverview = setFilter('Overview');

	const getDetail = setFilter('Motor Detail');

	const getNone = setFilter(null);

	return (
		<React.Fragment>
			<Row>
				<Col lg="6" sm="12">
					<h2>Information</h2>
				</Col>
				<Col lg="6" sm="12">
					<h2>Permission</h2>
				</Col>

				<Col lg="6">
					<FormikInputText row label="Name" name="name" placeholder="Name" required />
					<FormikChips row label="Tag" name="tag" placeholder="Tag" />
					<FormikInputTextArea row label="Description" name="description" placeholder="Description" />
				</Col>
				<Col lg="3" sm="12">
					<h3>None</h3>
					{getNone?.map((none, id) => {
						return (
							<div key={id} className="ml-2">
								<Field type="checkbox" name={`permissionsInput`} value={`${none.id}`} />
								<label className="ml-2" htmlFor={none.name}>
									{capitalizeFirstLetter(none.name)}
								</label>
							</div>
						);
					})}

					<h3>Admin</h3>
					{getAdmin &&
						getAdmin?.map((admin, id) => {
							return (
								<div key={id} className="ml-2">
									<Field type="checkbox" name={`permissionsInput`} value={`${admin.id}`} />
									<label className="ml-2" htmlFor={admin.name}>
										{capitalizeFirstLetter(admin.name)}
									</label>
								</div>
							);
						})}

					<h3>Motor Detail</h3>
					{getDetail?.map((detail, id) => {
						return (
							<div key={id} className="ml-2">
								<Field type="checkbox" name={`permissionsInput`} value={`${detail.id}`} />
								<label className="ml-2" htmlFor={detail.name}>
									{capitalizeFirstLetter(detail.name)}
								</label>
							</div>
						);
					})}
				</Col>
				<Col lg="3" sm="12">
					<h3>Motor Performance</h3>
					{getMotor?.map((motor, id) => {
						return (
							<div key={id} className="ml-2">
								<Field type="checkbox" name={`permissionsInput`} value={`${motor.id}`} />
								<label className="ml-2" htmlFor={motor.name}>
									{capitalizeFirstLetter(motor.name)}
								</label>
							</div>
						);
					})}

					<h3>Overview</h3>
					{getOverview?.map((overview, id) => {
						return (
							<div key={id} className="ml-2">
								<Field type="checkbox" name={`permissionsInput`} value={`${overview.id}`} />
								<label className="ml-2" htmlFor={overview.name}>
									{capitalizeFirstLetter(overview.name)}
								</label>
							</div>
						);
					})}
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default FormRuleInfo;
