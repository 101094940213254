import { css } from "@emotion/css";
import { Threshold } from "app/types";

export const wrapper = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;

  * {
    white-space: nowrap;
    margin-bottom: 0;
    line-height: 1;
  }

  span {
    font-size: 70%;
  }
`;

export const container = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export function statusToBackgroundColor(status: 'NORMAL' | 'CRITICAL' | 'MAJOR' | 'MINOR' | 'NOT_INSTALLED' | 'LOW_AVAILABILITY' | 'ABNORMAL' | 'OFFLINE' | string) {
  let backgroundColor: string;
  switch (status) {
    case 'NORMAL':
      backgroundColor = css`
        color: #fff !important;
        background-color: rgb(86, 166, 75) !important;
      `;
      break;
    case 'CRITICAL':
      backgroundColor = css`
        color: #fff !important;
        background-color: rgb(173, 3, 23) !important;
      `;
      break;
    case 'LOW_AVAILABILITY':
    case 'MAJOR':
      backgroundColor = css`
        color: #000 !important;
        background-color: rgb(255, 120, 10) !important;
      `;
      break;
    case 'ABNORMAL':
    case 'MINOR':
      backgroundColor = css`
        color: #000 !important;
        background-color: rgb(242, 204, 12) !important;
      `;
      break;
    case 'NOT_INSTALLED':
      backgroundColor = css`
        color: #000 !important;
        background-color: #ddd !important;
      `;
      break;
    default:
      backgroundColor = css`
        color: #fff !important;
        background-color: rgb(50, 116, 217) !important;
      `;
  }
  return backgroundColor;
}

export function getColorByName(name: string) {
	switch (name) {
		case 'green':  return '#73BF69';
		case 'dark-green':  return '#37872D';
		case 'semi-dark-green':  return '#56A64B';
		case 'light-green':  return '#96D98D';
		case 'super-light-green':  return '#C8F2C2';

		case 'yellow':  return '#FADE2A';
		case 'dark-yellow':  return '#E0B400';
		case 'semi-dark-yellow':  return '#F2CC0C';
		case 'light-yellow':  return '#FFEE52';
		case 'super-light-yellow':  return '#FFF899';

		case 'red':  return '#F2495C';
		case 'dark-red':  return '#C4162A';
		case 'semi-dark-red':  return '#E02F44';
		case 'light-red':  return '#FF7383';
		case 'super-light-red':  return '#FFA6B0';

		case 'blue':  return '#5794F2';
		case 'dark-blue':  return '#1F60C4';
		case 'semi-dark-blue':  return '#3274D9';
		case 'light-blue':  return '#8AB8FF';
		case 'super-light-blue':  return '#C0D8FF';

		case 'orange':  return '#FF9830';
		case 'dark-orange':  return '#FA6400';
		case 'semi-dark-orange':  return '#FF780A';
		case 'light-orange':  return '#FFB357';
		case 'super-light-orange':  return '#FFCB7D';

		case 'purple':  return '#B877D9';
		case 'dark-purple':  return '#8F3BB8';
		case 'semi-dark-purple':  return '#A352CC';
		case 'light-purple':  return '#CA95E5';
		case 'super-light-purple':  return '#DEB6F2';
		default: return "#000";
	}
}

export function getColor(number: number, steps: Threshold[] | undefined): string {
  let color = 'gray';
  if (steps) {
    const colorPalette: { [index: string]: any } = {
      green: '#73BF69',
      'dark-green': '#37872D',
      'semi-dark-green': '#56A64B',
      'light-green': '#96D98D',
      'super-light-green': '#C8F2C2',

      yellow: '#FADE2A',
      'dark-yellow': '#E0B400',
      'semi-dark-yellow': '#F2CC0C',
      'light-yellow': '#FFEE52',
      'super-light-yellow': '#FFF899',

      red: '#F2495C',
      'dark-red': '#C4162A',
      'semi-dark-red': '#E02F44',
      'light-red': '#FF7383',
      'super-light-red': '#FFA6B0',

      blue: '#5794F2',
      'dark-blue': '#1F60C4',
      'semi-dark-blue': '#3274D9',
      'light-blue': '#8AB8FF',
      'super-light-blue': '#C0D8FF',

      orange: '#FF9830',
      'dark-orange': '#FA6400',
      'semi-dark-orange': '#FF780A',
      'light-orange': '#FFB357',
      'super-light-orange': '#FFCB7D',

      purple: '#B877D9',
      'dark-purple': '#8F3BB8',
      'semi-dark-purple': '#A352CC',
      'light-purple': '#CA95E5',
      'super-light-purple': '#DEB6F2',
    };

    let indexStep = steps.findIndex(step => Number(step.value) > number) - 1;
    indexStep = indexStep === -2 ? steps.length - 1 : indexStep;

    color = colorPalette[steps[indexStep]?.color] || steps[indexStep]?.color;
  }
  return color;
}

export const trStyleFull = css`
  width: 100%;
  height: 100%;
`;
