import React from 'react'

type Props = {}

const CreateTask = (props: Props) => {
  return (
    <div>CreateTask</div>
  )
}

export default CreateTask
