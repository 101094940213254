import { FormikHelpers } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import ApiRuleForm from '../components/ApiRuleForm';
import { useCreateApiConfigurationMutation } from '../services/apiManagementApi';
import { FormApiConfiguration } from '../types';
import { locationService } from './../../../services/LocationService';

export interface ApiRuleFormProps {}

const CreateApiPage = () => {
	const [createApi, { isLoading }] = useCreateApiConfigurationMutation();
	const history = locationService.getHistory();

	const initialValues: FormApiConfiguration = {
		id: '',
		urlEndpoint: '',
		name: '',
		description: '',
		projectCategory: '',
		requestMethod: '',

		pathVariables: [
			{
				paramName: '',
				paramDescription: '',
				paramType: 'PATH_VARIABLE',
			},
		],
		requestHeaders: [
			{
				paramName: '',
				paramDescription: '',
				paramType: 'REQUEST_HEADER',
			},
		],
		requestParams: [
			{
				paramName: '',
				paramDescription: '',
				paramType: 'REQUEST_PARAM',
			},
		],
		requestBody: [
			{
				paramName: '',
				paramDescription: '',
				paramType: 'REQUEST_BODY',
			},
		],
	};

	const onSubmit = (values: FormApiConfiguration, formik: FormikHelpers<FormApiConfiguration>): void => {
		createApi(values)
			.unwrap()
			.then(() => {
				history.push({
					pathname: '/endpoint-api-management',
				});
			});
	};

	return (
		<Container fluid>
			<h2 className="my-3">API Management</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/endpoint-api-management/">All APIs</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>Add New</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<ApiRuleForm initialValues={initialValues} onSubmit={onSubmit} isSubmitting={isLoading} />
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default CreateApiPage;
