import React, { useMemo } from 'react';
import { FormikInputText, FormikInputTextArea, FormikSelect } from 'components';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';
import FormikCalendar from 'components/formik/FormikCalendar';
import { AbnormalEvent, FormAbnormalEvent } from '../types';
import * as Yup from 'yup';

type Props = {
	data?: AbnormalEvent,
    onSubmit: (data: FormAbnormalEvent) => void | Promise<void>;
    isLoading?: boolean;
}

const FormAbnormalEventModal = ({ data, visible, isLoading, onHide, onSubmit }: Props & DialogProps) => {
    const onHideModal = () => {
        if (typeof onHide === 'function') {
            onHide();
        }
    }

	const userLabelOptions = useMemo(() => {
		return [
			{ value: 'Fault', label: 'Fault' },
			{ value: 'Fine', label: 'Fine' },
		];
	}, []);

    return (
        <Dialog
            showHeader={false}
            visible={visible}
			style={{ width: 400 }}
            onHide={onHideModal}>
            <div>
                <h4 className='mt-2'>Add Abnormal Event</h4>
                <div className='mt-4'>
					<Formik
						enableReinitialize={true}
						initialValues={{
							id: data?.id || 0,
							objectName: data?.objectName || '',
							startTs: data?.startTs ? new Date(data.startTs) :  undefined,
							endTs: data?.endTs ? new Date(data.endTs) : undefined,
							userLabel: data?.userLabel || '',
							userDescription: data?.userDescription || '',
						}}
						onSubmit={onSubmit}
						validationSchema={Yup.object().shape({
							objectName: Yup.string().required('Object required.'),
							startTs: Yup.date().required('Start time required.'),
							endTs: Yup.date().required('End time required.'),
							userLabel: Yup.string().required('Label required.'),
							userDescription: Yup.string().required('Description required.'),
						})}
					>
						{(formik) => (
							<form onSubmit={formik.handleSubmit}>
								<FormikInputText className='mb-2' label='Object' name='objectName' />
								<FormikCalendar showTime hideOnDateTimeSelect hourFormat="12" className='mb-2' label='Start Time' name='startTs' showIcon />
								<FormikCalendar showTime hideOnDateTimeSelect hourFormat="12" className='mb-2' label='End Time' name='endTs' showIcon />
								<FormikSelect className='mb-2' label='Label' name='userLabel' options={userLabelOptions} />
								<FormikInputTextArea className='mb-2' label='Description' name='userDescription' rows={4} />

								<div className="d-flex align-items-center justify-content-between mt-4">
									<Button type='button' style={{ width: '7em'}} label="Cancel" onClick={onHide} className="p-button-outlined" />
									<Button type='submit' style={{ width: '7em'}} label="Save" icon="pi pi-save" autoFocus className="p-button-primary" loading={isLoading} />
								</div>
							</form>
						)}
					</Formik>
                </div>
            </div>
        </Dialog>
    )
}

export default FormAbnormalEventModal;
