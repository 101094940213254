// import { useLoginMutation } from "app/features/ZoneMinder/zmApi";
// import { ZM_PASSWORD, ZM_USERNAME } from "config";
import React, { createContext } from "react";

const ZM_STORAGE_KEY = 'zm-auth-token';

interface IZoneMinderContext {
    token: string | null;
}

export const ZoneMinderContext = createContext<IZoneMinderContext>({ token: null });

interface ZoneMinderProviderProps extends React.PropsWithChildren<any> {

}

export const ZoneMinderProvider: React.FC<ZoneMinderProviderProps> = ({ children }) => {
    // const [login] = useLoginMutation();
    const token = localStorage.getItem(ZM_STORAGE_KEY);

    // const authenticate = React.useCallback(async () => {
    //     const username = ZM_USERNAME;
    //     const password = ZM_PASSWORD;
    //     const res = await login({ username, password }).unwrap();
    //     await localStorage.setItem(ZM_STORAGE_KEY, res.access_token);

    //     return res.access_token;
    // }, [login]);

    // React.useEffect(() => {
    //     if (!token) {
    //         authenticate();
    //     }
    // }, [token, authenticate]);

    return (
        <ZoneMinderContext.Provider value={{ token }}>
            {children}
        </ZoneMinderContext.Provider>
    )
}
