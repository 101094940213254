import { css, cx } from '@emotion/css';
import React, { FC, useCallback, useMemo } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { locationService } from 'app/services/LocationService';
import { PANEL_TITLE_FONT_SIZE } from 'config/config';
import { useCutPanelMutation, useDeletePanelMutation, useDuplicatePanelMutation } from 'app/services/PanelService';
import { useAppSelector } from 'app/store';
import { useParams } from 'react-router';
import Dropdown from 'react-multilevel-dropdown';
import Swal from 'sweetalert2';
import { Panel as PanelModel } from 'app/types/Panel';
interface TargetLink {
	href: string;
	title: string;
	target: string;
	type?: 'internal' | 'external';
}
export interface Props {
	title?: string;
	description?: string;
	error?: string;
	links?: TargetLink[];
	panelId?: string;
	noHeader?: boolean;
	panelModel?: PanelModel;
}

interface PanelHeaderCornerProps {
	links?: TargetLink[];
	panelId?: string;
}

const PanelHeaderCorner = ({ links, panelId }: PanelHeaderCornerProps) => {
	let uniqueId = panelId || Math.random();

	if (links && links.length > 0) {
		return (
			<>
				<Tooltip target={`.customTooltip-${uniqueId}`} position="top" autoHide={false}>
					<div className="panel-info-content">
						{links && links.length > 0 && (
							<ul className="panel-info-corner-links">
								{links.map((link, idx) => {
									if (link.type && link.type === 'external') {
										return (
											<li key={idx}>
												<a
													className="panel-info-corner-links__item"
													href={link.href}
													target={link.target}
												>
													{link.title}
												</a>
											</li>
										);
									}

									const url = new URL(link.href);

									const onTargetClick = () => {
										locationService.push({
											pathname: url.pathname,
											search: url.search,
										});
									};

									return (
										<li key={idx}>
											<span onClick={onTargetClick}>{link.title}</span>
										</li>
									);
								})}
							</ul>
						)}
					</div>
				</Tooltip>
				<div className={`customTooltip-${uniqueId} panel-info-corner panel-info-corner--links`}>
					<i aria-hidden className="mdi" />
					<span className="panel-info-corner-inner" />
				</div>
			</>
		);
	}
	return null;
};

export const PanelHeader: FC<Props> = ({ error, title, links, panelId, noHeader, panelModel }) => {
	const className = cx('panel-header', { 'grid-drag-handle': !noHeader });

	const titleClassName = cx('panel-title-container', {
		'd-none': noHeader
	});

	const styles = panelStyles();

	const [deletePanel] = useDeletePanelMutation();

	const isEditing = useAppSelector((state) => state.updateGrid.isEditing);

	const history = locationService.getHistory();

	const { uid: pageId } = useParams<any>();

	const navigateEditPanel = () => {
		history.push({ pathname: `/dashboard-management/edit-panel/${pageId}/${panelId}` });
	};

	const handleDelete = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
		}).then((result) => {
			if (result.isConfirmed) {
				deletePanel(panelId);
				Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
			}
		});
	};

	const [duplicatePanel] = useDuplicatePanelMutation();

	const [cutPanel] = useCutPanelMutation();

	const handleDuplicate = () => {
		duplicatePanel({ panelId, pageId });
	};

	const menus = useAppSelector((state) => state.page.pages);

	const subMenu = useMemo(() => {
		return menus.filter((menu) => menu.pageType === 'DASHBOARD');
	}, [menus]);

	const handleDuplicateTo = (panelId, menu) => {
		const { id, name, path } = menu;
		duplicatePanel({ panelId, pageId: id })
			.then(() =>
				Swal.fire({
					title: 'success !',
					icon: 'success',
					html: `the panel has copied to page ${name}. <a href='/d/${id}/smart-site/${path}'>Click here to redirect </a>`,
				}),
			)
			.catch((err) =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: err,
				}),
			);
	};

	const cutValue = useCallback(
		(id) => {
			return {
				...panelModel,
				pageId: id,
			};
		},
		[panelModel],
	);

	const handleMoveTo = useCallback(
		(panelId, menu) => {
			const { id, name, path } = menu;
			const body = cutValue(id);
			cutPanel({ panelId, body })
				.then(() =>
					Swal.fire({
						title: 'success !',
						icon: 'success',
						html: `the panel has moved to page ${name}. <a href='/d/${id}/smart-site/${path}'>Click here to redirect </a>`,
					}),
				)
				.catch((err) =>
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: err,
					}),
				);
		},
		[cutPanel, cutValue],
	);

	return (
		<div>
			<div className={className}>
				<PanelHeaderCorner panelId={panelId} links={links} />
				<div className={titleClassName}>
					<div className="panel-title">
						<h2 className={styles.titleText}>{title}</h2>
						{isEditing && (
							<span className={styles.editButton}>
								<Dropdown
									menuClassName={styles.menuOverflow}
									buttonClassName={styles.buttonContainer}
									title={<i className="pi pi-ellipsis-v" style={{ fontSize: '1.5em' }}></i>}
								>
									<Dropdown.Item onClick={navigateEditPanel}>
										<i className="pi pi-pencil mr-2"></i> Edit Panel
									</Dropdown.Item>
									<Dropdown.Item onClick={handleDuplicate}>
										<i className="pi pi-copy mr-2"></i> Duplicate Panel
									</Dropdown.Item>
									<Dropdown.Item className={styles.overflow}>
										<i className="pi pi-external-link mr-2"></i> Copy Panel To
										<Dropdown.Submenu>
											{subMenu &&
												subMenu.map((menu) => (
													<Dropdown.Item 
														onClick={() => handleDuplicateTo(panelId, menu)}
														key={menu.id}
													>
														{menu.name}
													</Dropdown.Item>
												))}
										</Dropdown.Submenu>
									</Dropdown.Item>
									<Dropdown.Item className={styles.overflow}>
										<i className="pi pi-external-link mr-2"></i> Move Panel To
										<Dropdown.Submenu>
											{subMenu &&
												subMenu.map((menu) => (
													<Dropdown.Item
														onClick={() => handleMoveTo(panelId, menu)}
														key={menu.id}
													>
														{menu.name}
													</Dropdown.Item>
												))}
										</Dropdown.Submenu>
									</Dropdown.Item>
									<Dropdown.Item onClick={handleDelete} className={styles.deleteText}>
										<i className="pi pi-trash mr-2"></i> Delete Panel
									</Dropdown.Item>
								</Dropdown>
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const panelStyles = () => {
	return {
		titleText: css`
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: calc(100% - 38px);
			cursor: pointer;
			font-weight: 600;
			font-size: ${PANEL_TITLE_FONT_SIZE}px;
			margin: 0;

			&:hover {
				color: #000;
			}
			.panel-has-alert & {
				max-width: calc(100% - 54px);
			}
		`,
		editButton: css`
			width: 100%;
			position: absolute;
			text-align: end;
			cursor: pointer;
			margin-top: 5px;
		`,
		buttonContainer: css`
			padding: 0 !important;
			box-shadow: none !important;
			background-color: transparent !important;
			margin-right: 6px !important;
			font-size: 15px !important;
		`,
		deleteText: css`
			color: red !important;
		`,
		menuOverflow: css`
			z-index: 99999999999 !important;
			position:absolute !important;
			transform:translateY(-20%) !important;
		`,
		overflow: css`
			z-index: 99999999999 !important;
			position:relative !important;
		`,
	};
};
