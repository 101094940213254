import { useGetUserConfigurationsQuery } from 'app/features/UserManagement/services/userApi';
import { useMemo } from 'react';

export function useUserList() {
    const { data, ...query } = useGetUserConfigurationsQuery({});

    const options = useMemo(() => {
        if (!data || !data.content) return [];

        return data.content.map(({ username, userInfo }) => {
            return {
                value: username,
                label: userInfo?.name || username,
            };
        });
    }, [data]);

    return {
        data,
        options,
        ...query,
    };
}
