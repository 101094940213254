import { useToasts } from 'app/components/Toast';
import React, { useState } from 'react'
import {
	Container,
	Col,
	Row,
	Card,
	CardBody,
	Button
} from 'reactstrap';
import { useChangePasswordMutation, useUpdateUserInfoMutation, useUpdateProfilePictureMutation } from '../../authAPI';
import ChangePasswordDialog from '../../components/ChangePasswordDialog';
import FormUpdateProfile from '../../components/FormUpdateProfile';
import OverviewProfile from '../../components/OverviewProfile';
import { useAuth } from '../../hooks/useAuth';
import { ChangePasswordRequest, FormProfile } from '../../types';
import './ProfilePage.scss';

type Props = {
}

const ProfilePage = (props: Props) => {
	const { user } = useAuth();
	const [editMode, setEditMode] = useState(false);
	const toast = useToasts();
	const [showPasswordDialog, setShowPasswordDialog] = useState(false);

	const [updateProfile, { isLoading }] = useUpdateUserInfoMutation();
	const [changePassword, { isLoading: isChangingPassword }] = useChangePasswordMutation();
	const [uploadProfilePicture, { isLoading: isUploadingProfilePicture }] = useUpdateProfilePictureMutation();

	const togglePasswordDialog = () => {
		setShowPasswordDialog(!showPasswordDialog);
	}

	const toggleEdit = () => {
		setEditMode(!editMode);
	}

	const onSubmit = async (data: FormProfile) => {
		const { avatar, ...userInfo } = data;
		const formData = new FormData();

		formData.append('imageMultipart', avatar);
		try {
			await updateProfile(userInfo).unwrap();
			await uploadProfilePicture({ userId: user?.id || 0, body: formData }).unwrap();
			toggleEdit();
			toast.toastSuccess('Success', 'Profile Updated.');
			window.location.reload();
		} catch (error) {
			toast.toastSuccess('Error', 'Update profile failed.');
		}
	}

	const onSubmitChangePassword = async ({ newPassword, oldPassword }: ChangePasswordRequest) => {
		try {
			await changePassword({ id: user?.id!, newPassword, oldPassword }).unwrap();
			togglePasswordDialog();
		} catch (error: any) {
			toast.toastError('Error', error.data.message);
		}
	}

	const renderProfile = () => {
		if (editMode) {
			return (
				<FormUpdateProfile
					user={user!}
					isLoading={isLoading || isUploadingProfilePicture}
					onSubmit={onSubmit}
					onCancel={toggleEdit}
				/>
			);
		}

		return <OverviewProfile user={user} onChangePasswordClick={togglePasswordDialog} />;
	}

	return (
		<Container fluid>
			<h2 className="my-3">Hello {user?.userInfo?.name || user?.username}</h2>

			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center">
							<h2>My Profile</h2>
						</Col>
						<Col className="d-flex justify-content-end align-items-center">
							<Button color="link" onClick={toggleEdit}>
								{!editMode ? 'Edit' : 'Cancel' }
								{!editMode && <i className="fa fa-edit ml-2"></i> }
							</Button>
						</Col>
					</Row>
					<Row className='mt-4'>
						<Col>
							{renderProfile()}
						</Col>
					</Row>
				</CardBody>
			</Card>
			<ChangePasswordDialog visible={showPasswordDialog} onHide={togglePasswordDialog} onSubmit={onSubmitChangePassword} isLoading={isChangingPassword} />
		</Container>
	)
}

export default ProfilePage
