import React from 'react';
import { FormOverview } from 'components';
import { FormikProps } from 'formik';
import { Row, Col, CardBody, Card } from 'reactstrap';
import { SensorConfiguration } from '../types';

export interface AccountFomConfirmProps {
	formik: FormikProps<SensorConfiguration>;
}

const SensorFormConfirm = (props: AccountFomConfirmProps) => {
	const { values } = props.formik;

	return (
		<React.Fragment>
			<Row>
				<Col>
					<Row className="mt-4">
						<Col>
							<Card>
								<CardBody>
									<h3 className="mb-2">Information</h3>
									<FormOverview label="Name" value={values.name} stripped />
									<FormOverview label="Sensor Property" value={values.id} />
									<FormOverview label="Tagging" value={values.entityTags?.join(', ')} stripped />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row className="mt-4">
						<Col>
							<Card>
								<CardBody>
									<h3 className="mb-2">Additional</h3>

									<FormOverview label="Sensor Type" value={values?.type || ''} stripped />

									<FormOverview label="Description" value={values.description || ''} />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default SensorFormConfirm;
