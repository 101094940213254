import Restricted from 'app/components/Restricted';
import { useGetPageByIdQuery } from 'app/services/PageService';
import React, { useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import AddNewMenu from '../components/AddNewMenu';
const AddMenuPage: React.FC = () => {
	const { projectId } = useParams<any>();

	const { pageId } = useParams<any>();

	const { data: currentPageData } = useGetPageByIdQuery(pageId, { skip: !pageId });

	const currentPageName = useMemo(() => {
		if (currentPageData && currentPageData.name && currentPageData.name.length > 0) return currentPageData.name;
		return null;
	}, [currentPageData]);

	const renderForm = useCallback(() => {
		return <AddNewMenu projectId={projectId} pageId={pageId} currentPageData={currentPageData} />;
	}, [projectId, currentPageData, pageId]);

	return (
		<Restricted to={'UPDATE DASHBOARD'}>
			<Container fluid>
				<h2 className="my-3">Page Management</h2>
				<Card>
					<CardBody>
						<Row className="d-flex justify-content-between">
							<Col className="d-flex align-items-center" xs="12" md="10">
								<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
									<BreadcrumbItem>
										<Link to="/">Page Management</Link>
									</BreadcrumbItem>
									<BreadcrumbItem active>
										{pageId ? `Edit Menu Page - ${currentPageName}` : 'Add Menu Page'}
									</BreadcrumbItem>
								</Breadcrumb>
							</Col>
						</Row>
					</CardBody>
					{renderForm()}
				</Card>
			</Container>
		 </Restricted>
	);
};
export default AddMenuPage;
