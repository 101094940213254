import React from 'react';
import LoadingPage from 'app/components/LoadingPage';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { PanelProps } from 'app/types/Panel';
import { calculateFontSize } from 'app/utils';
import { css } from '@emotion/css';

import * as styles from './../../utils/styles';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { locationService } from 'app/services/LocationService';

interface QueryParamFilter {
	name: string;
	value: string;
	source: string;
}
interface TargetUrl {
	url: string;
	query?: QueryParamFilter[];
	type?: 'internal' | 'external';
}
interface StatusPanelOptions {
	label: string;
	info: string;
	fontColor: string;
	urlFilter: string;
	target?: TargetUrl;
}

export interface Props extends PanelProps<StatusPanelOptions> {}

const TritronikStatusPanel = (props: Props) => {
	const { panel, width, height, refreshInterval, fieldConfig, options } = props;

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const label = options?.label;

	const datasourceValue = React.useMemo(() => {
		if (!data) return 0;

		if (data.responseDataValue.length > 0 && data.responseDataValue.some((d) => d.datapoints !== undefined)) {
			return data.responseDataValue[0]?.datapoints[0]?.value;
		}

		return 0;
	}, [data]);

	let primary = calculateFontSize(datasourceValue, width * 0.5, height * 0.5, 1);
	let secondary = calculateFontSize(label, width * 0.3, height * 0.55, 1, 14);
	let infoFs = calculateFontSize(options.info || "", width * 0.3, height * 0.55, 1, 14);

	secondary = secondary < 12 ? 12 : secondary;
	primary = primary < 14 ? 14 : primary;

	const severityStyle = css`
		flex-direction: column;

		* {
			color: #fff;
		}

		h1 {
			font-size: ${primary}px;
		}

		h6 {
			font-size: ${secondary}px;
		}

		.severity-active {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: ${height * 0.05 < 6 ? 6 : height * 0.05}px;
			background-color: #fffb;
			content: '\\00a0';
		}

		.valueContainer {
			position: relative;
			margin-top: ${height * 0.05}px;
			display: flex;
			flex-direction: row;
			align-items: flex-end;
		}

		.info {
			font-size: ${infoFs}px;
			margin-bottom: .55rem;
			margin-left: 0.25rem;
		}

		@media (min-width: 769px) {
			flex-direction: column;

			.severity-active {
				top: 0;
				width: ${width * 0.05 < 6 ? 6 : width * 0.05}px;
				height: 100%;
			}
		}
	`;

	const backgroundColor = React.useMemo(() => {
		const bgColor = styles.getColor(datasourceValue, fieldConfig.defaults?.thresholds?.steps);
		const color = options?.fontColor || "#000";

		return css`
			color: ${color} !important;
			background-color: ${bgColor} !important;
		`;
	}, [datasourceValue, fieldConfig, options]);

	const buildQuery = (query: QueryParamFilter[]) => {
		const q = locationService.getSearch();

		if (query && query.length > 0) {
			query.forEach((f) => {
				switch (f.source) {
					// TODO: build query based on dataset, query, or static
					case 'dataset':
						q.set(f.name, f.value);
						break;
					default:
						q.set(f.name, f.value);
						break;
				}
			});
		}

		return q.toString();
	}

	const renderText = () => {
		if (options.target && options.target.url !== null && options.target.url !== '') {
			const targetUrl = process.env.NODE_ENV === 'development' ? options.target.url.replace('https://smart-solutions-dev.tritronik.com', 'http://localhost:3000') : options.target.url;
			const filterQuery = options.target.query && options.target.query.length > 0 ? `${buildQuery(options.target.query)}` : '';

			if (options.target.type && options.target.type === 'external') {
				return (
					<a href={`${targetUrl}?${filterQuery}`}><h1>{datasourceValue}</h1></a>
				)
			}

			const url = new URL(options.target.url);

			const onTargetClick = () => {
				locationService.push({
					pathname: url.pathname,
					search: filterQuery
				});
			}

			return <h1 style={{ cursor: 'pointer' }} onClick={onTargetClick}>{datasourceValue}</h1>
		}

		return <h1>{datasourceValue}</h1>
	}

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div className={`${styles.wrapper} ${backgroundColor}`}>
			<div className={`${styles.container} ${severityStyle}`}>
				<h6>{label}</h6>
				<div className="valueContainer">
					{renderText()}
					{options.info && <b className="info">{options.info}</b>}
				</div>
			</div>
		</div>
	);
};

export default TritronikStatusPanel;
