import React, { useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { locationService } from './services/LocationService';
import MainLayout from './components/MainLayout';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AuthLayout } from 'components';
import routes from './routes/routes';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';
import { useAppSelector } from './store';
import { useGetProjectQuery } from './services/ProjectService';
import MetaDecorator from './components/MetaDecorator';

const AppWrapper = () => {
    const projectId = useAppSelector((state) => state.application.ids);

    const { data: projectData } = useGetProjectQuery(projectId[0], { skip: !projectId[0] });

    const dynamicPageTitle = React.useMemo(() => {
        try {
            const settings = projectData && projectData.settings ? JSON.parse(projectData.settings) : null;
            return settings ? settings.pageTitle : 'Smart Solution Platform';
        } catch {
            return null;
        }
    }, [projectData]);

    const dynamicFavicon = React.useMemo(() => {
        try {
            const settings = projectData && projectData.settings ? JSON.parse(projectData.settings) : null;
            return settings ? settings.favicon : '/favicon.ico';
        } catch {
            return null;
        }
    }, [projectData]);

    useEffect(() => {
        const favicon = document.getElementById('favicon');

        if (dynamicFavicon && favicon) {
            favicon.setAttribute('href', dynamicFavicon);
        }
    }, [dynamicFavicon]);

    return (
        <>
            <MetaDecorator pageTitle={dynamicPageTitle} />
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Router history={locationService.getHistory()}>
                    <Switch>
                        <Route path="/auth" render={(props) => <AuthLayout routes={routes} {...props} />} />
                        <ProtectedRoute path="/" component={MainLayout} />
                    </Switch>
                </Router>
            </ErrorBoundary>
        </>
    );
};

export default AppWrapper;
