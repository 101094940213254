import React from 'react';
import { Field } from 'formik';
import { InputText, InputTextProps } from 'primereact/inputtext';
import classNames from 'classnames';
import FormikFormGroup from '../FormikFormGroup';

export interface FormikInputTextProps {
	[key: string]: any;
	className?: string;
	name: string;
	label?: string;
	required?: boolean;
	row?: boolean;
	caption?: string;
}

const FormikInputText = (props: FormikInputTextProps & InputTextProps) => {
	const { className ,id, name, label, required, row, caption, ...inputProps } = props;

	const renderInput = ({ field, form, meta }) => {
		return (
			<FormikFormGroup
				className={className}
				id={id}
				name={name}
				label={label}
				field={field}
				form={form}
				meta={meta}
				row={row}
				required={required}
				caption={caption}
			>
				<InputText
					id={id}
					{...field}
					{...inputProps}
					className={classNames('p-inputtext-sm w-100', {
						'p-invalid': Boolean(meta.touched && meta.error),
					})}
				/>
			</FormikFormGroup>
		);
	};

	return <Field name={name}>{renderInput}</Field>;
};

export default FormikInputText;
