import { css } from '@emotion/css';
import Battery, { BatteryStatus } from 'app/components/Battery';
import LoadingPage from 'app/components/LoadingPage';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { locationService } from 'app/services/LocationService';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { ThresholdsConfig } from 'app/types';
import { PanelProps } from 'app/types/Panel'
import { calculateFontSize, getColor } from 'app/utils';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';
import moment from 'moment';
import React, { useMemo } from 'react'


interface Threshold {
	thresholds?: ThresholdsConfig;
}

interface QueryParamFilter {
	name: string;
	value: string;
	source: string;
}

interface PageDetails {
	title: string;
	url: string;
	query?: QueryParamFilter[];
	type?: 'internal' | 'external';
}
interface FieldProps {
	field: string;
	header: string;
	bodyTemplate?: string;
	unit?: string;
	decimals?: number;
}
export interface TritronikBatteryInfoOptions {
	runoutEstimationFormat?: string;
	percentage?: Threshold;
	backup?: Threshold;
	current?: Threshold;
	fields?: FieldProps[];
	noDataDisplay?: string;
	details?: PageDetails;
}

interface RemainingTime {
	days: number;
	hours: number;
	minutes: number;
}

export interface BatteryInfo {
	isCharging?: boolean;
	isAbnormal?: boolean;
	isError?: boolean;
	percentage?: number;
	current?: number;
	voltage?: string;
	chargeRemainingTime?: RemainingTime;
	chargeRemainingTimeString?: string;
	backupRemainingTime?: RemainingTime;
	backupRemainingTimeString?: string;
	runoutEstimation?: string;
	totalBank?: number;
	totalCell?: number;
	type?: string;
	temperature?: number;
	status?: BatteryStatus;
}

export interface TritronikBatteryInfoPanelProps extends PanelProps<TritronikBatteryInfoOptions> {

}

const TritronikBatteryInfoPanel = (props: TritronikBatteryInfoPanelProps) => {
	const { width, height, panel, refreshInterval, options } = props;
	const params = usePanelDataParams();
	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const battery = useMemo(() => {
		let values: BatteryInfo = {
			percentage: 0,
			totalBank: 0,
			totalCell: 0,
			temperature: 0,
			type: 'None',
			backupRemainingTime: { days: 0, hours: 0, minutes: 0 },
			backupRemainingTimeString: '0h 0m',
			chargeRemainingTime: { days: 0, hours: 0, minutes: 0 },
			chargeRemainingTimeString: '0h 0m',
			runoutEstimation: '00:00',
			current: 0,
			isCharging: false,
			isAbnormal: false,
			isError: false,
		};

		if (data && data.responseDataValue) {
			const v = Array.isArray(data.responseDataValue) ? data.responseDataValue[0] : data.responseDataValue;
			values.percentage = v.avgSoc?.toFixed(2);

			values.totalBank = v.totalBank;
			values.totalCell = v.totalCell;
			values.temperature = v.avgCellTemp?.toFixed(2);
			values.type = v.type;
			const durr = moment.duration(v.backupRemainingTimeMillis, 'milliseconds');
			const d = durr.days();
			const h = durr.hours();
			const m = durr.minutes();
			values.backupRemainingTime = { days: d, hours: h, minutes: m };
			values.backupRemainingTimeString = `${d > 0 ? d+"d " : ""}${h}h ${m}m`;

			if (v.chargingTimeMillis !== 0) {
				const cDurr = moment.duration(v.chargingTimeMillis, 'milliseconds');
				const cD = cDurr.days();
				const cH = cDurr.hours();
				const cM = cDurr.minutes();
				values.chargeRemainingTime = { days: cD, hours: cH, minutes: cM };
				values.chargeRemainingTimeString = `${cD > 0 ? cD+"d " : ""}${cH}h ${cM}m`;
			}

			if ((values.percentage || 0) >= 100) {
				values.chargeRemainingTimeString = 'Full Charged'
			}

			values.current = v.totalCurrent || 0;

			const format = options.runoutEstimationFormat || 'LT (dddd)';
			values.runoutEstimation = moment(v.runoutEstimationTs).format(format);

			values.status = v.status;

			switch (v.status?.toLowerCase()) {
				case 'floating charge':
				case 'equal charge':
				case 'charging':
					values.isCharging = true;
					values.isAbnormal = false;
					values.isError = false;
					break;
				case 'discharge':
				case 'discharging':
				case 'rest':
				case 'resting':
					values.isCharging = false;
					values.isAbnormal = false;
					values.isError = false;
					break;
				case 'abnormal':
					values.isCharging = false;
					values.isAbnormal = true;
					values.isError = false;
					break;
				case 'error':
					values.isCharging = false;
					values.isAbnormal = false;
					values.isError = true;
					break;
				default:
					values.isCharging = false;
					values.isAbnormal = false;
					values.isError = false;
					break;
			}
		}

		return values;
	}, [data, options.runoutEstimationFormat]);

	const wrapper = css`
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: auto;

		* {
			white-space: nowrap;
			margin-bottom: 0;
			line-height: 1;
		}

		.divider-v {
			border-left: 1px solid #C7C2C2;
			height: ${height * 0.5}px;
			margin-left: ${width * 0.05}px;
			margin-right: ${width * 0.05}px;
			overflow: hidden;
		}

		.divider-h {
			border-bottom: 1px solid #C7C2C2;
			width: ${width * 0.95}px;
			overflow: hidden;
		}
	`;

	const container = css`
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 100%;
		padding: ${width * 0.0115}px;
	`;

	const infoStyle = css`
		overflow: hidden;

		h4 {
			margin-bottom: ${height * 0.05}px;
		}
	`;

	const statusContainerStyle = css`
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		width: 100%;

	`;

	const statusStyle = css`
		display: flex;
		flex: 1;
		padding: ${width * 0.0115}px;
		text-align: center;
		justify-content: center;
		align-items: center;

		h4 {
			font-size: ${width * 0.0095}px;
		}
	`;

	const valueFs = (text: string) => {
		return calculateFontSize(text, width * 0.15, height * 0.25, 1.5, 50);
	}

	const valueStyle = (text, color = 'black') => css`
		font-size: ${valueFs(`${text}`)}px;
		color: ${color};
		margin-bottom: ${height * 0.05}px;
	`;

	const renderInfo = (label, value, color = "green", textInfo: string | null = null) => {
		return (
			<div className={infoStyle}>
				<h4>{label}</h4>
				<h1 className={valueStyle(value, color)}>
					{value}
				</h1>
				{textInfo && ((battery.percentage || 0) < 100) && <h4>{textInfo}</h4>}
			</div>
		)
	}

	const getColorBackupRemainingTime = (): string => {
		return getColor(battery?.backupRemainingTime?.hours || 0, options?.backup?.thresholds?.steps);
	}
	const getColorRunoutEstimation = (): string => {
		return '#73BF69';
	}

	const rowStyle = css`
			display: flex;
			width: 100%;
			flex-direction: row;
			justify-content: flex-end;
			padding: ${height * 0.025}px ${width * 0.025}px;

			span {
				cursor: pointer;
				color: var(--primary);
				text-decoration: underline;
			}
		`;

	const overview = useMemo(() => {
		if (data && data.responseDataValue && options.fields && options.fields.length > 0) {
			const dataset = data.responseDataValue;
			const overviewFields = options.fields.map((f, i) => {
				const renderValue = () => {
					const value = dataset[f.field] || options.noDataDisplay || '-';

					if (f.bodyTemplate && f.bodyTemplate === 'valueFormat') {
						const decimals = f.decimals || 0;
						const unit = f.unit || 'none';
						const formatted = getValueFormat(unit)(value, decimals);
						return formattedValueToString(formatted);
					}

					return value;

				}
				return (
					<div key={`overview-${f.field}-${i}`} className={statusStyle}>
						<h4>{f.header}: {renderValue()}</h4>
					</div>
				)
			});

			return overviewFields;
		}

		return null;
	}, [data, options, statusStyle]);

	const details = useMemo(() => {
		if (!options.details) return null;

		if (!data?.responseDataValue) return null;

		const urlParams = locationService.getSearch();

		const dataset = data.responseDataValue;

		const { title, url, query, type } = options.details;

		if (query && query.length > 0) {
			query.forEach((q) => {
				const value = q.source === 'dataset' ? dataset[q.value] : q.value;
				if (value) {
					urlParams.set(q.name, value);
				}
			});
		}

		if (type === 'external') {
			return (
				<div className={rowStyle}>
					<a href={`${url}?${urlParams.toString()}`}>{title}</a>
				</div>
			)
		}

		const onDetailClick = () => {
			const urlTarget = new URL(url);
			locationService.push({
				pathname: urlTarget.pathname,
				search: urlParams.toString()
			});
		}

		return (
			<div className={rowStyle}>
				<span onClick={onDetailClick}>{title}</span>
			</div>
		)
	}, [data, options.details, rowStyle]);

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div className={wrapper}>
			{overview && (
				<>
					<div className="divider-h"></div>
					<div className={statusContainerStyle}>
						{overview}
					</div>
					<div className="divider-h"></div>
				</>
			)}
			<div className={container}>
				<Battery
					width={width * 0.8}
					height={height * 0.8}
					percentage={battery.percentage || 0}
					thresholds={options?.percentage?.thresholds}
					isCharging={battery.isCharging}
					isAbnormal={battery.isAbnormal}
					isError={battery.isError}
				/>

				{battery.isCharging ? (
					<>
						<div className="divider-v"></div>
						{renderInfo("Charge Remaining Time:", battery.chargeRemainingTimeString, '#73BF69', 'Until Full')}
					</>
				) : (
					<>
						<div className="divider-v"></div>
						{renderInfo("Current:", `${battery.current} A`, getColor(battery.current || 0, options.current?.thresholds?.steps))}
						<div className="divider-v"></div>
						{renderInfo("Backup Remaining Time (Avg):", `${battery.backupRemainingTimeString}`, getColorBackupRemainingTime())}
						<div className="divider-v"></div>
						{renderInfo("Runout Date Estimation:", `${battery.runoutEstimation}`, getColorRunoutEstimation())}
					</>
				)}
			</div>
			{details}
		</div>
	)
}

export default TritronikBatteryInfoPanel
