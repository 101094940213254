import React from 'react';
import { FormOverview } from 'components';
import { FormikProps } from 'formik';
import { Row, Col, CardBody, Card } from 'reactstrap';
import { AccountConfiguration } from '../types';

export interface AccountFomConfirmProps {
	formik: FormikProps<AccountConfiguration>;
}

const TenantFormConfirm = (props: AccountFomConfirmProps) => {
	const { values } = props.formik;

	return (
		<React.Fragment>
			<Row>
				<Col>
					<Row className="mt-4">
						<Col>
							<Card>
								<CardBody>
									<h3 className="mb-2">Information</h3>

									<FormOverview label="Name" value={values?.name || values.account?.name} stripped />

									<FormOverview label="Address" value={values?.address || values.account?.address} />

									<FormOverview
										label="Contact Number"
										value={values?.contact || values.account?.contact}
										stripped
									/>

									<FormOverview label="Email" value={values?.email || values.account?.email} />

									<FormOverview label="Application Type" value={values.applicationType} stripped />

									{values.adminUser?.username && values.adminUser.username.length > 0 ? (
										<FormOverview label="UserName" value={values.adminUser?.username || ''} />
									) : null}

									{values.adminUser?.password && values.adminUser.password.length > 0 ? (
										<FormOverview label="Password" value={'********'} stripped />
									) : null}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row className="mt-4">
						<Col>
							<Card>
								<CardBody>
									<h3 className="mb-2">Condition</h3>

									<FormOverview label="Type" value={values.type} stripped />
									<FormOverview label="URL/Endpoint" value={values.deviceApi?.url} />
									<FormOverview
										label="Header Request"
										value={values.deviceApi?.headerInfo}
										stripped
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default TenantFormConfirm;
