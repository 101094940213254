import React, { PropsWithChildren } from 'react';
import { DataTable, DataTableProps } from 'primereact/datatable';
import classNames from 'classnames';
import './PrimeTable.scss';

export interface PrimeTableProps extends DataTableProps {}

const PrimeTable = React.forwardRef<DataTable, PrimeTableProps & PropsWithChildren<unknown>>((props, ref) => {
	const { children, className, ...datatableProps } = props;
	return (
		<div className="prime-table">
			<DataTable
				ref={ref}
				{...datatableProps}
				className={classNames('p-datatable-custom', className)}
				rowHover
				paginator
				stripedRows
			>
				{children}
			</DataTable>
		</div>
	);
});

const defaultProps: PrimeTableProps = {
	currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords} entries',
	paginatorTemplate:
		'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
	rowsPerPageOptions: [5, 10, 25, 50, 100],
	selectionMode: 'checkbox',
};

PrimeTable.defaultProps = defaultProps;

export default PrimeTable;
