import { useMemo } from 'react';
import { useGetCageListQuery } from '../services';
import { ICageListRequest } from '../types';

export function useCageList(params: ICageListRequest = {}) {
    const { data, ...result } = useGetCageListQuery(params);

    const options = useMemo(() => {
        if (!data) return [];

        return data.map(({ id, cageName }) => ({ value: id, label: cageName }));
    }, [data]);

    return {
        data,
        options,
        ...result,
    };
}
