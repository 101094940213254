import { css } from '@emotion/css';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { ValueMapping } from 'app/types';
import { PanelProps } from 'app/types/Panel';
import { calculateFontSize } from 'app/utils';
import { getValueMappingResult } from 'app/utils/valueMappings';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';

interface DoorPanelOptions {
	mappings?: ValueMapping[]
	field_label: string
}

const statusColor = {
	"Open (Card)": '#F2495C',
	"Open (PIN)": '#F2495C',
	"Close (Card)": '#73BF69',
}

export interface TritronikDoorPanelProps extends PanelProps<DoorPanelOptions> { }

const TritronikDoorPanel = (props: TritronikDoorPanelProps) => {
	const { width, height, panel, refreshInterval, options } = props;

	const params = usePanelDataParams();

	const { data } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const [door, setDoor] = useState<any>({})

	const currentColor = statusColor[door?.door_status];

	const doorIcon = useMemo(() => {
		if (!door) return 'fa-door-closed';

		return door.door_status === 'Open (Card)' || door.door_status === 'Open (PIN)' ? 'fa-door-open' : 'fa-door-closed'
	}, [door]);

	const showCard = useMemo(() => {
		if (!door) return false;

		return door.door_status === 'Open (Card)' || door?.door_status === 'Close (Card)';
	}, [door])

	const showPin = useMemo(() => {
		if (!door) return false;

		return door.door_status === 'Open (PIN)';
	}, [door])

	const displayValue = useMemo(() => {
		if (!door) return null;
		const keyDisplayValue: string = options?.field_label || 'door_name';

		return door[keyDisplayValue] || 'N/A';
	}, [door, options])

	useEffect(() => {
		if (data && data.responseDataValue && data.responseDataValue.length > 0) {
			const dataValue = data?.responseDataValue[0].door_status
			const mapping = options.mappings || [];
			const mappingResult = getValueMappingResult(mapping, dataValue);

			setDoor((state: any) => {
				const responseData = data?.responseDataValue[0]
				return {
					...state,
					...responseData,
					door_status: mappingResult?.text,
				}
			})
		}
	}, [data, options])

	const fontSize: number = useMemo(() => {
		return calculateFontSize(displayValue, width * 0.25, height * 0.5, 1);
	}, [width, height, displayValue])

	const primaryFs = useMemo(() => {
		return calculateFontSize('X', width * 0.5, height * 0.5, 1);
	}, [width, height]);

	const secondaryFs = useMemo(() => {
		return calculateFontSize('D MMM, HH:mm', width / 2.5, 50, 1);
	}, [width]);

	const iconFs = useMemo(() => {
		return calculateFontSize('X', width * 0.1, height * 0.1, 1)
	}, [width, height])

	const doorStyle = css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		overflow: hidden;

		.row-1 {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			text-align: center;
			margin-bottom: 0.25rem;
			width: 100%;
			i {
				font-size: ${primaryFs}px;
			}
		}
		.row-2 {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			text-align: center;
			margin-bottom: 0.25rem;
			width: 100%;
			padding:0;
		}

		.row-3 {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			text-align: center;
			width: 100%;

			div {
				margin: 0;
				display: flex;
				flex: 1;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				font-size: ${secondaryFs}px;

				i {
					font-size: ${secondaryFs * 1.4}px;
					margin-right: 5%;
				}
			}
		}
	`;
	const iconStyle = css`
		position : absolute;
		right: 0;
		margin-right:0.5rem;
		margin-top:1.25rem;
		.cards {

			font-size:${iconFs}px;
			margin-right:0.25rem
		}
		.pins {

			font-size:${iconFs}px;
			margin-right:0.25rem;
		}
	`

	const renderIcon = () => {
		return (
			<i className={`fa ${doorIcon}`} style={{ color: currentColor }}></i>
		)
	}

	const renderDate = (date: number | null, icon: string) => {
		if (date) {
			const dateFormated = moment(new Date(date)).format('D MMM, HH:mm');
			return (
				<div>
					<i className={`fa ${icon}`}></i>
					{dateFormated}
				</div>
			);
		}
		return null;
	}
	return (
		<>
			<div className={`${iconStyle}`}>
				<span style={{ display: showCard ? 'inline' : 'none' }} className={`cards`}><i className="fa fa-address-card"></i></span>
				<span style={{ display: showPin ? 'inline' : 'none' }} className={`pins`}><i className="fa fa-calculator"></i></span>
			</div>
			<div className={`${doorStyle}`}>
				<div className={`row-1`}>{renderIcon()}</div>
				<div className={`row-2`} style={{ fontSize: fontSize, width: width }}>
					<span>{displayValue}</span>
				</div>
				<div className={`row-3`}>
					{renderDate(door?.dataTimestamp, 'fa-clock')}
				</div>
			</div>
		</>

	)
}

export default TritronikDoorPanel;
