import { locationService } from 'app/services/LocationService';
import { useConfigureProjectMutation } from 'app/services/ProjectService';
import { useAppSelector } from 'app/store';
import { Project } from 'app/types';
import { PrimeSteps } from 'components';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import React, { useCallback, useMemo, useState } from 'react'
import { Col, Form, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import ConfigureConfirmation from './ConfigureConfirmation';
import ConfigureInfo from './ConfigureInfo';

type Props = {
    projectId:string
}
export type Settings = {
    brandLogoUrl:string;
    favicon:string;
    pageTitle:string
}


const ProjectConfig:React.FC<Props> = ({projectId}) => {

    const formSteps = [{ label: 'PROJECT INFORMATION' }, { label: 'CONFIRMATION' }];

    const defaultPage = useAppSelector((state) => state.page.defaultPage)

    const projectData = useAppSelector(state => state.application.entities[projectId])

	const [activeStep, setActiveStep] = useState(0);

	const isLastStep = activeStep === formSteps.length - 1;

	const nextStep = () => {
		if (!isLastStep) {
			setActiveStep((currentStep) => currentStep + 1);
		}
	};

	const prevStep = () => {
		if (activeStep > 0) {
			setActiveStep((currentStep) => currentStep - 1);
		}
	};

	const renderForm = useCallback((step: number, formik: FormikProps<Project<Settings>>) => {
		switch (step) {
			case 1:
				return <ConfigureConfirmation formik={formik} />;
			default:
				return <ConfigureInfo projectId={projectId} formik={formik} />;
		}
	}, [projectId]);

    const projectDataObj = useMemo(() => {
        if(projectData){
            return JSON.parse(projectData.settings)
        }
        
    },[projectData])

    const initialValues = useMemo<Project<Settings>>(() => {
        return {
            id: projectData ? projectData.id : '',
            name: projectData ? projectData.name : '',
            description: projectData ? projectData.description : '',
            owner: projectData ? projectData.owner : '',
            path: projectData ? projectData.path : '',
            component: projectData ? projectData.component : '',
            settings: projectData ? projectDataObj : {},
            pages: projectData ? projectData.pages : []
        }
    },[projectData, projectDataObj])

    const valueToSend = (value):Project<Settings> => {
        return {
            ...value,
            settings:JSON.stringify(value.settings)
        }
    }

    const [configureProject] = useConfigureProjectMutation()
    
    const _handleSubmit = (values:Project<Settings>, formik:FormikHelpers<Project<Settings>>) => {
        if(isLastStep){
            configureProject({projectId, body:valueToSend(values)})
                .unwrap()
                .then(() => history.push({pathname:defaultPage}))
                .catch(() =>
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: 'Something went wrong!',
						}),
					);
        } else {
            nextStep()
            formik.setTouched({});
			formik.setSubmitting(false);
        }
    }
    const history = locationService.getHistory();

  return (
    <Formik initialValues={initialValues} onSubmit={_handleSubmit} enableReinitialize={true}>
        {(formik) => (
            <Form className="p-3" onSubmit={formik.handleSubmit}>
            <Row className="mt--1 mb-3">
                <Col>
                    <PrimeSteps model={formSteps} activeIndex={activeStep} />
                </Col>
            </Row>
            {renderForm(activeStep, formik)}
            <Row className="d-flex justify-content-end pr-3">
                <Button
                    type="button"
                    label="Cancel"
                    onClick={async () => {
                        await history.push({pathname:defaultPage})
                        formik.resetForm();
                    }}
                    className="mr-2 p-button-outlined"
                />
                {activeStep > 0 && (
                    <Button
                        type="button"
                        label="Previous"
                        onClick={prevStep}
                        className="mr-2 p-button-outlined"
                    />
                )}
                <Button label={isLastStep ? 'Apply' : 'Next'} type="submit" />
            </Row>
        </Form>
        )}
    </Formik>
  )
}

export default ProjectConfig