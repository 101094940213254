import React, { useCallback, useMemo } from 'react';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { PanelProps } from 'app/types/Panel';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import LoadingPage from 'app/components/LoadingPage';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import _ from 'lodash';
import { css } from '@emotion/css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatValue } from 'app/utils/formatValue';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';

interface LegendData {
    name?: string;
    min?: any;
    max?: any;
    avg?: any;
    total?: any;
}

interface MarkLineOptions {
    min?: boolean;
    max?: boolean;
    average?: boolean;
    median?: boolean;
}
interface LegendOptions {
    show?: boolean;
    min?: boolean;
    max?: boolean;
    avg?: boolean;
    total?: boolean;
}
export interface TritronikLineChartPanelOptions {
    chartOptions?: EChartsOption;
    legend?: LegendOptions;
    markLine?: MarkLineOptions;
    markArea?: object;
    decimals?: number;
    unit?: string;
    seriesOptions?: Record<string, any>;
    markAreaOptions?: Record<string, any>;
}

export interface TritronikLineChartPanelProps extends PanelProps<TritronikLineChartPanelOptions> {}

const TritronikLineChartPanel = (props: TritronikLineChartPanelProps) => {
    const { width, height, panel, refreshInterval, options } = props;

    const params = usePanelDataParams();

    const { data, isLoading } = useGetPanelDataQuery(
        { id: panel.id, ...params },
        { skip: !panel, pollingInterval: refreshInterval },
    );

    const decimals = options.decimals || 0;
    const unit = options.unit || 'none';

    const legend = options.legend;
    const showLegend = legend?.show === true;
    const chartWidth = showLegend ? width * 0.7 : width;

    const tickFormat = useCallback(
        (val, format = 'none') => {
            return formatValue(val, format, decimals);
        },
        [decimals],
    );

    const { chartSeries, calculatedData } = useMemo(() => {
        const chartSeries: any[] = [];

        const calculatedData: LegendData[] = [];

        if (data && data.responseDataValue && data.responseDataValue.length > 0) {
            data.responseDataValue.forEach((dt) => {
                const dataSeries: number[][] = [];

                let minVal: any = 0,
                    maxVal: any = 0,
                    avgVal: any = 0,
                    totalVal: any = 0;

                let yAxisIndex = 0;
                let format = 'none';
                // determine x axis index by aliasLabel from options
                if (
                    options.chartOptions &&
                    options.chartOptions.yAxis &&
                    Array.isArray(options.chartOptions.yAxis) &&
                    options.chartOptions.yAxis.length > 0
                ) {
                    yAxisIndex = options.chartOptions.yAxis.findIndex((axis) => {
                        return axis.series && Array.isArray(axis.series) && axis.series.includes(dt.aliasLabel);
                    });

                    yAxisIndex = yAxisIndex === -1 ? 0 : Number(yAxisIndex);

                    if (yAxisIndex !== -1) {
                        format = options.chartOptions.yAxis[yAxisIndex]?.format || 'none';
                    }
                }

                if (dt.datapoints && dt.datapoints.length > 0) {
                    const datapoints = dt.datapoints.map((d) => d.value);
                    minVal = _.min(datapoints);
                    maxVal = _.max(datapoints);
                    totalVal = _.sum(datapoints);
                    avgVal = totalVal / datapoints.length;

                    calculatedData.push({
                        name: dt.aliasLabel,
                        min: formatValue(minVal, format, decimals),
                        max: formatValue(maxVal, format, decimals),
                        avg: formatValue(avgVal, format, decimals),
                        total: formatValue(totalVal, format, decimals),
                    });

                    dt.datapoints.forEach((ds) => {
                        const value = ds.value?.toFixed(decimals);
                        dataSeries.push([ds.time, value]);
                    });
                }

                let markArea = {};
                let markLines: any[] = [];

                if (dt.markArea) {
                    markArea = {
                        itemStyle: {
                            color: 'rgba(255, 173, 177, 0.4)',
                        },
                        data: dt.markArea?.map((markArea) => {
                            const markAreaOptions = options?.markAreaOptions?.[markArea?.name] ?? {};
                            return [
                                {
                                    name: markArea?.name,
                                    xAxis: markArea?.startTime,
                                    ...markAreaOptions
                                },
                                {
                                    xAxis: markArea?.endTime,
                                    ...markAreaOptions
                                },
                            ];
                        }),
                    };
                }

                if (dt.thresholds) {
                    dt.thresholds?.forEach((th) => {
                        markLines.push({
                            name: th.name,
                            yAxis: th.value,
                            symbol: 'none',
                            lineStyle: {
                                color: '#e91e63',
                            },
                            label: {
                                show: true,
                                formatter: 'Max',
                            },
                        });
                    });
                }

                if (options.markLine) {
                    Object.keys(options.markLine).forEach((key) => {
                        markLines.push({
                            type: key,
                            name: _.capitalize(key),
                            label: {
                                show: false,
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    position: 'middle',
                                    formatter: (params) => `${params.name}: ${tickFormat(params.value, format)}`,
                                },
                            },
                        });
                    });
                }

                const seriesOptions = options?.seriesOptions?.[dt.aliasLabel] ?? {};
                chartSeries.push(_.merge({}, {
                    name: dt.aliasLabel,
                    type: 'line',
                    smooth: false,
                    data: dataSeries,
                    yAxisIndex: yAxisIndex,
                    areaStyle: {
                        opacity: 0.25,
                    },
                    showSymbol: false,
                    lineStyle: {
                        width: 1,
                    },
                    markArea: {
                        ...markArea,
                        ...options?.markArea,
                    },
                    markLine: {
                        symbol: ['none', 'none'],
                        data: markLines,
                        ...options?.markLine,
                    },
                }, seriesOptions));
            });
        }

        return { chartSeries, calculatedData };
    }, [data, decimals, options, tickFormat]);

    const chartOptions = React.useMemo(() => {
        let _yAxis: any = {
            type: 'value',
            boundaryGap: [0, '100%'],
            axisLabel: {
                formatter: (params) => {
                    return formattedValueToString(getValueFormat('none')(params, decimals));
                },
            },
            axisPointer: {
                snap: true,
            },
            scale: true,
        };

        if (
            options.chartOptions &&
            options.chartOptions.yAxis &&
            Array.isArray(options.chartOptions.yAxis) &&
            options.chartOptions.yAxis.length > 0
        ) {
            _yAxis = options.chartOptions.yAxis.map(({ axisLabel, ...axis }) => {
                const format = axis.format ? axis.format : 'none';
                return {
                    ...axis,
                    axisLabel: {
                        formatter: (params) => {
                            return formattedValueToString(getValueFormat(format)(params, decimals));
                        },
                    },
                };
            });
        }

        const mergedOptions = _.merge(options.chartOptions, { yAxis: _yAxis });

        const option: any = _.merge(
            {
                legend: {
                    show: true,
                    bottom: 10,
                    left: '10%',
                    type: 'scroll',
                },
                grid: {
                    right: showLegend ? '2.5%' : '5%',
                    left: '15%',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                    },
                },
                title: {
                    show: false,
                },
                xAxis: {
                    type: 'time',
                    boundaryGap: false,
                    axisLabel: {
                        formatter: {
                            year: '{yyyy}',
                            month: '{MMM} {yyyy}',
                            day: '{d} {MMM}',
                            hour: '{HH}:{mm}',
                            minute: '{HH}:{mm}',
                            second: '{HH}:{mm}:{ss}',
                            millisecond: '{hh}:{mm}:{ss} {SSS}',
                            none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}',
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '100%'],
                    axisLabel: {
                        formatter: (params) => {
                            return formattedValueToString(getValueFormat(unit)(params, decimals));
                        },
                    },
                    axisPointer: {
                        snap: true,
                    },
                    scale: true,
                },
                dataZoom: [
                    {
                        type: 'inside',
                    },
                ],
                series: chartSeries,
            },
            mergedOptions,
        );

        return option;
    }, [chartSeries, options.chartOptions, showLegend, unit, decimals]);

    if (isLoading) {
        return <LoadingPage />;
    }

    const styles = {
        wrapper: css`
            width: ${width}px;
            height: ${height * 0.9}px;
            overflow: auto;
            display: flex;
            flex-direction: row;

            .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
                font-size: 0.75rem;
            }

            .p-datatable .p-sortable-column .p-column-title,
            .p-datatable .p-sortable-column .p-sortable-column-icon,
            .p-datatable .p-sortable-column .p-sortable-column-badge {
                font-size: 0.75rem;
            }

            .p-datatable .p-column-header-content {
                justify-content: center;
            }
        `,
        summary: css`
            height: ${height * 0.9}px;
            width: 27%;
        `,
    };

    const bodyStyleNumber = {
        textAlign: 'right',
    };

    return (
        <div className={styles.wrapper}>
            <ReactEcharts style={{ width: chartWidth, height: height * 0.9 }} option={chartOptions} />
            {showLegend && (
                <div className={styles.summary}>
                    <DataTable value={calculatedData} removableSort size="small" responsiveLayout="scroll">
                        <Column field="name" header="" sortable bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column
                            field="min"
                            header="Min"
                            sortable
                            hidden={!legend?.min}
                            bodyStyle={bodyStyleNumber}
                        ></Column>
                        <Column
                            field="max"
                            header="Max"
                            sortable
                            hidden={!legend?.max}
                            bodyStyle={bodyStyleNumber}
                        ></Column>
                        <Column
                            field="avg"
                            header="Avg"
                            sortable
                            hidden={!legend?.avg}
                            bodyStyle={bodyStyleNumber}
                        ></Column>
                        <Column
                            field="total"
                            header="Total"
                            sortable
                            hidden={!legend?.total}
                            bodyStyle={bodyStyleNumber}
                        ></Column>
                    </DataTable>
                </div>
            )}
        </div>
    );
};

export default TritronikLineChartPanel;
