import { useGetPanelEndPointApiQuery } from 'app/services/PanelService';
import { createPanel } from 'app/types';
import { FormikProps } from 'formik';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import EndPointForm from './EndPointForm';
import SelectEndpointApi from './SelectEndpointApi';
import SelectPanelComponent from './SelectPanelComponent';
import '../style.css';

type Props = {
	formik: FormikProps<createPanel>;
	activeStep?: number;
	panelId?: string;
	setSelectedPanel?:any
};

const AddPanelInfo: FC<Props> = (props: Props) => {
	const { formik, setSelectedPanel } = props;

	const [apiId, setApiId] = useState('');

	const { data: endPointApi } = useGetPanelEndPointApiQuery(apiId, { skip: !apiId });

	useEffect(() => {
		if (endPointApi) {
			const endPointParams = endPointApi?.endpointApiParams?.map?.((e) => {
				const newObj = {
					paramName: e?.paramName,
					paramType: e?.paramType,
					id: e?.id,
					paramValue: '',
				};
				return newObj;
			});
			formik.setFieldValue('endPointParams', endPointParams);
		}
		// eslint-disable-next-line
	}, [endPointApi]);

	const renderForm = useCallback(() => {
		return <EndPointForm formik={formik} />;
	}, [formik]);

	return (
		<Row>
			<Col md={6}>
				<FormGroup row>
					<Label for="title" sm={3}>
						Panel Title
					</Label>
					<Col sm={9}>
						<Input
							id="title"
							name="title"
							type="text"
							value={formik.values.title}
							onChange={formik.handleChange}
							style={{ color: 'black' }}
						/>
						{formik.errors.title && <small style={{ color: 'red' }}>{formik.errors.title}</small>}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="description" sm={3}>
						Description
					</Label>
					<Col sm={9}>
						<Input
							id="description"
							name="description"
							type="textarea"
							value={formik.values.description}
							onChange={formik.handleChange}
							style={{ color: 'black' }}
						/>
						{formik.errors.description && (
							<small style={{ color: 'red' }}>{formik.errors.description}</small>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="panelComponentName" sm={3}>
						Panel Component
					</Label>
					<Col sm={9}>
						<SelectPanelComponent
							id="panelComponentName"
							value={formik.values.panelComponentName}
							onChange={(value) => {
								formik.setFieldValue('panelComponentName', value.value);
								setSelectedPanel(value.value)
							}}
							placeholder=""
						/>
						{formik.errors.panelComponentName && (
							<small style={{ color: 'red' }}>{formik.errors.panelComponentName}</small>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="componentConfig" sm={3}>
						Component Config
					</Label>
					<Col sm={9}>
						<Input
							className="prettyJSONFormat"
							id="componentConfig"
							name="componentConfig"
							type="textarea"
							value={formik.values.componentConfig}
							onChange={(e) => {
								formik.handleChange(e)
							}}
							style={{ color: 'black', height: '250px' }}
							disabled={!formik.values.componentConfig}
						/>
						{formik.errors.componentConfig && (
							<small style={{ color: 'red' }}>{formik.errors.componentConfig}</small>
						)}
					</Col>
				</FormGroup>
			</Col>
			<Col md={6}>
				<FormGroup row>
					<Label for="endPointApis" sm={3}>
						Endpoint API
					</Label>
					<Col sm={9}>
						<SelectEndpointApi
							id="endPointApis"
							value={formik.values.endPointApis}
							onChange={(value) => {
								formik.setFieldValue('endPointApis', value.value);
								setApiId(value.id);
							}}
							placeholder=""
							formik={formik}
						/>
						{formik.errors.endPointApis && (
							<small style={{ color: 'red' }}>{formik.errors.endPointApis}</small>
						)}
					</Col>
				</FormGroup>
				{renderForm()}
			</Col>
		</Row>
	);
};
export default AddPanelInfo;
