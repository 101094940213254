import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { FormikInputText, FormikInputTextArea, FormikSelect } from 'components';
import { FormikProps } from 'formik';
import { AccountConfiguration } from '../types';
import { OptionItem } from '../types';

export interface FormRuleInfoProps {
	formik?: FormikProps<AccountConfiguration>;
	applicationCategory: OptionItem[];
	typeApiCategory: OptionItem[];
}

const FormRuleInfo = (props: FormRuleInfoProps) => {
	const { formik, applicationCategory, typeApiCategory } = props;

	return (
		<React.Fragment>
			{formik?.values.account ? (
				<Row>
					<Col lg="6" sm="12">
						<h2>Information</h2>
						<FormikInputText row label="Name" name="account.name" placeholder="Name" required />
						<FormikInputText row label="Address" name="account.address" placeholder="Address" required />
						<FormikInputText
							row
							name="account.contact"
							label="Contact Number"
							placeholder="Contact Number"
							required
						/>
						<FormikInputText row name="account.email" label="Email" placeholder="Email" required />
						<FormikSelect
							label="Application Type"
							row
							id="application-type"
							name="applicationType"
							placeholder="Application Type"
							options={applicationCategory}
							required
						/>
						<FormikInputText
							row
							name="adminUser.username"
							label="User Name"
							placeholder="User name"
							required
						/>

						<FormikInputText
							row
							name="adminUser.password"
							label="Password"
							placeholder="Password"
							required
							type="password"
						/>
					</Col>
					<Col lg="6" sm="12">
						<h2>Data Source Integration</h2>
						<p>Data Source -1 </p>
						<Row>
							<Col>
								<CardBody>
									<FormikSelect
										label="Type"
										row
										id="type"
										name="type"
										placeholder="Type"
										options={typeApiCategory}
										required
									/>

									<FormikInputText
										row
										name="deviceApi.url"
										label="URL/Endpoint"
										placeholder="URL/Endpoint"
										required
									/>

									<FormikInputTextArea
										row
										name="deviceApi.headerInfo"
										label="Header Request"
										placeholder="Header"
									/>
								</CardBody>
							</Col>
						</Row>
					</Col>
				</Row>
			) : (
				<Row>
					<Col lg="6" sm="12">
						<h2>Information</h2>
						<FormikInputText row label="Name" name="name" placeholder="Name" required disabled />

						<FormikInputText row name="address" label="Address" placeholder="Address" required />

						<FormikInputText
							row
							name="contact"
							label="Contact Number"
							placeholder="Contact Number"
							required
						/>
						<FormikInputText row name="email" label="Email" placeholder="Email" required />
						<FormikSelect
							label="Application Type"
							row
							id="application-type"
							name="applicationType"
							placeholder="Application Type"
							options={applicationCategory}
						/>
					</Col>
					<Col lg="6" sm="12">
						<h2>Data Source Integration</h2>
						<p>Data Source -1 </p>
						<Row>
							<Col>
								<CardBody>
									<FormikSelect
										label="Type"
										row
										id="type"
										name="type"
										placeholder="Type"
										options={typeApiCategory}
									/>

									<FormikInputText
										row
										name="deviceApi.url"
										label="URL/Endpoint"
										placeholder="URL/Endpoint"
									/>

									<FormikInputTextArea
										row
										name="deviceApi.headerInfo"
										label="Header Request"
										placeholder="Header"
									/>
								</CardBody>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
		</React.Fragment>
	);
};

export default FormRuleInfo;
