import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { calculateFontSize, getColor } from 'app/utils';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import LoadingPage from 'app/components/LoadingPage';
import { getValueFormat, formattedValueToString } from 'app/valueFormats';

import { PanelProps } from 'app/types/Panel'
import 'echarts-liquidfill'

interface DummyOption {
    show?: boolean;
    data?: any;
}

interface TritronikLiquidFillOptions {
    dummy?: DummyOption;
    unit?: string | null;
    decimals?: number;
    capacity?: number | null;
}

export interface TritronikLiquidFillPanelProps extends PanelProps<TritronikLiquidFillOptions> { };

const TritronikLiquidFillPanel = (props: TritronikLiquidFillPanelProps) => {
    const { width, height, fieldConfig, panel, refreshInterval, options } = props;
    const params = usePanelDataParams();

    const { data: rawData, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

    const data = useMemo(() => {
        if (options.dummy && options.dummy.show) {
            return options.dummy?.data || 0;
        }

        if (rawData && rawData.responseDataValue && rawData.responseDataValue.length > 0 && Array.isArray(rawData.responseDataValue)) {
            const dataValue = rawData.responseDataValue[0]?.datapoints[0]?.value || 0;
            return dataValue;
        }
        return 0;
    }, [rawData, options.dummy]);

    const chartOptions = React.useMemo(() => {
        const steps = fieldConfig.defaults?.thresholds?.steps;
        const dataSeries: any[] = [];

        const decimals = options.decimals || 0;
        const unit = options.unit || 'none';
        const totalCapacity = options.capacity || 0;
        const capacity = getValueFormat(unit)(totalCapacity, decimals);
		const capacityText = formattedValueToString(capacity);

        const volume = getValueFormat(unit)(data, decimals);
		const volumeText = formattedValueToString(volume);

        const percentageData: number = (totalCapacity > 0 ? (data / totalCapacity) * 100 : (data / data) * 100);

        const dataColor = getColor(percentageData, steps);
        if (percentageData) {
            dataSeries.push({
                value: percentageData / 100, // echarts require data to be 0.1
				capacity: capacityText,
				volume: volumeText,
                itemStyle: {
                    color: dataColor,
                }
            })
        }

        const dim = Math.min(width, height);

        const labelText = `(${data * 100}%)\n\n${volumeText}\nof\n${capacityText}`;
        const labelFs = calculateFontSize(labelText, dim * 0.5, dim * 0.5, 1);

        const outlineWidth = dim * 0.015;

        return {
            series: [{
                type: 'liquidFill',
                data: dataSeries,
                label: {
					show: true,
					color: dataColor,
					fontSize: labelFs,
					formatter: function (param) {
						return `(${(param.value * 100).toFixed(2)}%)\n\n${param.data?.volume}\nof\n${param.data?.capacity}`;
					}
				},
                outline: {
                    show: true,
                    borderDistance: outlineWidth,
                    itemStyle: {
                        color: 'none',
                        borderColor: dataColor,
                        borderWidth: outlineWidth,
                        shadowBlur: 20,
                        shadowColor: 'rgba(0, 0, 0, 0.25)'
                    }
                }
            }],

            tooltip: {
                show: true,
                formatter: function () {
                    return `${formattedValueToString(volume)} of ${formattedValueToString(capacity)}`;
                }
            }
        }
    }, [data, fieldConfig, height, width, options]);

    if (isLoading) {
        return <LoadingPage />;
    }

    return (
        <ReactEcharts style={{ width: width, height: height * 0.9 }} option={chartOptions} />
    );
};

export default TritronikLiquidFillPanel;
