import { useMemo } from 'react';
import { useDebounceSearch } from 'app/hooks/useDebounceSearch';
import { useDatatable } from 'app/hooks/useDatatable';
import { FilterMatchMode } from 'primereact/api';
import { useGetDailyTasksQuery } from '../services';
import { IDailyTaskRequest } from '../types';

export const useDailyTaskDatatable = (params?: IDailyTaskRequest) => {
	const dt = useDatatable({
		first: 0,
		rows: 10,
		page: 0,
		sortField: 'day',
		sortOrder: 1,
		multiSortMeta: undefined,
		filters: {
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		},
	});

	const search = useDebounceSearch(dt.globalFilter);

	const { data: response, ...query } = useGetDailyTasksQuery({
		pageSize: dt.rows,
		pageNumber: dt.page ? dt.page + 1 : 1,
		sortFields: dt.sortField,
		sortOrder: dt.sortOrder === 1 ? 'asc' : dt.sortOrder === -1 ? 'desc' : undefined,
        search: params?.search ? params.search : (search && search !== '' ? search : undefined),
        ...params,
	});

	const totalRecords = useMemo(() => {
		if (!response?.content) return 0;

		return response.totalElements;
	}, [response]);

	return {
		...dt,
		...query,
		data: response?.content || [],
		totalRecords,
	};
};
