import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { FormikChips, FormikInputText, FormikInputTextArea } from 'components';
import { FormikProps } from 'formik';
import { SensorConfiguration } from '../types';
import { RadioButton } from 'primereact/radiobutton';

export interface FormRuleInfoProps {
	formik?: FormikProps<SensorConfiguration>;
	onTypeChange: (type: string) => void;
}

const FormRuleInfo = (props: FormRuleInfoProps) => {
	const { formik, onTypeChange } = props;

	const _onTypeChange = (value: any) => {
		formik?.setFieldValue('type', value);
		onTypeChange(value);
	};

	return (
		<React.Fragment>
			<Row>
				<Col>
					<Row>
						<Col lg="6" sm="12">
							<h2>Information</h2>
							<FormikInputText row label="Name" name="name" placeholder="Name" required />
							<FormikInputText row label="Sensor Property" name="id" placeholder="id" required disabled />
							<FormikChips row label="Tagging" name="entityTags" placeholder="Tag" required />
						</Col>
						<Col lg="6" sm="12">
							<h2>Additional</h2>
							<h4>Sensor Type</h4>
							<CardBody>
								<div className="d-flex flex-row align-items-center">
									<div className="field-radiobutton d-flex align-items-center mr-3">
										<RadioButton
											inputId="type-numeric"
											name="type"
											value="Numeric"
											onChange={(e) => _onTypeChange(e.value)}
											checked={formik?.values.type === 'Numeric'}
										/>
										<label htmlFor="type-numeric" className="ml-2 pt-1">
											Numeric
										</label>
									</div>

									<div className="field-radiobutton d-flex align-items-center">
										<RadioButton
											inputId="type-non-numeric"
											name="type"
											value="Non Numeric"
											onChange={(e) => _onTypeChange(e.value)}
											checked={formik?.values.type === 'Non Numeric'}
										/>
										<label htmlFor="type-non-numeric" className="ml-2 pt-1">
											Non-Numeric
										</label>
									</div>
								</div>
							</CardBody>

							<Row>
								<Col>
									<FormikInputTextArea
										row
										name="description"
										label="Description"
										placeholder="Description"
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default FormRuleInfo;
