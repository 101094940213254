import { useToasts } from 'app/components/Toast';
import { Dialog, DialogProps } from 'primereact/dialog';
import React from 'react';
import { useUpdateDailyTaskMutation } from '../../services';
import { IDailyTask } from '../../types';
import FormDailyTask, { TDailyTaskForm } from '../forms/FormDailyTask';

const ModalEditDailyTask = (props: DialogProps & { dailyTask: IDailyTask }) => {
    const [updateDailyTask, { isLoading }] = useUpdateDailyTaskMutation();
    const toast = useToasts();

    const onSubmit = async (values: TDailyTaskForm) => {
        const { cageId, ...data } = values;
        try {
            await updateDailyTask(data as IDailyTask).unwrap();
            toast.toastSuccess('Daily task updated', '');
            props.onHide();
        } catch (error) {
            toast.toastError('Update daily task failed.', error?.data?.message);
        }
    }

    return (
        <Dialog
            header="Update Daily Task"
            modal
            style={{ width: '30vw' }}
            {...props}
        >
            <FormDailyTask onSubmit={onSubmit} isLoading={isLoading} onCancel={props.onHide} dailyTask={props.dailyTask} />
        </Dialog>
    );
};

export default ModalEditDailyTask;
