export function useExplorationOptions() {
	const intervalOptions = [
		{ value: '1s', label: '1s' },
		{ value: '5s', label: '5s' },
		{ value: '10s', label: '10s' },
		{ value: '30s', label: '30s' },
		{ value: '1m', label: '1m' },
		{ value: '5m', label: '5m' },
		{ value: '10m', label: '10m' },
		{ value: '30m', label: '30m' },
		{ value: '1h', label: '1h' },
		{ value: '3h', label: '3h' },
		{ value: '6h', label: '6h' },
		{ value: '12h', label: '12h' },
		{ value: '1d', label: '1d' },
		{ value: '7d', label: '7d' },
		{ value: '30d', label: '30d' },
	];

	return {
		intervalOptions
	}
}
