import Restricted from 'app/components/Restricted';
import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import LoginConfig from '../components/LoginConfig';

const LoginConfigPage: React.FC = () => {
	const { projectId } = useParams<any>();

    const renderForm = useCallback(() => {
        return <LoginConfig projectId={projectId} />
    },[projectId])
	return (
		<Restricted to={'UPDATE DASHBOARD'}>
			<Container fluid>
				<h2 className="my-3">Login Configuration</h2>
				<Card>
					<CardBody>
						<Row className="d-flex justify-content-between">
							<Col className="d-flex align-items-center" xs="12" md="10">
								<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
									<BreadcrumbItem>
										<Link to={`/login-configuration/${projectId}`}>Login Configuration</Link>
									</BreadcrumbItem>
									<BreadcrumbItem active>Login Configuration</BreadcrumbItem>
								</Breadcrumb>
							</Col>
						</Row>
					</CardBody>
                    {renderForm()}
				</Card>
			</Container>
		</Restricted>
	);
};

export default LoginConfigPage;
