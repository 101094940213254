import LoadingPage from "app/components/LoadingPage";
import { locationService } from "app/services/LocationService";
import { ZM_API_WRAPPER_URL } from 'config';
import { BreadCrumb } from 'primereact/breadcrumb';
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from "reactstrap";
import { arrayChunk } from 'utils';
import CardCamera from "../components/CardCamera";
import { Group, Monitor } from "../types";
import { useGetGroupListQuery } from '../zmApi';
type Props = {}

const CCTVGridView: React.FC<Props> = (props) => {

    const { data, isLoading, isFetching } = useGetGroupListQuery();
    const [monitors, setMonitors] = useState<Monitor[]>([]);
    const [loadingMonitors, setLoadingMonitors] = useState(false);

    const fetchMonitorsByIds = useCallback(async (groups: Group[]) => {
        await groups.forEach(async (group) => {
            let requests = group.Monitor.map((m) => fetch(ZM_API_WRAPPER_URL + '/api/monitors/' + m.Id).then((res) => res.json()));
            setLoadingMonitors(true);
            return await Promise.all<Monitor>(requests)
                .then((results) => {
                    setLoadingMonitors(false);
                    // add group info into the monitor object
                    const mapMonitorWithGroup: any[] = results.map(({ Monitor, Monitor_Status, Event_Summary }) => {
                        return {
                            Monitor,
                            Monitor_Status,
                            Event_Summary,
                            Group: group.Group
                        };
                    });

                    setMonitors((state) => [...state, ...mapMonitorWithGroup]);
                })
                .catch(() => {
                    setLoadingMonitors(false);
                    return [];
                })
        });

    }, []);

    const history = locationService.getHistory();

    const goToDetail = React.useCallback((id, siteName = null) => {
        const search = siteName ? `name=${siteName}` : undefined;
        history.push({ pathname: `/cctv/camera/${id}`, search });
    }, [history]);

    function refreshPage() {
        window.location.reload();
    }

    const home = { icon: 'pi pi-home', url: '/' };
    const breadcrumbs = [
        { label: 'CCTV', url: '/cctv' },
        { label: 'Live View' },
    ];

    useEffect(() => {
        if (data && data.length > 0) {
            fetchMonitorsByIds(data);
        }
    }, [data, fetchMonitorsByIds]);

    return (
        <Container fluid>
            <div className="d-flex align-items-center justify-content-between py-3">
                <BreadCrumb model={breadcrumbs} home={home} />
                <div>
                    <button className="btn btn-icon btn-link btn-sm" type="button" onClick={refreshPage}>
                        <span className="btn-inner--text">Refresh</span>
                        <span className="btn-inner--icon"><i className="mdi mdi-18px mdi-refresh"></i></span>
                    </button>
                </div>
            </div>
            <div>
                {(isFetching || isLoading || loadingMonitors) ? (
                    <LoadingPage />
                ) : arrayChunk(monitors, 3).map((row, i) => {
                    return (
                        <div key={`monitor-group-${i}`} className="row mb-3" style={{ height: 300 }}>
                            {row.map((d: Monitor, j) => (
                                <div key={`Monitor-${j}-${d.Monitor.Id}`} className="col-12 col-md-4">
                                    <CardCamera data={d} onClickName={(id, siteName) => goToDetail(id, siteName)} />
                                </div>
                            ))}
                        </div>
                    )
                })}
            </div>
        </Container>
    )
}
export default CCTVGridView
