/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// plugins styles downloaded
import './assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import './assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import './assets/vendor/select2/dist/css/select2.min.css';
import './assets/vendor/quill/dist/quill.core.css';
import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';

// React Toastify
import 'react-toastify/dist/ReactToastify.min.css';
// React Prime
// import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
// Material Design Icons
import './assets/vendor/materialdesignicons/scss/materialdesignicons.scss';

// core styles
import './assets/css/custom-theme.css';
import './assets/scss/argon-dashboard-pro-react.scss?v1.1.0';

import "react-awesome-lightbox/build/style.css";

import App from './app/App';

ReactDOM.render(<App />, document.getElementById('root'));
