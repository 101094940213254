import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { PanelProps } from 'app/types/Panel'
import { DataTable, DataTableFilterMeta, DataTableProps } from 'primereact/datatable';
import { Column, ColumnProps } from 'primereact/column'
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';
import { css } from '@emotion/css';
import classNames from 'classnames';
import { Badge, BadgeSeverityType } from 'primereact/badge';
import _ from 'lodash';

import './TritronikTabelPanel.scss';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import { locationService } from 'app/services/LocationService';

import jsonlogic from 'json-logic-js';
import { useExport } from 'app/hooks/useExport';

type TemplateType = 'unitValueTemplate' | 'textLink' | string;

interface PathVariable {
	key: string;
	value: any;
	type: string;
}
export interface CustomCulumnProps extends ColumnProps {
	bodyTemplate?: TemplateType;
	unit?: string | any;
	[key: string]: any;
	replaceVariables?: PathVariable[];
}

interface RowClassRule {
	[key: string]: jsonlogic.RulesLogic
}
export interface TabelPanelPanelOptions extends DataTableProps {
	columns: CustomCulumnProps[];
	showGlobalFilter?: boolean;
	exportExcel?: boolean;
	rowClassRules?: RowClassRule;
}

export interface TritronikTabelPanelProps extends PanelProps<TabelPanelPanelOptions> {

}

const TritronikTabelPanel = (props: TritronikTabelPanelProps) => {
	const { options, panel, refreshInterval } = props;

	const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(undefined);
	const [globalFilterValue, setGlobalFilterValue] = useState('');

	const { columns: columnsOptions, ...dtOpts } = options;

	const params = usePanelDataParams();
	const { exportToExcel } = useExport()

	const { data: rawData, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const data = useMemo(() => {
		if (rawData && rawData.responseDataValue && Array.isArray(rawData.responseDataValue)) {
			return rawData.responseDataValue;
		}
		return [];
	}, [rawData]);

	const goToLink = useCallback((targetUrl: string, query: URLSearchParams) => {
		const url = new URL(targetUrl);
		const queryString = query.toString();

		locationService.push({
			pathname: url.pathname,
			search: queryString
		});
	}, []);

	const renderBodyTemplate = useCallback((template: TemplateType, rowData: any, opts: CustomCulumnProps) => {

		const unitValueTemplate = () => {
			const unit = opts.unit || 'none';
			const decimals = opts.decimals || 0;
			const valueMultiplier = opts.valueMultiplier || 1;
			const value = (opts.field && rowData[opts.field] !== undefined) ? rowData[opts.field] : 0;
            const valueMultiplied = value * valueMultiplier;
			const formatV = getValueFormat(unit)(valueMultiplied, decimals);
			return <span>{formattedValueToString(formatV)}</span>
		}

		const durationTimeTemplate = () => {
			const value = rowData[opts.field!];
            if (value === null) return '-';

			const durr = moment.duration(value, 'ms');
			const d = durr.days();
			const h = durr.hours();
			const m = durr.minutes();
			return <span>{`${d > 0 ? d + "d " : ""}${h}h ${m}m`}</span>;
		}

		const dateFormatTemplate = () => {
			const value = (opts.field && rowData[opts.field] !== undefined) ? rowData[opts.field] : 0;
			if (opts.unit === 'date') {
				return moment(value).format(opts.format || 'DD/MM/YYYY');
			}
			const v = getValueFormat(opts.unit)(value);
			return <span>{formattedValueToString(v)}</span>
		}

		const textLinkTemplate = () => {
			const query = locationService.getSearch();
			let linkUrl: string = opts.linkUrl;

			const linkType = opts.linkUrlType && opts.linkUrlType === 'external' ? 'external' : 'internal';
			if (opts.filters && opts.filters.length > 0) {
				opts.filters.forEach((q) => {
					const value = q.type === "dataset" ? rowData[q.value] : q.value;
					if (value) {
						query.set(q.query, value);
					}
				});
			}

			const hasPathVariables = new RegExp(/\{+[A-Za-z]+\}/g);
			if (hasPathVariables.test(linkUrl)) {
				if (opts.replaceVariables && opts.replaceVariables.length > 0) {
					opts.replaceVariables.forEach(({ key, value, type }) => {
						const resultValue = type === 'dataset' ? rowData[value] : value;
						if (resultValue) {
							linkUrl = linkUrl.replace(`{${key}}`, resultValue);
						}
					})
				}
			}


			if (linkType === 'internal') {
				return <span className={'text-primary'} style={{ cursor: 'pointer' }} onClick={() => goToLink(linkUrl, query)}>{rowData[opts.field!]}</span>
			}

			return <a href={`${linkUrl}?${query.toString()}`}>{rowData[opts.field!]}</a>
		}

		const statusBadgeTemplate = () => {
			const status = opts.field ? rowData[opts.field] : '-';

			const getSeverity = (): BadgeSeverityType | undefined => {
				const mapStatus = opts.mappings?.find((m) => _.toLower(m.value) === _.toLower(status));
				return mapStatus && mapStatus.status ? mapStatus.status : undefined;
			}

			const bodyStyle = css`
				font-size: 1rem;
				display: flex;
				align-items: center;

				.p-badge {
					min-width: .75rem !important;
				}

				.p-badge-dot {
					width: .75rem !important;
					height: .75rem !important;
					margin-right: .5rem;
				}

				.p-badge-none {
					background: gray !important;
				}
			`;

			const severity = getSeverity();

			return (
				<span className={bodyStyle}>
					<Badge severity={severity} className={classNames({ 'p-badge-none': severity === undefined })}></Badge>
					{status}
				</span>
			)
		}

		switch (template) {
			case 'unitValueTemplate': return unitValueTemplate();
			case 'textLink': return textLinkTemplate();
			case 'durationTimeTemplate': return durationTimeTemplate();
			case 'dateFormatTemplate': return dateFormatTemplate();
			case 'statusBadgeTemplate': return statusBadgeTemplate();

			default:
				return opts.field ? rowData[opts.field] : '-';
		}
	}, [goToLink]);

	const clearFilter = () => {
		initFilters();
	}

	const initFilters = () => {
		setFilters({
			'global': { value: null, matchMode: 'contains' }
		});
		setGlobalFilterValue('');
	}

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;

		setFilters((currentFilters) => ({
			...currentFilters,
			global: { value: e.target.value, matchMode: 'contains' }
		}));
		setGlobalFilterValue(value);
	}

	const onExportButtonClick = () => {
        const noData = (field: string) => ({ [field]: '-' });

		const exportedData = data.map((d) => {
			const keys = Object.keys(d);
			const mapped = keys.map((key) => {
				const columnOption = columnsOptions.find((c => c.field === key));
				// get the header with key
				if (!columnOption?.header) {
					return undefined;
				}

				const header = columnOption?.header || key;
				const value = d[key];
                const unit = columnOption?.unit || 'none';
                const decimals = columnOption.decimals || 0;
                const valueMultiplier = columnOption.valueMultiplier || 1;
                const valueMultiplied = value * valueMultiplier;

				if (unit === 'date') {
					return {
						[header as string]: moment(value).format(columnOption?.format || 'DD/MM/YYYY')
					}
				}

                if (unit === 'dthms') {
                    if (value === null) return noData(header as string);

                    const durr = moment.duration(value, 'ms');
                    const d = durr.days();
                    const h = durr.hours();
                    const m = durr.minutes();
                    const durationValue = `${d > 0 ? d + "d " : ""}${h}h ${m}m`;
                    return { [header as string]: durationValue }
                }

				if (unit === 'none' || !unit) {
					return { [header as string]: d[key] }
				}

				return {
					[header as string]: formattedValueToString(getValueFormat(unit)(valueMultiplied, decimals))
				}
			}).reduce((acc, curr) => {
				return {
					...curr,
					...acc,
				}
			}, {})

			return mapped;
		})
        const header = columnsOptions?.map((col) => col.header);
		exportToExcel(exportedData, panel.title, header);
	}

	const renderHeader = () => {
		if (!options.showGlobalFilter && !dtOpts.filterDisplay && !options.exportExcel) {
			return null;
		}
		return (
			<div className="d-flex flex-wrap justify-content-between align-items-center">
				<div>
					{options.showGlobalFilter && (
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
						</span>

					)}
				</div>
				{(dtOpts.filterDisplay || options.exportExcel) && (
					<div className='mt-2 md:mt-0'>
						{dtOpts.filterDisplay === 'menu' && (
							<Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter} />
						)}
						{options.exportExcel && (
							<Button type="button" icon="pi pi-file-excel" onClick={onExportButtonClick} className="p-button-outlined ml-2" tooltipOptions={{ position: 'left' }} tooltip="Export to Excel" />
						)}
					</div>
				)}
			</div>
		)
	}

	const header = renderHeader();

	const columns = useMemo(() => {
		if (columnsOptions && columnsOptions.length > 0) {
			return columnsOptions.map(({ bodyTemplate, ...col }, i) => {
				const { unit, ...column } = col;

				if (bodyTemplate) {
					return <Column key={`${col.field}-${i}`} {...column} body={(data) => renderBodyTemplate(bodyTemplate, data, col)} />
				}

				return (
					<Column key={`${col.field}-${i}`} {...column} />
				)
			});
		}

		return null;
	}, [columnsOptions, renderBodyTemplate]);

	const rowClassName = (data) => {
		let classes: any = {};

		const rowClassRules = dtOpts.rowClassRules;
		if (rowClassRules) {
			Object.keys(rowClassRules).forEach((className) => {
				const rules = rowClassRules[className];
				classes[className] = jsonlogic.apply(rules, data);
			});
		}

		return classes;
	}

	useEffect(() => {
		initFilters();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps


	const styles = {
		wrapper: css`
			width: 100%;
			height: 100%;
			overflow: 'hidden';
			padding: .75rem;
		`,
	}

	return (
		<div className={classNames(styles.wrapper, 'tritronik-table-panel')}>
			<DataTable
				{...dtOpts}
				className={classNames('p-datatable-panel', dtOpts.className)}
				rowClassName={rowClassName}
				loading={isLoading}
				dataKey={dtOpts.dataKey || 'id'}
				header={header}
				value={data}
				filters={filters}
				rows={dtOpts.rows || 10}
				filterDisplay={dtOpts.filterDisplay}
				paginatorTemplate={dtOpts.paginatorTemplate || "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"}
				rowsPerPageOptions={dtOpts.rowsPerPageOptions || [10, 25, 50]}
				currentPageReportTemplate={dtOpts.currentPageReportTemplate || "Showing {first} to {last} of {totalRecords} entries"}
				emptyMessage={dtOpts.emptyMessage || "No records found."}
			>
				{columns}
			</DataTable>
		</div>
	)
}

export default TritronikTabelPanel
