import { useModal } from 'app/hooks/useModal';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { nullableString } from 'app/utils/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Chip } from 'primereact/chip';
import { InputText } from 'primereact/inputtext';
import React, { useMemo, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import ModalCreateDailyTask from '../components/modals/ModalCreateDailyTask';
import ModalCreateTask from '../components/modals/ModalCreateTask';
import ModalEditDailyTask from '../components/modals/ModalEditDailyTask';
import ModalEditTask from '../components/modals/ModalEditTask';
import TaskExpansionTemplate from '../components/TaskExpansionTemplate';
import { useDailyTaskDatatable } from '../hooks/useDailyTaskDatatable';
import { useDeleteDailyTask } from '../hooks/useDeleteDailyTask';
import { useDeleteTask } from '../hooks/useDeleteTask';
import { IDailyTask, IDailyTaskRequest, ITask } from '../types';
import { locationService } from 'app/services/LocationService';

type Props = {};

type FilterParamsKey = keyof IDailyTaskRequest;
type FilterParams = Array<keyof IDailyTaskRequest | 'search' | 'startDate' | 'endDate'>;

const ListTask = (props: Props) => {
    const queryParams = usePanelDataParams();

    const filteredQueryParams = useMemo(() => {
        if (!queryParams) return {};

        const availableParams: FilterParams = [
            'productionId',
            'productionMonitoringId',
            'day',
            'cage',
            'taskName',
            'status',
            'assignTo',
            'search',
            'startDate',
            'endDate',
        ];

        return Object.entries(queryParams)
            .filter(([key]) => {
                return availableParams.includes(key as FilterParamsKey);
            })
            .reduce((obj, [key, value]) => {
                return { ...obj, [key]: value };
            }, {});
    }, [queryParams]);

    const {
        data,
        isLoading,
        isFetching,
        totalRecords,
        sortField,
        sortOrder,
        rows,
        first,
        globalFilter,
        onFilter,
        onPage,
        onSort,
        onGlobalFilterChange,
    } = useDailyTaskDatatable(filteredQueryParams);
    const [expandedRows, setExpandedRows] = useState<IDailyTask[]>([]);
    const modal = useModal();
    const modalEdit = useModal();
    const modalTask = useModal();
    const modalEditTask = useModal();
    const [selectedDailyTask, setSelectedDailyTask] = useState<IDailyTask | null>(null);
    const [selectedTask, setSelectedTask] = useState<ITask | null>(null);
    const { deleteTask } = useDeleteTask();
    const { deleteDailyTask } = useDeleteDailyTask();

    const renderHeader = () => {
        return (
            <div className="d-flex flex-wrap justify-content-between align-items-center">
                <div className="d-flex flex-fill">
                    <span className="p-input-icon-right w-100">
                        <InputText
                            value={globalFilter}
                            onChange={onGlobalFilterChange}
                            placeholder="Search daily task"
                            className="w-100"
                        />
                        <i className="pi pi-search" />
                    </span>
                </div>
            </div>
        );
    };

    const header = renderHeader();

    const onAddTask = (data: IDailyTask) => {
        setSelectedDailyTask(data);
        modalTask.show();
    };

    const onHideFormAddTask = () => {
        setSelectedDailyTask(null);
        modalTask.hide();
    };

    const onEditTask = (task: ITask, dailyTask: IDailyTask) => {
        setSelectedDailyTask(dailyTask);
        setSelectedTask(task);
        console.log(task, dailyTask);

        modalEditTask.show();
    };

    const onHideFormEditTask = () => {
        setSelectedDailyTask(null);
        setSelectedTask(null);
        modalEditTask.hide();
    };

    const onHideFormEditDailyTask = () => {
        setSelectedDailyTask(null);
        modalEdit.hide();
    };

    const actionBodyTemplate = (rowData: IDailyTask) => {
        return (
            <div className="d-flex align-items-center justify-content-end">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-text p-button-warning"
                    onClick={() => {
                        setSelectedDailyTask(rowData);
                        modalEdit.show();
                    }}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-text p-button-danger"
                    onClick={() => deleteDailyTask(rowData)}
                />
            </div>
        );
    };

    const renderFilters = () => {
        const filters = Object.keys(filteredQueryParams);
        if (filters.length === 0) return null;

        return Object.entries(filteredQueryParams).map(([key, value]) => {
            return (
                <Chip
                    key={`filter-daily-task-${key}`}
                    label={`${key}: ${value}`}
                    className="mb-2 mr-2"
                    removable
                    onRemove={() => {
                        locationService.partial({ [key]: undefined });
                    }}
                />
            );
        });
    };

    return (
        <Container fluid>
            <div>
                <h1 className="my-3">Task Management</h1>
                <div className="row">
                    <div className="col-12">
                        <Card>
                            <CardBody>
                                <div className="d-flex justify-content-between mb-2">
                                    <div className="d-flex flex-wrap">{renderFilters()}</div>
                                    <div style={{ flexBasis: 200 }}>
                                        <Button
                                            icon="mdi mdi-plus-circle"
                                            label="Add Daily Task"
                                            className="p-button-sm"
                                            onClick={() => modal.show()}
                                        />
                                    </div>
                                </div>
                                <DataTable
                                    lazy
                                    dataKey="id"
                                    value={data}
                                    header={header}
                                    loading={isLoading || isFetching}
                                    paginator
                                    globalFilter={globalFilter}
                                    rows={rows}
                                    first={first}
                                    onPage={onPage}
                                    onSort={onSort}
                                    onFilter={onFilter}
                                    totalRecords={totalRecords}
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                    expandedRows={expandedRows}
                                    onRowToggle={(e) => setExpandedRows(e.data)}
                                    rowExpansionTemplate={(data) => (
                                        <TaskExpansionTemplate
                                            data={data}
                                            onAddTask={onAddTask}
                                            onEditTask={onEditTask}
                                            onDeleteTask={deleteTask}
                                        />
                                    )}
                                >
                                    <Column expander style={{ width: '3em' }} />
                                    <Column
                                        field="date"
                                        header="Date"
                                        sortable
                                        body={({ date }) => nullableString(date)}
                                    />
                                    <Column field="day" header="Day" sortable body={({ day }) => nullableString(day)} />
                                    <Column
                                        field="taskName"
                                        header="Task"
                                        body={({ taskName }) => nullableString(taskName)}
                                    />
                                    <Column field="cage" header="Cage" body={({ cage }) => nullableString(cage)} />
                                    <Column
                                        field="productionName"
                                        header="Production Name"
                                        body={({ productionName }) => nullableString(productionName)}
                                    />
                                    <Column
                                        field="assignTo"
                                        header="Assign To"
                                        body={({ assignTo }) => nullableString(assignTo)}
                                    />
                                    <Column
                                        field="status"
                                        header="Status"
                                        body={({ status }) => nullableString(status)}
                                    />
                                    <Column headerStyle={{ width: '4rem' }} body={actionBodyTemplate}></Column>
                                </DataTable>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
            <ModalCreateDailyTask visible={modal.visible} onHide={modal.hide} />
            {selectedDailyTask && (
                <ModalEditDailyTask
                    visible={modalEdit.visible}
                    onHide={onHideFormEditDailyTask}
                    dailyTask={selectedDailyTask}
                />
            )}
            {selectedDailyTask && (
                <ModalCreateTask visible={modalTask.visible} onHide={onHideFormAddTask} dailyTask={selectedDailyTask} />
            )}
            {selectedTask && selectedDailyTask && (
                <ModalEditTask
                    visible={modalEditTask.visible}
                    onHide={onHideFormEditTask}
                    dailyTask={selectedDailyTask}
                    task={selectedTask}
                />
            )}
        </Container>
    );
};

export default ListTask;
