import Restricted from 'app/components/Restricted';
import { setIsEditing } from 'app/reducers/updateGridSlice';
import { useAppDispatch } from 'app/store';
import React, { FC, useCallback, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Container, Row, Col, Breadcrumb, BreadcrumbItem, CardBody } from 'reactstrap';
import AddPanel from '../components/AddPanel';
const AddPanelPage: FC = () => {
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(setIsEditing(true))
	}, [dispatch])
	const {panelId} = useParams<any>()
	const renderForm = useCallback(
		() => {
			return (<AddPanel panelId={panelId} />)
		},
		[panelId],
	)
	return (
		<Restricted to={'UPDATE DASHBOARD'}>
		<Container fluid>
			<h2 className="my-3">Dashboard Management</h2>
			<Card>
                <CardBody>
                <Row>
					<Col className="d-flex align-items-center" xs="12" md="6">
						<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
							<BreadcrumbItem>
								<Link to="/">Dashboard Management</Link>
							</BreadcrumbItem>
							<BreadcrumbItem active>{panelId ? 'Edit Panel' : 'Add New Panel'}</BreadcrumbItem>
						</Breadcrumb>
					</Col>
				</Row>
                </CardBody>
				{renderForm()}
			</Card>
		</Container>
		</Restricted>
	);
};
export default AddPanelPage;
