import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

export interface FlexRowRightItemProps {
  className?: string;
  isJSON?:boolean
}

const FlexRowRightItem = (props: FlexRowRightItemProps & PropsWithChildren<unknown>) => {
  const { className, isJSON } = props;
  return (
    <div style={{overflow:'auto'}} className={classNames(`d-flex justify-content-end ${isJSON ? 'text-left' : 'text-right'}`, className)}>
      {isJSON ? <pre>{props.children}</pre> : <>{props.children}</>}
    </div>
  )
}

export default FlexRowRightItem;
