import { ArrayHelpers, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { alertService } from '../services/AlertService';
import { AlertConfiguration, AlertConfigurationForm } from '../types';
import { validations } from '../validationSchema';
import FormConfirmation from './FormConfirmation';
import FormRuleInfo from './FormRuleInfo';
import { Row, Col } from 'reactstrap';
import { PrimeSteps } from 'components';
import { Button } from 'primereact/button';
import { history } from 'utils';

export interface AlertRuleFormProps {
	initialValues: AlertConfigurationForm;
	onSubmit: (values: AlertConfiguration, formik: FormikHelpers<AlertConfigurationForm>) => void;
	isSubmitting?: boolean;
}

const formSteps = [{ label: 'Rule Information' }, { label: 'Confirmation' }];

const AlertRuleForm = (props: AlertRuleFormProps) => {
	const [activeStep, setActiveStep] = useState(0);
	const isLastStep = activeStep === formSteps.length - 1;
	const currentValidation = validations[activeStep];

	const targetCategoryOptions = alertService.getTargetCategoryOptions();
	const severityOptions = alertService.getSeverityOptions();
	const channelOptions = alertService.getAlertChannelOptions();

	const [metricType, setMetricType] = useState('NUMERIC');

	const comparatorOptions = useMemo(() => {
		const opts = alertService.getComparatorOptions(metricType);
		return opts;
	}, [metricType]);

	const { initialValues, isSubmitting, onSubmit } = props;

	const nextStep = () => {
		if (!isLastStep) {
			setActiveStep((currentStep) => currentStep + 1);
		}
	};

	const prevStep = () => {
		if (activeStep > 0) {
			setActiveStep((currentStep) => currentStep - 1);
		}
	};

	const _onSubmit = (values: AlertConfigurationForm, formik: FormikHelpers<AlertConfigurationForm>) => {
		const { channelRecipient, ...rules } = values;
		const alertConfig: AlertConfiguration = {
			...rules,
			channelRecipient: channelRecipient.reduce((acc, cur) => {
				acc[cur.channel] = cur.recipients;
				return acc;
			}, {}),
		};

		onSubmit(alertConfig, formik);
	};

	const _handleSubmit = (values: any, formik: FormikHelpers<AlertConfigurationForm>): void => {
		if (isLastStep) {
			_onSubmit(values, formik);
		} else {
			nextStep();
			formik.setTouched({});
			formik.setSubmitting(false);
		}
	};

	const _addRule = useCallback((arrayHelper: ArrayHelpers) => {
		arrayHelper.push({
			id: 0,
			name: '',
			comparator: '',
			thresholdValue: 0,
			dataFunction: 'AVERAGE',
		});
	}, []);

	const _addChannel = useCallback((arrayHelper: ArrayHelpers) => {
		arrayHelper.push({
			channel: '',
			recipients: [],
		});
	}, []);

	const _renderForm = useCallback(
		(step: number, formik: FormikProps<AlertConfigurationForm>) => {

			const onMetricTypeChange = (value: string) => {
				setMetricType(value);
			}

			switch (step) {
				case 1:
					return <FormConfirmation formik={formik} />;
				default:
					return (
						<FormRuleInfo
							onMetricTypeChange={onMetricTypeChange}
							targetCategoryOptions={targetCategoryOptions}
							severityOptions={severityOptions}
							comparatorOptions={comparatorOptions}
							channelOptions={channelOptions}
							formik={formik}
							onAddRule={_addRule}
							onAddChannel={_addChannel}
						/>
					);
			}
		},
		[targetCategoryOptions, severityOptions, comparatorOptions, channelOptions, _addChannel, _addRule],
	);

	return (
		<Formik initialValues={initialValues} onSubmit={_handleSubmit} validationSchema={currentValidation}>
			{(formik: FormikProps<AlertConfigurationForm>) => (
				<Form onSubmit={formik.handleSubmit}>
					<Row className="mt-3">
						<Col>
							<PrimeSteps model={formSteps} activeIndex={activeStep} />
						</Col>
					</Row>
					<Row className="mt-3">
						<Col>{_renderForm(activeStep, formik)}</Col>
					</Row>
					<Row className="mt-3">
						<Col className="text-right">
							<Button
								label="Cancel"
								type="button"
								className="mr-2 p-button-outlined"
								onClick={() => history.goBack()}
							/>
							{activeStep > 0 && (
								<Button
									type="button"
									label="Previous"
									onClick={prevStep}
									className="mr-2 p-button-outlined"
								/>
							)}
							<Button
								label={isLastStep ? 'Apply' : 'Next'}
								loading={isSubmitting}
								disabled={isSubmitting}
								type="submit"
								className=""
							/>
						</Col>
					</Row>
				</Form>
			)}
		</Formik>
	);
};

export const defaultProps: AlertRuleFormProps = {
	initialValues: {
		id: 0,
		name: '',
		applyTo: '',
		targetId: '',
		tenantId: '',
		minuteInterval: 5,
		severity: '',
		metricType: '',
		conditions: [
			{
				id: 0,
				name: '',
				comparator: '',
				thresholdValue: 0,
				dataFunction: 'AVERAGE',
			},
		],
		message: '',
		channelRecipient: [
			{
				channel: 'email',
				recipients: [''],
			},
		],
		tags: []
	},
	onSubmit: () => { },
	isSubmitting: false,
};

AlertRuleForm.defaultProps = defaultProps;

export default AlertRuleForm;
