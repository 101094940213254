import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './../../../services/BaseApi';
import { PaginatedResponse, ApiConfiguration } from './../types';

export const apiConfigurationApi = createApi({
	reducerPath: 'apiConfigurationApi',
	baseQuery: baseQuery,
	tagTypes: ['ApiConfiguration'],
	endpoints: (builder) => ({
		getApiConfigurations: builder.query<PaginatedResponse<ApiConfiguration>, any>({
			query: (params) => ({
				url: '/v1/endpointApis/',
				params,
			}),
			providesTags: (result) =>
				result?.content
					? [
							...result.content.map(({ id }) => ({ type: 'ApiConfiguration' as const, id })),
							{ type: 'ApiConfiguration', id: 'LIST' },
					  ]
					: [{ type: 'ApiConfiguration', id: 'LIST' }],
		}),

		getApiConfiguration: builder.query<ApiConfiguration, number | string>({
			query: (id) => ({ url: `/v1/endpointApis/${id}` }),
			providesTags: (result, error, id) => [{ type: 'ApiConfiguration', id }],
		}),
		createApiConfiguration: builder.mutation<ApiConfiguration, Partial<ApiConfiguration>>({
			query: (body) => ({
				url: '/v1/endpointApis/',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'ApiConfiguration', id: 'LIST' }],
		}),
		updateApiConfiguration: builder.mutation<ApiConfiguration, Partial<ApiConfiguration>>({
			query: ({ id, ...body }) => ({
				url: `/v1/endpointApis/${id}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'ApiConfiguration', id }],
		}),
		deleteApiConfiguration: builder.mutation<ApiConfiguration, number | string>({
			query: (id) => ({
				url: `/v1/endpointApis/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, id) => [{ type: 'ApiConfiguration', id }],
		}),
	}),
});

export const {
	useGetApiConfigurationQuery,
	useGetApiConfigurationsQuery,
	useCreateApiConfigurationMutation,
	useUpdateApiConfigurationMutation,
	useDeleteApiConfigurationMutation,
} = apiConfigurationApi;
