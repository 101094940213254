import React, { useCallback, useMemo } from 'react'
import ReactEcharts from 'echarts-for-react';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { PanelProps } from 'app/types/Panel'
import LoadingPage from 'app/components/LoadingPage';
import styled from "@emotion/styled";

const colorMap = {
  open: "#F2495C",
  resolved: "#73BF69"
}
export interface TritronikAlertStatusPanelProps extends PanelProps {
  
}

const TritronikAlertStatusPanel = (props: TritronikAlertStatusPanelProps) => {
  const { width, height, panel, refreshInterval } = props;

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

  const datasource = useMemo(() => {
    if (data && data.responseDataValue && data.responseDataValue.length > 0) {
      return data.responseDataValue.map((ds) => ({
        name: ds.target,
        value: ds.datapoints[0].value
      }))
    }
    return [];
  }, [data]);

  const options = useMemo(() => {
    const dataSeries = datasource && datasource.map((ds) => {
      return {
        value: ds.value,
        name: ds.name,
        itemStyle: {
          color: colorMap[ds.name.toLowerCase()]
        }
      }
    })

    return {
      tooltip: {
        trigger: 'item',
        formatter: '{a} {b}: {c} ({d}%)'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: 'Alert',
          type: 'pie',
          radius: ['40%', '70%'],
          labelLine: {
            length: 30
          },
          label: {
            formatter: ' {per|{d}%} ',
            backgroundColor: '#F6F8FC',
            borderColor: '#8C8D8E',
            borderWidth: 1,
            borderRadius: 2,
            rich: {
              per: {
                color: '#4C5058',
                padding: [4, 4],
                borderRadius: 4,
                fontSize: 14
              }
            }
          },
          data: dataSeries
        }
      ]
    };  
  }, [datasource]);

  const renderAlertDetail = useCallback(() => {
    return datasource.map((ds, i) => (
      <AlertDetailItem key={i}>
        <AlertDetailLabel>{ds.name}</AlertDetailLabel>
        <AlertDetailValue>{ds.value}</AlertDetailValue>
      </AlertDetailItem>
    ))
  },[datasource]);

  if (isLoading) {
		return <LoadingPage />;
	}

	return (
    <Wrapper>
      <ReactEcharts style={{ width: width, height: height * 0.7 }} option={options} />
      <Separator />
      <AlertDetailContainer>
        <AlertDetailItem>
          <h3>Status</h3>
          <h3>Total</h3>
        </AlertDetailItem>
        {renderAlertDetail()}
      </AlertDetailContainer>
    </Wrapper>
  );
}

export default TritronikAlertStatusPanel

const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const AlertDetailContainer = styled.div``;

const AlertDetailItem = styled.div`
  display: flex;
  flex: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
`;

const AlertDetailLabel = styled.span`
  font-weight: 700;
`;

const AlertDetailValue = styled.a``;

const Separator = styled.hr`
  margin: 0.5rem 0;
  border-top: 1px dashed #ddd;
`;