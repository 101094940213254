import React, { useMemo, useRef } from 'react';
import { PanelProps } from 'app/types/Panel';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery, useGetSvgFileQuery } from 'app/services/PanelService';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';
import { getColor } from 'app/utils';
import LoadingPage from 'app/components/LoadingPage';
import _ from 'lodash';
import { locationService } from 'app/services/LocationService';
interface VisualMapOptions {
	svgUrl: string;
	zoom: number;
	minZoom?: number;
	maxZoom?: number;
	series: any;
	chart: any;
}
export interface TritronikVisualMapPanelProps extends PanelProps<VisualMapOptions> {

}

const TritronikVisualMapPanel = (props: TritronikVisualMapPanelProps) => {
	const { panel, refreshInterval, options } = props;
	const params = usePanelDataParams();
	const history = locationService.getHistory();

	const { data: rawData, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const svgUrl = useMemo(() => {
		const originalURL = options.svgUrl;
		return originalURL;
	}, [options.svgUrl]);

	const { data: svgFile } = useGetSvgFileQuery(svgUrl, { skip: !svgUrl });

	const echartRef = useRef<ReactEcharts | null>(null);

	const getDataWithAlias = React.useCallback((alias) => {
		if (rawData && rawData.responseDataValue && rawData.responseDataValue.length > 0) {
			const datasets = rawData.responseDataValue.filter((response) => response.aliasLabel === alias);

			if (datasets.length === 1) {
				return { value: datasets[0]?.datapoints[0]?.value || 0, groupId: 'single', unit: datasets[0]?.unit };
			}

			if (datasets.length > 1) {
				const initialValue: any = { body: {}, headers: {}, counter: 1 };
				const data = datasets.reduce((obj, item) => {
					const { datapoints, aliasLabel } = item;
					const value = datapoints[0]?.value || 0;
					// const keyName = item.subLabel?.toLowerCase()?.replace(' ', '_');
					// const formatValue = getValueFormat(item.unit||'none')(value);
					const labelFromSubAlias = item.subLabel?.split(' ');
					const xLabel = labelFromSubAlias && labelFromSubAlias.length > 0 ? labelFromSubAlias[0] : `X${obj.counter}`;
					const yLabel = labelFromSubAlias && labelFromSubAlias.length > 1 ? labelFromSubAlias[1] : `Y${obj.counter}`;

					const body = {
						...obj.body,
						[xLabel]: [
							...obj.body[xLabel] || [],
							{ value: value, unit: item.unit, aliasLabel, subLabel: item.subLabel },
						]
					}

					const headers = {
						...obj.headers,
						[yLabel]: yLabel
					};

					return {
						...obj,
						value: value,
						groupId: 'multi',
						title: aliasLabel,
						headers,
						body,
						counter: obj.counter + 1,
					}

				}, initialValue);
				return data;
			}

			return { value: 0 };
		}

		return { value: 0 };
	}, [rawData]);

	const chartOptions: any = useMemo(() => {
		const { series: seriesOptions, ...opts } = typeof options.chart !== 'undefined' ? options.chart : { series: [{ map: 'svg_map', data: [] }] };
		const defaultOptions = opts || {};
		const series: any[] = [];

		if (svgFile && seriesOptions && seriesOptions.length > 0) {
			echarts.registerMap(seriesOptions[0].map, { svg: svgFile });

			const getValueMapping = (mappings: any[], value: any) => {
				for (const vm of mappings) {
					switch (vm.type) {
						case 1:
							if (value === null) {
								continue;
							}
							const result = vm.value;
							if (result) {
								return result;
							}
							break;

						case 2:
							if (value === null) {
								continue;
							}
							const from = parseFloat(vm.from);
							const to = parseFloat(vm.to);
							const text = vm.text;
							const valueMap = parseFloat(value);

							if (valueMap >= from && valueMap <= to) {
								return text;
							}
							break;
					}
				}

				return value;
			}

			const mapData = ({ mapping: { unit, decimals, alias, thresholds, target, mappings, emphasis: emphasisData = {}, valueLabel = 'Value' }, ...restData }) => {

				const formatterMulti = (params: any): string => {
					const { title, headers, body } = params.data;

					const headerTemplate = Object.entries(headers).map(([key]) => `{header|${key}}`).join('');

					const bodyTemplate = Object.entries<any>(body).map(([key, value]) => {
						const labelTemplate = `{label|${key}}`;
						const valueTemplate = Array.isArray(value) && value.map((v) => {
							const val = v.value;
							// override unit options from data unit options mapping
							const selectedUnit = v.unit || unit || 'none';
							if (typeof mappings !== 'undefined') {
								return `{value|${getValueMapping(mappings, val) || '-'}}`
							}
							return `{value|${formattedValueToString(getValueFormat(selectedUnit)(val, decimals || 0)) || '-'}}`
						}).join('')
						return `${labelTemplate}${valueTemplate}`;
					});

					return [
						`{title|${title || 'Unknown'}}{abg|}`,
						`{empty|}${headerTemplate}`,
						'{hr|}',
						...bodyTemplate
					].join('\n');
				};

				const formatterSingle = (params: any): string => {
					let value = params.value;
					const unitData = params.unit || 'none';
					let formatValue = getValueFormat(unitData)(value, decimals);

					if (typeof mappings !== 'undefined' && mappings) {
						value = getValueMapping(mappings, params.value);
					}

					return [
						`{title|${params.name}}{abg|}`,
						'{hr|}',
						`{label|${valueLabel}}{value|${value} ${formatValue?.suffix || ''}}   `,
					].join('\n');
				}

				const { value, groupId, ...restValue } = getDataWithAlias(alias);

				const color = (thresholds && thresholds.steps) ? getColor(value, thresholds.steps) : 'transparent';

				const emphasis: any = _.merge({
					label: {
						position: 'inside',
						formatter: groupId === 'multi' ? formatterMulti : formatterSingle,
						backgroundColor: '#eee',
						borderColor: '#777',
						borderWidth: 1,
						borderRadius: 4,
						rich: {
							title: {
								color: '#eee',
								align: 'center',
								width: 200
							},
							abg: {
								backgroundColor: '#333',
								width: '100%',
								align: 'right',
								height: 25,
								borderRadius: [4, 4, 0, 0]
							},

							label: {
								align: 'left',
								height: 30,
								width: 50,
								padding: [0, 0, 0, 10]
							},
							empty: {
								width: 50
							},
							hr: {
								borderColor: '#777',
								width: '100%',
								borderWidth: 0.5,
								height: 0
							},
							header: {
								color: '#333',
								height: 24,
								align: 'center',
								width: 70
							},
							value: {
								width: 70,
								align: 'center'
							},
						}
					},
					labelLine: {
						show: false
					}
				}, emphasisData);

				const dataOptions = _.merge(restData, {
					label: {
						...restData?.label || {},
						formatter: (params) => {
							const formatValue = getValueFormat(unit || 'none')(params.value, decimals);
							return formattedValueToString(formatValue);
						},
					},
					tooltip: {},
					itemStyle: {
						areaColor: color,
					},
					emphasis
				});

				return {
					name: alias,
					value: value,
					target,
					...restValue,
					...dataOptions
				}
			}


			seriesOptions.forEach(({ data, ...restOptions }) => {
				series.push({
					...restOptions,
					data: data?.map((d) => mapData(d))
				});
			});
		}

		return {
			...defaultOptions,
			series: series
		};

	}, [svgFile, options.chart, getDataWithAlias])

	const onChartClick = (event) => {
		if (event?.data?.target?.url) {
			const url = new URL(event.data.target.url);

			history.push({
				pathname: url.pathname,
				search: url.search
			});
		}
	}

	const onEvents = {
		'click': onChartClick
	}

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<ReactEcharts
			option={chartOptions}
			ref={echartRef}
			opts={{ renderer: 'svg' }}
			style={{ height: '100%', width: '100%' }}
			onEvents={onEvents}
		/>
	)
}

export default TritronikVisualMapPanel
