/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useMemo } from 'react';
// reactstrap components
import './Login.scss';
import { renderer } from 'app/renderer';
import { useGetTenantConfigQuery } from 'app/services/ProjectService';
import { TenantConfig } from 'app/types';
import { APP_LOGIN_BG, APP_LOGIN_TITLE } from 'config';
import { smartSolutionLogo } from 'assets';
import FullScreenLoading from 'app/components/FullScreenLoading';
import { toast } from 'react-toastify';
import { history } from 'utils';
import { useLoginMutation } from '../../authAPI';
import { useAuth } from '../../hooks/useAuth';
import { Redirect } from 'react-router';

const availableLoginTemplates = ['LoginPageTemplateDefault', 'LoginPageTemplateRightForm'];
const defaultLoginPageTemplate = "LoginPageTemplateDefault";

const LoginPage: React.FC = () => {
	const [loginMutate, { isLoading }] = useLoginMutation();
	const { isAuthenticated } = useAuth();

	const defaultConfig: TenantConfig = {
		backgroundImg: APP_LOGIN_BG,
		logoImg: smartSolutionLogo,
		caption: APP_LOGIN_TITLE
	}

	const subDomain = useMemo(() => {
		const host = window.location.host;

		if (host.split('.').length > 2) {
			return window.location.host.split('.')[0];
		}

		// if host not have subdomain, return empty string
		return "";
	}, []);

	const { data: tenantData, isLoading: loadingConfig } = useGetTenantConfigQuery(subDomain, { skip: !Boolean(subDomain) });

	const tenantConfig = useMemo<TenantConfig>(() => {
		if (!tenantData) return defaultConfig;
		try {
			return JSON.parse(tenantData.configs)
		} catch {
			return defaultConfig
		}
	}, [defaultConfig, tenantData]);

	const loginTemplate = useMemo(() => {
		if (!tenantData) return defaultLoginPageTemplate;

		const templateName = tenantData.templateName;
		return availableLoginTemplates.includes(templateName) ? tenantData.templateName : defaultLoginPageTemplate;
	}, [tenantData]);

	const onSubmit = async ({ username, password }) => {
		try {
			await loginMutate({ username, password }).unwrap();
			history.replace('/');
		} catch (error: any) {
			toast.error(`Invalid credentials`);
		}
	}

	const renderedLoginPage = renderer({
		component: loginTemplate,
		props: {
			fieldConfig: tenantConfig,
			onSubmit: onSubmit,
			isSubmitting: isLoading
		}
	});

	if (isAuthenticated) {
		return <Redirect to="/" />;
	}

	if (loadingConfig) {
		return <FullScreenLoading />
	}

	return <>{renderedLoginPage}</>;
};

export default LoginPage;
