/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
// react library for routing
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from 'classnames';
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from 'react-perfect-scrollbar';
// reactstrap components
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav } from 'reactstrap';
import { IRouteProps } from './../types';
import { locationService } from 'app/services/LocationService';
import { useAppDispatch, useAppSelector } from 'app/store';
import { SidebarMenu } from 'app/types/navigation';
import { usePermission } from 'app/hooks/usePermission';
import { projectApi, useGetProjectQuery, useReorderPageMutation } from 'app/services/ProjectService';
import { setHandleDown, setHandleUp, setIsEditingPage, setIsReordering } from 'app/reducers/pageSlice';
import { css } from '@emotion/css';
import Restricted from './Restricted';

export interface SidebarLogoProps {
	innerLink?: string;
	// outterLink is for links that will direct the user outside the app
	// it will be rendered as simple <a href="...">...</a> tag
	outterLink?: string;
	// the image src of the logo
	imgSrc: string;
	// the alt for the img
	imgAlt: string;
}

export interface SidebarProps extends PropsWithChildren<any> {
	// function used to make sidenav mini or normal
	toggleSidenav: () => void;
	// prop to know if the sidenav is mini or normal
	sidenavOpen?: boolean;
	// links that will be displayed inside the component
	routes?: IRouteProps[];
	// logo
	logo?: SidebarLogoProps;
	// rtl active, this will make the sidebar to stay on the right side
	rtlActive?: boolean;
}

interface CollapseState {
	[key: string]: boolean;
}

const Sidebar: React.FC<SidebarProps> = (props) => {
	const location = locationService.getLocation();
	const menus = useAppSelector(state => state.page.menus);
	const isReordering = useAppSelector(state => state.page.isReordering)
    const { allowed: isAllowedTo } = usePermission();
	const isEditingPage = useAppSelector(state => state.page.isEditingPage)
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.js - route /admin/regular-forms
	const getCollapseInitialState = (routes: IRouteProps[]): boolean => {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
				return true;
			} else if (window.location.href.indexOf(routes[i].path || '/') !== -1) {
				return true;
			}
		}
		return false;
	};

	// this creates the intial state of this component based on the collapse routes
	// that it gets through this.props.routes
	const getCollapseStates = (routes: SidebarMenu[]): any => {
		let initialState: CollapseState = {};
		if (routes) {
			routes.map((prop: SidebarMenu) => {
				if (prop.collapse) {
					initialState = {
						[prop.state]: getCollapseInitialState(prop.views),
						...getCollapseStates(prop.views),
						...initialState,
					};
				}
				return null;
			});
		}
		return initialState;
	};

	const [collapseState, setCollapseState] = useState(getCollapseStates(menus));

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName: string) => {
		return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
	};
	// makes the sidenav normal on hover (actually when mouse enters on it)
	const onMouseEnterSidenav = () => {
		if (!document.body.classList.contains('g-sidenav-pinned')) {
			document.body.classList.add('g-sidenav-show');
		}
	};
	// makes the sidenav mini on hover (actually when mouse leaves from it)
	const onMouseLeaveSidenav = () => {
		if (!document.body.classList.contains('g-sidenav-pinned')) {
			document.body.classList.remove('g-sidenav-show');
		}
	};

	// this is used on mobile devices, when a user navigates
	// the sidebar will autoclose
	const closeSidenav = () => {
		if (window.innerWidth < 1200) {
			props.toggleSidenav();
		}
		// clear the query on menu click
		locationService.partial({}, true)
	};

	const projectId = useAppSelector(state => state.application.ids)

	const {data: projectData} = useGetProjectQuery(projectId[0])

	const pageOrders = useMemo(() => {  // function to define page order value in order to hit API
		if(menus && menus.length > 0){
			const sortedPages = menus.slice().sort((a,b):any => {
				if(a.pageOrder && b.pageOrder) return a.pageOrder - b.pageOrder
				return null
			  })

			return sortedPages.map(page => {
				return {
					pageId : page.id,
					pageOrder: page.pageOrder
				}
			})
		}
		return []
	},[menus])

	const [reorderPage] = useReorderPageMutation()

	const dispatch = useAppDispatch()

	const handleUp = useCallback((key) => {   //function to move up page order in sidebar menu

		dispatch(setHandleUp({menus, key}))

	},[dispatch,menus])

	const handleDown = useCallback((key) => {   //function to move down page order in sidebar menu

		dispatch(setHandleDown({menus, key}))
	},[dispatch, menus])

	const handleReorder = useCallback(() => { //function to execute reorder page
		dispatch(setIsReordering(false))
	
		const id = projectId[0]

		reorderPage({ id, body:pageOrders }).unwrap()

	},[pageOrders, projectId, reorderPage, dispatch])

	const handleCancel = useCallback(() => {
		dispatch(setIsReordering(false))
		dispatch(projectApi.util.invalidateTags([{ type: 'Project', id: 'LIST' }]));
	},[dispatch])

	const lastOrder = useMemo(() => { // value to memoize number of last order page
		return menus.length
	},[menus])

	const arrowStyle = css`
	.arrow {
		opacity:0.5;
		cursor:pointer;
	}
	.arrow:hover{
		opacity:1;
	}
	`
	// this function creates the links and collapses that appear in the sidebar (left menu)
	const createLinks = (menus: SidebarMenu[] | undefined) => {
		return (
			menus &&
			menus.map((menu, key) => {
				if (menu.invisible && !isAllowedTo("UPDATE DASHBOARD") ) return null;
				if (menu.invisible && !isReordering) return null
				if (menu.redirect) return null;

                // check permission if permission has value than sync with logged in user permissions
                if (menu.permission && menu.permission !== null) {
                    if (!isAllowedTo(menu.permission)) return null;
                }

				if (menu.collapse && menu.state && menu.views) {
					var st: CollapseState = {};
					st[menu.state] = !collapseState[menu.state];
					return (
						<NavItem key={key}>
							<NavLink
								href={`#${menu.name}`}
								data-toggle="collapse"
								aria-expanded={collapseState[menu.state]}
								className={classnames({
									active: getCollapseInitialState(menu.views),
								}, 'text-wrap')}
								onClick={(e) => {
									e.preventDefault();
									setCollapseState(st);
								}}
							>
								{menu.icon ? (
									<>
										<i className={menu.icon} />
										<span className="nav-link-text">{menu.name}</span>
									</>
								) : menu.miniName ? (
									<>
										<span className="sidenav-mini-icon"> {menu.miniName} </span>
										<span className="sidenav-normal"> {menu.name} </span>
									</>
								) : null}
							</NavLink>
							<Collapse isOpen={collapseState[menu.state]}>
								<Nav className="nav-sm flex-column">{createLinks(menu.views)}</Nav>
							</Collapse>
						</NavItem>
					);
				}

				if (menu.path) {
					return (
						<NavItem className={activeRoute(menu.path)} key={key}>
							<div className='d-flex justify-content-between' >
							<NavLink to={menu.path} activeClassName="" onClick={closeSidenav} tag={NavLinkRRD} style={{opacity:menu.invisible && isAllowedTo("UPDATE DASHBOARD") ? 0.5 : 1}} className="text-wrap pr-1">
								{menu.icon !== undefined ? (
									<>
										<i className={menu.icon} />
										<span className="nav-link-text">{menu.name}</span>
									</>
								) : menu.miniName !== undefined ? (
									<>
										<span className="sidenav-mini-icon"> {menu.miniName} </span>
										<span className="sidenav-normal"> {menu.name} </span>
									</>
								) : (
									menu.name
								)}
							</NavLink>
								<Restricted to={"UPDATE DASHBOARD"}>
									{isReordering ? (
										<div className={`mr-3 d-flex align-items-center flex-column justify-content-center ${arrowStyle} `}>
											{menu.pageOrder !== 1 ? <i onClick={() => handleUp(key)} className="pi pi-sort-up arrow" /> : null}
											{menu.pageOrder !== lastOrder ? <i onClick={() => handleDown(key)} className="pi pi-sort-down arrow" /> : null}
										</div>
									) : null}
									
								</Restricted>
							</div>
						</NavItem>
					);
				}
				return null;
			})
		);
	}

	const { logo } = props;
	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link,
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: '_blank',
		};
	}

	const dynamicLogo = React.useMemo(() => {
		try {
			const settings = projectData && projectData.settings ? JSON.parse(projectData.settings) : null;

			return settings ? settings.brandLogoUrl : null;
		} catch {
			return null;
		}
	}, [projectData]);

	

	const scrollBarInner = (
		<div className="scrollbar-inner">
			<div className="sidenav-header d-flex align-items-center">
				{logo ? (
					<NavbarBrand {...navbarBrandProps}>
						<img alt={logo.imgAlt} className="navbar-brand-img" src={dynamicLogo !== null ? dynamicLogo : logo.imgSrc } />
					</NavbarBrand>
				) : null}
				<div className="ml-auto">
					<div
						className={classnames('sidenav-toggler d-none d-xl-block', {
							active: props.sidenavOpen,
						})}
						onClick={props.toggleSidenav}
					>
						<div className="sidenav-toggler-inner">
							<i className="sidenav-toggler-line" />
							<i className="sidenav-toggler-line" />
							<i className="sidenav-toggler-line" />
						</div>
					</div>
				</div>
			</div>
			<div className="navbar-inner">
				<Collapse navbar isOpen={true}>
					<Nav navbar>
						{createLinks(menus)}
						{props.sidenavOpen && <Restricted to={"UPDATE DASHBOARD"}>
							{
								isEditingPage ? null : (
									<>
										<NavItem style={{padding:'15px'}}>
											<NavLink to={`/page-management/add-menuPage/${projectId[0]}`} onClick={() => dispatch(setIsEditingPage(!isEditingPage)) } tag={NavLinkRRD} style={{border:'1px dashed gray', width:'100%', borderRadius:'6px'}}>
												<i className="mdi mdi-plus"></i><strong>Add Menu Page</strong>
											</NavLink>
										</NavItem>
									</>
								)
							}
							{
								isReordering ? (
									<>
									<div className="d-flex justify-content-center save-reorder" style={{padding:'0 15px'}}>
										<button onClick={handleCancel} style={{width:'100%', marginRight:'0'}} className="btn btn-cancel" type='button'>
											<i style={{ marginRight: '0.5rem', transform:'translateY(10%)'  }} className="fas fa-times"></i>Cancel
										</button>
									</div>
									<div className="d-flex justify-content-center save-reorder" style={{padding:'0 15px', marginTop:'10px'}}>
										<button onClick={handleReorder} style={{width:'100%'}} className="btn activated" type='button'>
											<i style={{ marginRight: '0.5rem' }} className="far fa-save"></i>Save Reorder
										</button>
									</div>
									</>
								) : (
									<div className="d-flex justify-content-center" style={{padding:'0 15px'}}>
										<button onClick={() => dispatch(setIsReordering(true))} style={{width:'100%'}} className="btn activated" type='button'>
											<i style={{ marginRight: '0.5rem', transform:'translateY(10%)' }} className="fas fa-arrows-alt-v"></i>Reorder Page
										</button>
									</div>
								)
							}
							
						</Restricted>}
					</Nav>
				</Collapse>
			</div>
		</div>
	);
	return (
		<Navbar
			className={
				'sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ' +
				(props.rtlActive ? '' : 'fixed-left')
			}
			onMouseEnter={onMouseEnterSidenav}
			onMouseLeave={onMouseLeaveSidenav}
		>
			{navigator.platform.indexOf('Win') > -1 ? (
				<PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
			) : (
				scrollBarInner
			)}
		</Navbar>
	);
};

Sidebar.defaultProps = {
	routes: [{}],
	toggleSidenav: () => {},
	sidenavOpen: false,
	rtlActive: false,
};

export default Sidebar;
