import React from 'react';
import classnames from 'classnames';
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, FormFeedback, InputProps } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';

interface InputTextGroupProps extends InputProps {
	alternative?: boolean;
	error?: string | null;
	icon: string;
	invalid?: boolean;
	valid?: boolean;
	placeholder?: string;
	type?: InputType | undefined;
}

const InputTextGroup: React.FC<InputTextGroupProps> = (props) => {
	const { alternative, icon, invalid, error, placeholder, type, valid, ...rest } = props;
	return (
		<FormGroup
			className={classnames({
				'has-danger': invalid,
			})}
		>
			<InputGroup
				className={classnames('input-group-merge', {
					'input-group-alternative': alternative,
					'is-invalid': invalid,
				})}
			>
				<InputGroupAddon addonType="prepend">
					<InputGroupText
						className={classnames({
							'text-danger': invalid,
						})}
					>
						<i className={icon} />
					</InputGroupText>
				</InputGroupAddon>
				<Input placeholder={placeholder} type={type} {...rest} />
			</InputGroup>
			{invalid && <FormFeedback>{error}</FormFeedback>}
		</FormGroup>
	);
};

InputTextGroup.defaultProps = {
	alternative: true,
	error: null,
	icon: 'ni ni-email-83',
	invalid: false,
	valid: false,
	placeholder: '',
	type: 'text',
};

export default InputTextGroup;
