import { Page } from 'app/types';
import { PrimeSteps } from 'components';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { Button } from 'primereact/button';
import AddMenuConfirmation from './AddMenuConfirmation';
import AddMenuInfo from './AddMenuInfo';
import { useCreatePageMutation, useDeletePageMutation, useEditPageMutation } from 'app/services/PageService';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from 'app/store';
import { setIsEditingPage } from 'app/reducers/pageSlice';
import { locationService } from 'app/services/LocationService';
type Props = {
	projectId: string;
	currentPageData: Page;
	pageId?: string;
};
const AddNewMenu: React.FC<Props> = (props: Props) => {
	const { projectId, currentPageData, pageId } = props;

	const pages = useAppSelector((state) => state.page.pages);

	const defaultPage = useAppSelector((state) => state.page.defaultPage)

	const maxPageOrder = useMemo(() => {
		if (pages && pages.length > 0) {
			return Math.max(...pages?.map((p) => p.pageOrder!));
		} else return 0;
	}, [pages]);

	const formSteps = [{ label: 'PAGE INFORMATION' }, { label: 'CONFIRMATION' }];

	const [activeStep, setActiveStep] = useState(0);

	const isLastStep = activeStep === formSteps.length - 1;

	const nextStep = () => {
		if (!isLastStep) {
			setActiveStep((currentStep) => currentStep + 1);
		}
	};

	const prevStep = () => {
		if (activeStep > 0) {
			setActiveStep((currentStep) => currentStep - 1);
		}
	};

	const renderForm = useCallback((step: number, formik: FormikProps<Page>) => {
		switch (step) {
			case 1:
				return <AddMenuConfirmation formik={formik} />;
			default:
				return <AddMenuInfo pageId={pageId} formik={formik} />;
		}
	}, [pageId]);

	const isEditingPage = useAppSelector((state) => state.page.isEditingPage);

	const dispatch = useAppDispatch();

	const initialValues = useMemo<Page>(() => {
		return {
			component: currentPageData ? currentPageData.component : '',
			description: currentPageData ? currentPageData.description : '',
			iconName: currentPageData ? currentPageData.iconName : 'mdi mdi-view-dashboard',
			id: currentPageData ? currentPageData.id : undefined,
			miniName: currentPageData ? currentPageData.miniName : '',
			name: currentPageData ? currentPageData.name : '',
			pageOrder: currentPageData ? currentPageData.pageOrder : 0,
			pageType: currentPageData ? currentPageData.pageType : 'DASHBOARD',
			path: currentPageData ? currentPageData.path : '',
			permission: currentPageData ? currentPageData.permission : undefined,
			projectId: currentPageData ? currentPageData.projectId : projectId,
			title: currentPageData ? currentPageData.title : '',
			visibleInMenu: currentPageData ? currentPageData.visibleInMenu : true,
			componentConfig: '{}'
		};
	}, [projectId, currentPageData]);
	const valueToSend = useCallback(
		(values) => {
			const path = values.name.split(' ').join('-').toLowerCase();
			return {
				...values,
				path: path,
				pageOrder: currentPageData ? currentPageData.pageOrder : maxPageOrder + 1,
			};
		},
		[maxPageOrder, currentPageData],
	);

	const [createPage] = useCreatePageMutation();

	const [editPage] = useEditPageMutation();

	const [deletePage] = useDeletePageMutation();

	const _handleSubmit = (values: Page, formik: FormikHelpers<Page>) => {
		const allValue:Page = valueToSend(values);
		if (isLastStep) {
			if (pageId) {
				editPage({ pageId, body: allValue })
					.unwrap()
					.then(() => {
						history.goBack()
					})
					.then(() => dispatch(setIsEditingPage(!isEditingPage)))
					.catch(() =>
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: 'Something went wrong!',
						}),
					);
			} else {
				createPage(allValue)
					.unwrap()
					.then(() => {
						history.push({pathname:defaultPage})
					})
					.then(() => dispatch(setIsEditingPage(!isEditingPage)))
					.catch(() =>
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: 'Something went wrong!',
						}),
					);
			}
		} else {
			nextStep();
			formik.setTouched({});
			formik.setSubmitting(false);
		}
	};
	const history = locationService.getHistory();

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Please enter this field'),
		title: Yup.string().required('Please enter this field'),
		description: Yup.string().required('Please enter this field'),
		miniName: Yup.string().required('Please enter this field'),
		component: Yup.string().required('Please enter this field'),
	});

	const handleDelete = () => {
		Swal.fire({
			title: `Are you sure what to delete ${currentPageData.name} ?`,
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
		}).then((result) => {
			if (result.isConfirmed) {
				deletePage(pageId)
					.unwrap()
					.then(() => {
						history.push({pathname:defaultPage})
					})
					.catch(() =>
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: 'Something went wrong!',
						})
					);
				Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
			}
		});
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={_handleSubmit}
			validationSchema={validationSchema}
			enableReinitialize={true}
		>
			{(formik: FormikProps<Page>) => (
				<Form className="p-3" onSubmit={formik.handleSubmit}>
					<Row className="mt--1 mb-3">
						<Col>
							<PrimeSteps model={formSteps} activeIndex={activeStep} />
						</Col>
					</Row>
					{renderForm(activeStep, formik)}
					<Row className="d-flex justify-content-end pr-3">
						{pageId && activeStep === 0 ? (
							<Button
								type="button"
								className="p-button-outlined mr-2"
								style={{ color: 'red' }}
								onClick={handleDelete}
							>
								<i className="pi pi-trash mr-2"></i>Delete this Page
							</Button>
						) : null}
						<Button
							type="button"
							label="Cancel"
							onClick={async () => {
								await history.goBack();
								formik.resetForm();
								dispatch(setIsEditingPage(!isEditingPage));
							}}
							className="mr-2 p-button-outlined"
						/>
						{activeStep > 0 && (
							<Button
								type="button"
								label="Previous"
								onClick={prevStep}
								className="mr-2 p-button-outlined"
							/>
						)}
						<Button label={isLastStep ? 'Apply' : 'Next'} type="submit" />
					</Row>
				</Form>
			)}
		</Formik>
	);
};
export default AddNewMenu;
