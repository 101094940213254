import React from 'react';

type Props = {
    status?: string;
}
const CameraStatus = ({ status }: Props) => {
    switch (status?.toLowerCase()) {
        case 'connected':
            return <span className="badge badge-pill badge-success">{status}</span>
        case 'running':
            return <span className="badge badge-pill badge-warning">{status}</span>
        default:
            return <span className="badge badge-pill badge-danger">{status}</span>
    }
}
export default CameraStatus
