import { useGetPanelEndPointApisQuery } from 'app/services/PanelService';
import { createPanel } from 'app/types';
import { FormikProps } from 'formik';
import React, { useMemo } from 'react';
import Select from 'react-select';
type Props = {
	onChange: (e) => any;
	value: any;
	className?: string;
	id?: string;
	placeholder?: string;
	formik: FormikProps<createPanel>;
};
const SelectEndpointApi = ({ onChange, value, className, id, placeholder, formik }: Props) => {
	const { data: endPointApi } = useGetPanelEndPointApisQuery({});
	const panelComponentOptions = useMemo(
		() =>
			endPointApi?.map((panelComponent) => {
				let newObj = {
					label: panelComponent.name,
					value: panelComponent.name,
					id: panelComponent.id,
				};
				return newObj;
			}),
		[endPointApi],
	);
	const defaultValue = (options, value) => {
		return options ? options.find((option) => option.value === value) : '';
	};
	const customStyle = {
		control: (styles) => ({
			...styles,
			border: '1px solid #dee2e6',
			color: '#8898aa',
			height: 'calc(1.5em + 1.25rem + 5px)',
		}),
		indicatorSeparator: (styles) => ({ ...styles, opacity: '0' }),
	};
	return (
		<div className={className}>
			<Select
				value={defaultValue(panelComponentOptions, value)}
				onChange={(value) => {
					onChange(value);
					formik.setFieldValue(`endPointParams`, '');
				}}
				options={panelComponentOptions}
				id={id}
				placeholder={placeholder}
				styles={customStyle}
			/>
		</div>
	);
};
export default SelectEndpointApi;
