import { IDailyTask } from './../types';
import { useToasts } from 'app/components/Toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { useDeleteDailyTaskMutation } from '../services';

export function useDeleteDailyTask() {
    const [deleteMutation, result] = useDeleteDailyTaskMutation();
    const toast = useToasts();

    const deleteDailyTask = (task: IDailyTask) => {
        confirmDialog({
            message: `Deleted data can not be restored.`,
            header: `Delete task ${task.taskName}?`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: async () =>
                await deleteMutation(task.id)
                    .unwrap()
                    .then(() => {
                        toast.toastSuccess('Success', `Task deleted.`);
                    })
                    .catch((error) => {
                        toast.toastError(error?.data?.error, error?.data?.message);
                    }),
        });
    };

    return {
        result,
        deleteDailyTask,
    };
}
