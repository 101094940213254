import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';

export interface RowStatusProps {
	label: string;
	value: string | React.ReactNode;
	stripped?: boolean;
}

const RowStatus = ({ label, value, stripped }: RowStatusProps) => {
	return (
		<div className={classNames(styles.row, { 'stripped': stripped })}>
			<div className={styles.col}>{label}</div>
			<div className={styles.col}>{value}</div>
		</div>
	)
}

const styles = {
	row: css`
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		&.stripped {
			background-color: #F7FAFC;
		}
	`,

	col: css`
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding: 10px 20px;

		text-transform: capitalize;
	`,
}

export default RowStatus
