export const iconList = [
    {label:'CCTV', value:'mdi mdi-cctv'},
    {label:'Door Open', value:'mdi mdi-door-open'},
    {label:'Snow Flake', value:'mdi mdi-snowflake'},
    {label:'Flash', value:'mdi mdi-flash'},
    {label:'Lightning', value:'mdi mdi-lightning-bolt'},
    {label:'Server', value:'mdi mdi-server'},
    {label:'Tools', value:'mdi mdi-tools'},
    {label:'Alert', value:'mdi mdi-alert'},
    {label:'Dashboard', value:'mdi mdi-view-dashboard'},
    {label:'Fire Extinguisher', value:'mdi mdi-fire-extinguisher'},
    {label:'Radio Tower', value:'mdi mdi-radio-tower'},
    {label:'Account Group', value:'mdi mdi-account-group'},
    {label:'Battery', value:'mdi mdi-battery-50'},
    {label:'Bell', value:'mdi mdi-bell-alert'},
    {label:'Engine', value:'mdi mdi-engine'},
    {label:'Weather Windy', value:'mdi mdi-weather-windy'},
    {label:'Slot Machine', value:'mdi mdi-slot-machine'},
    {label:'Pin', value:'mdi mdi-map-marker'},
    {label:'Image', value:'mdi mdi-file-image'},
    {label:'Calendar', value:'mdi mdi-calendar'},
    {label:'Clock', value:'mdi mdi-clock'},
    {label:'Cog', value:'mdi mdi-cog'},
    {label:'File', value:'mdi mdi-file'},
    {label:'Folder', value:'mdi mdi-folder'},
    {label:'Store', value:'mdi mdi-store'},
    {label:'Water', value:'mdi mdi-water'},
    {label:'Web', value:'mdi mdi-web'},
    {label:'Wifi', value:'mdi mdi-wifi'}

]
export const pageTypeList = [
    {label: 'Resource Page', value:'RESOURCE'},
    {label: 'Dashboard Page', value:'DASHBOARD'},
]