import { createPanel } from 'app/types';
import { FormOverview } from 'components';
import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import '../style.css'
type Props = {
	formik: FormikProps<createPanel>;
};
const AddPanelConfirmation: FC<Props> = (props: Props) => {
	const { formik } = props;

	return (
		<Row>
			<Col>
				<Row className="mt-4">
					<Col lg="6">
						<Card>
							<CardBody>
								<h3 className="mb-2">Information</h3>
								<FormOverview label="Title" value={formik.values.title} stripped />
								<FormOverview label="Description" value={formik.values.description} />
								<FormOverview
									label="Panel Component Name"
									value={formik.values.panelComponentName}
									stripped
								/>
								<FormOverview isJSON={true} label="Component Config" value={formik.values.componentConfig} />
							</CardBody>
						</Card>
					</Col>
					<Col lg="6">
						<Card>
							<CardBody>
								<FormOverview label="EndPoint APIs" value={formik.values.endPointApis} stripped />
								{formik.values.endPointParams &&
									formik.values.endPointParams.map((obj, i) => (
										<FormOverview
											key={obj.id}
											label={obj.paramName}
											value={obj.paramValue}
											stripped={i % 2 === 0 ? false : true}
											isJSON={obj.paramType === "REQUEST_BODY" ? true : false}
										/>
									))}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
export default AddPanelConfirmation;
