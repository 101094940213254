import { useToasts } from 'app/components/Toast';
import { selectDateTime, updateDateTimeRange } from 'app/reducers/appSlice';
import { useAppDispatch, useAppSelector } from 'app/store';
import { DateRangePicker } from 'components';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ChartSensor from './components/ChartSensor';
import FormSensorConfiguration, { SensorConfigurationForm } from './components/FormSensorConfiguration';
import { useExplorationOptions } from './hooks/useExplorationOptions';
import { useGetDeviceDataMutation } from './services/dataExplorationApi';
import { DeviceDataModel, DeviceDataRequestParams } from './types';
import { locationService } from 'app/services/LocationService';

const defaultInitialValues: SensorConfigurationForm = {
	deviceId: '',
	sensorName: '',
	timeGroupping: '',
	sensorParam: '',
	subparamName: ''
}

const DataExploration: React.FC = () => {
	const dispatch = useAppDispatch();
	const { beginTs, endTs } = useAppSelector(selectDateTime);
	const { intervalOptions } = useExplorationOptions();
	const [getDeviceData, { isLoading }] = useGetDeviceDataMutation();
	const [deviceData, setDeviceData] = useState<DeviceDataModel[]>([]);
	const toast = useToasts();
	const [initialValues, setInitialValues] = useState<SensorConfigurationForm>(defaultInitialValues);
	const [filterParams, setFilterParams] = useState<Partial<DeviceDataRequestParams>>({});

	const fetchDeviceData = useCallback(async (params: Partial<DeviceDataRequestParams>) => {
		try {
			const result = await getDeviceData(params).unwrap();
			setDeviceData(result);
		} catch (error) {
			setDeviceData([]);
			toast.toastError('Failed to load device data.', error?.data?.message);
		}
	}, [toast, getDeviceData]);

	const onFilterDateChange = (start: Moment, end: Moment) => {
		dispatch(updateDateTimeRange({
			beginTs: start.valueOf(),
			endTs: end.valueOf(),
		}));
		if (filterParams.deviceId || filterParams.sensorName || filterParams.sensorParam || filterParams.subparamName || filterParams.timeGroupping) {
			fetchDeviceData({ ...filterParams, beginTs, endTs });
		}
	};

	const onApplyFilter = async (values: SensorConfigurationForm) => {
		setFilterParams(values);
		fetchDeviceData({ ...values, beginTs, endTs });
		const { deviceId, sensorName, sensorParam, subparamName, timeGroupping } = values;

		locationService.partial({
			deviceId: deviceId || undefined,
			sensorName: sensorName || undefined,
			sensorParam: sensorParam || undefined,
			subparamName: subparamName || undefined,
			timeGroupping: timeGroupping || undefined,
		}, true);
	}

	const onReset = () => {
		setDeviceData([]);
		setInitialValues(defaultInitialValues);
		locationService.partial({
			deviceId: undefined,
			sensorName: undefined,
			sensorParam: undefined,
			subparamName: undefined,
			timeGroupping: undefined,
		})
	}

	useEffect(() => {
		const queryParams = locationService.getSearchObject();
		const { deviceId, sensorName, sensorParam, subparamName, timeGroupping }: SensorConfigurationForm = queryParams as any;
		setInitialValues({
			deviceId: deviceId || '',
			sensorName: sensorName || '',
			sensorParam: sensorParam || '',
			subparamName: subparamName || '',
			timeGroupping: timeGroupping || '',
		});

		if (deviceId || sensorName || sensorParam || subparamName || timeGroupping) {
			fetchDeviceData({ deviceId, sensorName, sensorParam, subparamName, timeGroupping, beginTs, endTs });
		}
		// eslint-disable-next-line
	}, [fetchDeviceData]);

	return (
		<>
			<div>
				<Container fluid style={{ marginBottom: 20 }}>
					<Row className="mt-3">
						<Col className='d-flex justify-content-end'>
							<div>
								<DateRangePicker
									onChange={onFilterDateChange}
									startDate={moment(beginTs)}
									endDate={moment(endTs)}
									format="ll"
								/>
							</div>
						</Col>
					</Row>
					<Row className='mt-3'>
						<Col>
							<Card>
								<CardBody>
									<h4 className='text-center mb-3'>Data Exploration Input</h4>
									<FormSensorConfiguration
										intervalOptions={intervalOptions}
										onSubmit={onApplyFilter}
										isLoading={isLoading}
										initialValues={initialValues}
										onReset={onReset}
									/>
									<div className="row">
										<div className="col">
											<ChartSensor data={deviceData} isLoading={isLoading} />
										</div>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}
export default DataExploration
