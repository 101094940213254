import { FormOverview } from 'components'
import { FormikProps } from 'formik'
import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { SiteData } from '../types'

type Props = {
    formik:FormikProps<SiteData>
}

const SiteConfirmation:React.FC<Props> = (props) => {
  const { values } = props.formik
  return (
    <Row>
				<Col>
					<Row className="mt-4">
						<Col lg="6">
							<Card>
								<CardBody>
									<h3 className="mb-2">Information</h3>
									<FormOverview label="Name" value={values.name} stripped />
									<FormOverview label="Contact Number" value={values.region} />
									<FormOverview label="Device ID" value={values.deviceId} />
								</CardBody>
							</Card>
						</Col>
						<Col lg="6">
							<Card>
								<CardBody>
									<h3 className="mb-2">Mapping</h3>
									<FormOverview label="Latitude" value={values.latitude} stripped />
									<FormOverview label="Longitude" value={values.longitude} />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Col>
			</Row>
  )
}

export default SiteConfirmation