import React, { useMemo, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Row, Col } from 'reactstrap';
import RowStatus from 'app/components/RowStatus';
import { defaultUserProfile } from './../../../../assets';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { User, UserInfo, FormProfile } from '../types';
import * as Yup from 'yup';
import { FormikInputText } from 'components';

type Props = {
	user: User;
	onSubmit: (data: FormProfile, formik: FormikHelpers<FormProfile>) => void;
	isLoading?: boolean;
	onCancel: () => void;
}

const FormUpdateProfile = ({ user, isLoading, onSubmit, onCancel }: Props) => {
	const userProfilePicture = user.userProfilePicture;

	const avatar = useMemo(() => userProfilePicture?.actualLink || defaultUserProfile, [userProfilePicture]);
	const imageRef = useRef<HTMLInputElement>(null);
	const [preview, setPreview] = useState(avatar);

	const handleImageChange = (e, setFieldValue) => {
		if (e.target.files.length) {
			let reader: any = new FileReader();
			let file = e.target.files[0];

			reader.onloadend = () => {
				setPreview(reader.result);
				setFieldValue('avatar', file);
			}

			reader.readAsDataURL(file);
		}
	};

	const openImage = () => {
		if (imageRef.current) {
		  imageRef.current.click();
		}
	  }

	const onLoadImageError = (event) => {
		event.target.src = defaultUserProfile;
		event.onerror = null
	}

	const initialValues: FormProfile = {
		id: user.id || 0, // This is user ID, beceuse backend need this not userInfo ID
		name: user.userInfo?.name || '',
		email: user.userInfo?.email || '',
		phoneNumber: user.userInfo?.phoneNumber || '',
		telegramUserId: user.userInfo?.telegramUserId || '',
		avatar: '',
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name field required.'),
		email: Yup.string().required('Email field required.'),
		phoneNumber: Yup.string().nullable(),
		telegramUserId: Yup.string().nullable(),
	})

	return (
		<Formik enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
			{(formik: FormikProps<UserInfo>) => (
				<Row>
					<Col md="2" xs="12" className="d-flex justify-content-center">
						<div className="profilepic">
							<label htmlFor="upload-picture">
							<input type="file" accept="image/*" id="upload-picture" className="d-none" ref={imageRef} onChange={(e) => handleImageChange(e, formik.setFieldValue)} />
							<img alt="avatar" className="profilepic__image" src={preview} width="150" height="150" onError={onLoadImageError} />
							</label>
							<div className="profilepic__content" onClick={openImage}>
							<span className="profilepic__icon"><i className="fas fa-camera"></i></span>
							<span className="profilepic__text">Change Picture</span>
							</div>
						</div>
						<div className="profilepic__hint_content">
							<span className="profilepic__hint_text">Click image to change</span>
						</div>
					</Col>
					<Col md="10" xs="12">
						<Form onSubmit={formik.handleSubmit}>
							<Row>
								<Col md="6" xs="12" className="px-5">
									<RowStatus stripped label='Name' value={(
										<FormikInputText
											row
											id="name"
											name="name"
											placeholder="Full Name"
											required
											className='mb-0'
										/>
									)} />
									<RowStatus label='Email' value={(
										<FormikInputText
											row
											id="email"
											name="email"
											placeholder="Email Address"
											required
											className='mb-0'
										/>
									)} />
									<RowStatus stripped label='Phone Number' value={(
										<FormikInputText
											row
											id="phoneNumber"
											name="phoneNumber"
											placeholder="Phone Number"
											className='mb-0'
										/>
									)} />
									<RowStatus label='Telegram Conversation ID' value={(
										<FormikInputText
											row
											id="telegramUserId"
											name="telegramUserId"
											placeholder="Telegram Conversation ID"
											className='mb-0'
										/>
									)} />
								</Col>
								<Col md="6" xs="12" className="px-5">
									<RowStatus stripped label='Username' value={user?.username || '-'} />
									<RowStatus label='Password' value={'********'} />
								</Col>
							</Row>

							<Row className="mt-3">
								<Col className="text-right px-5">
									<Button
										label="Cancel"
										type="button"
										className="mr-2 p-button-outlined"
										onClick={onCancel}
									/>
									<Button
										label={'Apply'}
										loading={isLoading}
										disabled={isLoading}
										type="submit"
									/>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			)}
		</Formik>
	)
}

export default FormUpdateProfile;
