import { useMemo } from 'react';
import { useGetProductionListQuery } from '../services';
import { IProductionListRequest } from '../types';

export function useProductionList(params: IProductionListRequest = {}, queryOptions = {}) {
    const { data, ...result } = useGetProductionListQuery(params, queryOptions);

    const options = useMemo(() => {
        if (!data) return [];

        return data.map(({ id, name }) => ({ value: id, label: name }));
    }, [data]);

    return {
        data,
        options,
        ...result,
    };
}
