import { FormikInputText, FormikInputTextArea, FormikSelect } from 'components';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button } from 'primereact/button';
import React, { useMemo } from 'react';
import { NullableString } from '../../types';
import { taskValidationSchema } from '../../utils/validation';

export type TTaskForm = {
    id: string;
    dailyTaskId: string;
    name: string;
    desc: NullableString;
    notes: NullableString;
    imageEvidenceUrl: NullableString;
    status: NullableString;
    inputField: NullableString;
    inputValue: NullableString;
    finishTime: NullableString;
};

type Props = {
    dailyTaskId: string;
    title?: string;
    task?: TTaskForm;
    onSubmit: (values: TTaskForm, formikHelpers: FormikHelpers<TTaskForm>) => void | Promise<any>;
    onCancel?: () => void;
    inputFieldOptions?: any[];
    isLoading?: boolean;
};

const FormTask = ({ task, onSubmit, onCancel, inputFieldOptions, dailyTaskId, isLoading }: Props) => {
    const initialValues = useMemo(() => {
        if (!task) {
            return {
                id: '',
                dailyTaskId: dailyTaskId,
                name: '',
                desc: '',
                notes: null,
                imageEvidenceUrl: null,
                status: null,
                inputField: null,
                inputValue: null,
                finishTime: null,
            } as TTaskForm;
        }

        return task;
    }, [task, dailyTaskId]);

    const submitLabel = task ? 'Save Changes' : 'Create Task';

    return (
        <Formik<TTaskForm> initialValues={initialValues} onSubmit={onSubmit} validationSchema={taskValidationSchema}>
            {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                    <FormikInputText label="Name" name="name" required />
                    <FormikInputTextArea label="Description" name="desc" rows={3} />
                    <FormikSelect editable showClear label="Input to Field" name="inputField" options={inputFieldOptions} />

                    <div className='d-flex align-items-center justify-content-end'>
                        <Button type="button" label="Cancel" icon="pi pi-times" className="p-button-text p-button-sm" onClick={onCancel} />
                        <Button type="submit" label={submitLabel} icon="pi pi-save" className="p-button-sm" autoFocus disabled={isLoading || isSubmitting} loading={isLoading} />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default FormTask;
