import { css } from '@emotion/css';
import LoadingPage from 'app/components/LoadingPage';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { ThresholdsMode } from 'app/types';
import { PanelProps } from 'app/types/Panel';
import { calculateFontSize, getColor } from 'app/utils';
import { getValueMappingResult } from 'app/utils/valueMappings';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';

interface TimestampOptions {
	show?: boolean;
	format?: string;
	isCalendar?: boolean;
	calendarFormats?: moment.CalendarSpec;
}
export interface TritronikTextPanelOptions {
	timestamp?: TimestampOptions;
}

export interface TritronikTextPanelProps extends PanelProps<TritronikTextPanelOptions> {
}

const TritronikTextPanel = (props: TritronikTextPanelProps) => {
	const { width, height, fieldConfig, panel, refreshInterval, options } = props;

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const { valueFormatted, timestamp } = useMemo(() => {
		const decimals = fieldConfig?.defaults?.decimals || 0;
		const unit = fieldConfig?.defaults?.unit || 'none';
		if (data && data.responseDataValue && data.responseDataValue.length > 0 && data.responseDataValue[0].datapoints) {

			let value = 0;
			let timestamp = 0;
			if (data.responseDataValue[0].datapoints && data.responseDataValue[0]?.datapoints.length > 0) {
				value = data.responseDataValue[0]?.datapoints[0].value;
				timestamp = data.responseDataValue[0]?.datapoints[0].time;
			}
			return {
				valueFormatted: getValueFormat(unit)(value, decimals),
				timestamp: timestamp
			}
		}
		return { valueFormatted: {text:'N/A'}, timestamp: null };

	}, [data, fieldConfig]);

	const displayValue = useMemo(() => {
		const mappings = fieldConfig?.defaults?.mappings || [];
		const mappingResult = getValueMappingResult(mappings, valueFormatted.text);

		if (mappingResult && mappingResult.text) {
			return mappingResult.text;
		}
		return formattedValueToString(valueFormatted);
	}, [fieldConfig, valueFormatted]);

	const getTimestampFormat = useCallback((ts: any) => {
		const opts = options.timestamp;

		if (opts?.isCalendar) {
			if (opts.calendarFormats) {
				return moment(ts).calendar(undefined, opts.calendarFormats);
			}
			return moment(ts).calendar();
		}

		return moment(ts).format(opts?.format);
	}, [options.timestamp]);

	const showTimestamp = options.timestamp && options.timestamp.show === true;

	const timestampString = showTimestamp ? `(${getTimestampFormat(timestamp)})` : '';

	const fontSize = calculateFontSize(displayValue, width * 0.5, height * 0.5, 1);
	const fontSizeTime = calculateFontSize(timestampString, width * 0.3, height * 0.3, 1);

	const thresholds =
			fieldConfig?.defaults?.thresholds || TritronikTextPanel.defaultProps.fieldConfig.defaults.thresholds;

	const steps = thresholds.steps;

	const textColor = getColor(parseFloat(valueFormatted.text), steps);

	if (isLoading) {
		return <LoadingPage />;
	}

	const container = css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		overflow: hidden;
	`;

	const textStyle = css`
		font-size: ${fontSize}px;
		overflow: hidden !important;
		color: ${textColor};
	`;
	const textTimeStyle = css`
		font-size: ${fontSizeTime}px;
		overflow: hidden !important;
	`;

	return (
		<div className={container}>
			<h1 className={textStyle}>{displayValue}</h1>
			{showTimestamp && <span className={textTimeStyle}>{timestampString}</span>}
		</div>
	);
};

TritronikTextPanel.defaultProps = {
	fieldConfig: {
		defaults: {
			min: 0,
			max: 100,
			thresholds: {
				mode: ThresholdsMode.Absolute,
				steps: [
					{ value: -Infinity, color: 'green' },
					{ value: 80, color: 'red' },
				],
			},
		},
	},
};

export default TritronikTextPanel;
