import { baseQuery } from './../../services/BaseApi';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ChangePasswordRequest, Identifier, LoginRequest, LoginResponse, User, UserInfo, UserProfilePicture, UploadProfilePicture } from './types';

export const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery: baseQuery,
	tagTypes: ['Profile'],
	endpoints: (builder) => ({
		login: builder.mutation<LoginResponse, LoginRequest>({
			query: (credentials) => ({
				url: '/Auth/login',
				method: 'POST',
				body: credentials
			})
		}),
		getProfile: builder.query<User, void>({
			query: () => ({
				url: '/user/current'
			}),
			providesTags: ['Profile']
		}),
		updateUserInfo: builder.mutation<User, UserInfo>({
			query: ({ id, ...body }) => ({
				url: `/user/user-info/${id}`,
				body: body,
				method: 'PUT',
			}),
			async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(authApi.endpoints.getProfile.initiate(undefined, { forceRefetch: true }));
				} catch { }
			},
		}),
		changePassword: builder.mutation<User, Identifier & ChangePasswordRequest>({
			query: ({ id, ...body }) => ({
				url: `/user/${id}/password`,
				body: body,
				method: 'PUT',
			}),
		}),
		updateProfilePicture: builder.mutation<UserProfilePicture, UploadProfilePicture>({
			query: ({ userId, body }) => ({
				url: `/user-profile-picture/upload`,
				method: 'POST',
				body: body,
				params: { userId }
			}),
			invalidatesTags: ['Profile']
		})
	})
});

export const {
	useLoginMutation,
	useGetProfileQuery,
	useUpdateUserInfoMutation,
	useChangePasswordMutation,
	useUpdateProfilePictureMutation,
} = authApi;
