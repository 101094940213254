export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function randomString(length) {
    return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
}

export function arrayChunk(arr: any[], n: number) {
    const array: any[] = arr.slice();
    const chunks: any[] = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
};
