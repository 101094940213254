import { Page } from 'app/types';
import { iconList, pageTypeList } from 'app/utils/List';
import { FormikCheckbox, FormikInputText, FormikInputTextArea, FormikSelect } from 'components';
import { FormikProps } from 'formik';
import React from 'react';
import { Col, Row } from 'reactstrap';
type Props = {
	formik: FormikProps<Page>;
	pageId?: string
};
const AddMenuInfo: React.FC<Props> = (props: Props) => {
	const { formik, pageId } = props;
	return (
		<Row>
			<Col lg="6" sm="12">
				<FormikInputText row name="name" label="Page Name" placeholder="Name"  required />
				<FormikInputText row name="title" label="Page Title" placeholder="Title" required />
				<FormikInputTextArea row name="description" label="Description" placeholder="Description" required />
				{!pageId && (<FormikSelect row name="pageType" label="Page Type" options={pageTypeList} optionDisabled="Resource Page" />)}
			</Col>
			<Col lg="6" sm="12">
				<FormikSelect row name="iconName" label="icon Name" options={iconList} hasIcon={formik.values.iconName} />
				<FormikInputText row name="miniName" label="Mini Name" placeholder="Mini Name" required />
				<FormikInputText row name="component" label="Component" placeholder="Component" />
				<FormikCheckbox row name='visibleInMenu' label="Visible in Menu" />
			</Col>
		</Row>
	);
};
export default AddMenuInfo;