import { css } from '@emotion/css';
import { PanelProps } from 'app/types/Panel';
import { calculateFontSize, container, getColor, wrapper } from 'app/utils';
import React from 'react';
import classNames from 'classnames';

import { getValueFormat } from 'app/valueFormats';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import LoadingPage from 'app/components/LoadingPage';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
export interface IData {
	number: number | null;
	unit: string;
	color: string;
}

export interface IAverage {
	percentage: number;
	value: number;
	month: number;
}

export const averageStyle = css`
	select {
		position: absolute;
		left: 0;
		opacity: 0;
	}

	&:hover {
		* {
			color: #007bff;
		}
	}
`;

export interface Props extends PanelProps {}

const TritronikConsumptionPanel = (props: Props) => {
	const { width, height, panel, fieldConfig, refreshInterval } = props;

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const [selectedAvg, setSelectedAvg] = React.useState<IAverage | null>(null);

	const mtd = React.useMemo((): IData | undefined => {
		if (data && data.responseDataValue && data.responseDataValue.length > 0) {
			const mtdData = data.responseDataValue.find((d) => d.timeSubject === 'mtd')?.average as number;

			const mtdFormattedValue = getValueFormat(fieldConfig.defaults.unit)(mtdData);
			return {
				number: Number(mtdFormattedValue.text),
				unit: mtdFormattedValue.suffix || '',
				color: getColor(mtdData, fieldConfig.defaults.thresholds?.steps),
			};
		}

		return undefined;
	}, [data, fieldConfig]);

	const avgs = React.useMemo((): IAverage[] | undefined => {
		if (data && data.responseDataValue && Array.isArray(data.responseDataValue) && data.responseDataValue.length > 0) {
			const arrMonth = data.responseDataValue.map<number>((d) => parseInt(d.month));
			const arrAverage = data.responseDataValue.map((d) => d.average);

			const averages = data.responseDataValue
				.map<IAverage>((_, index) => {
					let percentage = (100 * (arrAverage[0] - arrAverage[index])) / arrAverage[index];
					percentage = Math.floor(percentage * 10) / 10;
					return { value: arrAverage[index], month: arrMonth[index], percentage };
				})
				.slice(1)
				.filter((avg) => avg.percentage !== Infinity);

			return averages;
		}

		return undefined;
	}, [data]);

	React.useEffect(() => {
		if (avgs && avgs.length > 0) {
			setSelectedAvg(avgs[0]);
		}
	}, [avgs]);

	const limitMinimumFontSize = (fontSize: number, minimumSize: number) =>
		fontSize < minimumSize ? minimumSize : fontSize;

	let secondaryFontSize = calculateFontSize('X mo avgXXXX XXX', width / 2, 24, 1);
	let primaryFontSize = calculateFontSize('XXXX XXX', width, height - 30 - secondaryFontSize, 1);

	primaryFontSize = limitMinimumFontSize(primaryFontSize, 16);
	secondaryFontSize = limitMinimumFontSize(secondaryFontSize, 14);

	const spacing = css`
		margin: 8px 8px;
	`;
	const primary = css`
		font-size: ${primaryFontSize}px;
	`;
	const secondary = css`
		h6,
		select,
		.material-icons {
			font-size: ${secondaryFontSize}px;
			color: #607d8b;
		}
	`;

	const renderPrimaryValue = React.useCallback(
		(data: IData | undefined) => {
			if (!data) return null;
			return (
				<>
					<h6 style={{ fontSize: secondaryFontSize, color: '#607d8b' }}>
						{new Date().toLocaleString('default', { month: 'long' })}
					</h6>
					<h1 className={`${primary} ${spacing}`} style={{ color: data.color }}>
						{data.number}
						<span>{data.unit}</span>
					</h1>
				</>
			);
		},
		[spacing, primary, secondaryFontSize],
	);

	const onChangeAvg = React.useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
		const avgSelected = JSON.parse(event.target.value) as IAverage;
		setSelectedAvg(avgSelected);
	}, []);

	const renderAverageValue = React.useCallback(
		(averages: IAverage[] | undefined) => {
			if (!averages) return null;

			const avgFormattedValue = getValueFormat(fieldConfig.defaults.unit)(selectedAvg?.value || 0);

			const avg: IData = {
				number: Number(avgFormattedValue.text),
				color: 'black',
				unit: avgFormattedValue.suffix || '',
			};

			const icon =
				selectedAvg && selectedAvg?.percentage !== 0 ? (
					<i
						className={classNames('mdi mr-2', {
							'mdi-arrow-up': selectedAvg.percentage > 0,
							'mdi-arrow-down': selectedAvg?.percentage <= 0,
						})}
						style={{ zoom: 0.75 }}
					></i>
				) : null;

			return (
				<div className={`${container} ${secondary}`} style={{ width: 'auto', position: 'relative' }}>
					<div className={`${averageStyle} ${spacing}`}>
						<select onChange={onChangeAvg}>
							{averages.map((avg, index) => (
								<option value={JSON.stringify(avg)} key={index}>
									{avg.month} month
								</option>
							))}
						</select>
						<h6>
							{selectedAvg?.month}
							<span className="label-avg"> mo avg</span>
							&nbsp;&nbsp;{avg.number}
							<span>{avg.unit}</span>
						</h6>
					</div>
					<div className={spacing} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
						{icon}
						<h6>
							{Math.abs(selectedAvg?.percentage || 0)}
							<span> %</span>
						</h6>
					</div>
				</div>
			);
		},
		[spacing, secondary, selectedAvg, onChangeAvg, fieldConfig],
	);

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div className={wrapper}>
			{renderPrimaryValue(mtd)}
			{renderAverageValue(avgs)}
		</div>
	);
};

export default TritronikConsumptionPanel;
