import React, { useCallback } from 'react'
import { PanelProps } from 'app/types/Panel'
// import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
// import { useGetPanelDataQuery } from 'app/services/PanelService';
// import LoadingPage from 'app/components/LoadingPage';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

import dummyData from "./dummyData.json";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
`;

const Col = styled.div`
  flex: 1;
  padding: 0.25rem 0.5rem;
`;

const Label = styled.span`
  font-weight: 700;
  font-size: 0.75rem;
  `
const Value = styled.span`
  font-weight: 500;
  font-size: 0.75rem;
`

export interface TritronikListStatusPanelProps extends PanelProps {
  
}

const TritronikListStatusPanel = (props: TritronikListStatusPanelProps) => {
  const { width/* , panel, refreshInterval */ } = props;
  // const params = usePanelDataParams();
	// const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

  // if (isLoading) {
	// 	return <LoadingPage />;
	// }

  const wrapper = css`
    width: ${width}px;
    height: 100%;
    border: 1px solid #e9ecef;
    overflow: auto;
  `;

  const renderList = useCallback(() => {
    if (dummyData && dummyData.length > 0) {
      return dummyData.map((d) => {
        return (
          <Row>
            <Col><Label>{d.label}</Label></Col>
            <Col><Value>{d.value}</Value></Col>
          </Row>
        )
      })
    }

    return null;
  },[])

  return (
    <div className={wrapper}>
      {renderList()}
    </div>
  )
}

export default TritronikListStatusPanel
