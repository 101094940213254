import React, { useState } from 'react'
import { Dialog, DialogProps } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ChangePasswordRequest } from '../types';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { FormikInputText } from 'components';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';

interface FormChangePassword extends ChangePasswordRequest {
	confirmPassword: string;
}

interface Props extends DialogProps {
	onSubmit: (data: FormChangePassword, formik: FormikHelpers<FormChangePassword>) => void;
	isLoading?: boolean;
}

const ChangePasswordDialog = (props: Props) => {
	const [showPassword, setShowPassword] = useState(false);
	const initialValues: FormChangePassword = {
		oldPassword: '',
		newPassword: '',
		confirmPassword: '',
	}

	const validationSchema = Yup.object().shape({
		oldPassword: Yup.string().required('Old password required.'),
		newPassword: Yup.string().required('New password required.'),
		confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Confirmation password must match new password.').required('Confirmation password required.')
	});

	const onToggleShowPassword = (e: CheckboxChangeParams) => {
		setShowPassword(e.checked);
	}

	const inputType = showPassword ? 'text' : 'password';

	return (
		<div className='change-password-dialog'>
			<Dialog header="Change password" visible={props.visible} style={{ width: '35vw' }} onHide={props.onHide}>
				<div className=''>
					<Formik
						initialValues={initialValues}
						onSubmit={props.onSubmit}
						validationSchema={validationSchema}
						validateOnBlur={false}
						validateOnChange={false}
					>
						{(formik) => (
							<Form onSubmit={formik.handleSubmit}>
								<FormikInputText type={inputType} row id='oldPassword' name='oldPassword' label='Old Password' placeholder='Your Old Password' />
								<FormikInputText type={inputType} row id='newPassword' name='newPassword' label='New Password' placeholder='New Password' />
								<FormikInputText type={inputType} row id='confirmPassword' name='confirmPassword' label='Confirmation Password' placeholder='Confirmation Password' />

								<div className="field-checkbox d-flex align-items-center">
									<Checkbox inputId="showPassword" name="showPassword" value="1" onChange={onToggleShowPassword} checked={showPassword} />
									<label className='ml-2' htmlFor="showPassword">Show Password</label>
								</div>
								<div className='d-flex justify-content-end'>
									<Button label='Cancel' className='p-button-outlined mr-2' onClick={props.onHide} type='button' />
									<Button label='Apply' type='submit' loading={props.isLoading} disabled={props.isLoading} />
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Dialog>
		</div>
	)
}

export default ChangePasswordDialog;
