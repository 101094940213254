import React from 'react';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';

export interface OverlayMenuProps {
	items: MenuItem[];
	id: string;
}

const OverlayMenu = ({ id, items }: OverlayMenuProps) => {
	const menu = React.useRef<Menu>(null);
	return (
		<React.Fragment>
			<Menu model={items} ref={menu} popup id={id} />
			<Button
				type="button"
				icon="mdi mdi-dots-vertical"
				className="p-button-link text-dark"
				aria-controls={id}
				aria-haspopup
				onClick={(event) => menu.current?.toggle(event)}
			/>
		</React.Fragment>
	);
};

export default OverlayMenu;
