import LoadingPage from "app/components/LoadingPage";
import { useQuery } from "app/hooks/useQuery";
import { BreadCrumb } from 'primereact/breadcrumb';
import React from 'react';
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import CardCamera from "../components/CardCamera";
import { useGetMonitorQuery } from '../zmApi';

type Props = {}

const MonitorDetailPage: React.FC<Props> = (props) => {
    const params = useParams<any>();
    const queryParams = useQuery();

    const monitorId = params.monitorId;
    const siteName = queryParams.get('name');

    const { data, isLoading, isFetching } = useGetMonitorQuery(monitorId, {
        skip: !monitorId
    });

    function refreshPage() {
        window.location.reload(true);
    }

    const home = { icon: 'pi pi-home', url: '/' };
    const breadcrumbs = [
        { label: 'CCTV', url: '/cctv/live-view' },
        ...(siteName ? [{ label: siteName, url: `/cctv/site?name=${siteName}` }] : []),
        { label: data?.Monitor?.Name ?? monitorId },
    ];

    return (
        <Container fluid>
            <div className="d-flex align-items-center justify-content-between py-3">
                <BreadCrumb model={breadcrumbs} home={home} />
                <div>
                    <button className="btn btn-icon btn-link btn-sm" type="button" onClick={refreshPage}>
                        <span className="btn-inner--text">Refresh</span>
                        <span className="btn-inner--icon"><i className="mdi mdi-18px mdi-refresh"></i></span>
                    </button>
                </div>
            </div>
            <div>
                {(!data || isLoading || isFetching) ? (
                    <LoadingPage />
                ) : (
                    <div className="row mb-3" style={{ height: 600 }}>
                        <div className="col">
                            <CardCamera data={data} scale={75} />
                        </div>
                    </div>
                )}

            </div>
        </Container>
    )
}
export default MonitorDetailPage
