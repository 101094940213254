import { css } from '@emotion/css';
import LoadingPage from 'app/components/LoadingPage';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { DisplayValue } from 'app/types';
import { PanelProps } from 'app/types/Panel'
import { calculateFontSize, getColor } from 'app/utils';
import { getValueFormat } from 'app/valueFormats';
import React, { useMemo } from 'react'

export interface TritronikThreePhasePanelProps extends PanelProps {

}

const TritronikThreePhasePanel = (props: TritronikThreePhasePanelProps) => {
	const { width, height, fieldConfig, panel, refreshInterval } = props;

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const dimension = Math.min(width, height);

	const thresholds = fieldConfig.defaults.thresholds;

	const { e, eQ, iR, iS, iT, vR, vS, vT } = useMemo(() => {
		const getDataByKey = (key: string, color: string | null = null): DisplayValue | null => {
			const target = data?.responseDataValue.find((d) => d.aliasLabel === key);
			if (target) {
				const unit = target.unit;
				const baseValue = target.datapoints && target.datapoints.length > 0 ? target.datapoints[0].value : 0;
				const formattedValue = getValueFormat(unit)(baseValue);

				const colorFromThreshold = thresholds?.steps ? getColor(baseValue, thresholds?.steps) : 'green'
				return {
					numeric: parseFloat(formattedValue.text),
					text: formattedValue.text,
					suffix: formattedValue.suffix,
					color: color ? color : colorFromThreshold
				}
			}
			return null;
		}

		const e = getDataByKey('energy'); // total Energy Usage
		const eQ = getDataByKey('frequency', 'gray'); // Energy Frequency

		const iR = getDataByKey('current-R', '#73BF69'); // Current R
		const iS = getDataByKey('current-S', '#73BF69'); // Current S
		const iT = getDataByKey('current-T', '#73BF69'); // Current T

		const vR = getDataByKey('voltage-R', 'gray'); // Voltage R
		const vS = getDataByKey('voltage-S', 'gray'); // Voltage S
		const vT = getDataByKey('voltage-T', 'gray'); // Voltage T


		return { e, eQ, iR, iS, iT, vR, vS, vT };
	}, [data, thresholds]);

	const primaryFS = useMemo(() => {
		const fs = calculateFontSize('XXXX XXX', dimension * 0.4, dimension * 0.25, 1);
		return fs < 10 ? 10 : fs;
	}, [dimension]);

	const labelFontSize = useMemo(() => {
		const fs = calculateFontSize('X', dimension * 0.1, dimension * 0.1, 1);
		return fs < 8 ? 8 : fs;
	}, [dimension]);

	const secondaryFS = useMemo(() => {
		const fs = calculateFontSize('XXXX XXX', dimension * 0.2, dimension * 0.1, 1);
		return fs < 8 ? 8 : fs;
	}, [dimension]);

	const wrapper = css`
		height: 100%;
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.row-1 {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			text-align: center;
			width: 100%;
		}

		.row-2 {
			display: flex;
			flex-direction: row;
			align-items: center;
			text-align: center;
		}

		.row-3 {
			display: flex;
			flex-direction: row;
			justify-content: center;
			text-align: center;
			width: 100%;

			div {
				padding: 0 ${width * 0.1}px;
				border-right: solid 1px #d3d3d3;

				h6:first-child {
					margin-bottom: 5px;
				}
			}

			div:last-child {
				border: none;
			}
		}

		hr {
			border-color: #d3d3d3;
			width: calc(70% + 70px);
			margin: 0px;
		}

		.primary {
			font-size: ${primaryFS}px;
		}

		.primary2 {
			font-size: ${primaryFS * 0.8}px;
		}

		.secondary {
			font-size: ${secondaryFS}px;
		}

		.label {
			font-size: ${labelFontSize}px;
		}

		.mx-2 {
			margin-left: 1rem;
			margin-right: 1rem;
		}
	`;

	const renderCurrent = (value: DisplayValue | null) => {
		if (!value) return null;

		return (
			<h1 className="primary" style={{ color: value.color }}>
				{value.text}
				<span>{value.suffix}</span>
			</h1>
		)
	}

	const renderRequency = (value: DisplayValue | null) => {
		if (!value) return null;

		return (
			<h6 className="secondary" style={{ color: value.color }}>
				{value.text}
				<span>{value.suffix}</span>
			</h6>
		)
	}

	const renderRST = (iValue: DisplayValue | null, vValue: DisplayValue | null, phase: string) => {
		return (
			<div>
				<h6 className="label" style={{ zoom: 0.8 }}>
					{phase}
				</h6>
				{iValue && (
					<h6 className="primary" style={{ color: iValue.color }}>
						{`${iValue.text} ${iValue.suffix}`}
					</h6>
				)}
				{vValue && (
					<h6 className="secondary" style={{ color: vValue.color }}>
						{`${vValue.text} ${vValue.suffix}`}
					</h6>
				)}
			</div>
		)
	}

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div className={wrapper}>
			<div className={`row-1`}>
				{renderCurrent(e)}
				<div className="mx-2"></div>
				{renderRequency(eQ)}
			</div>
			<hr />
			<div className="row-3">
				{renderRST(iR, vR, 'R')}
				{renderRST(iS, vS, 'S')}
				{renderRST(iT, vT, 'T')}
			</div>
		</div>
	)
}

export default TritronikThreePhasePanel
