import React from 'react';
import { Field, FieldProps } from 'formik';
import { Checkbox, CheckboxProps } from 'primereact/checkbox';
import classNames from 'classnames';
import FormikFormGroup from '../FormikFormGroup';

export interface FormikCheckboxProps extends CheckboxProps {
  [key: string]: any;
  name: string;
  label?: string;
  required?: boolean;
  row?: boolean;
}

const FormikCheckbox = (props: FormikCheckboxProps) => {
  const { id, name, label, required, row, ...inputProps } = props;
  

  const renderInput = ({ field, form, meta }: FieldProps) => {
    
    return (
      <FormikFormGroup id={id} name={name} label={label} field={field} form={form} meta={meta} row={row} required={required}>
        <Checkbox id={id} {...field} checked={field.value} {...inputProps} className={classNames('w-100', {
          'p-invalid': Boolean(meta.touched && meta.error)
        })} />
      </FormikFormGroup>
    )
  }

  return (
    <Field name={name}>{renderInput}</Field>
  );
};

export default FormikCheckbox;
