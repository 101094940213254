import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/css';
import { PanelProps } from 'app/types/Panel';
import { calculateFontSize } from 'app/utils';
import moment from 'moment'
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';

enum DoorStatusEnum {
	open, closed, none
}
type DoorStatusType = keyof typeof DoorStatusEnum;

const statusColor = {
	open: '#F2495C',
	closed: '#73BF69',
	none: '#BBBBBB',
}

interface DoorStatus {
	currentStatus: DoorStatusType;
	currentStatusDate: number | null;
	lastStatus: DoorStatusType;
	lastStatusDate: number | null;
}

interface DoorPanelOptions {
	closeIcon?: string;
	openIcon?: string;
}
export interface TritronikDoorStatusPanelProps extends PanelProps<DoorPanelOptions> {

}

const TritronikDoorStatusPanel = (props: TritronikDoorStatusPanelProps) => {
	const { width, height, panel, refreshInterval } = props;
	const params = usePanelDataParams();

	const { data } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const [doorStatus, setDoorStatus] = useState<DoorStatus>({
		currentStatus: 'closed',
		currentStatusDate: null,
		lastStatus: 'none',
		lastStatusDate: null,
	});

	const currentColor = statusColor[doorStatus.currentStatus];
	const iconName = doorStatus.currentStatus === 'open' ? 'fa-door-open' : 'fa-door-closed';
	const lastIconName = doorStatus.lastStatus === 'open' ? 'fa-door-open' : 'fa-door-closed';

	useEffect(() => {
		if (Array.isArray(data?.responseDataValue)) {
			if (data && data.responseDataValue && data.responseDataValue.length > 0) {
				const doorStatuses = data.responseDataValue;
				const lastDoorStatus = doorStatuses.find((d) => d.target === "last_door");
				const lastDoorOpen = doorStatuses.find((d) => d.target === "last_open");

				let _doorStatus: DoorStatus = {
					currentStatus: 'closed',
					currentStatusDate: null,
					lastStatus: 'none',
					lastStatusDate: null,
				};
				// update status door based on data
				if (lastDoorStatus) {
					_doorStatus.currentStatusDate = lastDoorStatus.datapoints[0]?.time;
					// sensor value 0 is open, sensor value 1 is closed
					_doorStatus.currentStatus = parseInt(lastDoorStatus.datapoints[0]?.value) === 1 ? 'closed' : 'open';
				}
				if (lastDoorOpen) {
					_doorStatus.lastStatusDate = lastDoorOpen.datapoints[0]?.time;
					// sensor value 0 is open, sensor value 1 is closed
					_doorStatus.lastStatus = parseInt(lastDoorOpen.datapoints[0]?.value) === 1 ? 'closed' : 'open';
				}

				setDoorStatus(_doorStatus);
			}
		}
	}, [data]);

	const primaryFs = useMemo(() => {
		return calculateFontSize('X', width * 0.5, height * 0.5, 1);
	}, [width, height]);
	const secondaryFs = useMemo(() => {
		return calculateFontSize('D MMM, HH:mm', width / 2.5, 50, 1);
	}, [width]);

	const doorStyle = css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		overflow: hidden;

		.row-1 {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			text-align: center;
			margin-bottom: 2rem;
			width: 100%;
			i {
				font-size: ${primaryFs}px;
			}
		}

		.row-2 {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			text-align: center;
			width: 100%;

			div {
				margin: 0;
				display: flex;
				flex: 1;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				font-size: ${secondaryFs}px;

				i {
					font-size: ${secondaryFs * 1.4}px;
					margin-right: 5%;
				}
			}
		}
	`;

	const renderIcon = () => {
		return (
			<i className={`fa ${iconName}`} style={{ color: currentColor }}></i>
		)
	}

	const renderDate = (date: number, icon: string) => {
		if (date) {
		  const dateFormated = moment(new Date(date)).format('D MMM, HH:mm');
		  return (
			<div>
			  <i className={`fa ${icon}`}></i>
			  {dateFormated}
			</div>
		  );
		}
		return null;
	  }

	return (
		<div className={`${doorStyle}`}>
			<div className={`row-1`}>{renderIcon()}</div>
			<div className={`row-2`}>
				{renderDate(doorStatus.currentStatusDate!, 'fa-clock')}
				{renderDate(doorStatus.lastStatusDate!, lastIconName)}
			</div>
		</div>
	)
}

export default TritronikDoorStatusPanel;
