import { useMemo } from 'react';
import { useDebounceSearch } from 'app/hooks/useDebounceSearch';
import { useDatatable } from 'app/hooks/useDatatable';
import { FilterMatchMode } from 'primereact/api';
import { useGetUserConfigurationsQuery } from '../services/userApi';

export const useUserDatatable = () => {
	const dt = useDatatable({
		first: 0,
		rows: 5,
		page: 0,
		pageCount: 0,
		sortField: 'id',
		sortOrder: -1,
		multiSortMeta: undefined,
		filters: {
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		},
	});

	const search = useDebounceSearch(dt.globalFilter);

	const { data: accountRespons, ...query } = useGetUserConfigurationsQuery({
		pageSize: dt.rows,
		pageNumber: dt.page,
		sortFields: dt.sortField,
		sortOrder: dt.sortOrder === 1 ? 'DESC' : dt.sortOrder === -1 ? 'ASC' : undefined,
		search: search || undefined,
	});

	const totalRecords = useMemo(() => {
		if (!accountRespons?.content) return 0;

		return accountRespons.totalElements;
	}, [accountRespons]);

	return {
		...dt,
		...query,
		data: accountRespons?.content || [],
		totalRecords,
	};
};
