import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { PanelProps } from 'app/types/Panel'
import React, { useMemo } from 'react'
import _ from "lodash";
import ReactEcharts from 'echarts-for-react';
import LoadingPage from 'app/components/LoadingPage';
import { Link } from 'react-router-dom';


interface PanelOptions {
	linkToDetail: string;
	linkToDetailLabel: string;
}

export interface TritronikAlertPieChartPanelProps extends PanelProps<PanelOptions> {}


const TritronikAlertPieChartPanel = (props: TritronikAlertPieChartPanelProps) => {
	const { width, height, panel, refreshInterval, options } = props;
	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const option = useMemo(() => {
		let option = {
			tooltip: {
				trigger: 'item'
			},
			legend: {
				show: true,
				bottom: '10%'
			},
			series: [
				{
					name: 'Alert',
					type: 'pie',
					radius: '60%',
					center: ['50%', '50%'],
					data: [
						{
							value: 0,
							name: 'CRITICAL',
							itemStyle: {
								color: '#C4162A'
							}
						},
						{
							value: 0,
							name: 'MAJOR',
							itemStyle: {
								color: '#FF780A'
							}
						},
						{
							value: 0,
							name: 'MINOR',
							itemStyle: {
								color: '#C7C2C2'
							}
						}
					],
					roseType: 'radius',
					label: {
						show: false
					},
					animationType: 'scale',
					animationEasing: 'elasticOut',
					animationDelay: 200
				}
			]
		};

		if (data && data.responseDataValue && Array.isArray(data.responseDataValue)) {
			const { responseDataValue } = data;
			const groupped = _.mapValues(_.groupBy(responseDataValue, 'severity'), d => d.reduce(a => a + 1, 0));

			let dataSeries = option.series[0].data.map((d) => {
				return {
					...d,
					value: groupped[d.name] || 0
				}
			});

			option.series[0].data = dataSeries.sort(function (a, b) {
				return a.value - b.value;
			});
		}

		return option;
	}, [data]);

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div style={{ width, height: height * 0.92 }}>
			<ReactEcharts style={{ width: '100%', height: options.linkToDetail ? '90%' : '100%' }} option={option} />;
			{options.linkToDetail && (
				<div style={{
					flex: 1,
					textAlign: 'center'
				}}>
					<Link to={options.linkToDetail}>{options.linkToDetailLabel || "See Details"}</Link>
				</div>
			)}
		</div>
	)
}

export default TritronikAlertPieChartPanel
