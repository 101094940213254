import React from 'react';
import { usePermission } from '../hooks/usePermission';
import AccessForbidden from './AccessForbidden';

type Props = {
    to?: string | string[];
    fallback?: JSX.Element | boolean | string;
}

const Restricted: React.FunctionComponent<Props> = ({ to, fallback, children }) => {
    const { allowed } = usePermission();

    if (allowed(to)) {
        return <>{children}</>
    }

    if (typeof fallback === 'boolean') {
        return <AccessForbidden />;
    }

    if (React.isValidElement(fallback)) {
        return <>{fallback}</>
    }

    return null;
}

export default Restricted;
