import React from 'react';
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import FormikFormGroup from '../FormikFormGroup';
import _ from "lodash";

export interface FormikSelectProps extends DropdownProps {
  [key: string]: any;
  name: string;
  label?: string;
  required?: boolean;
  row?: boolean;
  formGroupClass?: string;
  hasIcon?:string
}

const FormikSelect = (props: FormikSelectProps) => {
  const { id, name, label, required, row, formGroupClass, hasIcon, ...inputProps } = props;

  const renderInput = ({ field, form, meta }: FieldProps) => {
    return (
      <FormikFormGroup
	  	className={formGroupClass}
	  	id={id}
		name={name}
		label={label}
		field={field}
		form={form}
		meta={meta}
		row={row}
		required={required}
    hasIcon={hasIcon}
	>
        <Dropdown
          id={id}
          {..._.omit(field, 'onBlur')}
          {...inputProps}
          className={classNames('w-100', {
          'p-invalid': Boolean(meta.touched && meta.error)
          })}
        />
      </FormikFormGroup>
    )
  }

  return (
    <Field id={id} name={name}>{renderInput}</Field>
  );
};

export default FormikSelect;
