import React, { useCallback, useMemo } from 'react'
import { css } from '@emotion/css';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { ThresholdsMode } from 'app/types';
import { PanelProps } from 'app/types/Panel'
import { calculateFontSize, getColor } from 'app/utils';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';
import LoadingPage from 'app/components/LoadingPage';
import { Divider } from 'primereact/divider';

import styled from '@emotion/styled';
import moment from 'moment';

interface Sensor {
	value: number;
	unit: string;
}

interface SensorValue {
	sensor: Sensor;
	label: string;
	date?:any
}

interface TimeStamp {
		show:boolean,
		format:string
}

interface Options {
	calculateDelta: boolean;
	labelDelta: string;
	iconSize?: number;
	valueFontSize?: number;
	labelFontSize?: number;
	valueDeltaFontSize?: number;
	labelDeltaFontSize?: number;
	timestamp:TimeStamp
}

interface DataMap {
	d1: SensorValue;
	d2: SensorValue;
}

export interface TritronikDeltaStatusPanelProps extends PanelProps<Options> {

}

const TritronikDeltaStatusPanel = (props: TritronikDeltaStatusPanelProps) => {
	const { panel, width, height, refreshInterval, fieldConfig, options } = props;
	const params = usePanelDataParams();

	const { data: rawData, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const decimals = fieldConfig?.defaults?.decimals || 2;
	const thresholds = fieldConfig?.defaults?.thresholds || TritronikDeltaStatusPanel.defaultProps.fieldConfig.defaults.thresholds;
	const steps = thresholds.steps;

	const labelDelta = options?.labelDelta || TritronikDeltaStatusPanel.defaultProps.fieldConfig.options.labelDelta;

	const calculateDelta = Boolean(options.calculateDelta);

	const { d1, d2 }: DataMap = useMemo(() => {
		let d1: SensorValue = {
			sensor: {
				value: 0,
				unit: 'none'
			},
			label: '-',
			date:'N/A'
		};
		let d2: SensorValue = {
			sensor: {
				value: 0,
				unit: 'none'
			},
			label: '-',
			date:'N/A'
		};

		if (rawData && rawData.responseDataValue && rawData.responseDataValue.length > 0) {
			const sensors = rawData.responseDataValue;

			sensors.forEach((sensor, idx) => {
				const value = sensor.datapoints[0].value || 0;
				if (idx === 0) {
					d1 = {
						sensor: {
							value: value,
							unit: sensor.unit
						},
						label: sensor.aliasLabel,
						date:sensor.datapoints[0].time
					}
				}

				if (idx === 1) {
					d2 = {
						sensor: {
							value: value,
							unit: sensor.unit
						},
						label: sensor.aliasLabel,
						date:sensor.datapoints[0].time
					}
				}
			});
		}

		return { d1, d2 };
	}, [rawData]);

	const delta: SensorValue = useMemo(() => {
		const v1 = d1.sensor.value;
		const v2 = d2.sensor.value;
		const delta = Math.abs(v1 - v2);

		return {
			sensor: {
				value: delta,
				unit: d1.sensor.unit || d2.sensor.unit
			},
			label: labelDelta
		}
	}, [d1, d2, labelDelta]);

	const d1Format = getValueFormat(d1.sensor.unit)(d1.sensor.value, decimals);
	const d1StringValue = formattedValueToString(d1Format);

	const d2Format = getValueFormat(d2.sensor.unit)(d2.sensor.value, decimals);
	const d2StringValue = formattedValueToString(d2Format);

	const valueString = d1StringValue.length > d2StringValue.length ? d1StringValue : d2StringValue;
	const labelString = d1.label.length > d2.label.length ? d1.label : d2.label;

	const valueFs = calculateFontSize(valueString, width * (calculateDelta ? 0.25 : 0.35), height * (calculateDelta ? 0.2 : 0.35), 1);

	const deltaFormat = getValueFormat(delta.sensor.unit)(delta.sensor.value);
	const deltaStringValue = formattedValueToString(deltaFormat);

	const fs = useMemo(() => {
		const { iconSize, valueFontSize, labelFontSize, calculateDelta, valueDeltaFontSize, labelDeltaFontSize } = options;
		return {
			iconSize: iconSize ? iconSize : calculateFontSize('X', width * 0.15, height * 0.15, 1),
			valueFontSize: valueFontSize ? valueFontSize : calculateFontSize(valueString, width * (calculateDelta ? 0.25 : 0.35), height * (calculateDelta ? 0.2 : 0.35), 1),
			labelFontSize: labelFontSize ? labelFontSize : calculateFontSize(labelString, width * (calculateDelta ? 0.15 : 0.25), height * (calculateDelta ? 0.15 : 0.25), 1),
			valueDeltaFontSize: valueDeltaFontSize ? valueDeltaFontSize : calculateFontSize(deltaStringValue, width * 0.2, height * 0.2, 1),
			labelDeltaFontSize: labelDeltaFontSize ? labelDeltaFontSize : calculateFontSize(delta.label, width * 0.1, height * 0.1, 1),
		};
	},  [options, deltaStringValue, width, height, valueString, labelString, delta]);

	const showDate:boolean = useMemo(() => {

		if(options && options.timestamp?.show) return true

		return false
	},[options])

	const dateFormat:string = useMemo(() => {
		
		if(options && options.timestamp?.format){
			return options.timestamp.format
		}
		
		return 'HH:mm'
		
		
	},[options])

	const renderDate = useCallback((date: number | string, icon: string) => {
		if (date) {
		  const dateFormated = moment(new Date(date)).format(dateFormat);
		  return (
			<p>
			  <i className={`fa ${icon}`}></i>
			  {dateFormated}
			</p>
		  );
		}
		return null;
	  },[dateFormat])

	const wrapper = css`
		height: 100%;
		width: 100%;
		overflow: hidden;
		section {
			height: ${calculateDelta ? 50 : 100}%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			flex: 1;
		}

		.item {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			p {
				text-transform: capitalize;
			}
		}

		.d1 {
			color: ${getColor(d1.sensor.value, steps)};
			font-size: ${fs.valueFontSize ? fs.valueFontSize: valueFs}px;

		}
		.d2 {
			color: ${getColor(d2.sensor.value, steps)};
			font-size: ${fs.valueFontSize ? fs.valueFontSize: valueFs}px;
		}
	`;

	const IconDelta = styled.i`
		font-size: ${fs.iconSize}px !important;
	`;

	const DeltaWrapper = styled.div`
		display: flex;
		flex-direction: column;
		text-align: center;
		margin-bottom: 2rem;
	`;
	const DeltaContainer = styled.div`
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	`;
	const ValueDeltaText = styled.p`
		font-size: ${fs.valueDeltaFontSize}px;
		margin: 0;
		line-height: 0;
	`;
	const LabelDeltaText = styled.p`
		font-size: ${fs.labelDeltaFontSize}px;
		margin: 0;
		line-height: 0;
	`;

	const CenterDiv = styled.div`
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
	`;

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div className={wrapper}>
			{calculateDelta && (
				<DeltaWrapper>
					<DeltaContainer>
						<CenterDiv>
							<IconDelta className='mdi mdi-delta' />
						</CenterDiv>
						<CenterDiv>
							<ValueDeltaText>{deltaStringValue}</ValueDeltaText>
						</CenterDiv>
					</DeltaContainer>
					<LabelDeltaText>{delta.label}</LabelDeltaText>
				</DeltaWrapper>
			)}
			<section>
				<div className="item">
					<h1 className="d1">{d1StringValue}</h1>
					<p>{d1.label}</p>
					{showDate && renderDate(d1.date, 'fa-clock') }
				</div>
				<Divider layout='vertical' className='m-0' />
				<div className="item">
					<h1 className="d2">{d2StringValue}</h1>
					<p>{d2.label}</p>
					{showDate && renderDate(d2.date, 'fa-clock') }
				</div>
			</section>
		</div>
	)
}

TritronikDeltaStatusPanel.defaultProps = {
	showThresholdMarkers: true,
	showThresholdLabels: false,
	fieldConfig: {
		defaults: {
			min: 0,
			max: 100,
			thresholds: {
				mode: ThresholdsMode.Absolute,
				steps: [
					{ value: -Infinity, color: 'green' },
					{ value: 80, color: 'red' },
				],
			},
		},
		options: {
			calculateDelta: false,
			labelDelta: 'Delta',
			timeStamp:{
				show:false,
				format:"HH:mm"
			}
		}
	},
};

export default TritronikDeltaStatusPanel
