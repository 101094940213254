import { useToasts } from 'app/components/Toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { useDeleteTaskMutation } from '../services';
import { ITask } from '../types';

export function useDeleteTask() {
    const [deleteMutation, result] = useDeleteTaskMutation();
    const toast = useToasts();

    const deleteTask = (task: ITask) => {
        confirmDialog({
            message: `Deleted data can not be restored.`,
            header: `Delete task ${task.name}?`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: async () =>
                await deleteMutation(task.id)
                    .unwrap()
                    .then(() => {
                        toast.toastSuccess('Success', `Task deleted.`);
                    })
                    .catch((error) => {
                        toast.toastError(error?.data?.error, error?.data?.message);
                    }),
        });
    };

    return {
        result,
        deleteTask,
    };
}
