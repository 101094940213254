import { useCallback } from 'react';
import { FormRoleValues } from '../components/FormRuleInfo';

import { useGetPermissionCreateMutation, useUpdateRoleConfigurationMutation } from '../services/roleApi';

export function useUpdateRole() {
	const [update, { isLoading: updatingRole }] = useUpdateRoleConfigurationMutation();
	const [setPermissionsToRole, { isLoading: settingPermissions }] = useGetPermissionCreateMutation();

	const updateRole = useCallback(
		async (data: FormRoleValues) => {
			const { permissionsInput, ...roleData } = data;
			try {
				const newRole = await update(roleData).unwrap();

				await setPermissionsToRole({
					roleId: newRole.id,
					featureIds: permissionsInput || [],
				});
				// if yes
			} catch (error) {
				// if error
				console.log(error);
			}
		},
		[update, setPermissionsToRole],
	);

	return {
		updateRole,
		isLoadingUpdate: updatingRole || settingPermissions,
	};
}
