/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useAuth } from 'app/features/auth/hooks/useAuth';
import { locationService } from 'app/services/LocationService';
import { useAppSelector } from 'app/store';
import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
// reactstrap components
import {
	Collapse,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	// Form,
	// FormGroup,
	// Input,
	// InputGroup,
	// InputGroupAddon,
	// InputGroupText,
	// NavItem,
	// NavLink,
	Media,
	Nav,
	Navbar,
	NavItem,
	UncontrolledDropdown,
} from 'reactstrap';
import Restricted from './Restricted';

export interface AppNavbarProps extends PropsWithChildren<any> {
	toggleSidenav: () => void;
	sidenavOpen: boolean;
	theme: "dark" | "light";
}

const AppNavbar: React.FC<AppNavbarProps> = (props) => {
	const { user, avatar, doLogout } = useAuth();

	const history = locationService.getHistory();

	const projectId = useAppSelector(state => state.application.ids[0])

	return (
		<>
			<Navbar
				className={classnames(
					'navbar-top navbar-expand',
					{ 'navbar-dark bg-default': props.theme === 'dark' },
					{ 'navbar-light bg-secondary': props.theme === 'light' },
				)}
			>
				<Container fluid>
					<Collapse navbar isOpen={true}>
						<Nav className="align-items-center ml-md-auto" navbar>
							<NavItem className="d-xl-none">
								<div
									className={classnames(
										'pr-3 sidenav-toggler',
										{ active: props.sidenavOpen },
										{ 'sidenav-toggler-dark': props.theme === 'dark' },
									)}
									onClick={props.toggleSidenav}
								>
									<div className="sidenav-toggler-inner">
										<i className="sidenav-toggler-line" />
										<i className="sidenav-toggler-line" />
										<i className="sidenav-toggler-line" />
									</div>
								</div>
							</NavItem>
						</Nav>
						<Nav className="align-items-center ml-auto ml-md-auto" navbar>
							<UncontrolledDropdown nav>
								<DropdownToggle className="nav-link pr-0" color="" tag="a">
									<Media className="align-items-center">
										<span className="avatar avatar-sm rounded-circle">
											<img alt="..." src={avatar} />
										</span>
										<Media className="ml-2 d-none d-lg-block">
											<span className="mb-0 text-sm font-weight-bold">
												{user?.userInfo?.name || user?.username}
											</span>
										</Media>
									</Media>
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem className="noti-title" header tag="div">
										<h6 className="text-overflow m-0">Welcome!</h6>
									</DropdownItem>
									<DropdownItem href="#profile" onClick={(e) => {
										e.preventDefault();
										history.push('/profile');
									}}>
										<i className="mdi mdi-18px mdi-account" />
										<span>My profile</span>
									</DropdownItem>
									<Restricted to="UPDATE DASHBOARD">
										<DropdownItem href="#profile" onClick={(e) => {
											e.preventDefault();
											history.push(`/project-configuration/${projectId}`);
										}}>
											<i className="mdi mdi-18px mdi-cog" />
											<span>Project Settings</span>
										</DropdownItem>
										<DropdownItem href="#profile" onClick={(e) => {
											e.preventDefault();
											history.push(`/login-configuration/${projectId}`);
										}}>
											<i className="mdi mdi-18px mdi-image" />
											<span>Login Settings</span>
										</DropdownItem>
									</Restricted>
									<DropdownItem divider />
									<DropdownItem
										href="#logout-action"
										onClick={(e) => {
											e.preventDefault();
											doLogout();
										}}
									>
										<i className="mdi mdi-18px mdi-power-settings" />
										<span>Logout</span>
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		</>
	);
};

AppNavbar.defaultProps = {
	toggleSidenav: () => {},
	sidenavOpen: false,
	theme: 'dark',
};

export default AppNavbar;
