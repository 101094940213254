import { useGetPanelComponentQuery } from 'app/services/PanelService'
import React from 'react'
import Select from 'react-select'
type Props = {
    onChange:(e) => any
    value:any
    className?:string
    id?:string
    placeholder?:string
}
const SelectPanelComponent = ({ onChange, value, className, id, placeholder}:Props) => {
    const {data: panelComponents} = useGetPanelComponentQuery({})
	const panelComponentOptions = panelComponents?.map(panelComponent => {
		let newObj = {
			label:panelComponent.panelComponentName,
			value:panelComponent.panelComponentName
		}
		return newObj
	})
    const defaultValue = (options, value) => {
        return options ? options.find(option => option.value === value):""
    }
    const customStyle = {
        control:(styles) => ({
            ...styles,
            border: '1px solid #dee2e6',
            color: '#8898aa',
            height:'calc(1.5em + 1.25rem + 5px)'
        }),
        indicatorSeparator: (styles) => ({ ...styles, opacity: "0" }),
    }
  return (
    <div className={className}>
        <Select
            value={defaultValue(panelComponentOptions, value)}
            onChange={value=>{
                onChange(value)
            }}
            options={panelComponentOptions}
            id={id}
            placeholder={placeholder}
            styles={customStyle}
        />
    </div>
  )
}
export default SelectPanelComponent
