import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { FormikChips, FormikInputText, FormikInputTextArea, FormikSelect } from 'components';
import { ArrayHelpers, FieldArray, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { OptionItem } from '../types';
import { AlertConfigurationForm } from '../types';
import { RadioButton } from 'primereact/radiobutton';

export interface FormRuleInfoProps {
	targetCategoryOptions: OptionItem[];
	severityOptions: OptionItem[];
	comparatorOptions: OptionItem[];
	channelOptions: OptionItem[];
	formik: FormikProps<AlertConfigurationForm>;
	onAddRule: (arrayHelpers: ArrayHelpers) => void;
	onAddChannel: (arrayHelpers: ArrayHelpers) => void;
	onMetricTypeChange: (type: string) => void;
}

const FormRuleInfo = (props: FormRuleInfoProps) => {
	const {
		targetCategoryOptions,
		severityOptions,
		comparatorOptions,
		channelOptions,
		formik,
		onAddChannel,
		onAddRule,
		onMetricTypeChange,
	} = props;

	const _onMetricTypeChange = (value: any) => {
		formik.setFieldValue('metricType', value);
		onMetricTypeChange(value);
	}
	return (
		<React.Fragment>
			<Row>
				<Col>
					<Row>
						<Col lg="6" sm="12">
							<h2>Rule Information</h2>
							<FormikInputText
								row
								id="alert-rule-name"
								name="name"
								label="Rule Name"
								placeholder="Rule Name"
								required
							/>
							<FormikSelect
								row
								id="alert-rule-applyTo"
								name="applyTo"
								label="Target Category"
								placeholder="Target Category"
								required
								options={targetCategoryOptions}
							/>
							<FormikInputText
								row
								id="alert-rule-targetId"
								name="targetId"
								label="Device ID"
								placeholder="Device ID"
								caption="if blank will be implemented to all site and device"
								required
							/>
							<FormikSelect
								row
								id="alert-rule-severity"
								name="severity"
								label="Severity"
								placeholder="Severity"
								required
								options={severityOptions}
							/>
							<FormikInputTextArea
								row
								id="alert-rule-message"
								name="message"
								label="Notification"
								placeholder="Notification"
								rows={4}
							/>
							<FormikChips
								row
								id={`tags`}
								name={`tags`}
								label="Tags"
								placeholder="Tags"
								block
							/>
						</Col>
						<Col lg="6" sm="12">
							<Row>
								<Col>
									<FieldArray
										name="conditions"
										render={(arrayHelpers: ArrayHelpers) => (
											<>
												<div className="d-flex align-items-center">
													<h2>Rule Condition</h2>
													<Button
														type="button"
														className="d-none p-button-link ml-2" // TODO: show this after support multi rule
														icon="mdi mdi-plus-circle"
														label="Add Another Condition"
														onClick={() => onAddRule(arrayHelpers)}
													/>
												</div>
												<div>
													{formik.values.conditions &&
														formik.values.conditions.length > 0 &&
														formik.values.conditions.map((condition, index) => (
															<Card className='shadow-none mb-0' key={`conditon-${index}`}>
																<CardBody>
																<h4>Metric Type</h4>
																<div className='d-flex flex-row align-items-center'>
																	<div className="field-radiobutton d-flex align-items-center mr-3">
																		<RadioButton inputId='metric-type-numeric' name='metricType' value="NUMERIC" onChange={e => _onMetricTypeChange(e.value)} checked={formik.values.metricType === 'NUMERIC'} />
																		<label htmlFor="metric-type-numeric" className='ml-2 pt-1'>NUMERIC</label>
																	</div>
																	<div className="field-radiobutton d-flex align-items-center">
																		<RadioButton inputId='metric-type-non-numeric' name='metricType' value="NON_NUMERIC" onChange={e => _onMetricTypeChange(e.value)} checked={formik.values.metricType === 'NON_NUMERIC'} />
																		<label htmlFor="metric-type-numeric" className='ml-2 pt-1'>NON-NUMERIC</label>
																	</div>
																</div>
																	<Row>
																		<Col>
																			<FormikInputText
																				id={`alert-rule-condition-name-${index}`}
																				name={`conditions.${index}.name`}
																				label="Condition Name"
																				placeholder="Condition Name"
																				required
																			/>
																		</Col>
																		<Col>
																			<FormikInputText
																				id={`alert-rule-condition-dataFunction-${index}`}
																				name={`conditions.${index}.dataFunction`}
																				label="Data Function"
																				placeholder="Data Function"
																				disabled
																			/>
																		</Col>
																	</Row>
																	<Row>
																		<Col>
																			<FormikSelect
																				id={`alert-rule-condition-comparator-${index}`}
																				name={`conditions.${index}.comparator`}
																				label="Operator"
																				placeholder="Operator"
																				required
																				options={comparatorOptions}
																			/>
																		</Col>
																		<Col>
																			<FormikInputText
																				id={`alert-rule-condition-thresholdValue-${index}`}
																				name={`conditions.${index}.thresholdValue`}
																				label="Threshold Value"
																				placeholder="Thershold Value"
																				required
																				type={formik.values.metricType === 'NUMERIC' ? 'number' : 'text'}
																			/>
																		</Col>
																	</Row>
																	<div className="d-flex justify-content-end">
																		<Button
																			label="Remove"
																			icon="mdi mdi-delete"
																			className="d-none p-button-sm p-button-danger" // TODO: show this after support multi rule
																			onClick={() => arrayHelpers.remove(index)}
																		/>
																	</div>
																</CardBody>
															</Card>
														))}
												</div>
											</>
										)}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<FieldArray
										name="channelRecipient"
										render={(arrayHelpers: ArrayHelpers) => (
											<>
												<div className="d-flex align-items-center">
													<h2>Recipients</h2>
													<Button
														type="button"
														className="p-button-link ml-2"
														icon="mdi mdi-plus-circle"
														label="Add Another Channel"
														onClick={() => onAddChannel(arrayHelpers)}
													/>
												</div>
												<div className='mt-2'>
													{formik.values.channelRecipient &&
														formik.values.channelRecipient.length > 0 &&
														formik.values.channelRecipient.map((recipient, index) => (
															<Card key={`channelRecipient-${index}`} className="shadow-none border-bottom">
																<CardBody>
																	<div>
																		<FormikSelect
																			row
																			id={`alert-rule-channel-name-${index}`}
																			name={`channelRecipient.${index}.channel`}
																			label="Channel"
																			placeholder="Channel"
																			required
																			options={channelOptions}
																		/>
																		<FormikChips
																			row
																			id={`alert-rule-channel-recipient-${index}`}
																			name={`channelRecipient.${index}.recipients`}
																			label="Recipients"
																			placeholder="Recipients"
																			required
																			block
																		/>
																	</div>
																	<div className="d-flex justify-content-end">
																		<Button
																			label="Remove"
																			icon="mdi mdi-delete"
																			className="p-button-sm p-button-danger"
																			onClick={() => arrayHelpers.remove(index)}
																		/>
																	</div>
																</CardBody>
															</Card>
														))}
												</div>
											</>
										)}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default FormRuleInfo;
