import React, { useCallback, useMemo, useState } from 'react'
import { PanelProps } from 'app/types/Panel'
import { AlertStatus, DataList, IconType, Status } from './types';

import { nav, list, wrapper } from './styles';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import LoadingPage from 'app/components/LoadingPage';

export interface TritronikAlertListPanelProps extends PanelProps { }

interface CollapsedState {
	[key: string]: boolean;
}

const TritronikAlertListPanel = (props: TritronikAlertListPanelProps) => {
	const { width, height, panel, refreshInterval } = props;
	const [active, setActive] = useState<Status>(AlertStatus.CRITICAL);
	const [collapse, setCollapse] = useState<CollapsedState>({});

	const params = usePanelDataParams();

	const { data, isLoading } = useGetPanelDataQuery({ id: panel.id, ...params }, { skip: !panel, pollingInterval: refreshInterval });

	const onChangeTab = (tab: Status) => {
		setActive(tab);
	}

	const collapseHandler = (id: any) => {
		setCollapse
		((current) => ({
			...current,
			[id]: !Boolean(current[id])
		}))
	}

	const dataList: DataList[] = useMemo(() => {
		if (!data?.responseDataValue) return [];

		const { responseDataValue } = data;

		if ( Array.isArray(responseDataValue) && responseDataValue && responseDataValue.length > 0) {
			return responseDataValue.filter((d) => d.severity.toLowerCase() === active).map<DataList>(({ id, lastMessage, severity, lastUpdated, detailUrl }) => {
				return { id, lastUpdated, lastMessage, detailUrl, severity }
			});
		}
		return [];
	}, [active, data]);

	const listElement = useCallback(() => {
		if (!dataList) return null;

		return dataList.map((dt, i) => (
			<li className={active} key={`alert-${dt.severity}-${i}`}>
				<i className={`mdi mdi-${IconType[active]} icon`}></i>
				<div style={{ width: width - 140 }}>
					<div className={`${collapse[dt.id] ? '' : 'hidden'}`}>
						<a href={dt.detailUrl}>{dt.lastMessage}</a>
					</div>
					<small>{dt.lastMessage}</small>
				</div>
				<i className={`arrow mdi mdi-${collapse[dt.id] ? 'chevron-up' : 'chevron-down'}`} onClick={() => collapseHandler(dt.id)}></i>
			</li>
		))
	}, [dataList, active, width, collapse]);

	const emptyList = useMemo(() => {
		if (dataList.length === 0) {
			return <h4 style={{ margin: 20, textAlign: 'center', textTransform: 'uppercase' }}>NO ALERT</h4>
		}

		return null;
	}, [dataList]);

	const getCount = useCallback((status: Status) => {
		if (!data?.responseDataValue) return 0;

		const { responseDataValue } = data;

		return responseDataValue.filter((d) => d.severity.toLowerCase() === status).length;
	}, [data]);

	const ciritcalCount = getCount('critical');
	const majorCount = getCount('major');
	const minorCount = getCount('minor');

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div className={wrapper}>
			<ul className={nav}>
				<li className="critical">
					<a href="#critical" className={active === 'critical' ? 'active' : ''} onClick={(e) => {
						e.preventDefault();
						onChangeTab(AlertStatus.CRITICAL);
					}}>
						CRITICAL {ciritcalCount ? <span>{ciritcalCount}</span> : null}
					</a>
				</li>
				<li className="major">
					<a href="#major" className={active === 'major' ? 'active' : ''} onClick={(e) => {
						e.preventDefault();
						onChangeTab(AlertStatus.MAJOR);
					}}>
						MAJOR {majorCount ? <span>{majorCount}</span> : null}
					</a>
				</li>
				<li className="minor">
					<a href="#minor" className={active === 'minor' ? 'active' : ''} onClick={(e) => {
						e.preventDefault();
						onChangeTab(AlertStatus.MINOR);
					}}>
						MINOR {minorCount ? <span>{minorCount}</span> : null}
					</a>
				</li>
			</ul>
			<ul className={list} style={{ height: height - 100 }}>
				{emptyList}
				{listElement()}
			</ul>
		</div>
	)
}

export default TritronikAlertListPanel;
