import React, { useEffect, useMemo, useState } from 'react';

import { PanelProps } from 'app/types';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { Timeline } from 'primereact/timeline';
import { css } from '@emotion/css';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnProps } from 'primereact/column';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';
import _ from 'lodash';

import './TritronikTimelinePanel.scss';
import { ProgressSpinner } from 'primereact/progressspinner';

interface IData {
    label: string;
    value: any;
    unit: string;
}

interface IDataDetails {
    [key: string]: any;
}
interface ITimelineData {
    summary: IData[];
    details: IDataDetails[];
}
interface TimelineData {
    key?: string;
    type: string;
    title: string;
    description: string;
    timestamp: number;
    data: ITimelineData | null;
    isMilestone: boolean;
    isActive: boolean;
}

interface ColumnOptions extends ColumnProps {
    unit?: string;
    bodyTemplate?: string;
}

interface IMarkerStyle {
    color?: string;
    activeColor?: string;
    disableColor?: string;
}

interface IRowUnit {
    key: string;
    value: string;
    unit: string;
}

interface TimelineOptions {
    columns: ColumnOptions[];
    rowsUnit: IRowUnit[];
    minWidthContent: number;
    markerStyle: IMarkerStyle;
}

interface TimelineProps extends PanelProps<TimelineOptions> {}

const TritronikTimelinePanel = (props: TimelineProps) => {
    const { panel, height, refreshInterval, options } = props;
    const [activeItemKey, setActiveItemKey] = useState<any>(null);

    const params = usePanelDataParams();
    const { data, isLoading, isFetching } = useGetPanelDataQuery(
        { id: panel.id, ...params },
        { skip: !panel, pollingInterval: refreshInterval },
    );

    const timelineData = useMemo<TimelineData[]>(() => {
        if (!data) return [];

        return data.responseDataValue.map(
            ({ type, title, data, description, timestamp, isMilestone, isActive }, key) => {
                return { key, type, title, description, timestamp, data, isMilestone, isActive } as TimelineData;
            },
        );
    }, [data]);

    const colorsMap = useMemo(
        () => ({
            active: options?.markerStyle?.activeColor ?? '#FF9800',
            inactive: options?.markerStyle?.color ?? '#607D8B',
            disabled: options?.markerStyle?.disableColor ?? '#bbbbbb',
        }),
        [options],
    );

    const classes = useMemo(
        () => ({
            wrapper: css`
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                overflow: hidden;

                * {
                    line-height: 1;
                }
            `,
            contentWrapper: css`
                display: flex;
                width: 100%;
                overflow: auto;
                padding: 14px;
                height: 100%;
                align-items: flex-start;
            `,
            summary: css`
                margin-bottom: 1rem;
            `,
            card: {
                container: css`
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    margin-top: 10px;

                    span {
                        font-size: 12px;
                    }
                `,
                item: css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0.5rem;

                    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

                    :fist-child {
                        border-bottom: none;
                    }
                    :last-child {
                        border-top: none;
                    }
                `,
                value: css`
                    font-weight: bold;
                `,
            },
            timeline: {
                container: css`
                    display: flex;
                    flex-direction: column;
                    min-width: ${options?.minWidthContent ?? 100}px;
                    height: ${height * 0.68}px;

                    h5,
                    h6 {
                        line-height: 1;
                    }

                    h5 {
                        font-weight: 700;
                    }
                    margin-right: 1rem;
                    padding: 1rem;
                    padding-left: 0;
                `,
                content: css`
                    overflow: auto;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                `
            },
            loading: {
                container: css`
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `,
            },
            marker: css`
                display: flex;
                width: 1rem;
                height: 1rem;
                align-items: center;
                justify-content: center;
                color: #ffffff;
                border-radius: 50%;
                z-index: 1;
            `,
        }),
        [height, options],
    );

    const content = (item: TimelineData) => {
        const isActive = activeItemKey === item.key;
        const isPast = item.type === 'past';
        const isPresent = item.type === 'present';
        const isFuture = item.type === 'future';
        const { data } = item;

        const renderSummary = () => {
            if (!data || !data.summary || (isPast && !isActive) || (isPresent && !isActive)) {
                return null;
            }

            return (
                <div className={classes.card.container}>
                    {data.summary.map(({ label, value, unit }, i) => {
                        return (
                            <React.Fragment key={`summary-item-${i}`}>
                                <div className={classes.card.item}>
                                    <span>{label}</span>
                                    <span className={classes.card.value}>
                                        {value} {unit}
                                    </span>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            );
        };

        const renderContent = () => {
            if (!data || !data.details || !isActive) {
                return null;
            }

            if (data && data.details && data.details.length > 0) {
                const renderBodyTemplate = (template: string, rowData: any, opts: ColumnOptions) => {
                    console.log(rowData, opts.field);

                    const unitValueTemplate = () => {
                        let unit = opts.unit || 'none';

                        // lookup overrides unit per rows if any

                        if (options && options.rowsUnit && options.rowsUnit.length > 0) {
                            const { rowsUnit } = options;
                            const unitConfig = rowsUnit.find((row) => {
                                return _.toLower(row.value) === _.toLower(rowData[row.key])
                            });
                            if (unitConfig) {
                                unit = unitConfig.unit;

                            }
                        }
                        const decimals = 0;
                        const value = opts.field && rowData[opts.field] !== undefined ? rowData[opts.field] : 0;
                        const formatV = getValueFormat(unit)(value, decimals);
                        return <span>{formattedValueToString(formatV)}</span>;
                    };

                    switch (template) {
                        case 'unitValueTemplate':
                            return unitValueTemplate();

                        default:
                            return opts.field ? rowData[opts.field] : '-';
                    }
                };

                const renderColumns = () => {
                    if (options && options.columns && options.columns.length > 0) {
                        return options.columns.map(({ unit, bodyTemplate, ...col }, idx) => {
                            if (bodyTemplate && bodyTemplate !== '') {
                                return (
                                    <Column
                                        key={`${col.field}-${idx}`}
                                        {...col}
                                        body={(data) => renderBodyTemplate(bodyTemplate, data, { ...col, unit })}
                                    />
                                );
                            }

                            return <Column key={`${col.field}-${idx}`} {...col} />;
                        });
                    }

                    return null;
                };

                return (
                    <DataTable
                        className="p-tritronik-timeline"
                        value={data.details}
                        size="small"
                        responsiveLayout="scroll"
                    >
                        {renderColumns()}
                    </DataTable>
                );
            }

            return null;
        };

        const makeStyle = () => {
            const minWidth = options?.minWidthContent ?? 120;

            if ((data && (data.summary || data.details) && isActive) || (isFuture && data?.summary)) {
                return { width: 300 };
            }

            return { width: minWidth };
        };

        return (
            <div key={item.key} className={`${classes.timeline.container}`} style={makeStyle()}>
                <h5>{item.title}</h5>
                {item.description && <h6>{item.description}</h6>}

                <div className={classes.timeline.content}>
                    <div className={classes.summary}>{renderSummary()}</div>
                    <div>{renderContent()}</div>
                </div>
            </div>
        );
    };

    const onClickItem = (item: any) => {
        if (item.type !== 'future') {
            setActiveItemKey(item.key);
        }
    };

    const marker = (item: any) => {
        const isActive = activeItemKey === item.key;
        const color = isActive ? 'active' : 'inactive';
        const isFuture = item.type === 'future';

        return (
            <div
                className={css`
                    position: relative;
                `}
            >
                <span
                    className={classes.marker}
                    style={{
                        backgroundColor: colorsMap[color],
                        cursor: isFuture ? 'default' : 'pointer',
                    }}
                    onClick={() => onClickItem(item)}
                ></span>
                {item.isMilestone && (
                    <span
                        onClick={() => onClickItem(item)}
                        className={css`
                            position: absolute;
                            display: flex;
                            width: 2rem;
                            height: 2rem;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            z-index: 3;
                            top: -0.5rem;
                            left: -0.5rem;
                            background-color: ${colorsMap[color]};
                            cursor: ${isFuture ? 'default' : 'pointer'};
                        `}
                    ></span>
                )}
            </div>
        );
    };

    useEffect(() => {
        if (timelineData && timelineData.length > 0) {
            const timeline = timelineData.find(({ isActive }) => isActive === true);

            if (timeline) {
                setActiveItemKey(timeline.key);
            }
        }
    }, [timelineData]);

    return (
        <div className={`${classes.wrapper}`}>
            <div className={classes.contentWrapper}>
                {isLoading || isFetching ? (
                    <div className={classes.loading.container}>
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                    </div>
                ) : (
                    <Timeline
                        value={timelineData}
                        layout="horizontal"
                        content={content}
                        dataKey="key"
                        marker={marker}
                    />
                )}
            </div>
        </div>
    );
};

export default TritronikTimelinePanel;
