import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React from 'react'

type Props = {
	search?: boolean;
	onSearch?: React.ChangeEventHandler<HTMLInputElement>;
	createButton?: boolean;
	onCreateButtonClick?: () => void;
}

const CustomTableHeader = (props: Props) => {
	return (
		<div className='d-flex flex-wrap align-items-center justify-content-between'>
			<div className='search'>
				{props.search && (
					<span className="p-input-icon-left">
						<i className="pi pi-search" />
						<InputText
							type="search"
							onChange={props.onSearch}
							placeholder="Search"
						/>
					</span>
				)}
			</div>
			<div className='actions'>
				{props.createButton && (
					<Button
						label="Add New"
						icon="mdi mdi-plus-circle"
						iconPos="right"
						className="p-button-link text-default"
						onClick={props.onCreateButtonClick}
					/>
				)}
			</div>
		</div>
	)
}

export default CustomTableHeader
