import React from 'react'

type Props = {}

const AccessForbidden = (props: Props) => {
  return (
    <div className='w-100 h-100 p-5' style={{ minWidth: 300, minHeight: 200, overflow: 'hidden' }}>
        <div className='text-center'>
            <div className="font-size-bold" style={{ fontSize: 40 }}>403</div>
            <div className='text-danger'style={{ marginBottom: 10 }}>Access Denied/Forbidden</div>
            <div className='text-danger'>You have no access to access this resource.</div>
        </div>
        <div className='text-center mt-3'>
            <i className='mdi mdi-alert text-warning' style={{ fontSize: 100 }}></i>
        </div>
    </div>
  )
}

export default AccessForbidden
