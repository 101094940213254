import React from 'react';
import { Field } from 'formik';
import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea';
import classNames from 'classnames';
import FormikFormGroup from '../FormikFormGroup';

export interface FormikInputTextAreaProps {
  [key: string]: any;
  name: string;
  label?: string;
  required?: boolean;
  row?: boolean;
}

const FormikInputTextArea = (props: FormikInputTextAreaProps & InputTextareaProps) => {
  const { id, name, label, required, row, ...inputProps } = props;

  const renderInput = ({ field, form, meta }) => {
    return (
      <FormikFormGroup id={id} name={name} label={label} field={field} form={form} meta={meta} row={row} required={required}>
        <InputTextarea id={id} {...field} {...inputProps} className={classNames('w-100', {
          'p-invalid': Boolean(meta.touched && meta.error)
        })} />
      </FormikFormGroup>
    )
  }

  return (
    <Field name={name}>{renderInput}</Field>
  );
};

export default FormikInputTextArea;
