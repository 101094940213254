import { FormOverview } from 'components';
import { FormikProps } from 'formik';
import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { capitalizeFirstLetter } from 'utils';
import { AlertConfigurationForm } from '../types';

export interface AccountFomConfirmProps {
	formik: FormikProps<AlertConfigurationForm>;
}

const AccountFomConfirm = (props: AccountFomConfirmProps) => {
	const { values } = props.formik;
	console.log(values);

	const renderConditionsOverview = () => {
		return (
			values.conditions &&
			values.conditions.length > 0 &&
			values.conditions.map((condition, i) => {
				return (
					<React.Fragment key={`condition-overview-${i}`}>
						<FormOverview label="Name" value={condition.name} stripped />
						<FormOverview label="Operator" value={condition.comparator} />
						<FormOverview label="Threshold Value" value={condition.thresholdValue} stripped />
						<div className="border-bottom"></div>
					</React.Fragment>
				);
			})
		);
	};

	const renderRecipientOverview = () => {
		return (
			values.channelRecipient &&
			values.channelRecipient.length > 0 &&
			values.channelRecipient.map((channel, i) => {
				return (
					<React.Fragment key={`channel-overview-${i}`}>
						<FormOverview
							leftItemClassName="font-weight-bold"
							stripped
							label={`Notification Channel ${i + 1}`}
							value={capitalizeFirstLetter(channel.channel)}
						/>
						<FormOverview
							label={`${capitalizeFirstLetter(channel.channel)} Recipients`}
							leftItemClassName="offset-1 font-weight-bold"
						/>
						{channel.recipients &&
							channel.recipients.length > 0 &&
							channel.recipients.map((recipient, r) => (
								<React.Fragment key={`channel-recipient-overview-${r}`}>
									<FormOverview value={recipient} stripped={r % 2 === 0} />
								</React.Fragment>
							))}
						<div className="border-bottom"></div>
					</React.Fragment>
				);
			})
		);
	};
	return (
		<React.Fragment>
			<Row>
				<Col>
					<h2>Confirmation</h2>
				</Col>
			</Row>
			<Row>
				<Col>
					<Row className="mt-4">
						<Col>
							<Card>
								<CardBody>
									<h3 className="mb-2">Rules Information</h3>
									<FormOverview label="Rule Name" value={values.name} stripped />
									<FormOverview label="Target Category" value={values.applyTo} />
									<FormOverview label="Device ID" value={values.targetId} stripped />
									<FormOverview label="Severity" value={values.severity} />
									<FormOverview label="Notification" value={values.message} stripped />
									<FormOverview label="Tags" value={values.tags?.join(', ') || '-'} />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row className="mt-4">
						<Col>
							<Card>
								<CardBody>
									<h3 className="mb-2">Rules Information</h3>
									{renderConditionsOverview()}
									<h3 className="mt-4 mb-2">Recipient</h3>
									{renderRecipientOverview()}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default AccountFomConfirm;
