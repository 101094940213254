import { FieldConfig } from "./dataFrame";

export interface FieldConfigSource<TOptions extends object = any> {
  // Defaults applied to all numeric fields
  defaults: FieldConfig<TOptions>;
  [key: string]: any;
}

export enum FieldConfigProperty {
  Unit = 'unit',
  Min = 'min',
  Max = 'max',
  Decimals = 'decimals',
  DisplayName = 'displayName',
  NoValue = 'noValue',
  Thresholds = 'thresholds',
  Mappings = 'mappings',
  Links = 'links',
  Color = 'color',
}
