import { css } from '@emotion/css';

const color = {
	critical: '#e91e63',
	major: '#ff9800',
	minor: '#ffeb3b',
};
export const nav = css`
  display: flex;
  flex-wrap: nowrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  overflow: hidden;
  user-select: none;

  a {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
	font-size: .75rem;
  }

  a:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
  }

  span {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    margin-left: 5px;
  }

  .critical a {
    color: ${color.critical};

    span {
      color: #fff;
      background-color: ${color.critical};
    }

    &.active {
      color: #fff;
      background-color: ${color.critical};

      span {
        color: ${color.critical};
        background-color: #fff;
      }
    }
  }

  .major a {
    color: ${color.major};

    span {
      color: #fff;
      background-color: ${color.major};
    }

    &.active {
      color: #fff;
      background-color: ${color.major};

      span {
        color: ${color.major};
        background-color: #fff;
      }
    }
  }

  .minor a {
    color: #000;

    span {
      color: #000;
      background-color: ${color.minor};
    }

    &.active {
      color: #000;
      background-color: ${color.minor};

      span {
        color: #000;
        background-color: #fff;
      }
    }
  }
`;

export const list = css`
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  padding: .25em .25em;

  li {
    border-bottom: 1px solid #ddd;
    display: flex;
    padding: 10px 0;
  }

  .arrow {
    cursor: pointer;
    zoom: 0.7;
    margin-top: 5px;
    user-select: none;

    &:hover {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .arrow,
  .icon {
    padding: 0 20px;
  }

  .hidden {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .critical .icon {
    color: ${color.critical};
  }
  .major .icon {
    color: ${color.major};
  }
  .minor .icon {
    color: ${color.minor};
  }
`;

export const wrapper = css`
	margin: 10px;
`;
