import React from 'react';
import { Field } from 'formik';
import { Chips, ChipsProps } from 'primereact/chips';
import classNames from 'classnames';
import FormikFormGroup from '../FormikFormGroup';

export interface FormikChipsProps {
	[key: string]: any;
	name: string;
	label?: string;
	required?: boolean;
	row?: boolean;
	caption?: string;
	block?: boolean;
}

const FormikChips = (props: FormikChipsProps & ChipsProps) => {
	const { id, name, label, required, row, caption, block, ...inputProps } = props;

	const renderInput = ({ field, form, meta }) => {
		return (
			<FormikFormGroup
				id={id}
				name={name}
				label={label}
				field={field}
				form={form}
				meta={meta}
				row={row}
				required={required}
				caption={caption}
			>
				<Chips
					id={id}
					{...field}
					{...inputProps}
					className={classNames('p-inputtext-sm', {
						'p-invalid': Boolean(meta.touched && meta.error),
						'd-block': block
					})}
				/>
			</FormikFormGroup>
		);
	};


	return <Field name={name}>{renderInput}</Field>;
};

export default FormikChips;
