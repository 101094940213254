import { Button } from 'primereact/button';
import React from 'react';

type Props = {
	error?: any;
	resetErrorBoundary?(): void;
}

const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
	return (
		<div className='d-flex flex-column w-full h-full align-items-center justify-content-center p-5'>
			<i className='mdi mdi-alert text-danger' style={{ fontSize: 80 }}></i>
			<h1>An error occurred:</h1>
			<pre className='text-danger'>{error.message}</pre>
			<Button onClick={resetErrorBoundary}>Try again</Button>

		</div>
	)
}
export default ErrorFallback;
