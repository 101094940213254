import { useToasts } from 'app/components/Toast';
import { setIsEditingPage } from 'app/reducers/pageSlice';
import { locationService } from 'app/services/LocationService';
import { useAppDispatch } from 'app/store';
import { OverlayMenu, PrimeTable } from 'components';
import { Button } from 'primereact/button';
import { Column, ColumnBodyType, ColumnProps } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { useSiteDatatable } from '../hooks/useSiteDataTable';
import { useDeleteSiteMutation } from '../services/siteApi';
import { SiteData } from '../types';

const SiteManagementPage: React.FC = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setIsEditingPage(false)); // disable editing state in order to show add menu button
	}, [dispatch]);

	const toast = useToasts();

	const { data, isLoading, totalRecords, rows, first, filters, sortField, sortOrder, onPage, onSort, onFilter } =
		useSiteDatatable();

	const [selectedRows, setSelectedRows] = useState(null);
	const [globalFilter, setGlobalFilter] = useState(null);
	const dt = useRef<DataTable>(null);

	const history = locationService.getHistory();

	const _handleEdit = useCallback(
		(id: string) => {
			history.push({ pathname: `/site-management/edit/${id}` });
		},
		[history],
	);

	const [deleteSite] = useDeleteSiteMutation();

	const _handleDelete = useCallback(
		(id: string) => {
			confirmDialog({
				message: `Do you want to delete this user with ID: ${id}?`,
				header: 'Delete Confirmation',
				icon: 'pi pi-info-circle',
				acceptClassName: 'p-button-danger',
				accept: async () =>
					deleteSite(id)
						.unwrap()
						.then(() => {
							toast.toastSuccess('Success', `User Management deleted.`);
						})
						.catch((error) => {
							toast.toastError(error?.data?.error, error?.data?.message);
						}),
			});
		},
		[deleteSite, toast],
	);

	const columns: any = useMemo(() => {
		const actionTemplateBody = (rowData: SiteData): ColumnBodyType => {
			const items = [
				{
					label: 'Edit',
					icon: 'mdi mdi-pencil',
					command: () => _handleEdit(rowData.id),
				},
				{
					label: 'Delete',
					icon: 'mdi mdi-delete',
					command: () => _handleDelete(rowData.id),
				},
			];
			return (
				<React.Fragment>
					<OverlayMenu id={`overlay-menu-${rowData.id}`} items={items} />
				</React.Fragment>
			);
		};

		const mapInfo = (rowData: SiteData): ColumnBodyType => {
			const url = `https:www.google.com/maps/search/?api=1&query=${rowData.latitude}%2C${rowData.longitude}`;
			return (
				<>
					<a href={url} target="_blank" rel="noopener noreferrer">
						<i style={{ cursor: 'pointer' }} className="mdi mdi-map"></i>
					</a>
				</>
			);
		};

		return [
			{
				field: 'id',
				header: 'ID',
			},
			{
				field: 'name',
				header: 'SITE NAME',
			},
			{
				field: 'deviceId',
				header: 'Device ID',
			},
			{
				field: 'region',
				header: 'REGION',
			},

			{
				field: 'status',
				header: 'STATUS',
			},

			{
				header: 'MAP',
				body: mapInfo,
			},

			{
				headerStyle: {
					width: '5em',
				} as CSSProperties,
				body: actionTemplateBody,
				bodyStyle: {
					textAlign: 'center',
					overflow: 'visible',
				} as CSSProperties,
			},
		];
	}, [_handleEdit, _handleDelete]);

	const CreateNewUser = () => {
		history.push({
			pathname: '/site-management/new',
		});
	};

	const renderHeader = () => {
		return (
			<div className="table-header">
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						type="search"
						onInput={(e: any) => setGlobalFilter(e.target.value)}
						placeholder="Search"
					/>
				</span>

				<Button
					label="Add New"
					icon="mdi mdi-plus-circle"
					iconPos="right"
					className="p-button-link text-default"
					onClick={CreateNewUser}
				/>
			</div>
		);
	};

	const renderColumns = () => {
		return columns && columns.map((column: ColumnProps, i) => <Column key={i} {...column} />);
	};

	const header = renderHeader();

	return (
		<Container fluid>
			<h1 className="my-3">Site Management</h1>
			<div>
				<Card>
					<CardBody>
						<PrimeTable
							lazy
							ref={dt}
							value={data}
							header={header}
							dataKey="id"
							globalFilter={globalFilter}
							selection={selectedRows}
							onSelectionChange={(e) => setSelectedRows(e.value)}
							rows={rows}
							first={first}
							sortField={sortField}
							sortOrder={sortOrder}
							loading={isLoading}
							totalRecords={totalRecords}
							filters={filters}
							onPage={onPage}
							onSort={onSort}
							onFilter={onFilter}
						>
							{renderColumns()}
						</PrimeTable>
					</CardBody>
				</Card>
			</div>
		</Container>
	);
};

export default SiteManagementPage;
