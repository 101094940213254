import {  createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Panel } from './../types';
import { panelApi } from 'app/services/PanelService';

const panelAdapter = createEntityAdapter<Panel>();

interface PanelStates {
	status: string;
	panels?: any;
	selectedEndPoint:any
	initialValueEditForm?:any
	initialStatus?:string
}

const initialState = panelAdapter.getInitialState<PanelStates>({
	status: 'idle',
	panels: [],
	selectedEndPoint:{},
	initialValueEditForm:{},
	initialStatus:'idle'
});

const panelSlice = createSlice({
	name: 'panel',
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(panelApi.endpoints.getPanels.matchFulfilled, (state, { payload }) => {
			const panels = payload.content;
			panelAdapter.upsertMany(state, panels);
			state.status = 'success';
			state.panels = payload;
		});
		builder.addMatcher(panelApi.endpoints.getPanels.matchPending, (state, { payload }) => ({
			...state,
			status: 'loading',
		}));
		builder.addMatcher(panelApi.endpoints.getPanels.matchRejected, (state, { payload }) => ({
			...state,
			status: 'rejected',
		}));
		builder.addMatcher(panelApi.endpoints.getPanelEndPointApi.matchFulfilled, (state, {payload}) => ({
			...state,
			selectedEndPoint:payload
		}));
		builder.addMatcher(panelApi.endpoints.getSpecifiedPanelData.matchFulfilled, (state, {payload}) => ({
			...state,
			initialValueEditForm:payload,
			initialStatus:'success'
		}));
		builder.addMatcher(panelApi.endpoints.getSpecifiedPanelData.matchPending, (state, {payload}) => ({
			...state,
			initialStatus:'loading'
		}));
		builder.addMatcher(panelApi.endpoints.getSpecifiedPanelData.matchRejected, (state, {payload}) => ({
			...state,
			initialStatus:'rejected'
		}));
	},
});

export default panelSlice.reducer;
