import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UpdateGrid {
	x: number;
	w: number;
	y: number;
	h: number;
	i: string;
	static?: boolean;
}

interface Wrapper {
	updatedGrid: UpdateGrid[];
	isEditing?:boolean
}

const initialState: Wrapper = {
	updatedGrid: [],
	isEditing:false
};

const updateGridSlice = createSlice({
	name: 'updateGrid',
	initialState: initialState,
	reducers: {
		setGrid: (state, action: PayloadAction<UpdateGrid[]>): any => {
			state.updatedGrid = action.payload;
		},
		setIsEditing:(state, action):any =>({
			...state,
			isEditing: action.payload
		})
	},
});

export const { setGrid, setIsEditing } = updateGridSlice.actions;
export default updateGridSlice.reducer;
