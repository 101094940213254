import { Field } from 'formik';
import React from 'react';
import FormikFormGroup from '../FormikFormGroup';
import classNames from 'classnames';

type FormikInputFileProps = {
	[key: string]: any;
	className?: string;
	name: string;
	label?: string;
	required?: boolean;
	row?: boolean;
	caption?: string;
	url?: string;
    defaultImg?:any
};

const FormikInputFile: React.FC<FormikInputFileProps> = (props) => {
	const { className, id, name, label, required, row, caption, url, defaultImg, ...inputProps } = props;

	const renderInput = ({ field, form, meta }) => {
		return (
			<FormikFormGroup
				className={className}
				id={id}
				name={name}
				label={label}
				field={field}
				form={form}
				meta={meta}
				row={row}
				required={required}
				caption={caption}
			>
				<div className="d-flex justify-content-between">
				<input
					id={id}
					type="file"
					{...field}
					{...inputProps}
					className={classNames('p-inputtext-sm w-100', {
						'p-invalid': Boolean(meta.touched && meta.error),
					})}
				/>
                <img style={{maxWidth:'150px', maxHeight:'30px'}} src={defaultImg} loading='lazy' alt={name} />
				</div>
				
			</FormikFormGroup>
		);
	};

	return <Field name={name}>{renderInput}</Field>;
};

export default FormikInputFile;
