export type Status = 'critical' | 'major' | 'minor';
export enum AlertStatus {
	CRITICAL = 'critical',
	MAJOR = 'major',
	MINOR = 'minor',
}
export interface DataList {
	id: any;
	lastMessage: string;
	lastUpdated: string;
	severity: string;
	detailUrl: string;
}

export enum IconType {
	critical = 'alert',
	major = 'alert-octagon',
	minor = 'alert-box',
}
