import LoadingPage from 'app/components/LoadingPage';
import { FormikHelpers } from 'formik';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import AlertRuleForm, { defaultProps } from '../components/AlertRuleForm';
import { useGetAlertConfigurationQuery, useUpdateAlertConfigurationMutation } from '../services/alertApi';
import { AlertConfiguration, AlertConfigurationForm } from '../types';
import { locationService } from './../../../services/LocationService';


interface PageParams {
	id: string;
}
export interface Props { }

const EditAlertConfigurationPage = (props: Props) => {
	const params = useParams<PageParams>();
	const { data, isLoading } = useGetAlertConfigurationQuery(params.id, { skip: !params.id });
	const history = locationService.getHistory();

	const [updateAlert, { isLoading: isUpdating }] = useUpdateAlertConfigurationMutation();

	const initialValues = useMemo<AlertConfigurationForm>(() => {
		if (!data) return defaultProps.initialValues;

		const { channelRecipient, ...alert } = data;

		const getChannelRecipient = (channel: string): string[] => {
			if (channelRecipient.hasOwnProperty(channel)) {
				return channelRecipient[channel];
			}
			return [];
		};

		return {
			...alert,
			channelRecipient: Object.keys(channelRecipient).map((channel) => {
				return {
					channel: channel,
					recipients: getChannelRecipient(channel),
				};
			}),
		};
	}, [data]);

	React.useEffect(() => console.log(initialValues), [initialValues]);

	const onSubmit = (values: AlertConfiguration, formik: FormikHelpers<AlertConfigurationForm>): void => {
		updateAlert(values).unwrap().then(() => {
			history.push({
				pathname: '/alert-configuration',
			});
		});
	};

	if (isLoading) {
		return <LoadingPage />
	}

	return (
		<Container fluid>
			<h2 className="my-3">Alert Configuration</h2>
			<Card>
				<CardBody>
					<Row>
						<Col className="d-flex align-items-center" xs="12" md="6">
							<Breadcrumb listClassName="bg-white text-lg p-0 m-0">
								<BreadcrumbItem>
									<Link to="/alert-configuration/">Rules</Link>
								</BreadcrumbItem>
								<BreadcrumbItem active>Add New</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<AlertRuleForm
								initialValues={initialValues}
								onSubmit={onSubmit}
								isSubmitting={isUpdating}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default EditAlertConfigurationPage;
