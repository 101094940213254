import React, { useMemo } from 'react'
import ReactEcharts from 'echarts-for-react';
import { DeviceDataModel } from '../types';

export interface ChartSensorProps {
	className?: string;
	data?: DeviceDataModel[];
	isLoading?: boolean;
}

const ChartSensor: React.FC<ChartSensorProps> = ({ className, data, isLoading }) => {

	const option = useMemo(() => {
		const series: any[] = [];

		if (data && data.length > 0) {
			data.forEach((d) => {
				series.push({
					name: d.target,
					type: 'line',
					data: d.datapoints.map((dp) => [dp.time, dp.value]),
					symbol: 'none',
				})
			})
		}

		return {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross'
				}
			},
			grid: {
				left: '5%',
				right: '45%',
				top: '10%',
				bottom: '10%'
			},
			legend: {
				type: 'scroll',
				orient: 'vertical',
				right: 0,
				top: '10%',
				bottom: '10%',
				icon: 'roundRect'
			},
			xAxis: {
				type: 'time',
				boundaryGap: false,
				axisLabel: {
					formatter: {
						year: '{yyyy}',
						month: '{MMM} {yyyy}',
						day: '{d} {MMM}',
						hour: '{HH}:{mm}',
						minute: '{HH}:{mm}',
						second: '{HH}:{mm}:{ss}',
						millisecond: '{hh}:{mm}:{ss} {SSS}',
						none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
					}
				}
			},
			yAxis: {
				type: 'value',
				boundaryGap: [0, '100%'],
				axisPointer: {
					snap: true,
				},
				scale: true,
			},
			series: series,
			dataZoom: [
				{
					type: 'inside',
				},
			],
		}
	}, [data]);

	return (
		<div className={className} style={{ flex: '1 1 auto' }}>
			<ReactEcharts notMerge option={option} style={{ width: '100%' }} showLoading={isLoading} />
		</div>
	)
}

ChartSensor.displayName = 'ChartSensor'

export default ChartSensor
