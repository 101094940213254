import { selectPageByPath, setIsEditingPage } from 'app/reducers/pageSlice';
import { useAppDispatch, useAppSelector } from 'app/store';
import { OverlayMenu, PrimeTable } from 'components';
import { usePageInfo } from 'hooks/usePageInfo';
import { Column, ColumnBodyType, ColumnProps } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import FormAbnormalEventModal from '../components/FormAbnormalEventModal';
import { useAbnormalEventDatatable } from '../hooks/useAbnormalEventDatatable';
import { useCreateAbnormalEventMutation, useDeleteAbnormalEventMutation, useUpdateAbnormalEventMutation } from '../services/abnormalEventAPI';
import { AbnormalEvent, FormAbnormalEvent } from '../types';
import CustomTableHeader from './../../../../app/components/CustomTableHeader';
import { useToasts } from './../../../../app/components/Toast';
import { anchorBodyTemplate, dateBodyTemplate, LinkUrlOptions, textBodyTemplate } from './../../../components/DatatableBodyTemplates';

type Props = {}
interface PageConfig {
	table: {
		link: {
			_target: string;
			options: LinkUrlOptions;
		}
	}
}

const AbnormalEventListPage = (props: Props) => {

	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(setIsEditingPage(false)) // disable editing state in order to show add menu button
	},[dispatch])

	const toast = useToasts();
	const dtRef = useRef<DataTable>(null);
	const dt = useAbnormalEventDatatable();
	const [createAbnormalEvent, createResult] = useCreateAbnormalEventMutation();
	const [updateAbnormalEvent, updateResult] = useUpdateAbnormalEventMutation();
	const [deleteAbnormalEvent] = useDeleteAbnormalEventMutation();
	const [selectedData, setSelectedData] = useState<AbnormalEvent | undefined>(undefined);
	const page = useAppSelector((state) => selectPageByPath(state, 'abnormal-event'));
	const { pageInfo } = usePageInfo(page?.id);
	const config: PageConfig = useMemo(() => {
		if (!pageInfo) return null;

        try {
            return JSON.parse(pageInfo.componentConfig || '{}')
        } catch (error) {
            return {};
        }
	}, [pageInfo]);
	const [showFormModal, setShowFormModal] = useState(false);
	const header = <CustomTableHeader search onSearch={dt.onGlobalFilterChange} createButton onCreateButtonClick={() => setShowFormModal(true)} />;

	const _handleEdit = useCallback((data: AbnormalEvent) => {
		setSelectedData(data);
		setShowFormModal(true);
	}, []);

	const _handleDelete = useCallback((id: number) => {
		confirmDialog({
			message: `Deleted data can not be restored.`,
			header: 'Delete this abnormal event?',
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: async () => deleteAbnormalEvent(id).unwrap().then(() => {
				toast.toastSuccess('Success', `Abnormal event deleted.`);
			}).catch((error) => {
				toast.toastError(error?.data?.error, error?.data?.message);
			})
		});
	}, [deleteAbnormalEvent, toast]);

	const columns = useMemo<ColumnProps[]>(() => {
		const actionTemplateBody = (rowData: AbnormalEvent): ColumnBodyType => {
			const items = [
				{
					label: 'Edit',
					icon: 'mdi mdi-pencil',
					command: () => _handleEdit(rowData),
				},
				{
					label: 'Delete',
					icon: 'mdi mdi-delete',
					command: () => _handleDelete(rowData.id),
				},
			];
			return (
				<React.Fragment>
					<OverlayMenu id={`alert-rule-${rowData.id}`} items={items} />
				</React.Fragment>
			);
		};

		const renderBodyTemplate = (data: any, field: string, template: string) => {
			if (config?.table?.link && config?.table.link._target === field) {
				return anchorBodyTemplate(data, field, config?.table?.link?.options)
			}

			switch (template) {
				case 'textBodyTemplate':
					return textBodyTemplate(data, field)
				case 'dateBodyTemplate':
					return dateBodyTemplate(data, field)

				default:
					return data[field] || '-';
			}
		}

		return [
			{
				field: 'id',
				header: 'Anomaly ID',
				sortable: true,
				sortField: 'id',
				body: (data) => renderBodyTemplate(data, 'id', 'textBodyTemplate'),
			},
			{
				field: 'objectName',
				header: 'Object',
				sortable: true,
				sortField: 'objectName',
				body: (data) => renderBodyTemplate(data, 'objectName', 'textBodyTemplate'),
			},
			{
				field: 'startTs',
				header: 'From',
				sortable: true,
				sortField: 'startTs',
				body: (data) => renderBodyTemplate(data, 'startTs', 'dateBodyTemplate'),
			},
			{
				field: 'endTs',
				header: 'To',
				sortable: true,
				sortField: 'endTs',
				body: (data) => renderBodyTemplate(data, 'endTs', 'dateBodyTemplate'),
			},
			{
				field: 'userLabel',
				header: 'Label',
				sortable: true,
				sortField: 'userLabel',
				body: (data) => renderBodyTemplate(data, 'userLabel', 'textBodyTemplate'),
			},
			{
				field: 'userDescription',
				header: 'Description',
				body: (data) => renderBodyTemplate(data, 'userDescription', 'textBodyTemplate'),
			},
			{
				headerStyle: { width: '5em' },
				body: actionTemplateBody,
				bodyStyle: {
					textAlign: 'center',
					overflow: 'visible',
				},
			},
		];
	}, [_handleEdit, _handleDelete, config]);

	const renderColumns = () => {
		return columns && columns.map((column: ColumnProps, i) => <Column key={i} {...column} />);
	}

	const onSubmit = ({ startTs, endTs, ...data }: FormAbnormalEvent) => {
		console.log({ startTs: startTs?.getTime(), endTs: endTs?.getTime() });

		if (selectedData) {
			updateAbnormalEvent({
				...data,
				startTs: startTs?.getTime(),
				endTs: endTs?.getTime(),
			})
				.then(() => {
					setShowFormModal(false);
					setSelectedData(undefined);
					toast.toastSuccess('Information', 'Abnormal event updated.');
				});
		} else {
			createAbnormalEvent({
				...data,
				startTs: startTs?.getTime(),
				endTs: endTs?.getTime(),
			})
				.then(() => {
					setShowFormModal(false);
					toast.toastSuccess('Information', 'Abnormal event created.');
				});
		}
	}

	return (
		<Container fluid>
			<h2 className="my-3">Abnormal Event</h2>
			<div>
				<Card>
					<CardBody>
						<PrimeTable
							lazy
							ref={dtRef}
							header={header}
							value={dt.data}
							loading={dt.isLoading}
							dataKey="id"
							globalFilter={dt.globalFilter}
							rows={dt.rows}
							first={dt.first}
							sortField={dt.sortField}
							sortOrder={dt.sortOrder}
							totalRecords={dt.totalRecords}
							filters={dt.filters}
							onPage={dt.onPage}
							onSort={dt.onSort}
							onFilter={dt.onFilter}
						>
							{renderColumns()}
						</PrimeTable>
					</CardBody>
				</Card>
			</div>
			<FormAbnormalEventModal
				visible={showFormModal}
				onHide={() => setShowFormModal(false)}
				onSubmit={onSubmit}
				isLoading={createResult.isLoading || updateResult.isLoading}
				data={selectedData}
			/>
		</Container>
	)
}

export default AbnormalEventListPage;
