import { FormikProps, useFormik } from 'formik';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import { PrimeSteps } from 'components';
import AddPanelInfo from './AddPanelInfo';
import AddPanelConfirmation from './AddPanelConfirmation';
import { createBrowserHistory } from 'history';
import { Layout } from 'react-grid-layout';
import { useAppSelector } from 'app/store';
// eslint-disable-next-line
import { createPanel, allCreatePanelValue } from 'app/types';
import { useCreatePanelMutation, useEditPanelMutation, useGetComponentConfigQuery, useGetPanelEndPointApiQuery, useGetSpecifiedPanelDataQuery } from 'app/services/PanelService';
import Swal from 'sweetalert2'
import FullScreenLoading from 'app/components/FullScreenLoading';
type Props = {
	panelId:string
}
const AddPanel: FC<Props> = (props: Props) => {

	const {panelId} = props

	useGetSpecifiedPanelDataQuery(panelId, {skip:!panelId})

	const currentPanelData = useAppSelector(state => state.panel.initialValueEditForm)

	const selectedEndPoint = useAppSelector((state) => state.panel.selectedEndPoint)

	const isLoading = useAppSelector(state => state.panel.initialStatus)

	const configuredParamsEdit = useAppSelector(state => state.panel.initialValueEditForm?.endpointInfoConfig?.configuredParams)

	const history = createBrowserHistory();

	const { pageId } = useParams<any>();

	const formSteps = [{ label: 'PANEL INFORMATION' }, { label: 'CONFIRMATION' }];

	const [activeStep, setSelectedPanelctiveStep] = useState(0);

	const isLastStep = activeStep === formSteps.length - 1;

	const nextStep = () => {
		if (!isLastStep) {
			setSelectedPanelctiveStep((currentStep) => currentStep + 1);
		}
	};
	const prevStep = () => {
		if (activeStep > 0) {
			setSelectedPanelctiveStep((currentStep) => currentStep - 1);
		}
	};
	const renderForm = useCallback((step: number, formik: FormikProps<createPanel>) => {
		if(isLoading === 'loading'){
			return <FullScreenLoading />
		} else {
			switch (step) {
				case 1:
					return <AddPanelConfirmation formik={formik} />;
				default:
					return <AddPanelInfo setSelectedPanel={setSelectedPanel} activeStep={activeStep} formik={formik} panelId={panelId} />;
			}
		}

	}, [activeStep, panelId, isLoading]);

	const apiIdEdit = currentPanelData?.endpointInfoConfig?.endpointApi?.id

	const { data: endPointApiInit } = useGetPanelEndPointApiQuery(apiIdEdit, { skip: !apiIdEdit });

	const endPointParamsInit = useMemo(() =>
		endPointApiInit?.endpointApiParams?.map?.((e) => {

			let configuredParamsInit:any = []

			if(configuredParamsEdit){
				const parsed = JSON.parse(configuredParamsEdit)
				const newArray = Object.entries(parsed)
				configuredParamsInit = newArray

			}
			const tempArray = configuredParamsInit.map(el => {
				if(e.paramName === el[0]) {
					return el[1]
				} else {
					return undefined
				}
			})

			const paramValue = tempArray.find(el => {
				return el !== undefined
			})

			const newObj = {
				paramName: e?.paramName,
				paramType: e?.paramType,
				id: e?.id,
				paramValue:e?.paramType === 'REQUEST_BODY' && typeof e.paramValue !== 'string' ? JSON.stringify(paramValue, undefined, '\t') : paramValue
			};
			return newObj;
		}),
	[endPointApiInit, configuredParamsEdit])

	const currentConfig = useMemo(() => {
		if(currentPanelData?.componentConfig){
			const parsed = JSON.parse(currentPanelData?.componentConfig)
			return JSON.stringify(parsed, undefined, '\t')
		} else return ''
	},[currentPanelData])

	const initialValues = useMemo<createPanel>(() =>
		{
			if(panelId){
				return {
					title:currentPanelData?.title ? currentPanelData?.title : '',
					description:currentPanelData?.description ,
					panelComponentName:currentPanelData?.panelComponentName,
					componentConfig:currentConfig,
					endPointApis:currentPanelData?.endpointInfoConfig?.endpointApi?.name,
					endPointParams:endPointParamsInit && endPointParamsInit.length && endPointParamsInit.length > 0 ? endPointParamsInit : null
				}
			} else {
				return {
					title:'',
					description:'',
					panelComponentName:'',
					componentConfig:'',
					endPointApis:'',
					endPointParams:[]
				}
			}
		},
	 [currentPanelData, endPointParamsInit, panelId, currentConfig,]
	 )

	const validationSchema = Yup.object().shape({
		title: Yup.string().trim(),
		description: Yup.string().trim().required('please enter this field'),
		panelComponentName: Yup.string().trim().required("please select one component's name"),
		componentConfig: Yup.string().trim().required('please enter this field'),
		endPointApis: Yup.string().trim().required('please enter this field'),
	});
	const [createPanel] = useCreatePanelMutation()

	const [editPanel] = useEditPanelMutation()

	const formik = useFormik<createPanel>({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: () => {
			try {
				JSON.parse(formik.values.componentConfig)
				if (isLastStep) {
					if(panelId){
						editPanel({panelId, body : allValue})
							.unwrap()
							.then(() => history.goBack())
							.catch(() => Swal.fire({
								icon: 'error',
								title: 'Oops...',
								text: 'Something went wrong!',
							  }))
					} else {
						createPanel(allValue)
							.unwrap()
							.then(() => history.goBack())
							.catch(() => Swal.fire({
								icon: 'error',
								title: 'Oops...',
								text: 'Something went wrong!',
							  }))
					}

				} else {
					nextStep();
					formik.setTouched({});
					formik.setSubmitting(false);
				}
			} catch {
				formik.setFieldError('componentConfig', 'JSON format is not valid !')
			}
		},
		enableReinitialize:true,
	});

	const { data: componentConfig } = useGetComponentConfigQuery(formik.values.panelComponentName, {
		skip: !formik.values.panelComponentName,
	});

	const [selectedPanel, setSelectedPanel] = useState('')

	useEffect(() => {
		if(currentConfig && panelId){
			formik.setFieldValue('componentConfig', currentConfig)
		}
		if(componentConfig && selectedPanel){
			const configParsed = JSON.parse(componentConfig.defaultComponentConfig)
			const beautyComponentConfig = JSON.stringify(configParsed, undefined, '\t');
			formik.setFieldValue('componentConfig', beautyComponentConfig);
		}
		// eslint-disable-next-line
	},[currentConfig, componentConfig, panelId, selectedPanel])

	const gridPos: Layout = {
		x: 0,
		y: 99999,
		w: 5,
		h: 5,
		i: '1',
	};

	const configuredParams = useMemo(() => {
		let temp = {}
		formik.values.endPointParams?.forEach?.(e => {
			if(e.paramType === 'REQUEST_BODY') {
				try {
					temp[e.paramName] = JSON.parse(e.paramValue)
				} catch {
					temp[e.paramName] = e.paramValue
				}
				
			} else {
				temp[e.paramName] = e.paramValue
			}			
		})
		return temp
	},[formik.values.endPointParams])

	const allValue:allCreatePanelValue = useMemo(() =>{
		return {
			componentConfig:formik.values.componentConfig,
			description:formik.values.description,
			endpointInfoConfig:{
				configuredParams:JSON.stringify(configuredParams),
				endpointApi:{
					id:selectedEndPoint.id,
				},
				id:panelId ? currentPanelData?.endpointInfoConfig?.id : '',
				notes:''
			},
			gridLayoutConfig:panelId ? currentPanelData?.gridLayoutConfig : JSON.stringify(gridPos),
			id:panelId ? panelId : '',
			pageId:pageId,
			panelComponentName:formik.values.panelComponentName,
			title:formik.values.title,
		}
	}, [configuredParams, formik, currentPanelData, panelId, pageId, selectedEndPoint.id, gridPos ])

	return (
			<Form className="p-3" onSubmit={formik.handleSubmit}>
				<Row className="mt--1 mb-3">
					<Col>
						<PrimeSteps model={formSteps} activeIndex={activeStep} />
					</Col>
				</Row>
				{renderForm(activeStep, formik)}
				<Row className="d-flex justify-content-end pr-3">
					<Button
						type="button"
						label="Cancel"
						onClick={async () => {
							await history.goBack()
							formik.resetForm()
						}}
						className="mr-2 p-button-outlined"
					/>
					{activeStep > 0 && (
						<Button type="button" label="Previous" onClick={prevStep} className="mr-2 p-button-outlined" />
					)}
					<Button label={isLastStep ? 'Apply' : 'Next'} type="submit" />
				</Row>
			</Form>
		);


};
export default AddPanel;
